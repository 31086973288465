import { useEffect } from 'react';

import { getLoginHistory } from 'api/apiThunks';
import { selectDerivedUser } from 'api/usersSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ViewFrame } from 'features/frame/ViewFrame';
import { LoginHistoryGrid } from 'components/grids/LoginHistoryGrid';
import { getOperations } from 'utils/operable';

/**
 * The User Login History view
 */
export const LoginHistory = (): JSX.Element => {
  const user = useAppSelector(selectDerivedUser);
  const viewOperations = getOperations(user, ['recruiter', 'loginHistory']);
  const { userId, email, loginHistory = [] } = user;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userId && email && !viewOperations[1]) {
      dispatch(getLoginHistory({ userId, value: email }));
    }
  }, [dispatch, userId, email, viewOperations]);

  if (!userId) return <></>;

  return (
    <ViewFrame viewLoader={{ message: 'Loading login history', viewOperations }}>
      <LoginHistoryGrid loginHistory={loginHistory} />
    </ViewFrame>
  );
};
