import { getFriendlyError } from './apiResult';

const castArray = <T>(obj: T | T[]) => (Array.isArray(obj) ? obj : [obj]);

/**
 * Create an empty Operable object
 */
export function createOperable<OpName>(): Operable<OpName> {
  return {
    operationMap: {},
    keyedOperationMap: {},
  };
}

/**
 * Get the operation values for any number of operation types
 * @param operable The slice state with operations
 * @param opNames The operation types
 * @returns The operation values
 */
export function getOperations<OpName>(
  operable: Operable<OpName> | undefined,
  opNames: OpName | OpName[] = []
): Operation[] {
  opNames = castArray(opNames);
  return opNames.map((opName) => operable?.operationMap[opName]);
}

/**
 * Get the keyed operation values for many keys and any number of operation types
 * @param operable The slice state with operations
 * @param keys The keys to index into the operations data
 * @param opNames The operation types
 * @returns Lists of operation values, with one list for each key
 */
export function getKeyedOperations<OpName>(
  operable: Operable<OpName> | undefined,
  keys: string[] = [],
  opNames: OpName | OpName[] = []
): Operation[][] {
  const opNamesCast = castArray(opNames);
  return keys.map((key) => opNamesCast.map((opName) => operable?.keyedOperationMap[opName]?.[key]));
}

/**
 * Set an operation value, returning the data if completed.
 * @param operable The slice state with operations
 * @param opName The operation type
 * @param value The operation value
 * @param description A friendly description of the operation
 * @returns If the operation is completed successfully, the result data. Undefined otherwise.
 */
export function setOperation<OpName, T>(
  operable: Operable<OpName>,
  opName: OpName,
  value: ApiResult<T>,
  description: string
): T | undefined {
  operable.operationMap[opName] = {
    status: value.status,
    error: getFriendlyError(value, description),
  };

  if (value.status === 'succeeded') {
    return value.data;
  }
}

/**
 * Set a keyed operation value, returning the data if completed.
 * @param operable The slice state with operations
 * @param opName The operation type
 * @param key The key to index into the operations data
 * @param value The operation value
 * @param description A friendly description of the operation
 * @returns If the operation is completed successfully, the result data. Undefined otherwise.
 */
export function setKeyedOperation<OpName, T>(
  operable: Operable<OpName>,
  opName: OpName,
  key: string,
  value: ApiResult<T>,
  description: string
): T | undefined {
  if (!operable.keyedOperationMap[opName]) {
    operable.keyedOperationMap[opName] = {};
  }

  // Record should never be false, but TypeScript doesn't know that.
  const record = operable.keyedOperationMap[opName];
  if (record) {
    record[key] = {
      status: value.status,
      error: getFriendlyError(value, description),
    };
  }

  if (value.status === 'succeeded') {
    return value.data;
  }
}
