export const requiredField = (fieldValues: Record<string, unknown>, field: string) => {
  return !fieldValues[field] ? 'This field is required.' : '';
};

export const minCharactersField = (
  fieldValues: Record<string, unknown>,
  field: string,
  minChars: number
) => {
  return fieldValues[field] && (fieldValues[field] as string).length < minChars
    ? `Please enter at least ${minChars} characters`
    : '';
};

export const isValidRolloverEndDate = (
  skuRolloverTimeEnd: number,
  skuExpiryTime: number | undefined = 0
) => {
  return skuRolloverTimeEnd > skuExpiryTime
    ? 'Rollover End Date can not be greater than End Date'
    : '';
};
