import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Typography from '@mui/material/Typography';

import { FallbackProps } from 'react-error-boundary';

export const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps): JSX.Element => {
  return (
    <Alert
      severity="error"
      action={
        <IconButton color="inherit" onClick={() => resetErrorBoundary()}>
          <RestartAltIcon />
          <Typography>Reload</Typography>
        </IconButton>
      }
    >
      <AlertTitle>Admin Crash</AlertTitle>
      <Typography>
        {
          'This pane has crashed. You can try to reload the pane, or you can report the error to the '
        }
        <Link
          href="https://seekout-hq.slack.com/archives/C0421BQH72P"
          target="_blank"
          rel="noopener"
        >
          #admin-support
        </Link>
        {' slack channel'}
      </Typography>
      <Typography>
        <ArrowForwardIosIcon fontSize="small" />
        {error.stack?.split('\n').slice(0, 2)}
      </Typography>
    </Alert>
  );
};
