import { updateOrganization } from 'api/apiThunks';
import { selectDerivedOrganization } from 'api/organizationsSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { OrganizationForm } from 'components/organizationForm/OrganizationForm';
import { ViewFrame } from 'features/frame/ViewFrame';
import { useState } from 'react';
import { getOperations } from 'utils/operable';
import { getOrgParams } from 'utils/organization';

/**
 * The organization edit view
 */
export const Edit = (): JSX.Element => {
  const [operationType, setOperationType] = useState<OrgOpName>();
  const orgState = useAppSelector(selectDerivedOrganization);
  const { org } = orgState;

  const contentOperations = operationType ? getOperations(orgState, operationType) : [];
  const dispatch = useAppDispatch();

  const saveOrganization = async (formInput: IOrganization) => {
    setOperationType('updateSettings');
    const params: OrgParams = await getOrgParams(formInput);
    dispatch(updateOrganization(params));
  };

  if (!org) return <></>;

  return (
    <ViewFrame
      contentLoader={{
        message: 'Updating Organization',
        contentOperations,
        forceClose: !operationType,
        onClose: () => setOperationType(undefined),
      }}
    >
      <OrganizationForm
        initialValues={{ ...org } as Record<string, FormFieldValue>}
        onSubmit={saveOrganization}
      />
    </ViewFrame>
  );
};
