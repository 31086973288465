import { FormControlLabel, Switch, TextField } from '@mui/material';
import { AutoComplete } from 'components/AutoComplete';
import { DateSelector } from 'components/DateSelector';
import { FeatureSelector } from 'components/FeatureSelector';
import { FileInput } from 'components/FileInput';
import { Selector } from 'components/Selector';
import dayjs from 'dayjs';
import { ChangeEvent } from 'react';

export const FormField = ({
  type,
  name,
  label,
  placeHolder,
  onChange,
  error,
  helperText,
  defaultValue,
  options,
  ...additionalProps
}: FormFieldProps) => {
  if (type === 'toggle') {
    return (
      <FormControlLabel
        control={
          <Switch
            name={name}
            checked={defaultValue as boolean | undefined}
            onChange={onChange as ToggleChangeEvent}
          />
        }
        label={label}
        sx={{ width: 'fit-content' }}
        {...additionalProps}
      />
    );
  }

  if (type === 'dropdown') {
    return (
      <Selector
        label={label}
        options={options || []}
        value={defaultValue as string | string[]}
        setValue={(val) => {
          const event = { target: { name, type, value: val } };
          (onChange as InputChangeEvent)(event as ChangeEvent<HTMLInputElement>);
        }}
        sx={{ mt: 2, padding: 0 }}
        error={error}
        {...additionalProps}
        isDisabled={(additionalProps as Record<string, unknown>)['disabled'] as boolean | undefined}
      />
    );
  }

  if (type === 'autocomplete') {
    return (
      <AutoComplete
        label={label}
        options={options || []}
        value={defaultValue as string}
        setValue={(val: string) => {
          const event = { target: { name, type, value: val } };
          (onChange as InputChangeEvent)(event as ChangeEvent<HTMLInputElement>);
        }}
        sx={{ mt: 2, padding: 0 }}
        error={error}
        {...additionalProps}
        isDisabled={(additionalProps as Record<string, unknown>)['disabled'] as boolean | undefined}
      />
    );
  }

  if (type === 'date') {
    return (
      <DateSelector
        label={label}
        value={defaultValue ? dayjs(defaultValue as number | string) : null}
        setValue={(val) => {
          const event = { target: { name, type, value: val.valueOf().toString() } };
          (onChange as InputChangeEvent)(event as ChangeEvent<HTMLInputElement>);
        }}
        error={error}
        helperText={helperText}
        sx={{ margin: 0, mt: 2 }}
        {...additionalProps}
      />
    );
  }

  if (type === 'feature') {
    return (
      <FeatureSelector
        label={label}
        value={defaultValue as FeatureAccess}
        onChange={(val) => {
          const event = { target: { name, type, value: val.toString() } };
          (onChange as InputChangeEvent)(event as ChangeEvent<HTMLInputElement>);
        }}
        sx={{ borderBottom: 0, mt: 2 }}
      />
    );
  }

  if (type === 'file') {
    return (
      <FileInput
        name={name}
        label={label}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const event = { target: { name, type, files: e.target.files } };
          (onChange as InputChangeEvent)(event as ChangeEvent<HTMLInputElement>);
        }}
        {...additionalProps}
      />
    );
  }

  return (
    <TextField
      name={name}
      label={label}
      placeholder={placeHolder || label}
      size="medium"
      sx={{ width: '100%', mt: 2 }}
      onChange={onChange as InputChangeEvent}
      error={error}
      helperText={helperText}
      value={defaultValue ?? ''}
      type={type || 'text'}
      // Autocomplete is surprisingly annoying to disable. This is a way that seems to work.
      autoComplete="new-password"
      inputProps={type === 'number' ? { min: 0 } : {}}
      {...additionalProps}
    />
  );
};
