import { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

type Props = {
  placeholder: string;
  suggestions: SelectOption[];
  onSubmit: (value: string, isEnter: boolean) => void;
  onChange?: (value: string) => void;
  onClear?: () => void;
  label?: string;
  size?: 'small' | 'medium';
  disableSubmitReset?: boolean;
};

/**
 * Autocomplete search bar
 */
export const AutocompleteSearchBar = ({
  placeholder,
  suggestions,
  onSubmit,
  onChange,
  onClear,
  label,
  size = 'small',
  disableSubmitReset,
}: Props): JSX.Element => {
  const [inputValue, setInputValue] = useState('');

  const firstOption =
    inputValue.length > 0 ? `Press enter to use "${inputValue}"` : 'Begin typing to search';
  const options = [{ text: firstOption, value: '' }, ...suggestions];

  return (
    <Autocomplete
      freeSolo
      size={size}
      onChange={(event, newValue, reason) => {
        if (reason === 'createOption' && typeof newValue === 'string') {
          // Enter key pressed in textbox
          onSubmit(newValue, true);
        } else if (reason === 'selectOption' && newValue && typeof newValue === 'object') {
          onSubmit(newValue.value, false);
        } else if (reason === 'clear' && onClear) {
          onClear();
        }
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === 'reset' && disableSubmitReset) return;

        setInputValue(newInputValue);

        if (onChange) {
          onChange(newInputValue);
        }

        if (reason === 'clear' && onClear) {
          onClear();
        }
      }}
      options={options}
      getOptionLabel={(option) => {
        if (typeof option === 'string') return option;
        return option.text;
      }}
      getOptionDisabled={(option) => !option.value}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start" sx={{ color: 'inherit' }}>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
};
