import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

import { ConfirmModal } from 'components/modals/ConfirmModal';
import { uploadCsvFile } from 'utils/files';

const Input = styled('input')({ display: 'none' });
const Label = styled('label')({ alignSelf: 'center' });

/** A data row; ColumName -> Value */
type Row = Record<string, string>;
type OperationType = 'add' | 'delete';

interface Props {
  /** Id of file input control */
  id: string;
  /** Upload operation type */
  operationType: OperationType;
  /** Callback when upload operation state changes */
  onUploadOperation: (ops: Operation[]) => void;
  /** Callback when upload operation succeed */
  onUploadSuccess: () => void;
  /** Callback when confrim modal is accepted */
  onSubmit: (rows: Record<string, string>[]) => void;
  /** True if this entire component should be disabled */
  disabled?: boolean;
  /** Required csv columns (Domain, AllowStatus) */
  csvColumns?: string[];
  /** The text to used for the upload button, default: "Upload CSV" */
  buttonText?: string;
}

/**
 * A header component with CSV upload and dynamic column selectors
 */
export const UploadDomainsCsv = ({
  id,
  operationType,
  onUploadOperation,
  onUploadSuccess: onUploadSuccess,
  onSubmit,
  disabled,
  csvColumns = ['Domain'],
  buttonText = 'Upload CSV',
}: Props): JSX.Element => {
  // Confirm Modal
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalTitle, setConfirmModalTitle] = useState('');
  const [requiredColumns, setRequiredColumns] = useState<string[]>([]);
  const [csvRows, setCsvRows] = useState<Row[]>([]);

  const clearFileInput = () => {
    (document.getElementById(id) as HTMLInputElement).value = '';
  };

  const onCsvUploadSuccess = (newColumns: string[], newRows: Row[]) => {
    const missingColumns: string[] = [];
    for (const column of csvColumns) {
      if (!newColumns.includes(column)) {
        missingColumns.push(column);
      }
    }
    if (missingColumns.length > 0) {
      setRequiredColumns(missingColumns);
      clearFileInput();
      return;
    }
    setRequiredColumns([]);
    const rows = newRows.map((r) => ({
      domain: r['Domain'],
      allowStatus: r['AllowStatus'],
    }));
    setCsvRows(rows);
    const modalTitle =
      operationType === 'add'
        ? `Add ${newRows.length} domains to SMB allow / block list?`
        : `Delete ${newRows.length} domains from SMB allow / block list?`;
    setConfirmModalTitle(modalTitle);
    setConfirmModalOpen(true);
    onUploadSuccess();
    clearFileInput();
  };

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Label htmlFor={id}>
          <Input
            accept="text/csv"
            id={id}
            type="file"
            onChange={(e) => uploadCsvFile(e, onUploadOperation, onCsvUploadSuccess)}
          />
          <Button variant="contained" component="span" sx={{ minWidth: 125 }} disabled={disabled}>
            {buttonText}
          </Button>
        </Label>
        {requiredColumns.length > 0 && (
          <Typography variant="body2" color="error" sx={{ mt: 1, maxWidth: 220 }}>
            {`Could not process the file. The CSV file must have ${requiredColumns.join(
              ' and '
            )} column${requiredColumns.length > 1 ? 's' : ''} in it.`}
          </Typography>
        )}
      </Box>
      <ConfirmModal
        open={confirmModalOpen}
        prompt={confirmModalTitle}
        onClose={() => setConfirmModalOpen(false)}
        onAccept={() => {
          setConfirmModalOpen(false);
          onSubmit(csvRows);
        }}
      />
    </>
  );
};
