import Box from '@mui/material/Box';
import { clearAuth, selectDerivedAuth } from 'api/authSlice';
import { useAuthFetch } from 'api/apiMetaThunks';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { App } from 'App';
import { Loader } from 'components/Loader';
import { createFailedOperation } from 'utils/apiResult';
import { getOperations } from 'utils/operable';

export const AuthWrapper = (): JSX.Element => {
  const oldAuth = useAppSelector((state) => selectDerivedAuth(state, false));
  const newAuth = useAppSelector((state) => selectDerivedAuth(state, true));
  let operations = [...getOperations(oldAuth, 'apiUser'), ...getOperations(newAuth, 'apiUser')];

  useAuthFetch(oldAuth, newAuth);

  const dispatch = useAppDispatch();

  if (oldAuth.isAuthorized && newAuth.isAuthorized) {
    return <App />;
  }

  const oldApiNotAdmin = oldAuth.isAuthenticated && !oldAuth.isAuthorized;
  const newApiNotAdmin = newAuth.isAuthenticated && !newAuth.isAuthorized;

  let message: string | JSX.Element = 'Authenticating - you may be redirected to sign in via SSO';

  if (oldApiNotAdmin || newApiNotAdmin) {
    const displayString = oldApiNotAdmin ? oldAuth.displayString : newAuth.displayString;
    const apiVersion = oldApiNotAdmin ? 'Old' : 'New';
    operations = [
      createFailedOperation(
        `User ${displayString} does not have Admin permissions (${apiVersion} API)`
      ),
    ];
    message = (
      <div>
        <p>You are signed in but do not have Admin permissions</p>
        <p>If permissions were recently granted, there is a 15-minute delay</p>
        <p>
          You can click this button to retry:
          <button onClick={() => dispatch(clearAuth())}>Retry Authorization</button>
        </p>
      </div>
    );
  }

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        margin: '-8px',
        textAlign: 'center',
      }}
    >
      <Loader message={message} operations={operations} />
    </Box>
  );
};
