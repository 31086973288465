export enum WorkflowSetting {
  Off,
  On,
  Disabled,
}

export enum DiversitySetting {
  Off,
  On,
  EuropeAllOff,
  EuropeRaceOff,
  InternalTalentOff,
}

export enum SearchIndex {
  NorthAmerica = 'NorthAmerica',
  Europe = 'Europe',
  Asia = 'Asia',
  Africa = 'Africa',
  Oceania = 'Oceania',
  SouthAmerica = 'SouthAmerica',
  Public = 'Public',
  GitHub = 'GitHub',
  Healthcare = 'Healthcare',
  Expert = 'Expert',
  InternalTalent = 'Employee',
  ATS = 'ATS',
}

export enum IntegrationSetting {
  None = 'None',
  Export = 'Export Only',
  Connect = 'Rediscovery (Connect) + Export',
  Full = 'Rediscovery (Full) + Export',
}

export enum AuthType {
  OAuth = 'oauth',
  Custom = 'custom',
}

export enum SearchType {
  Public = 'resume',
  GitHub = 'github',
  Expert = 'academic',
  Healthcare = 'healthcare',
  InternalTalent = 'eto',
}

export enum PowerFiltersSearchType {
  Public = 'public',
  GitHub = 'github',
  Healthcare = 'healthcare',
  InternalTalent = 'eto',
}

export enum SkuType {
  Sku = 'sku',
  AddOn = 'addon',
  SignupOffer = 'offer',
}

export enum EmailQuality {
  Basic = 'Basic',
  Better = 'Better',
  Best = 'Best',
}

export enum RoiReportType {
  RoiReport = 'RoiReport',
  SeekOutActivityReport = 'SeekOutActivityReport',
}

export enum Privilege {
  Intermediate = 'Intermediate',
  All = 'All',
}

export enum AllowStatus {
  Allow = 'Allow',
  Block = 'Block',
}

//#region Helper Function

export const enumFromValue = <T extends Record<string, unknown>>(val: string, _enum: T) => {
  const entry = Object.entries(_enum).find(([, value]) => value === val);
  return entry ? entry[0] : val;
};

//#endregion
