import { useCallback, useEffect, useState } from 'react';

import { bulkDeleteUser } from 'api/apiMetaThunks';
import { clearOperations, selectDerivedOrganization } from 'api/organizationsSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { StandardGrid } from 'components/grids/StandardGrid';
import { ViewFrame } from 'features/frame/ViewFrame';
import { anyFailedResult, anyLoadingResult, opAllKeyedSkippedOrSuccess } from 'utils/apiResult';
import { asCleanEmail } from 'utils/convert';
import { UploadMappingHeader } from 'components/headers/UploadMappingHeader';
import { getKeyedOperations, getOperations } from 'utils/operable';
import { getSFOrderItemsOperations } from 'utils/organization';

/**
 * The organization bulk delete users view
 */
export const DeleteUsers = (): JSX.Element => {
  // Upload Data
  const [uploadValues, setUploadValues] = useState<string[]>([]);

  // Processing state
  const [mode, setMode] = useState('prep' as 'prep' | 'upload' | 'delete' | 'errorCheck' | 'done');
  const [uploadOperations, setUploadOperations] = useState<Operation[]>([]);
  const [processingUserIds, setProcessingUserIds] = useState<string[]>([]);

  const org = useAppSelector(selectDerivedOrganization);
  const viewOperations = [...getOperations(org, 'users'), ...getSFOrderItemsOperations(org)];
  const deleteContentOperations = getKeyedOperations(org, processingUserIds, 'deleteUser').flat();
  const { orgId, users = [], licenseMap = {} } = org;

  const contentOperations = mode === 'upload' ? uploadOperations : deleteContentOperations;

  const dispatch = useAppDispatch();

  const complete = useCallback(() => {
    if (orgId) {
      dispatch(clearOperations({ orgId, opNames: ['removeLicense', 'deleteUser'] }));
    }
    setMode('done');
  }, [dispatch, orgId]);

  useEffect(() => {
    if (mode === 'delete') {
      bulkDeleteUser(dispatch, org, processingUserIds, () => setMode('errorCheck'));
    } else if (mode === 'errorCheck') {
      if (opAllKeyedSkippedOrSuccess(org)) {
        complete();
      }
    }
  }, [dispatch, mode, org, processingUserIds, complete]);

  if (!orgId) return <></>;

  const operationMessage = mode === 'upload' ? 'Uploading CSV' : 'Deleting Users';
  const operationOnClose = mode === 'upload' ? () => setMode('prep') : complete;

  let message = '';
  const removeSet = new Set(uploadValues.map((x) => x?.toLowerCase()).filter((x) => !!x));
  const duplicateCount = uploadValues.length - removeSet.size;

  if (duplicateCount) {
    message += `${duplicateCount} duplicate entries. `;
  }

  const orgUsers = users.filter((orgUser) =>
    removeSet.has(asCleanEmail(orgUser.emailAddress)?.toLowerCase() || '')
  );
  const unmatchedCount = removeSet.size - orgUsers.length;

  if (unmatchedCount) {
    message += `${unmatchedCount} unmatched rows. `;
  }

  const isProcessing = mode === 'upload' || mode === 'delete' || mode === 'errorCheck';
  const canSubmit = mode === 'prep' && !!orgUsers.length;

  return (
    <ViewFrame
      viewLoader={{
        message: 'Loading users and licenses for the organization. This might take a minute.',
        viewOperations,
      }}
      header={
        <UploadMappingHeader
          onUploadOperation={(ops) => {
            setUploadOperations(ops);
            setMode(anyLoadingResult(ops) || anyFailedResult(ops) ? 'upload' : 'prep');
          }}
          onChange={(values) => {
            setUploadValues(values.map((x) => x['Email']));
            setMode('prep');
          }}
          onSubmit={() => {
            setProcessingUserIds(orgUsers.map((orgUser) => orgUser.userId));
            setMode('delete');
          }}
          fields={[
            {
              name: 'Email',
              description: 'The email of the user to delete',
              validation: 'email',
            },
          ]}
          disabled={isProcessing}
          canSubmit={canSubmit}
          confirmMessage={`Permanently delete ${orgUsers.length} users and all associated data?`}
          warningMessage={message}
          submitText="Delete Users"
          csvTemplate="Bulk-Delete-Users-Template"
        />
      }
      contentLoader={{
        message: operationMessage,
        contentOperations,
        forceClose: !isProcessing,
        onClose: operationOnClose,
      }}
    >
      <StandardGrid
        dataSet={orgUsers}
        tipModel="OrgUser"
        getRowId={(x) => x.userId}
        getOpenAction={(x) =>
          x.newUserId ? { email: x.emailAddress, type: 'NewUser' } : { id: x.userId, type: 'User' }
        }
        filterPlaceholder="Filter the displayed users. (Warning: filtered-out users will still be deleted)"
        cols={[
          {
            name: 'Name',
            valueProperty: 'fullName',
          },
          {
            name: 'Email',
            valueProperty: 'emailAddress',
            type: 'email',
          },
          {
            name: 'Team',
            valueProperty: 'assignedTeam',
            getValue: (x) => x.assignedTeam?.name,
          },
          {
            name: 'License',
            getValue: (x) => {
              const license = licenseMap[x.userId];
              if (license) {
                return `${license.orderNumber}: ${license.skuName} (${license.endDate})`;
              }
              if (x.currentSkuName) return `None (${x.currentSkuName})`;
              return 'None';
            },
          },
        ]}
      />
    </ViewFrame>
  );
};
