import { useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import { alpha, styled } from '@mui/material/styles';

const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  flexGrow: 1,
  marginLeft: theme.spacing(2),

  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.secondary.main, 0.15),
  '&:hover': { backgroundColor: alpha(theme.palette.secondary.main, 0.25) },
}));

const SearchInput = styled(OutlinedInput)(({ theme }) => ({
  color: 'inherit',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  input: {
    padding: theme.spacing(1),
  },
}));

interface Props {
  onChange?: (val: string) => void;
  onSubmit?: (val: string) => void;
  placeholder?: string;
}

/**
 * A generic Search Bar component
 */
export const SearchBar = ({ onChange, onSubmit, placeholder }: Props): JSX.Element => {
  const [searchText, setSearchText] = useState('');

  return (
    <Wrapper>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (onSubmit) onSubmit(searchText.trim());
        }}
      >
        <SearchInput
          value={searchText}
          autoComplete="off"
          placeholder={placeholder || 'Search...'}
          inputProps={{
            role: 'searchbox',
            'aria-label': 'search',
            name: 'text',
          }}
          startAdornment={<SearchIcon />}
          endAdornment={
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: searchText ? 'visible' : 'hidden' }}
              onClick={() => {
                setSearchText('');
                if (onChange) onChange('');
              }}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          }
          fullWidth
          onChange={(e) => {
            setSearchText(e.target.value);
            if (onChange) onChange(e.target.value);
          }}
        />
      </form>
    </Wrapper>
  );
};
