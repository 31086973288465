import { useEffect, useState } from 'react';

import { getMonitorCategories, getMonitorLogs } from 'api/apiThunks';
import { selectDerivedSystem } from 'api/systemSlice';
import { selectDerivedUser } from 'api/usersSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { MonitorGrid } from 'components/grids/MonitorGrid';
import { MonitorHeader } from 'components/headers/MonitorHeader';
import { ViewFrame } from 'features/frame/ViewFrame';
import { getOperations } from 'utils/operable';

/**
 * The User monitor view
 */
export const Monitor = (): JSX.Element => {
  const [forceCloseLoader, setForceCloseLoader] = useState(true);

  const system = useAppSelector(selectDerivedSystem);
  const { clientAdminLogs = false, admins = [], monitorCategories = {}, monitorLogs = [] } = system;
  const viewOperations = getOperations(system, 'monitorCategories');
  const contentOperations = getOperations(system, 'monitorLogs');

  const { userId } = useAppSelector(selectDerivedUser);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!viewOperations[0]) {
      dispatch(getMonitorCategories(clientAdminLogs));
    }
  }, [dispatch, viewOperations, clientAdminLogs]);

  if (!userId) return <></>;

  const categories = Object.fromEntries(
    Object.entries(monitorCategories).filter(([, filters]) => filters.includes('userId'))
  );

  return (
    <ViewFrame
      viewLoader={{ message: 'Loading monitor log categories', viewOperations }}
      header={
        <MonitorHeader
          admins={admins}
          monitorCategories={categories}
          onSubmit={(options) => {
            setForceCloseLoader(false);
            dispatch(
              getMonitorLogs({
                clientAdminLogs,
                logOptions: options,
              })
            );
          }}
          userId={userId}
        />
      }
      contentLoader={{
        message: 'Loading monitor logs',
        contentOperations,
        forceClose: forceCloseLoader,
        onClose: () => setForceCloseLoader(true),
      }}
    >
      <MonitorGrid monitorLogs={monitorLogs} />
    </ViewFrame>
  );
};
