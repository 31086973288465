import { useEffect, useState } from 'react';

import { getAtsCandidateStatus, getATSReports } from 'api/apiThunks';
import { selectDerivedOrganization } from 'api/organizationsSlice';
import { selectDerivedSystem } from 'api/systemSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ColumnContent } from 'components/columns/ColumnContent';
import { MapTable } from 'components/columns/MapTable';
import { TextControlHeader } from 'components/headers/TextControlHeader';
import { ViewFrame } from 'features/frame/ViewFrame';
import { createFailedOperation } from 'utils/apiResult';
import { asTimeDeltaDisplayString } from 'utils/convert';
import { getOperations } from 'utils/operable';
import { Box } from '@mui/material';
import { Selector } from 'components/Selector';
import { getDownloadedAtsTypeOptions } from 'utils/selectorOptions';

/**
 * The Organization ATS Report view
 */
export const AtsReport = (): JSX.Element => {
  const [operationKey, setOperationKey] = useState('');
  const [selectedAtsType, setSelectedAtsType] = useState('');

  const system = useAppSelector(selectDerivedSystem);
  const viewOperations = getOperations(system, 'atsReports');
  const [getAtsReportsOp] = viewOperations;
  const { atsReports = [] } = system;

  const org = useAppSelector(selectDerivedOrganization);
  const contentOperations = getOperations(org, 'atsCandidateStatus');
  const { orgId, atsCandidateStatus: candidate } = org;
  const orgAtsReports = (atsReports ?? []).filter((x) => x.userId === orgId);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!getAtsReportsOp) {
      dispatch(getATSReports());
    }
  }, [dispatch, getAtsReportsOp]);

  useEffect(() => {
    if (orgAtsReports.length > 0 && selectedAtsType === '') {
      setSelectedAtsType(orgAtsReports[0].ats);
    }
  }, [orgAtsReports, selectedAtsType]);

  if (!orgId) return <></>;

  const atsReport = orgAtsReports.find((x) => x.ats === selectedAtsType);

  // Block the view on ATS report not found
  if (getAtsReportsOp?.status === 'succeeded' && !atsReport) {
    viewOperations.push(createFailedOperation('No ATS Report found for this Organization'));
  }

  const atsId = atsReport?.id ?? '';

  // Try to parse out the error blob into something cleaner to display
  let error = {} as unknown;
  if (atsReport?.message) {
    try {
      error = JSON.parse(atsReport.message);
    } catch (e) {
      error = atsReport.message;
    }
  }

  return (
    <ViewFrame
      viewLoader={{ message: 'Loading ATS Report', viewOperations }}
      header={
        <Box>
          <TextControlHeader
            label="Candidate Id"
            tooltip="Find the ATS download status for this candidate"
            validationType="string"
            submitText="Find"
            onSubmit={(candidateId: string) => {
              setOperationKey(candidateId);
              dispatch(getAtsCandidateStatus({ orgId, candidateId, atsId }));
            }}
          />
          <Selector
            label="ATS Type"
            options={getDownloadedAtsTypeOptions(orgAtsReports)}
            value={selectedAtsType}
            setValue={(val) => setSelectedAtsType(val as string)}
            sx={{ minWidth: 200 }}
          />
        </Box>
      }
      contentLoader={{
        message: `Loading Candidate Status for ${operationKey}`,
        contentOperations,
        forceClose: !operationKey,
        onClose: () => setOperationKey(''),
      }}
    >
      <ColumnContent
        columns={[
          <MapTable
            title="ATS Report"
            defaultModel="ATSReport"
            defaultValue={atsReport}
            data={{
              'ATS Name': 'ats',
              'Candidates Downloaded': 'totalDownloadedCandidates',
              'Candidates Indexed': 'totalIndexedCandidates',
              'Candidates Matched': 'totalMatchedCandidates',
              Jobs: 'totalJobs',
              Attachments: 'totalAttachments',
              'Average Response Time': 'averageResponseTime',
            }}
          />,
          <MapTable
            title="ATS Report: Last Download"
            defaultModel="ATSReport"
            defaultValue={atsReport}
            data={{
              'Full Download': 'isFullDownload',
              Start: 'startTS',
              Duration: {
                value: asTimeDeltaDisplayString(atsReport?.startTS, atsReport?.endTS),
                keyTooltip: 'The time spent running the last download',
              },
              Status: {
                value:
                  !atsReport?.error || Object.keys(atsReport?.error).length === 0
                    ? 'Completed'
                    : 'Failed',
              },
              Error: {
                value: error,
              },
            }}
          />,
          <MapTable
            title="Candidate Status"
            defaultModel="ATSCandidateStatus"
            defaultValue={candidate}
            data={{
              ID: 'id',
              'First Download': 'firstDownloadTS',
              'Last Download': 'lastDownloadTS',
              'Last Indexed': 'lastIndexedTS',
              'Matched Profile': 'matchedProfile',
              'Job List': 'jobList',
              Error: 'error',
            }}
          />,
        ]}
      />
    </ViewFrame>
  );
};
