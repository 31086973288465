import UAParser from 'ua-parser-js';

import { StandardGrid } from 'components/grids/StandardGrid';
import { asDateISOString } from 'utils/convert';

interface Props {
  loginHistory?: ILoginHistory[];
}

/**
 * A filterable DataGrid that displays login history objects
 */
export const LoginHistoryGrid = ({ loginHistory = [] }: Props): JSX.Element => {
  return (
    <StandardGrid
      dataSet={loginHistory}
      tipModel="LoginHistory"
      getRowId={(x) => x.id}
      filterPlaceholder="Filter the results"
      cols={[
        {
          name: 'Timestamp',
          description: 'The time the activity occurred',
          valueProperty: 'dateCreated',
          type: 'date',
        },
        {
          name: 'Type',
          description: 'The type of login attempt',
          getValue: (x) => (x.isSso ? 'SSO' : x.isImpersonation ? 'Impersonation' : 'Password'),
          relativeWidth: 0.5,
        },
        {
          name: 'Success',
          valueProperty: 'success',
          type: 'boolean',
          relativeWidth: 0.5,
        },
        {
          name: 'Result',
          description: 'The result of the login attempt',
          valueProperty: 'message',
        },
        {
          name: 'Browser',
          valueProperty: 'userAgent',
          getValue: (x) => {
            const { browser, os, ua } = UAParser(x.userAgent);
            if (!browser.name) return ua;
            return `${browser.name} ${browser.version} on ${os.name} ${os.version}`;
          },
        },
        {
          name: 'IP',
          description: 'The IP address of the login attempt',
          valueProperty: 'ip',
          relativeWidth: 0.5,
        },
        {
          name: 'Other',
          description: 'The other properties logged with the event',
          getValue: (x) => {
            const result: Record<string, unknown> = {};
            const set = (name: string, val: unknown) => (val ? (result[name] = val) : undefined);

            set('Password Fails', x.passwordFailedCount);
            set('Lockout Start', asDateISOString(x.passwordLockoutTS));

            let ssoAction = x.ssoUserCreated ? 'User Created' : '';
            ssoAction = x.ssoNameUpdated ? 'Name Updated' : ssoAction;
            ssoAction = x.ssoMigrateFromEmail
              ? `Migrated from ${x.ssoMigrateFromEmail}`
              : ssoAction;
            set('SSO Action', ssoAction);

            set('User', x.mappedUserId);
            set('Recruiter', x.mappedRecruiterId);
            set('Organization', x.mappedOrganizationId);
            set('Impersonator', x.impersonatorUserId);
            set('User Agent', x.userAgent);

            return result;
          },
          type: 'blob',
        },
      ]}
    />
  );
};
