import Button from '@mui/material/Button';
import { useState } from 'react';
import startCase from 'lodash/startCase';

import { getMessagingHistory } from 'api/apiThunks';
import { selectDerivedUser } from 'api/usersSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Selector } from 'components/Selector';
import { StandardGrid } from 'components/grids/StandardGrid';
import { ViewFrame } from 'features/frame/ViewFrame';
import { ActivityTypes } from 'utils/enums';
import { getOperations } from 'utils/operable';

const activityTypeOptions = [{ value: 'all', text: 'All Message Types' }].concat(
  ActivityTypes.map((x) => ({ value: x, text: startCase(x) }))
);

/**
 * The user messaging history view
 */
export const MessagingHistory = (): JSX.Element => {
  const [activityType, setActivityType] = useState('all');
  const [forceCloseLoader, setForceCloseLoader] = useState(true);

  const user = useAppSelector(selectDerivedUser);
  const contentOperations = getOperations(user, 'messagingHistory');
  const { userId, messagingHistory = [] } = user;

  const dispatch = useAppDispatch();

  if (!userId) return <></>;

  return (
    <ViewFrame
      header={
        <>
          <Selector
            label="Find events with this activity type (max 1000)"
            options={activityTypeOptions}
            value={activityType}
            setValue={(val) => setActivityType(val as string)}
          />

          <Button
            variant="contained"
            onClick={() => {
              setForceCloseLoader(false);
              dispatch(getMessagingHistory({ userId, value: activityType }));
            }}
            sx={{ marginTop: (theme) => theme.spacing(2) }}
          >
            Find
          </Button>
        </>
      }
      contentLoader={{
        message: 'Getting Messaging History',
        contentOperations,
        forceClose: forceCloseLoader,
        onClose: () => setForceCloseLoader(true),
      }}
    >
      <StandardGrid
        dataSet={messagingHistory}
        tipModel="EmailActivity"
        getRowId={(x) => x.id}
        filterPlaceholder="Filter the results"
        cols={[
          {
            name: 'Timestamp',
            description: 'The time the activity occurred',
            valueProperty: 'dateCreated',
            type: 'date',
          },
          {
            name: 'Activity Type',
            valueProperty: 'activityType',
            getValue: (x) => startCase(x.activityType),
            getTooltip: (x) => x.activityType,
          },
          {
            name: 'Operation',
            description: 'The operation being performed',
            valueProperty: 'operation',
          },
          {
            name: 'Properties',
            description: 'The arbitrary bag of properties logged with the event',
            innerModel: 'EmailActivity',
            valueProperty: 'props',
            type: 'blob',
            relativeWidth: 5,
          },
        ]}
      />
    </ViewFrame>
  );
};
