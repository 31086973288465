import { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getOrganizationsByDomainContains, getLogout } from 'api/apiThunks';
import { selectDerivedAuth } from 'api/authSlice';
import { AutocompleteSearchBar } from 'components/AutocompleteSearchBar';
import {
  removeAutocompleteSearchResults,
  addLookupTab,
  selectAutocompleteSearchResults,
} from 'features/frame/appViewSlice';
import logo4 from 'images/seekout-logo.svg';
import { asSplitName } from 'utils/convert';

interface HeaderProps {
  onClick?: () => void;
}

/**
 * The header bar across the top of the application
 */
export const Header = ({ onClick }: HeaderProps): JSX.Element => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [inputValue, setInputValue] = useState('');

  const { apiUser } = useAppSelector((state) => selectDerivedAuth(state, true));
  const autoCompleteResults = useAppSelector(selectAutocompleteSearchResults) ?? [];

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (inputValue.length >= 3) {
      const handler = setTimeout(
        () => dispatch(getOrganizationsByDomainContains({ value: inputValue })),
        500
      );
      return () => clearTimeout(handler);
    } else {
      dispatch(removeAutocompleteSearchResults());
    }
  }, [inputValue, dispatch]);

  // Menu variables and functions
  const isMenuOpen = Boolean(menuAnchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setMenuAnchorEl(null);
  };
  const handleLogout = () => {
    handleClose();
    dispatch(getLogout(true));
    dispatch(getLogout(false));
  };

  const suggestions = autoCompleteResults.map((x) => ({
    value: x.id,
    text: `${x.name} (${x.domain})`,
  }));

  // Get initials of first and last name
  function getInitials() {
    const fullName = apiUser?.name;
    const { firstName, lastName } = asSplitName(fullName);
    const initials = (firstName ? firstName[0] : '') + (lastName ? lastName[0] : '');
    return initials || 'N/A';
  }

  return (
    <AppBar position="static">
      <Toolbar variant="dense">
        <img src={logo4} />
        <Typography variant="h6" sx={{ mx: 1, paddingTop: 0.25 }}>
          | admin
        </Typography>
        <Box
          sx={{
            flexGrow: 1,
            marginLeft: 2,
            backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.15),
            '&:hover': {
              backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.25),
            },
            '.MuiAutocomplete-inputRoot': {
              color: (theme) => theme.palette.secondary.main,
            },
            '.MuiAutocomplete-clearIndicator': {
              color: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          <AutocompleteSearchBar
            placeholder={'Search for a user or organization'}
            suggestions={suggestions}
            onSubmit={(value, isEnter) => {
              dispatch(addLookupTab(isEnter ? value : { id: value, type: 'Organization' }));
              if (onClick) onClick();
            }}
            onChange={(value) => setInputValue(value)}
            disableSubmitReset
          />
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Avatar sx={{ bgcolor: (theme) => theme.palette.secondary.light, width: 35, height: 35 }}>
          <Button
            id="basic-button"
            aria-label="account of current user"
            aria-controls={isMenuOpen ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isMenuOpen ? 'true' : undefined}
            onClick={handleClick}
          >
            {getInitials()}
          </Button>
        </Avatar>
        <Menu
          id="basic-menu"
          anchorEl={menuAnchorEl}
          open={isMenuOpen}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};
