import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { addExtraReducers } from 'api/slice';
import {
  deleteUser,
  getNewUser,
  getOrganization,
  getRecruiter,
  getUser,
  getOrganizationsByDomainContains,
  deleteOrganization,
} from 'api/apiThunks';
import { RootState } from 'app/store';
import { createLookup, isLookupMatch } from 'utils/lookup';
import { asCleanEmail, asFullName } from 'utils/convert';

/* Selectors */

/**
 * Return the all of the current lookups
 */
export function selectLookupTabs(state: RootState): Lookup[] {
  return state.appView.lookupTabs;
}

/**
 * Return the index of the selected lookup tab
 */
export function selectCurrentLookupTabIndex(state: RootState): number {
  return state.appView.currentLookupTabIndex;
}

/**
 * Return the currently selected lookup tab
 */
export function selectCurrentLookupTab(state: RootState): Lookup {
  return state.appView.lookupTabs[state.appView.currentLookupTabIndex];
}

/**
 * Return autocomplete search results
 */
export function selectAutocompleteSearchResults(state: RootState) {
  return state.appView.autocompleteSearchResults;
}

/* Reducers */

interface AppViewState {
  lookupTabs: Lookup[];
  currentLookupTabIndex: number;
  autocompleteSearchResults?: IOrganization[];
}

const initialState: AppViewState = {
  lookupTabs: [{ name: 'System', type: 'SYSTEM' }],
  currentLookupTabIndex: 0,
};

export const appViewSlice = createSlice({
  name: 'appView',
  initialState,
  reducers: {
    addLookupTab: (state, action: PayloadAction<string | Lookup>) => {
      const lookup =
        typeof action.payload === 'string' ? createLookup(action.payload) : action.payload;

      if (lookup) {
        lookup.email = asCleanEmail(lookup.email);

        const existingIndex = state.lookupTabs.findIndex((lookupTab) =>
          isLookupMatch(lookup, lookupTab)
        );

        if (existingIndex >= 0) {
          state.currentLookupTabIndex = existingIndex;
        } else {
          state.lookupTabs.push(lookup);
          state.currentLookupTabIndex = state.lookupTabs.length - 1;
        }
      }
    },
    removeLookupTab: (state, action: PayloadAction<number>) => {
      removeLookup(state, action.payload);
    },
    setCurrentLookupTabIndex: (state, action: PayloadAction<number>) => {
      const index = action.payload;
      if (state.lookupTabs[index]) {
        state.currentLookupTabIndex = index;
      }
    },
    setContentIndex: (state, action: PayloadAction<number>) => {
      const lookup = state.lookupTabs[state.currentLookupTabIndex];
      const index = action.payload;
      lookup.contentIndex = index;
    },
    setContentSubIndex: (state, action: PayloadAction<number>) => {
      const lookup = state.lookupTabs[state.currentLookupTabIndex];
      const index = action.payload;

      if (!lookup.contentSubIndices) {
        lookup.contentSubIndices = {};
      }

      lookup.contentSubIndices[lookup.contentIndex || 0] = index;
    },
    removeAutocompleteSearchResults: (state) => {
      delete state.autocompleteSearchResults;
    },
  },
  extraReducers: (builder) => {
    addExtraReducers(getOrganization, updateForOrg, builder);
    addExtraReducers(getNewUser, updateForNewUser, builder);
    addExtraReducers(getUser, updateForUser, builder);
    addExtraReducers(getRecruiter, updateForRecruiter, builder);
    addExtraReducers(deleteUser, removeUserLookup, builder);
    addExtraReducers(getOrganizationsByDomainContains, setOrganizationsByDomainContains, builder);
    addExtraReducers(deleteOrganization, removeOrgLookup, builder);
  },
});

export const {
  addLookupTab,
  removeLookupTab,
  setCurrentLookupTabIndex,
  setContentIndex,
  setContentSubIndex,
  removeAutocompleteSearchResults,
} = appViewSlice.actions;

export const appViewReducer = appViewSlice.reducer;

/* Helpers */

function getLookupIndex(state: AppViewState, matcher?: Lookup) {
  if (matcher) {
    return state.lookupTabs.findIndex((x) => isLookupMatch(x, matcher));
  }
}

function getLookup(state: AppViewState, matcher?: Lookup) {
  const index = getLookupIndex(state, matcher);
  if (index != undefined && index >= 0) {
    return state.lookupTabs[index];
  }
}

function removeLookup(state: AppViewState, index: number) {
  if (state.lookupTabs[index]) {
    state.lookupTabs.splice(index, 1);

    if (state.currentLookupTabIndex >= index) {
      state.currentLookupTabIndex--;
    }
  }
}

function finalizeNewUser(lookup: Lookup, original: Lookup) {
  if (
    lookup.type === 'NewUser' ||
    (lookup.userStatus === 'failed' && lookup.newUserStatus === 'succeeded')
  ) {
    lookup.id = original.id || lookup.newUserInProgressData?.id;
    lookup.email = original.email || lookup.newUserInProgressData?.email;
    lookup.name = lookup.newUserInProgressData?.name;
    lookup.type = 'NewUser';
  }
}

function updateForOrg(state: AppViewState, lookup: Lookup, result: ApiResult<IOrganization>) {
  const tab = getLookup(state, lookup);
  if (!tab) return;

  if (result.status !== 'succeeded' || !result.data) return;

  // Finalize Org type
  const org = result.data;
  tab.id = org.id;
  tab.domain = org.domain;
  tab.name = org.name;
  tab.type = 'Organization';
}

function updateForNewUser(
  state: AppViewState,
  lookup: Lookup,
  wrappedResult: ApiResult<{ results: INewUser[] }>
) {
  const tab = getLookup(state, lookup);
  if (!tab) return;

  // The API returns an array of length 1 on success, null on failure
  // Convert here to single value
  const innerResults = wrappedResult.data?.results;
  const result = { ...wrappedResult, data: innerResults ? innerResults[0] : undefined };

  tab.newUserStatus = result.status;

  if (result.status !== 'succeeded' || !result.data) return;
  if (tab.userStatus === 'succeeded') return;

  const newUser = result.data;

  const name = asFullName(newUser.firstName, newUser.lastName);

  tab.newUserInProgressData = {
    id: newUser.id,
    email: newUser.email,
    name,
  };

  finalizeNewUser(tab, lookup);
}

function updateForUser(state: AppViewState, lookup: Lookup, result: ApiResult<IUser>) {
  const tab = getLookup(state, lookup);
  if (!tab) return;

  tab.userStatus = result.status === 'succeeded' ? 'loading' : result.status;

  finalizeNewUser(tab, lookup);
}

function updateForRecruiter(
  state: AppViewState,
  lookup: Lookup,
  result: ApiResult<IRecruiterInfo>
) {
  const tab = getLookup(state, lookup);
  if (!tab) return;

  tab.userStatus = result.status;

  finalizeNewUser(tab, lookup);

  if (result.status !== 'succeeded' || !result.data) return;

  // Finalize User type
  const recruiter = result.data.recruiter;
  tab.id = lookup.id || recruiter.userId;
  tab.email = lookup.email || recruiter.primaryEmail;
  tab.name = recruiter.name;
  tab.type = 'User';
}

function removeUserLookup(
  state: AppViewState,
  { userId, newUserId }: ParamsBase,
  result: ApiResult<boolean>
) {
  if (result.status === 'succeeded' && result.data) {
    let index: number | undefined;

    if (userId) {
      index = getLookupIndex(state, { id: userId });
    }

    if (newUserId && index == undefined) {
      index = getLookupIndex(state, { id: newUserId });
    }

    if (index != undefined && index >= 0) {
      removeLookup(state, index);
    }
  }
}

function setOrganizationsByDomainContains(
  state: AppViewState,
  _: ValueParams,
  result: ApiResult<IOrganization[]>
) {
  if (result.status === 'succeeded') {
    state.autocompleteSearchResults = result.data;
  }
}

function removeOrgLookup(state: AppViewState, { orgId }: ParamsBase, result: ApiResult<unknown>) {
  if (result.status === 'succeeded') {
    let index: number | undefined;

    if (orgId) {
      index = getLookupIndex(state, { id: orgId });
    }

    if (index != undefined && index >= 0) {
      removeLookup(state, index);
    }
  }
}
