import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  addAdminUser,
  getAdminUsers,
  getATSReports,
  getATSSupported,
  getSSOHistory,
  deleteAdminUser,
  getSkuV1,
  getSkuV2,
  getLoginHistory,
  getMonitorLogs,
  getMonitorCategories,
  getTooltips,
  postTooltip,
  getAtsConnections,
  updateAtsConnection,
  createOrganization,
  createOrUpdateAtsMeta,
  deleteAtsMeta,
  getExportFormatFields,
  getSystemExportFormats,
  createOrUpdateExportFormat,
  deleteExportFormat,
  updateExportFormatField,
  deleteExportFormatField,
  createOrUpdateSkuV1,
  createOrUpdateSkuV2,
  deleteSkuV2,
  deleteSkuV1,
  getEmailTrackingReport,
  updateAdminUserPrivilege,
  getUserInfo,
  deleteUserInfo,
  addSmbDomain,
  getSmbDomains,
  deleteSmbDomain,
  getInactiveSmbUsers,
} from 'api/apiThunks';
import { addExtraReducers } from 'api/slice';
import { RootState } from 'app/store';
import { createOperable, setKeyedOperation, setOperation } from 'utils/operable';

/* Selectors */

/**
 * Select data, operation state, and precalculated state for the system
 * @param state The root redux state
 * @returns The derived system data and operation state
 */
export function selectDerivedSystem(state: RootState): DerivedSystem {
  const system = state.system;
  const { tooltips = [] } = system;

  return {
    ...system,
    operationMap: system?.operationMap ?? {},
    keyedOperationMap: system?.keyedOperationMap ?? {},
    tooltipMap: Object.fromEntries(tooltips.map((tip) => [tip.model, tip])),
  };
}

/* Reducers */

export const systemSlice = createSlice({
  name: 'system',
  initialState: createOperable() as System,
  reducers: {
    clearSystemCache: (state: System) => {
      clearSystem(state);
    },
    setClientAdminLogs: (state: System, action: PayloadAction<boolean | undefined>) => {
      state.clientAdminLogs = action.payload;
    },
    clearMonitorLogs: (state: System) => {
      state.monitorLogs = [];
    },
    clearOperations: (state, action: PayloadAction<{ opNames: SystemOpName[] }>) => {
      const { opNames } = action.payload;
      opNames.forEach((opName) => {
        state.operationMap[opName] = undefined;
        state.keyedOperationMap[opName] = {};
      });
    },
  },
  extraReducers: (builder) => {
    addExtraReducers(getAdminUsers, setAllAdmin, builder);
    addExtraReducers(createOrganization, setCreateOrganization, builder);
    addExtraReducers(getATSSupported, setATSSupported, builder);
    addExtraReducers(getAtsConnections, setAtsConnections, builder);
    addExtraReducers(getATSReports, setATSReports, builder);
    addExtraReducers(addAdminUser, setAddAdminPermissions, builder);
    addExtraReducers(updateAdminUserPrivilege, setUpdateAdminPermissions, builder);
    addExtraReducers(deleteAdminUser, setRemoveAdminPermissions, builder);
    addExtraReducers(getSSOHistory, setSSOHistory, builder);
    addExtraReducers(getLoginHistory, setLoginHistory, builder);
    addExtraReducers(getSkuV1, setSkuV1s, builder);
    addExtraReducers(createOrUpdateSkuV1, setUpdateSkuV1, builder);
    addExtraReducers(deleteSkuV1, setDeleteSkuV1, builder);
    addExtraReducers(getSkuV2, setSkuV2s, builder);
    addExtraReducers(createOrUpdateSkuV2, setUpdateSkuV2, builder);
    addExtraReducers(deleteSkuV2, setDeleteSkuV2, builder);
    addExtraReducers(getMonitorLogs, setMonitorLogs, builder);
    addExtraReducers(getMonitorCategories, setMonitorCategories, builder);
    addExtraReducers(getTooltips, setTooltips, builder);
    addExtraReducers(postTooltip, setTooltip, builder);
    addExtraReducers(updateAtsConnection, setUpdateAtsConnection, builder);
    addExtraReducers(createOrUpdateAtsMeta, setUpdateATSMeta, builder);
    addExtraReducers(deleteAtsMeta, setDeleteATSMeta, builder);
    addExtraReducers(getExportFormatFields, setExpotFormatFields, builder);
    addExtraReducers(getSystemExportFormats, setExportFormats, builder);
    addExtraReducers(createOrUpdateExportFormat, setUpdateExportFormat, builder);
    addExtraReducers(deleteExportFormat, setDeleteExportFormat, builder);
    addExtraReducers(updateExportFormatField, setUpdateExportFormatField, builder);
    addExtraReducers(deleteExportFormatField, setDeleteExportFormatField, builder);
    addExtraReducers(getEmailTrackingReport, setEmailTrackings, builder);
    addExtraReducers(getUserInfo, setUserInfo, builder);
    addExtraReducers(deleteUserInfo, setDeleteUserInfo, builder);
    addExtraReducers(getSmbDomains, setSmbDomains, builder);
    addExtraReducers(getInactiveSmbUsers, setInactiveSmbUsers, builder);
    addExtraReducers(addSmbDomain, setAddSmbDomain, builder);
    addExtraReducers(deleteSmbDomain, setDeleteSmbBlockedDomain, builder);
  },
});

export const { clearSystemCache, setClientAdminLogs, clearMonitorLogs, clearOperations } =
  systemSlice.actions;

export const systemReducer = systemSlice.reducer;

function setAllAdmin(state: System, lookup: void, result: ApiResult<IAdminUser[]>) {
  state.admins = setOperation(state, 'admins', result, 'Get Admin Information');
}

function setCreateOrganization(state: System, org: OrgParams, result: ApiResult<IOrganization>) {
  setOperation(state, 'createOrganization', result, 'Create Organization');
}

function setATSSupported(state: System, lookup: void, result: ApiResult<IATSMeta[]>) {
  state.atsSupported = setOperation(state, 'atsSupported', result, 'Get ATS Supported');
}

function setUpdateATSMeta(state: System, input: AtsMetaParams, result: ApiResult<IATSMeta>) {
  const atsMeta = setOperation(state, 'updateATSMeta', result, 'Update ATS Meta');

  if (atsMeta && state.atsSupported) {
    const index = state.atsSupported.findIndex((ats) => ats.id === atsMeta.id);
    if (index > -1) {
      state.atsSupported[index] = atsMeta;
    } else {
      state.atsSupported.push(atsMeta);
    }
  }
}

function setDeleteATSMeta(
  state: System,
  { value: atsMetaId }: ValueParams,
  result: ApiResult<boolean>
) {
  const success = setOperation(state, 'deleteATSMeta', result, 'Delete ATS Meta');

  if (state.atsSupported && success) {
    const index = state.atsSupported.findIndex((x) => x.id === atsMetaId);
    if (index > -1) {
      state.atsSupported.splice(index, 1);
    }
  }
}

function setAtsConnections(
  state: System,
  orgId: string | undefined,
  result: ApiResult<IATSConnection[]>
) {
  // If orgId was provided, then this was organization-specific
  if (!orgId) {
    state.atsConnections = setOperation(state, 'atsConnections', result, 'Get ATS Connections');
  }
}

function setATSReports(state: System, lookup: void, result: ApiResult<IATSReport[]>) {
  state.atsReports = setOperation(state, 'atsReports', result, 'Get ATS Reports');
}

function setAddAdminPermissions(state: System, email: string, result: ApiResult<IAdminUser>) {
  const description = `Add Admin Permissions for ${email}`;
  const admin = setOperation(state, 'addAdmin', result, description);

  if (state.admins && admin) {
    state.admins.push(admin);
  }
}

function setUpdateAdminPermissions(
  state: System,
  { email, actualUserId }: AdminUserParams,
  result: ApiResult<IAdminUser>
) {
  const description = `Update Admin Privilege for ${email}`;
  const admin = setOperation(state, 'updateAdmin', result, description);

  if (state.admins && admin) {
    const index = state.admins.findIndex((x) => x.actualUserId === actualUserId);
    if (index > -1) {
      state.admins[index] = admin;
    }
  }
}

function setRemoveAdminPermissions(state: System, adminId: string, result: ApiResult<boolean>) {
  const success = setOperation(state, 'removeAdmin', result, 'Remove Admin Permissions');

  if (state.admins && success) {
    const index = state.admins.findIndex((x) => x.id === adminId);

    if (index >= 0) {
      state.admins.splice(index, 1);
    }
  }
}

function setSSOHistory(
  state: System,
  { value: email }: ValueParams,
  result: ApiResult<ISSOHistory[]>
) {
  const description = `Get SSO History (${email})`;
  state.ssoHistory = setOperation(state, 'ssoHistory', result, description);
}

function setLoginHistory(
  state: System,
  { value: email }: ValueParams,
  result: ApiResult<ILoginHistory[]>
) {
  const description = `Get Login History (${email})`;
  state.loginHistory = setOperation(state, 'loginHistory', result, description);
}

function setSkuV1s(state: System, lookup: void, result: ApiResult<ISku[]>) {
  state.skuV1 = setOperation(state, 'skuV1', result, 'Get V1 Sku list');
}

function setUpdateSkuV1(state: System, arg: ResourceParams<ISku>, result: ApiResult<ISku>) {
  const sku = setOperation(state, 'saveSku', result, 'Save Sku (V1)');

  if (state.skuV1 && sku) {
    if (sku.features) {
      // sku is upgraded to V3
      const v2Sku = <ISkuV2>sku;
      v2Sku.isNew = true;
      // remove from skuV1 list
      let index = state.skuV1.findIndex((x) => x.id === sku.id);
      if (index > -1) {
        state.skuV1.splice(index, 1);
      }
      // add to skuV2 list
      if (state.skuV2) {
        index = state.skuV2.findIndex((x) => x.id === sku.id);
        if (index > -1) {
          state.skuV2[index] = v2Sku;
        } else {
          state.skuV2.push(v2Sku);
        }
      }
    } else {
      const index = state.skuV1.findIndex((x) => x.id === sku.id);
      if (index > -1) {
        state.skuV1[index] = sku;
      } else {
        state.skuV1.push(sku);
      }
    }
  }
}

function setDeleteSkuV1(state: System, arg: ResourceParams<ISku>, result: ApiResult<ISku>) {
  const sku = setOperation(state, 'deleteSku', result, 'Delete Sku (V1)');

  if (state.skuV1 && sku) {
    const index = state.skuV1.findIndex((x) => x.id === sku.id);
    if (index > -1) {
      state.skuV1.splice(index, 1);
    }
  }
}

function setSkuV2s(state: System, lookup: void, result: ApiResult<ISkuV2[]>) {
  state.skuV2 = setOperation(state, 'skuV2', result, 'Get V2 Sku list');
}

function setUpdateSkuV2(state: System, arg: ResourceParams<ISkuV2>, result: ApiResult<ISkuV2>) {
  const sku = setOperation(state, 'saveSku', result, 'Save Sku (V2)');

  if (state.skuV2 && sku) {
    sku.isNew = true;
    const index = state.skuV2.findIndex((x) => x.id === sku.id);
    if (index > -1) {
      state.skuV2[index] = sku;
    } else {
      state.skuV2.push(sku);
    }
  }
}

function setDeleteSkuV2(state: System, skuId: string, result: ApiResult<boolean>) {
  const success = setOperation(state, 'deleteSku', result, 'Delete Sku (V2)');

  if (state.skuV2 && success) {
    const index = state.skuV2.findIndex((x) => x.id === skuId);
    if (index > -1) {
      state.skuV2.splice(index, 1);
    }
  }
}

function setMonitorCategories(
  state: System,
  clientAdmin: boolean,
  result: ApiResult<MonitorCategories>
) {
  state.monitorCategories = setOperation(
    state,
    'monitorCategories',
    result,
    'Get Monitor Categories'
  );
}

function setMonitorLogs(
  state: System,
  { logOptions }: MonitorLogParams,
  result: ApiResult<MonitorLog[]>
) {
  const description = `Get Monitor Logs (${logOptions.category})`;
  state.monitorLogs = setOperation(state, 'monitorLogs', result, description);
}

function setTooltips(state: System, arg: void, result: ApiResult<TooltipDefinition[]>) {
  state.tooltips = setOperation(state, 'tooltips', result, 'Get tooltip definitions');
}

function setTooltip(state: System, input: TooltipInput, result: ApiResult<TooltipDefinition>) {
  const tooltip = setOperation(state, 'updateTooltip', result, 'Update tooltip definition');

  if (tooltip && state.tooltips) {
    const index = state.tooltips.findIndex((tip) => tip.id === tooltip.id);
    if (index >= 0) {
      state.tooltips[index] = tooltip;
    } else {
      state.tooltips.push(tooltip);
    }
  }
}

function clearSystem(system: System) {
  const operableKeys = Object.keys(createOperable());
  const dataKeys = Object.keys(system).filter((key) => !operableKeys.includes(key));

  for (const key of operableKeys) {
    system[key as keyof Operable<SystemOpName>] = {};
  }

  for (const key of dataKeys) {
    delete system[key as keyof System];
  }
}

function setUpdateAtsConnection(
  state: System,
  input: ResourceParams<AtsConnectionInput>,
  result: ApiResult<IATSConnection>
) {
  // No call to setOperation because this wasn't a system-based operation
  const atsConnection = result.status === 'succeeded' ? result.data : undefined;

  if (state.atsConnections && atsConnection) {
    const index = state.atsConnections.findIndex((x) => x.id === atsConnection.id);

    if (index >= 0) {
      state.atsConnections[index] = atsConnection;
    }
  }
}

function setExpotFormatFields(state: System, arg: void, result: ApiResult<string[]>) {
  state.exportFormatFields = setOperation(
    state,
    'exportFormatFields',
    result,
    'Get export format fields'
  );
}

function setExportFormats(state: System, arg: void, result: ApiResult<IExportFormat[]>) {
  state.exportFormats = setOperation(state, 'exportFormats', result, 'Get Export Formats');
}

function setUpdateExportFormat(
  state: System,
  arg: ResourceParams<IExportFormat>,
  result: ApiResult<IExportFormat>
) {
  const exportFormat = setOperation(state, 'addExportFormat', result, 'Add Export Format');

  if (state.exportFormats && exportFormat) {
    const index = state.exportFormats.findIndex((x) => x.id === exportFormat.id);
    if (index > -1) {
      state.exportFormats[index] = exportFormat;
    } else {
      state.exportFormats.push(exportFormat);
    }
  }
}

function setDeleteExportFormat(
  state: System,
  { value: exportFormatId }: ValueParams,
  result: ApiResult<boolean>
) {
  const success = setOperation(state, 'deleteExportFormat', result, 'Delete Export Format');

  if (state.exportFormats && success) {
    const index = state.exportFormats.findIndex((x) => x.id === exportFormatId);
    if (index > -1) {
      state.exportFormats.splice(index, 1);
    }
  }
}

function setUpdateExportFormatField(
  state: System,
  arg: ExportFormatFieldParams,
  result: ApiResult<IExportFormat>
) {
  const exportFormat = setOperation(
    state,
    'updateExportFormatField',
    result,
    'Update Export Format Field'
  );

  if (state.exportFormats && exportFormat) {
    const index = state.exportFormats.findIndex((x) => x.id === exportFormat.id);
    if (index > -1) {
      state.exportFormats[index] = exportFormat;
    }
  }
}

function setDeleteExportFormatField(
  state: System,
  { exportFormatId }: Partial<ExportFormatFieldParams>,
  result: ApiResult<IExportFormat>
) {
  const exportFormat = setOperation(
    state,
    'deleteExportFormatField',
    result,
    'Delete Export Format Field'
  );

  if (state.exportFormats && exportFormat) {
    const index = state.exportFormats.findIndex((x) => x.id === exportFormatId);
    if (index > -1) {
      state.exportFormats[index] = exportFormat;
    }
  }
}

function setEmailTrackings(
  state: System,
  args: DateRangeParams,
  result: ApiResult<IEmailTracking[]>
) {
  state.emailTrackings = setOperation(state, 'emailTrackings', result, 'Get Email Tracking Report');
}

function setUserInfo(state: System, userEmail: string, result: ApiResult<UserInfo>) {
  state.userInfo = setOperation(state, 'userInfo', result, 'Get User Information');
}

function setDeleteUserInfo(state: System, userEmail: string, result: ApiResult<boolean>) {
  const success = setOperation(state, 'deleteUserInfo', result, 'Deleting User Information');
  if (success && state.userInfo) {
    state.userInfo.accountInfo = undefined;
  }
}

function setSmbDomains(state: System, arg: void, result: ApiResult<ISmbDomain[]>) {
  state.smbDomains = setOperation(state, 'smbDomains', result, 'Get SMB Blocked Domains');
}

function setInactiveSmbUsers(state: System, arg: void, result: ApiResult<INewUser[]>) {
  state.inactiveSmbUsers = setOperation(
    state,
    'inactiveSmbUsers',
    result,
    'Get Inactive SMB Users'
  );
}

function setAddSmbDomain(
  state: System,
  { resource }: ResourceParams<ISmbDomain>,
  result: ApiResult<ISmbDomain>
) {
  const smbBlockedDomain = setKeyedOperation(
    state,
    'addSmbDomain',
    resource?.userId || '',
    result,
    'Add SMB Domain'
  );
  if (state.smbDomains && smbBlockedDomain) {
    state.smbDomains.push(smbBlockedDomain);
  }
}

function setDeleteSmbBlockedDomain(
  state: System,
  { value: domain }: ValueParams,
  result: ApiResult<boolean>
) {
  const success = setKeyedOperation(
    state,
    'deleteSmbDomain',
    domain,
    result,
    `Delete SMB Domain ${domain}`
  );
  if (state.smbDomains && success) {
    const index = state.smbDomains.findIndex((x) => x.userId === domain);
    if (index > -1) {
      state.smbDomains.splice(index, 1);
    }
  }
}
