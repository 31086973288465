import { Box, Button, SxProps, Theme } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { Tip } from 'components/Tip';
import { useEffect, useState } from 'react';
import { Feature, FeatureAccess } from 'types/server/common/enums';
import { FeatureRadioGroup } from './FeatureRadioGroup';
import { enumFromValue } from 'types/enums';

interface Props {
  label: string;
  value?: FeatureAccess;
  onSave: (value: FeatureAccess) => void;
  tipModel?: string;
  tipProperty?: string;
  sx?: SxProps<Theme>;
  setSensitivitySettingsOpen: (value: boolean) => void;
  disabled?: boolean;
}

const targetedFeature = enumFromValue(Feature.InternaltalentNewView.toString(), Feature);
/**
 * An org level FeatureAccess selection control
 */
export const OrgFeatureSelector = ({
  label,
  value,
  onSave,
  tipModel,
  tipProperty,
  sx,
  setSensitivitySettingsOpen,
  disabled,
}: Props): JSX.Element => {
  const [featureValue, setFeatureValue] = useState<FeatureAccess | undefined>(value);

  useEffect(() => setFeatureValue(value), [value]);
  const isInternalTalentView = label === targetedFeature;
  const showInternalTalentSettings = featureValue !== FeatureAccess.Off && isInternalTalentView;

  return (
    <FormControl sx={{ borderBottom: 1, borderStyle: 'groove', ...sx }}>
      <Tip model={tipModel} property={tipProperty}>
        <FormLabel sx={{ color: 'inherit', '&.Mui-focused': { color: 'inherit' } }}>
          {label}
        </FormLabel>
      </Tip>
      <Box sx={{ display: 'flex' }}>
        <FeatureRadioGroup
          value={featureValue}
          onChange={(val) => setFeatureValue(val)}
          disabled={disabled}
        />
        <Button
          variant="contained"
          size="small"
          disabled={value === featureValue}
          onClick={() => onSave(featureValue ?? FeatureAccess.InheritSku)}
          sx={{ height: 'fit-content' }}
        >
          Apply
        </Button>
      </Box>
      {showInternalTalentSettings ? (
        <Button onClick={() => setSensitivitySettingsOpen(true)}>
          Configurable Data Sensitivity Settings
        </Button>
      ) : null}
    </FormControl>
  );
};
