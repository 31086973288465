import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import dayjs from 'dayjs';
import { useState } from 'react';

import { getOrgSearchHistory } from 'api/apiThunks';
import { selectDerivedOrganization } from 'api/organizationsSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { DateSelector } from 'components/DateSelector';
import { StandardGrid } from 'components/grids/StandardGrid';
import { ViewFrame } from 'features/frame/ViewFrame';
import { getOperations } from 'utils/operable';

/**
 * The search history view
 */
export const SearchHistory = (): JSX.Element => {
  const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day'));
  const [endDate, setEndDate] = useState(dayjs());
  const [forceCloseLoader, setForceCloseLoader] = useState(true);

  const org = useAppSelector(selectDerivedOrganization);
  const contentOperations = getOperations(org, 'searchHistory');
  const { orgId, users, searchHistory } = org;

  const dispatch = useAppDispatch();

  if (!orgId) return <></>;

  const disableSubmit = !startDate.isValid() || !endDate.isValid();

  const orgUsers = users?.reduce(
    (prev, cur) => ({
      ...prev,
      [cur.userId]: cur,
    }),
    {} as Record<string, IOrgUser>
  );

  // Each searchHistory item maybe represent multiple queries
  // Flatten them out such that historyItem.queryList always contains a single entry
  const data =
    searchHistory?.flatMap((historyItem) =>
      historyItem.queryList.map((query, index) => ({
        ...historyItem,
        id: `${historyItem.id}-${index}`,
        fullName: orgUsers?.[historyItem.userId].fullName,
        teamName: orgUsers?.[historyItem.userId].assignedTeam?.name,
        queryList: [query],
      }))
    ) ?? [];

  const fetcher = () => {
    const params = {
      orgId,
      startDate: startDate.startOf('day').toISOString(),
      endDate: endDate.endOf('day').toISOString(),
    };

    setForceCloseLoader(false);
    dispatch(getOrgSearchHistory(params));
  };

  return (
    <ViewFrame
      header={
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <DateSelector label="Start Date" value={startDate} setValue={setStartDate} />
          <DateSelector label="End Date" value={endDate} setValue={setEndDate} />
          <Button variant="contained" disabled={disableSubmit} onClick={fetcher}>
            Search
          </Button>
        </Box>
      }
      contentLoader={{
        message: 'Getting Search History',
        contentOperations,
        forceClose: forceCloseLoader,
        onClose: () => setForceCloseLoader(true),
      }}
    >
      <StandardGrid
        dataSet={data}
        tipModel="SearchHistory"
        getRowId={(x) => x.id}
        filterPlaceholder="Filter the results"
        getOpenAction={(x) => ({ id: x.userId, type: 'User' })}
        cols={[
          {
            name: 'User',
            valueProperty: 'emailAddress',
            getValue: (x) => x.emailAddress || x.userId,
          },
          {
            name: 'Name',
            getValue: (x) => x.fullName,
            hide: orgUsers === undefined,
          },
          {
            name: 'Team',
            getValue: (x) => x.teamName,
            hide: orgUsers === undefined,
          },
          {
            name: 'Type',
            valueProperty: 'searchType',
            relativeWidth: 0.5,
          },
          {
            name: 'Search',
            valueProperty: 'name',
          },
          {
            name: 'Description',
            valueProperty: 'subTitle',
            relativeWidth: 3,
          },
          {
            name: 'Url',
            getValue: (x) => `https://app.seekout.io/${x.queryList[0]}`,
            relativeWidth: 3,
          },
          {
            name: 'Date',
            valueProperty: 'dateCreated',
            type: 'date',
          },
        ]}
      />
    </ViewFrame>
  );
};
