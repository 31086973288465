import { createOrganization } from 'api/apiThunks';
import { selectDerivedSystem } from 'api/systemSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { NoPermission } from 'components/NoPermission';
import { OrganizationForm } from 'components/organizationForm/OrganizationForm';
import { ViewFrame } from 'features/frame/ViewFrame';
import { useState } from 'react';
import { Privilege } from 'types/enums';
import { getOperations } from 'utils/operable';
import { getOrgParams } from 'utils/organization';
import { usePrivilegeSelector } from 'utils/usePrivilegeSelector';

/**
 * The system create organization view
 */
export const CreateOrganization = (): JSX.Element => {
  const [operationType, setOperationType] = useState<SystemOpName>();

  const system = useAppSelector(selectDerivedSystem);
  const contentOperations = operationType ? getOperations(system, operationType) : [];
  const adminPrivilege = usePrivilegeSelector();

  const dispatch = useAppDispatch();

  const saveOrganization = async (formInput: IOrganization) => {
    setOperationType('createOrganization');
    const params: OrgParams = await getOrgParams(formInput);
    dispatch(createOrganization(params));
  };

  return (
    <ViewFrame
      contentLoader={{
        message: 'Creating Organization',
        contentOperations,
        forceClose: !operationType,
        onClose: () => setOperationType(undefined),
      }}
    >
      {adminPrivilege !== Privilege.All ? (
        <NoPermission />
      ) : (
        <OrganizationForm onSubmit={saveOrganization} />
      )}
    </ViewFrame>
  );
};
