import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useState } from 'react';

import { ValidationTextField } from 'components/ValidationTextField';
import { Selector } from 'components/Selector';
import { TimespanOptions } from 'utils/enums';
import { getAdminOptions, getMonitorCategoryOptions } from 'utils/selectorOptions';
import { AutocompleteSearchBar } from 'components/AutocompleteSearchBar';
import { isGuid } from 'utils/convert';
import { Toggle } from 'components/Toggle';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { clearMonitorLogs, selectDerivedSystem, setClientAdminLogs } from 'api/systemSlice';
import { getMonitorCategories } from 'api/apiThunks';

type TimespanValue = typeof TimespanOptions[number]['value'];
type Filter = 'actingUserId' | 'userId' | 'orgId';

function getValidFilters(cats: MonitorCategories, selected: string): Filter[] {
  const validTargets = cats[selected] ?? ['userId', 'orgId'];
  return ['actingUserId', ...validTargets];
}

interface Props {
  admins: IAdminUser[];
  monitorCategories: MonitorCategories;
  onSubmit: (options: MonitorLogOptions) => void;
  orgId?: string;
  userId?: string;
}

/**
 * A header component with ...
 */
export const MonitorHeader = ({
  admins,
  monitorCategories,
  onSubmit,
  orgId,
  userId,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const system = useAppSelector(selectDerivedSystem);
  const { clientAdminLogs = false } = system;

  const [category, setCategory] = useState('all');
  const [timespan, setTimespan] = useState<TimespanValue>(TimespanOptions[0].value);
  const [actingUserFilter, setActingUserFilter] = useState('');
  const [targetUserFilter, setTargetUserFilter] = useState({ val: '', isValid: true });
  const [targetOrgFilter, setTargetOrgFilter] = useState({ val: '', isValid: true });

  const categoryOptions = getMonitorCategoryOptions(monitorCategories);
  const suggestions = getAdminOptions(admins);

  const validQueryFilters = getValidFilters(monitorCategories, category);
  const hideTargetUserFilter = userId || !validQueryFilters.includes('userId');
  const hideTargetOrgFilter = orgId || !validQueryFilters.includes('orgId');

  const enableSubmit =
    (targetUserFilter.isValid || hideTargetUserFilter) &&
    (targetOrgFilter.isValid || hideTargetOrgFilter);

  const submitOptions: MonitorLogOptions = {
    category,
    timespan,
    limit: 100,
    filters: {},
  };

  if (actingUserFilter) {
    submitOptions.filters.actingUserId = actingUserFilter;
  }

  if (userId) {
    submitOptions.filters.userId = userId;
  } else if (!hideTargetUserFilter && targetUserFilter.val) {
    submitOptions.filters.userId = targetUserFilter.val;
  }

  if (orgId) {
    submitOptions.filters.orgId = orgId;
  } else if (!hideTargetOrgFilter && targetOrgFilter.val) {
    submitOptions.filters.orgId = targetOrgFilter.val;
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Toggle
        label="Client Admin"
        value={clientAdminLogs}
        tooltip="Operations performed from Team Admin page in SeekOut App"
        onChange={(value) => {
          dispatch(setClientAdminLogs(value));
          dispatch(getMonitorCategories(value));
          dispatch(clearMonitorLogs());
        }}
      />
      <Selector
        label="Category"
        options={categoryOptions}
        value={category}
        setValue={(val) => setCategory(val as string)}
      />
      <Selector
        label="Timespan"
        options={TimespanOptions}
        value={timespan}
        setValue={(val) => {
          setTimespan(val as TimespanValue);
        }}
      />
      <Box sx={{ width: '20%', py: 1 }}>
        <AutocompleteSearchBar
          placeholder={'Actor UserId (optional)'}
          suggestions={suggestions}
          onSubmit={(val, isEnter) => {
            if (!isEnter || isGuid(val)) {
              setActingUserFilter(val);
            }
          }}
          onClear={() => setActingUserFilter('')}
          size="medium"
          label={actingUserFilter}
        />
      </Box>
      {!hideTargetUserFilter && (
        <ValidationTextField
          label="Target UserId (optional)"
          size="medium"
          validationType="guid"
          value={targetUserFilter.val}
          onChange={(val, isValid) => setTargetUserFilter({ val, isValid: !val || isValid })}
        />
      )}
      {!hideTargetOrgFilter && (
        <ValidationTextField
          label="Target OrgId (optional)"
          size="medium"
          validationType="guid"
          value={targetOrgFilter.val}
          onChange={(val, isValid) => setTargetOrgFilter({ val, isValid: !val || isValid })}
        />
      )}
      <Button
        variant="contained"
        disabled={!enableSubmit}
        onClick={() => onSubmit(submitOptions)}
        sx={{ alignSelf: 'center' }}
      >
        Find
      </Button>
    </Box>
  );
};
