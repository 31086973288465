import { selectDerivedNewUser } from 'api/newUsersSlice';
import { useAppSelector } from 'app/hooks';
import { SettingsGrid } from 'components/grids/SettingsGrid';

/**
 * The new user settings view
 */
export const Settings = (): JSX.Element => {
  const { newUser } = useAppSelector(selectDerivedNewUser);

  if (!newUser) return <></>;

  return <SettingsGrid dataSet={{ NewUser: newUser }} />;
};
