import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

interface Props {
  open: boolean;
  prompt: string;
  onClose: () => void;
  onAccept: () => void;
}

/**
 * A modal dialog to get confirmation prior to destructive action
 */
export const ConfirmModal = ({ open, prompt, onClose, onAccept }: Props) => {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogContent>
        <DialogContentText>{prompt}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            onClose();
            onAccept();
          }}
          autoFocus
        >
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};
