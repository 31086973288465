import { useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { SxProps, Theme } from '@mui/system';
import { Tip } from 'components/Tip';
import { isValidString } from 'utils/convert';

interface Props {
  label: string;
  tooltip?: string;
  validationType: StringFormat;
  size?: Parameters<typeof TextField>[0]['size'];
  value?: string;
  disabled?: boolean;
  prefix?: string;
  onChange: (val: string, isValid: boolean) => void;
  multiline?: boolean;
  sx?: SxProps<Theme>;
}

/**
 * A standard validated text field
 */
export const ValidationTextField = ({
  label,
  tooltip = '',
  validationType,
  size,
  value,
  disabled,
  prefix,
  onChange,
  multiline,
  sx,
}: Props): JSX.Element => {
  const [error, setError] = useState(false);
  const sxCopy: SxProps<Theme> = { margin: (theme) => theme.spacing(1), ...sx };

  return (
    <Tip title={tooltip}>
      <TextField
        label={label}
        size={size}
        value={value || ''}
        disabled={disabled}
        error={error}
        helperText={error ? `Requires ${validationType}` : ''}
        // Autocomplete is surprisingly annoying to disable. This is a way that seems to work.
        autoComplete="new-password"
        onChange={(e) => {
          const value = e.target.value;
          if (!value) {
            // Empty text doesn't show an error, but is invalid to the parent
            setError(false);
            onChange('', false);
          } else {
            const valid = isValidString(validationType, value);
            setError(!valid);
            onChange(value, valid);
          }
        }}
        multiline={multiline}
        sx={sxCopy}
        InputProps={{
          startAdornment: prefix ? (
            <InputAdornment position="start">{prefix}</InputAdornment>
          ) : undefined,
        }}
      />
    </Tip>
  );
};
