import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SxProps, Theme } from '@mui/system';
import { Tip } from 'components/Tip';

interface Props {
  label: string;
  options: readonly SelectOption[];
  value: string | string[];
  setValue: (val: string | string[]) => void;
  isDisabled?: boolean;
  tooltip?: string;
  sx?: SxProps<Theme>;
  error?: boolean;
  additionalProps?: Record<string, unknown>;
}

/**
 * A standard selector control
 */
export const Selector = ({
  label,
  options,
  value,
  setValue,
  isDisabled,
  tooltip = '',
  sx,
  error,
  ...additionalProps
}: Props): JSX.Element => {
  const sxCopy: SxProps<Theme> = { padding: (theme) => theme.spacing(1), ...sx };

  return (
    <Tip placement="left" title={tooltip}>
      <FormControl disabled={isDisabled} sx={sxCopy} error={error}>
        <Select
          value={value}
          onChange={(event) => setValue(event.target.value)}
          {...additionalProps}
        >
          {options.map((option) => (
            <MenuItem value={option.value} key={option.value}>
              {option.text}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{label}</FormHelperText>
      </FormControl>
    </Tip>
  );
};
