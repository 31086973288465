import { Box, Button, Chip } from '@mui/material';
import { FormFields } from 'components/form/FormFields';
import { ChangeEvent, useCallback, useMemo, useReducer, useState } from 'react';
import { requiredField } from 'utils/form';
import { ColumnContent } from '../columns/ColumnContent';
import { getFeatureOptions, getPageSizesOptions } from 'utils/selectorOptions';
import { ITEM_HEIGHT, ITEM_PADDING_TOP } from 'constant';

const getToggleDefaultValue = ({ formInput, field }: FormValueProps<SkuV3MetaState>) => {
  return {
    defaultValue: formInput[field as keyof ISkuV2] || false,
  };
};

const validationFields = ['skuId', 'name'];

const column1Fields: FormFieldInfo[] = [
  {
    name: 'skuId',
    label: 'Sku Id',
  },
  {
    name: 'name',
    label: 'Name',
  },
  {
    type: 'number',
    name: 'msrp',
    label: 'MSRP',
  },
  {
    type: 'number',
    name: 'emailsPerMonth',
    label: 'Emails Per Month',
  },
  {
    type: 'number',
    name: 'exportPerMonth',
    label: 'Export Per Month',
  },
  {
    type: 'number',
    name: 'maxAccountsPerDomain',
    label: 'Max Accounts Per Domain',
  },
  {
    type: 'toggle',
    name: 'isSmb',
    label: 'Is SMB',
    getDefaultValue: getToggleDefaultValue,
    additionalProps: {
      sx: { mt: 2 },
    },
  },
];

const column2Fields: FormFieldInfo[] = [
  {
    type: 'number',
    name: 'cseQueryLimit',
    label: 'CSE Query Limit',
  },
  {
    type: 'number',
    name: 'snailMailLimit',
    label: 'Snail Mail Limit',
  },
  {
    name: 'otherNetworks',
    label: 'Other Networks',
  },
  {
    type: 'number',
    name: 'freeTrialTime',
    label: 'Free Trial Time',
  },
  {
    type: 'date',
    name: 'expiryDate',
    label: 'Expiry Date',
  },
  {
    type: 'dropdown',
    name: 'maxPageSize',
    label: 'Max Page Size',
    options: getPageSizesOptions(),
    getDefaultValue: ({ formInput }: FormValueProps<SkuV3MetaState>) => {
      return {
        defaultValue: `${formInput.maxPageSize || ''}`,
      };
    },
  },
];

const featuresField: FormFieldInfo = {
  type: 'dropdown',
  name: 'features',
  label: 'Features',
  getDefaultValue: ({ formInput }: FormValueProps<SkuV3MetaState>) => {
    return {
      defaultValue: formInput.features || [],
    };
  },
  additionalProps: {
    sx: { width: '100%', padding: 0, mt: 0 },
    multiple: true,
    MenuProps: {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    },
  },
};

interface Props extends FormProps<ISkuV2> {
  disabled?: boolean;
}

interface SkuV3MetaState extends FormState, Partial<ISkuV2> {}

const skuV3Reducer = (state: SkuV3MetaState, newState: SkuV3MetaState) => ({
  ...state,
  ...newState,
});

/**
 * A form to create/edit v3 sku
 */
export const SkuV3Form = ({
  onSubmit,
  onCancel,
  sx,
  initialValues,
  disabled,
}: Props): JSX.Element => {
  const [formErrors, setFormErrors] = useState({} as FormErrors);
  const [formInput, setFormInput] = useReducer(skuV3Reducer, { ...(initialValues || {}) });

  const validateForm = useCallback(
    (fieldValues: Record<string, unknown>, isSubmit = false) => {
      if (isSubmit) {
        // Do full form validation
        validationFields.forEach((field) => {
          formErrors[field] = requiredField(fieldValues, field);
        });
      } else {
        Object.entries(fieldValues)
          .filter(([key]) => validationFields.includes(key))
          .forEach(([key]) => {
            formErrors[key] = requiredField(fieldValues, key);
          });
      }

      setFormErrors({ ...formErrors });
      return Object.values(formErrors).every((x) => x === '');
    },
    [formErrors]
  );

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const fieldValue: Record<string, unknown> = {};
    let newValue;
    switch (e.target.type) {
      case 'number':
      case 'date':
        newValue = parseInt(e.target.value);
        break;

      case 'checkbox':
        newValue = e.target.checked;
        break;

      default:
        newValue = e.target.value;
        break;
    }

    if (Object.keys(fieldValue).length === 0) {
      fieldValue[name] = newValue;
    }
    setFormInput(fieldValue);
    validateForm(fieldValue);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm(formInput, true)) return;
    onSubmit(formInput as ISkuV2);
  };

  const columnFields = (formFields: FormFieldInfo[]) => {
    return FormFields<ISkuV2>({
      formFields,
      formInput: formInput as ISkuV2,
      formErrors: formErrors,
      handleInput: handleInput,
    });
  };

  const isValidForm = useMemo(() => {
    return Object.values(formErrors).every((x) => x === '');
  }, [formErrors]);

  const col1Fields = useMemo(() => {
    const fields = [...column1Fields];
    const index = fields.findIndex((o) => o.name === 'skuId');
    if (index > -1) {
      fields[index].additionalProps = { disabled: !!initialValues?.id };
    }
    return fields;
  }, [initialValues]);

  const featureOptions: SelectOption[] = getFeatureOptions();
  const getFeatureName = (value: string) => {
    return featureOptions.find((x) => x.value === value)?.text;
  };
  const featuresDropdownField = {
    ...featuresField,
    options: featureOptions,
    additionalProps: {
      ...featuresField.additionalProps,
      renderValue: (selected: string[]) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selected.map((value) => (
            <Chip key={value} label={getFeatureName(value)} />
          ))}
        </Box>
      ),
    },
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        width: '100%',
        margin: (theme) => theme.spacing(0, 'auto', 4, 'auto'),
        ...sx,
      }}
      autoComplete="off"
    >
      <ColumnContent
        columns={[<>{columnFields(col1Fields)}</>, <>{columnFields(column2Fields)}</>]}
        growWidth
      />
      <Box sx={{ pl: 2, pr: 2 }}>{columnFields([featuresDropdownField])}</Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <Button sx={{ mr: 2 }} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant={!isValidForm ? 'outlined' : 'contained'}
          color={!isValidForm ? 'error' : undefined}
          sx={{ width: 125 }}
          disabled={disabled}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
