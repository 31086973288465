import { useState } from 'react';

import { getSSOHistory } from 'api/apiThunks';
import { selectDerivedSystem } from 'api/systemSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { SSOHistoryGrid } from 'components/grids/SSOHistoryGrid';
import { TextControlHeader } from 'components/headers/TextControlHeader';
import { ViewFrame } from 'features/frame/ViewFrame';
import { getOperations } from 'utils/operable';

/**
 * The System SSO History view (for emails that may or may not be activated)
 */
export const SSOHistory = (): JSX.Element => {
  const [operationKey, setOperationKey] = useState('');

  const system = useAppSelector(selectDerivedSystem);
  const contentOperations = getOperations(system, 'ssoHistory');
  const { ssoHistory = [] } = system;

  const dispatch = useAppDispatch();

  return (
    <ViewFrame
      header={
        <TextControlHeader
          label="Email"
          tooltip="Get the SSO history for this email address"
          validationType="email"
          submitText="Find"
          onSubmit={(emailText: string) => {
            setOperationKey(emailText);
            dispatch(getSSOHistory({ value: emailText }));
          }}
        />
      }
      contentLoader={{
        message: `Loading SSO History for ${operationKey}`,
        contentOperations,
        forceClose: !operationKey,
        onClose: () => setOperationKey(''),
      }}
    >
      <SSOHistoryGrid dataSet={ssoHistory} />
    </ViewFrame>
  );
};
