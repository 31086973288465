import { SxProps, TextField, Theme, Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';

const orgUsageGoalsFormFields: FormFieldInfo[] = [
  {
    name: 'daysActive',
    label: 'Days Active',
  },
  {
    name: 'searches',
    label: 'Searches',
  },
  {
    name: 'addToProject',
    label: 'Add to Project',
  },
  {
    name: 'insights',
    label: 'Insights',
  },
];

interface UsageGoal {
  daysActive?: number;
  searches?: number;
  addToProject?: number;
  insights?: number;
}

interface UsageGoalsProps extends Partial<FormFieldProps> {
  name: string;
  defaultValue?: unknown;
  onChange: InputChangeEvent;
  sx?: SxProps<Theme>;
}

export const UsageGoals = ({ name, defaultValue, onChange, sx }: UsageGoalsProps) => {
  const defaultGoalValue = defaultValue as UsageGoal | undefined;
  const [goals, setGoals] = useState<UsageGoal>({
    daysActive: defaultGoalValue?.daysActive,
    searches: defaultGoalValue?.searches,
    addToProject: defaultGoalValue?.addToProject,
    insights: defaultGoalValue?.insights,
  });

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    goals[e.target.name as keyof UsageGoal] = parseInt(e.target.value);
    setGoals(goals);
    const event = { target: { name, type: 'object', value: JSON.stringify(goals) } };
    onChange(event as ChangeEvent<HTMLInputElement>);
  };

  const frequency = name === 'orgUsageMonthlyGoals' ? 'Monthly' : 'Weekly';

  return (
    <>
      <Typography variant="h6" sx={sx}>{`Org Usage ${frequency} Goals`}</Typography>
      {orgUsageGoalsFormFields.map((field) => (
        <TextField
          key={field.name}
          name={field.name}
          label={field.label}
          placeholder={field.label}
          size="medium"
          sx={{ width: '100%', mt: 2 }}
          type="number"
          onChange={handleOnChange}
          defaultValue={goals[field.name as keyof UsageGoal]}
          inputProps={{ min: 0 }}
        />
      ))}
    </>
  );
};
