import { asCleanEmail, isEmail, isGuid } from 'utils/convert';

/**
 * Return the UI string for the Lookup
 */
export function getLookupDisplayText(lookup: Lookup): string {
  return lookup.name || lookup.email || lookup.domain || lookup.id || '';
}

/**
 * Create a Lookup from an id or other string that can resolve to an id
 */
export function createLookup(value: string): Lookup | undefined {
  if (value) {
    if (isGuid(value)) return { id: value };

    const email = asCleanEmail(value);
    if (isEmail(email)) return { email };

    // Orgs domains should be an actual domain, but there are legacy examples of arbitrary strings
    return { domain: value, type: 'Organization' };
  }
}

/**
 * Compare two lookups and return true if they are "equivalent"
 */
export function isLookupMatch(lookup1: Lookup, lookup2: Lookup): boolean {
  const match = (val1?: string, val2?: string) =>
    val1 != undefined && val1.toLowerCase() === val2?.toLowerCase();
  return (
    match(lookup1.id, lookup2.id) ||
    match(lookup1.email, lookup2.email) ||
    match(lookup1.domain, lookup2.domain)
  );
}

/**
 * Return a friendly description for error strings
 */
export function lookupFriendlyError(lookup: Lookup): string {
  if (lookup.name) return `Name = ${lookup.name}`;
  if (lookup.email) return `Email = ${lookup.email}`;
  if (lookup.domain) return `Domain = ${lookup.domain}`;
  if (lookup.id) return `Id = ${lookup.id}`;
  return '';
}
