import { useEffect } from 'react';
import { getTransactionHistory } from 'api/apiThunks';
import { selectDerivedUser } from 'api/usersSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { StandardGrid } from 'components/grids/StandardGrid';
import { ViewFrame } from 'features/frame/ViewFrame';
import { getOperations } from 'utils/operable';
import { Typography } from '@mui/material';

/**
 * The User's Maxio Transaction History view
 */
export const MaxioTransactionHistory = (): JSX.Element => {
  const user = useAppSelector(selectDerivedUser);
  const viewOperations = getOperations(user, ['maxioTransactionHistory']);
  const [maxioTransactionHistoryOp] = viewOperations;
  const { userId, recruiter, maxioTransactionHistory = [] } = user;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userId && recruiter?.smbLicense && !maxioTransactionHistoryOp) {
      dispatch(
        getTransactionHistory({ userId, value: recruiter.smbLicense.subscriptionId.toString() })
      );
    }
  }, [dispatch, userId, recruiter, maxioTransactionHistoryOp]);

  if (!userId) return <></>;

  if (!recruiter?.smbLicense) {
    return (
      <ViewFrame>
        <Typography variant="h6" sx={{ color: (theme) => theme.palette.error.dark, mt: 2 }}>
          The user does not have a SMB License
        </Typography>
      </ViewFrame>
    );
  }

  return (
    <ViewFrame viewLoader={{ message: 'Loading Maxio Transaction History', viewOperations }}>
      <StandardGrid
        dataSet={maxioTransactionHistory}
        tipModel="Transaction"
        getRowId={(x) => x.id.toString()}
        cols={[
          {
            name: 'Transaction Id',
            valueProperty: 'id',
          },
          {
            name: 'Date',
            description: 'Timestamp indicating when the Transaction was created',
            valueProperty: 'created_at',
          },
          {
            name: 'Type',
            description: 'The type of the transaction',
            valueProperty: 'type',
          },
          {
            name: 'Success',
            description: 'Whether or not the Transaction was successful',
            valueProperty: 'success',
          },
          {
            name: 'Amount',
            description: 'The amount of the Transaction',
            valueProperty: 'amount_in_cents',
            getValue: (x) => `$${(x.amount_in_cents / 100).toFixed(2)}`,
          },
          {
            name: 'Gateway Handle',
            description: 'The handle from the remote Gateway',
            valueProperty: 'gateway_handle',
          },
          {
            name: 'Full Transaction',
            description: 'Full transaction details',
            getValue: (x) => x,
            type: 'blob',
          },
        ]}
      />
    </ViewFrame>
  );
};
