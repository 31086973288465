import { IS_UNIT_TEST } from 'constant';

/**
 * Return true if any Operation is undefined or loading
 */
export function anyLoadingResult(results: Operation[] = []): boolean {
  return results.some((x) => !x || x.status === 'loading');
}

/**
 * Return the count of Operations that are undefined or loading
 */
export function getLoadingCount(results: Operation[] = []): number {
  return results.filter((x) => !x || x.status === 'loading').length;
}

/**
 * Return true if any Operation is failed
 */
export function anyFailedResult(results: Operation[] = []): boolean {
  return results.some((x) => x?.status === 'failed');
}

/**
 * Return the string version of any error messages in the Operations
 */
export function allErrorMessages(results: Operation[] = []): string[] {
  return results
    .filter((x) => x?.status === 'failed')
    .map((result) => result?.error)
    .filter((x) => !!x) as string[];
}

/**
 * If the APIResult has an error, return a friendlier error message
 * @param message The descriptive message for the operation being attempted
 */
export function getFriendlyError(result: ApiResult<unknown>, message: string): string | undefined {
  if (IS_UNIT_TEST) {
    // Use a static message for jest tests, otherwise comparisons become very tricky
    message = 'TEST ERROR';
  }

  if (result.error) {
    return `${message}: ${result.error.message}`;
  } else if (result.status === 'failed') {
    return `${message}: Request Failed`;
  }
}

/**
 * Return true if every operation result is either absent is succeeded
 */
export function opAllKeyedSkippedOrSuccess(org: DerivedOrganization) {
  const operations = Object.values(org.keyedOperationMap)
    .map((x) => Object.values(x))
    .flat();
  return operations.every((op) => !op || op.status === 'succeeded');
}

/**
 * Return a placeholder failed operation
 */
export function createFailedOperation(description: string): Operation {
  return {
    status: 'failed',
    error: description,
  };
}
