import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

import { ConfirmModal } from 'components/modals/ConfirmModal';
import { Selector } from 'components/Selector';
import { ValidationTextField } from 'components/ValidationTextField';
import { uploadCsvFile } from 'utils/files';
import { isValidString } from 'utils/convert';
import Link from '@mui/material/Link';

const Input = styled('input')({ display: 'none' });
const Label = styled('label')({ alignSelf: 'center' });

// These type definitions help clarify what is in these records

/** A data row; ColumName -> Value */
type Row = Record<string, string>;
/** A mapping of FieldName -> ColumnName */
type Mapping = Record<string, string>;
/** A mapped row; FieldName -> Value */
type MappedRow = Record<string, string>;

interface Field {
  name: string;
  description: string;
  validation: StringFormat;
  options?: SelectOption[] | boolean;
  default?: string;
}

interface Props {
  /** Callback when upload operation state changes */
  onUploadOperation: (ops: Operation[]) => void;
  /** Callback when the mapping changes */
  onChange: (rows: MappedRow[]) => void;
  /** Callback when mapping is submitted */
  onSubmit: () => void;
  /** The field (selector) definitions */
  fields: Field[];
  /** True if the parent component is in a submittable state */
  canSubmit?: boolean;
  /** True if this entire component should be disabled */
  disabled?: boolean;
  /** The message to display in the confirmation modal, default: "Are you sure?" */
  confirmMessage?: string;
  /** Any warning message to display due to parent state */
  warningMessage?: string;
  /** The text to use for the submit button, default: "Submit" */
  submitText?: string;
  /** The name of csv template file */
  csvTemplate?: string;
}

/**
 * A header component with CSV upload and dynamic column selectors
 */
export const UploadMappingHeader = ({
  onUploadOperation,
  onChange,
  onSubmit,
  fields,
  canSubmit,
  disabled,
  confirmMessage = 'Are you sure?',
  warningMessage,
  submitText = 'Submit',
  csvTemplate,
}: Props): JSX.Element => {
  // Confirm Modal
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalTitle, setConfirmModalTitle] = useState('');
  // CSV data
  const [columns, setColumns] = useState<string[]>([]);
  const [rows, setRows] = useState<Row[]>([]);
  // Selections
  const [fieldMapping, setFieldMapping] = useState<Mapping>(
    Object.fromEntries(
      fields.filter((x) => x.default !== undefined).map((x) => [x.name, x.default as string])
    )
  );
  const [mappingErrors, setMappingErrors] = useState('');

  const columnNameOptions = columns.map((x) => ({ value: x, text: x }));

  const updateMapping = (newMapping: Mapping, newColumns?: string[], newRows?: Row[]) => {
    newColumns ??= columns;
    newRows ??= rows;
    const mapping = { ...fieldMapping, ...newMapping };
    const { mappedRows, errors } = filterByMapping(fields, newColumns, newRows, mapping);
    setFieldMapping(mapping);
    setMappingErrors(errors);
    onChange(mappedRows);
  };

  const onUploadSuccess = (newColumns: string[], newRows: Row[]) => {
    setColumns(newColumns);
    setRows(newRows);
    updateMapping(getDefaultMapping(fields, newColumns, newRows[0]), newColumns, newRows);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Label htmlFor="contained-button-file">
            <Input
              accept="text/csv"
              id="contained-button-file"
              type="file"
              onChange={(e) => uploadCsvFile(e, onUploadOperation, onUploadSuccess)}
            />
            <Button variant="contained" component="span" disabled={disabled} sx={{ minWidth: 125 }}>
              Upload CSV
            </Button>
          </Label>
          {csvTemplate ? (
            <Link href={`/${csvTemplate}.csv`} sx={{ marginTop: 1 }}>
              CSV Template
            </Link>
          ) : null}
        </Box>

        <Divider orientation="vertical" variant="middle" flexItem sx={{ marginLeft: 2 }} />

        {fields.map((field) => {
          return (
            <FieldPicker
              key={field.name}
              field={field}
              value={fieldMapping[field.name] ?? ''}
              width={`${90 / fields.length}%`}
              setFieldMapping={(val) => updateMapping({ [field.name]: val })}
              baseOptions={field.options}
              csvOptions={columnNameOptions}
              isDisabled={disabled}
            />
          );
        })}

        <Divider orientation="vertical" variant="middle" flexItem sx={{ marginRight: 2 }} />

        <Button
          variant="contained"
          disabled={!canSubmit || disabled}
          onClick={() => {
            setConfirmModalTitle(confirmMessage);
            setConfirmModalOpen(true);
          }}
          sx={{ alignSelf: 'center', minWidth: 150 }}
        >
          {submitText}
        </Button>
      </Box>

      <Typography>{warningMessage}</Typography>
      <Typography>{mappingErrors}</Typography>

      <ConfirmModal
        open={confirmModalOpen}
        prompt={confirmModalTitle}
        onClose={() => setConfirmModalOpen(false)}
        onAccept={onSubmit}
      />
    </>
  );
};

interface FieldPickerProps {
  field: Field;
  value: string;
  width: string;
  setFieldMapping: (val: string) => void;
  baseOptions?: SelectOption[] | boolean;
  csvOptions?: SelectOption[];
  isDisabled?: boolean;
}

function FieldPicker({
  field,
  value,
  width,
  setFieldMapping,
  baseOptions = [],
  csvOptions = [],
  isDisabled,
}: FieldPickerProps) {
  if (typeof baseOptions === 'boolean') {
    baseOptions = [
      { text: 'FALSE', value: 'false' },
      { text: 'TRUE', value: 'true' },
    ];
  }

  const hasBase = !!baseOptions.length;
  const hasCsv = !!csvOptions.length;
  let tooltip = field.description;

  if (!hasBase && !hasCsv) {
    return (
      <ValidationTextField
        key={field.name}
        label={field.name}
        tooltip={tooltip}
        validationType={field.validation}
        value={value}
        onChange={setFieldMapping}
        disabled={isDisabled}
      />
    );
  }

  if (hasCsv) {
    const baseLabel = hasBase ? ' or a default value for all rows' : '';
    tooltip = `${tooltip}. Select CSV column name${baseLabel}.`;
  }

  return (
    <Selector
      key={field.name}
      label={field.name}
      tooltip={tooltip}
      options={[
        ...csvOptions.map(({ text, value }) => ({ text: `Column: ${text}`, value })),
        ...baseOptions,
      ]}
      value={value}
      setValue={(val) => setFieldMapping(val as string)}
      isDisabled={isDisabled}
      sx={{ width, maxWidth: 250 }}
    />
  );
}

/**
 * Return a mapping: FieldName -> Name of the first column whose example row value is valid, if any
 */
function getDefaultMapping(fields: Field[], columnNames: string[], exampleRow?: Row) {
  const mapping: Mapping = {};
  let columns = [...columnNames];

  if (exampleRow) {
    for (const field of fields) {
      if (field.validation !== 'string') {
        for (const columnName of columns) {
          if (isValidString(field.validation, exampleRow[columnName].trim())) {
            mapping[field.name] = columnName;
            columns = columns.filter((x) => x !== columnName);
            break;
          }
        }
      } else if (Array.isArray(field.options)) {
        for (const columnName of columns) {
          if (findSelectOption(field.options, exampleRow[columnName].trim())) {
            mapping[field.name] = columnName;
            columns = columns.filter((x) => x !== columnName);
            break;
          }
        }
      } else if (field.validation === 'string' && columnNames.includes(field.name)) {
        mapping[field.name] = field.name;
        columns = columns.filter((x) => x !== field.name);
      }

      if (mapping[field.name] === undefined) {
        // Set to default or empty
        mapping[field.name] = field.default ?? '';
      }
    }
  }

  return mapping;
}

/**
 * Apply the mapping to the rows, returning successfully mapped rows and a description of row errors
 */
function filterByMapping(
  fields: Field[],
  columns: string[],
  rows: Row[],
  mapping: Mapping
): { mappedRows: MappedRow[]; errors: string } {
  const result = { mappedRows: [] as MappedRow[], errors: '' };

  const { baseFields, csvFields } = splitFields(fields, columns, mapping);

  if (!baseFields) return result;

  // Get an object with only the base values
  const baseRow = Object.fromEntries(baseFields.map((x) => [x.name, mapping[x.name]]));

  // If we don't have CSV rows, then take the base row as-is
  if (!rows.length) {
    result.mappedRows.push(baseRow);
    return result;
  }

  const errors: MappingErrors = {};

  // Map each row, adding either a mapped row or relevant errors
  rows.forEach((row, index) => {
    const mappedRow = { ...baseRow };
    let hasError = false;

    for (const field of csvFields) {
      const csvValue = row[mapping[field.name]].trim();
      const errType = setMappedRow(mappedRow, field, csvValue);
      if (errType) {
        hasError = true;
        addMappingError(errors, errType, field, index, csvValue);
      }
    }

    if (!hasError) {
      result.mappedRows.push(mappedRow);
    }
  });

  result.errors = getMappingErrorsString(errors);

  return result;
}

function setMappedRow(row: MappedRow, field: Field, value: string): MappingErrorType | undefined {
  if (!isValidString(field.validation, value)) return 'type';

  if (Array.isArray(field.options)) {
    const optValue = findSelectOption(field.options, value);
    if (optValue) {
      row[field.name] = optValue;
      return;
    }
    return 'option';
  }

  row[field.name] = field.options ? value.toLowerCase() : value;
}

function findSelectOption(options: SelectOption[], value: string): string | undefined {
  for (const { text, value: optVal } of options) {
    if (
      value === text ||
      value.toLowerCase() === optVal.toLowerCase() ||
      // It matches the value without any extra display stuff on the end
      // eg. text = "OP-1: Seekout Sku (2025-06-12) (999999)", value = "OP-1: Seekout Sku"
      (text.startsWith(value) && text.slice(value.length, value.length + 2) === ' (')
    ) {
      return optVal;
    }
  }
}

/**
 * Split the fields into two sets: base and csv. Returns {} on failure.
 */
function splitFields(fields: Field[], columns: string[], mapping: Mapping) {
  // Are any selections missing?
  if (fields.some((x) => mapping[x.name] === undefined)) return {};

  // Get only fields NOT mapped to a CSV column
  const baseFields = fields.filter((x) => !columns.includes(mapping[x.name]));

  // Are any of those fields invalid?
  // No need to return errors here, they are already displayed in the field UI
  if (baseFields.some((x) => !isValidString(x.validation, mapping[x.name]))) return {};

  // Get the other half of the fields
  const csvFields = fields.filter((x) => columns.includes(mapping[x.name]));

  return { baseFields, csvFields };
}

type MappingErrorType = 'type' | 'option';
interface MappingError {
  message: string;
  count: number;
  examples: string[];
}
type MappingErrors = Record<string, Record<MappingErrorType, MappingError>>;

/**
 * Add a mapping error into an errors object
 */
function addMappingError(
  errors: MappingErrors,
  type: MappingErrorType,
  field: Field,
  index: number,
  value: string
) {
  const name = field.name;

  if (!errors[name]) {
    errors[name] = {
      type: { message: `non-${field.validation} values`, count: 0, examples: [] },
      option: { message: 'unknown options', count: 0, examples: [] },
    };
  }

  const error = errors[name][type];
  error.count++;

  if (error.examples.length < 3) {
    error.examples.push(`Row ${index}: "${value}"`);
  }
}

/**
 * Stringify a mapping errors object for display
 */
function getMappingErrorsString(errors: MappingErrors) {
  return Object.entries(errors)
    .flatMap(([fieldName, err]) =>
      Object.values(err)
        .filter(({ count }) => !!count)
        .map(({ message, count, examples }) => {
          const errString = `${fieldName}: ${count} ${message} (${examples.join(', ')})`;
          return errString.length > 100 ? `${errString.slice(0, 97)}...` : errString;
        })
    )
    .join(' / ');
}
