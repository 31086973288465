import { AsyncThunk } from '@reduxjs/toolkit';
import { useEffect } from 'react';

import {
  createNewUser as createNewUserThunk,
  updateSFLicense,
  sendActivationEmail,
} from 'api/apiThunks';
import { clearOperations } from 'api/organizationsSlice';
import { useAppDispatch } from 'app/hooks';

/*
 * This file contains the Bulk Thunks - collections of calls to take action across multiple users
 * This allows us to abstract the API structure further from the view logic
 * Eg: From the user's (and view's) perspective, deleting many users is a single operation
 *     But multiple API calls need to be made to complete it
 */

type Dispatch = ReturnType<typeof useAppDispatch>;
type Thunk<In, Out> = AsyncThunk<Out, In, Record<string, unknown>>;

/** Defines a thunk running across multiple users */
interface BulkThunk<In, Out> {
  opName: OrgOpName;
  thunk: Thunk<In, Out>;
  thunkInputs: In[];
  parallelCount: number;
  parallelMax: number;
}

/** Defines the run space of the bulk create operation */
interface BulkCreateDef {
  createUser: BulkThunk<ResourceParams<CreateUserDef>, ICreateUserResult>;
  assignLicense: BulkThunk<SFOrderItemAssignParams, boolean>;
  sendActivationEmail: BulkThunk<ParamsBase, void>;
  windowCount: number;
}

// The maximum number of users to operate on simultaneously (the "sliding window")
const WindowSize = 5;

const getBulkThunkBase = (opName: OrgOpName) => ({
  opName,
  thunkInputs: [],
  parallelCount: 0,
  parallelMax: 5,
});

const getCreateUserBulkThunk = () => ({
  ...getBulkThunkBase('createUser'),
  thunk: createNewUserThunk,
  parallelMax: 1,
});

const getAssignLicenseBulkThunk = () => ({
  ...getBulkThunkBase('assignLicense'),
  thunk: updateSFLicense,
  parallelMax: 1,
});

const getSendActivationEmailBulkThunk = () => ({
  ...getBulkThunkBase('sendActivationEmail'),
  thunk: sendActivationEmail,
});

/**
 * Clear the operation state from a useCreateNewUsers operation
 * @param orgId The id of the org
 * @param trigger Top-level flag to trigger the behavior in this hook
 */
export function useClearCreateNewUsersOperations(orgId?: string, trigger = false) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (trigger && orgId) {
      clearOperations({ orgId, opNames: ['createUser', 'assignLicense', 'sendActivationEmail'] });
    }
  }, [dispatch, orgId, trigger]);
}

/**
 * Run the necessary calls to create a new user
 * @param org The current organization data, if any
 * @param userDefs The definitions of the users to create
 * @param trigger Top-level flag to trigger the behavior in this hook
 * @returns One operation representing each user, or empty if no work needed
 */
export function useCreateNewUsers(
  org: DerivedOrganization,
  userDefs: CreateUserDef[],
  trigger = false
): Operation[] {
  const bulkDef: BulkCreateDef = {
    createUser: getCreateUserBulkThunk(),
    assignLicense: getAssignLicenseBulkThunk(),
    sendActivationEmail: getSendActivationEmailBulkThunk(),
    windowCount: 0,
  };
  const operations: Operation[] = [];

  if (trigger) {
    // Gather the actions into the bulkThunks and save the operation state
    for (const userDef of userDefs) {
      operations.push(createNewUserStep(org, bulkDef, userDef));
    }
  }

  // Dispatch any new api calls
  const dispatch = useAppDispatch();
  useBulkDispatchHelper(dispatch, bulkDef.createUser);
  useBulkDispatchHelper(dispatch, bulkDef.assignLicense);
  useBulkDispatchHelper(dispatch, bulkDef.sendActivationEmail);

  // If there are no failed/in-progress operations, present as no operations to the caller
  if (operations.every((op) => op?.status === 'succeeded')) return [];

  return operations;
}

/**
 *
 * @param org The current organization data, if any
 * @param bulkDef The definition of the parent bulk operation
 * @param userDef The definition of the user to create
 * @returns The operation representing the current action for this user
 */
function createNewUserStep(
  org: DerivedOrganization,
  bulkDef: BulkCreateDef,
  userDef: CreateUserDef
): Operation {
  // Past the window?
  if (bulkDef.windowCount >= WindowSize) return;

  const { orgId, createdUsers, licenseMap = {} } = org;
  const { id: userId = '', orderItemId, sendActivationEmail: isSendActivationEmail } = userDef;

  // The "userId" on userDef is a placeholder, since the server assigns the id on creation
  // Get the real userId from the createdUser object if it exists
  const { id: realId = '' } = createdUsers?.[userId] ?? {};

  if (orgId && userId && !realId) {
    // The user object needs to be created
    const op = updateBulkThunk(org, bulkDef.createUser, userId, {
      orgId,
      newUserId: userId,
      resource: userDef,
    });
    updateWindow(bulkDef, op);
    return op;
  }

  if (realId) {
    const license = licenseMap[realId];
    const baseParams = { orgId, userId: realId, newUserId: realId };

    if (orderItemId && !license) {
      // A license needs to be added
      const op = updateBulkThunk(org, bulkDef.assignLicense, realId, {
        ...baseParams,
        orderItemId,
      });
      updateWindow(bulkDef, op);
      return op;
    } else if (isSendActivationEmail) {
      // An activation email needs to be sent
      const op = updateBulkThunk(org, bulkDef.sendActivationEmail, realId, baseParams);
      updateWindow(bulkDef, op);

      // Don't return on success, as we'll need to update the window count below
      if (op?.status != 'succeeded') {
        return op;
      }
    }
  }

  if (bulkDef.windowCount) {
    // Even though fully successful, we are in the window
    bulkDef.windowCount++;
  }

  return { status: 'succeeded' };
}

/**
 * Update the window count if the operation requires action
 */
function updateWindow(bulkDef: BulkCreateDef, op: Operation) {
  if (!op || op.status === 'loading') {
    bulkDef.windowCount++;
  }
}

/**
 * Update a bulk thunk based on a user's operation state
 * @param org The current organization data, if any
 * @param bulkThunk The bulk thunk
 * @param userId The user to check
 * @param params A parameter group to use if we need to call the thunk
 * @returns The operation state
 */
function updateBulkThunk<In, Out>(
  org: DerivedOrganization,
  bulkThunk: BulkThunk<In, Out>,
  userId: string,
  params: In
): Operation {
  const operation = org.keyedOperationMap[bulkThunk.opName]?.[userId];

  if (!operation && bulkThunk.parallelCount < bulkThunk.parallelMax) {
    // Needs to be dispatched
    bulkThunk.parallelCount++;
    bulkThunk.thunkInputs.push(params);
  } else if (operation?.status === 'loading') {
    // Waiting on result
    bulkThunk.parallelCount++;
  }

  return operation;
}

/**
 * A helper to standardize the format around dispatching a bulk thunk
 * @param dispatch The dispatcher
 * @param bulkThunk The bulk thunk to dispatch
 */
function useBulkDispatchHelper<In, Out>(dispatch: Dispatch, bulkThunk: BulkThunk<In, Out>) {
  const { thunk, thunkInputs } = bulkThunk;
  useEffect(() => {
    if (thunk && thunkInputs) {
      for (const params of thunkInputs) {
        dispatch(thunk(params));
      }
    }
  }, [dispatch, thunk, thunkInputs]);
}
