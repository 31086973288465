import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  deleteUser as deleteUserThunk,
  getLoginHistory,
  getNewUser as getNewUserInfo,
  getSSOHistory,
  updateOrgAdmin,
} from 'api/apiThunks';
import { addExtraReducers } from 'api/slice';
import { RootState } from 'app/store';
import { selectCurrentLookupTab } from 'features/frame/appViewSlice';
import { asCleanEmail } from 'utils/convert';
import { lookupFriendlyError } from 'utils/lookup';
import { createOperable, setOperation } from 'utils/operable';

/* Selectors */

/**
 * Select data, operation state, and precalculated state for the current lookup new user
 * @param state The root redux state
 * @returns The derived user data and operation state
 */
export function selectDerivedNewUser(state: RootState): DerivedNewUser {
  const lookup = selectCurrentLookupTab(state);
  const newUser = getNewUser(state.newUsers, lookup.id) || getNewUser(state.newUsers, lookup.email);

  return {
    ...newUser,
    operationMap: newUser?.operationMap ?? {},
    keyedOperationMap: newUser?.keyedOperationMap ?? {},
    newUserId: newUser?.newUser?.id,
    email: asCleanEmail(newUser?.newUser?.email),
  };
}

/* Reducers */

type StateType = Record<string, NewUser>;

export const newUsersSlice = createSlice({
  name: 'newUsers',
  initialState: {} as StateType,
  reducers: {
    clearNewUserCache: (state, action: PayloadAction<Lookup>) => {
      clearNewUser(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    addExtraReducers(getNewUserInfo, setNewUser, builder);
    addExtraReducers(deleteUserThunk, setDeleteUser, builder);
    addExtraReducers(getLoginHistory, setLoginHistory, builder);
    addExtraReducers(getSSOHistory, setSSOHistory, builder);
    addExtraReducers(updateOrgAdmin, setOrgAdmin, builder);
  },
});

export const { clearNewUserCache } = newUsersSlice.actions;

export const newUsersReducer = newUsersSlice.reducer;

function getNewUser(newUsers: StateType, userId?: string) {
  return userId ? newUsers[userId] : undefined;
}

function clearNewUser(newUsers: StateType, lookup: Lookup) {
  const { id, email } = lookup;

  if (id) {
    delete newUsers[id];
  }

  if (email) {
    delete newUsers[email];
  }
}

function setNewUser(
  users: StateType,
  lookup: Lookup,
  wrappedResult: ApiResult<{ results: INewUser[] }>
) {
  const user: NewUser =
    getNewUser(users, lookup.id) ?? getNewUser(users, lookup.email) ?? createOperable();

  // The API returns an array of length 1 on success, null on failure
  // Convert here to single value
  const innerResults = wrappedResult.data?.results;
  const result = { ...wrappedResult, data: innerResults ? innerResults[0] : undefined };

  if (result.status === 'succeeded' && !result.data) {
    // This endpoint returns success when it doesn't find a new user
    result.status = 'failed';
  }

  const description = `Get NewUser with ${lookupFriendlyError(lookup)}`;
  user.newUser = setOperation(user, 'newUser', result, description);

  const id = user.newUser?.id || lookup.id;
  const email = lookup.email;

  if (id) users[id] = user;
  if (email) users[email] = user;
}

function setDeleteUser(users: StateType, { newUserId }: ParamsBase, result: ApiResult<boolean>) {
  const user = getNewUser(users, newUserId);
  if (!user) return;

  const success = setOperation(user, 'delete', result, `Delete User (${newUserId})`);

  if (success) {
    const email = user.newUser?.email;

    if (email) {
      delete users[email];
    }

    if (newUserId) {
      delete users[newUserId];
    }
  }
}

function setLoginHistory(
  newUsers: StateType,
  { newUserId, value: email }: ValueParams,
  result: ApiResult<ILoginHistory[]>
) {
  const newUser = getNewUser(newUsers, newUserId);
  if (!newUser) return;

  newUser.loginHistory = setOperation(
    newUser,
    'loginHistory',
    result,
    `Get Login History (${email})`
  );
}

function setSSOHistory(
  newUsers: StateType,
  { newUserId, value: email }: ValueParams,
  result: ApiResult<ISSOHistory[]>
) {
  const newUser = getNewUser(newUsers, newUserId);
  if (!newUser) return;

  newUser.ssoHistory = setOperation(newUser, 'ssoHistory', result, `Get SSO History (${email})`);
}

function setOrgAdmin(
  newUsers: StateType,
  { newUserId, flag: isOrgAdmin }: FlagParams,
  result: ApiResult<INewUser | IRecruiter>
) {
  const newUser = getNewUser(newUsers, newUserId);
  if (!newUser) return;

  const success = setOperation(newUser, 'updateOrgAdmin', result, 'Update Org Admin');

  if (success && newUser.newUser) {
    newUser.newUser.userType = isOrgAdmin ? 'admin' : 'standard';
  }
}
