import { Box, Divider } from '@mui/material';
import { bulkAddSmbDomain, bulkDeleteSmbDomain } from 'api/apiMetaThunks';
import { getSmbDomains } from 'api/apiThunks';
import { clearOperations, selectDerivedSystem } from 'api/systemSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { StandardGrid } from 'components/grids/StandardGrid';
import { UploadDomainsCsv } from 'components/smb/UploadDomainsCsv';
import { ViewFrame } from 'features/frame/ViewFrame';
import { useCallback, useEffect, useState } from 'react';
import { Privilege } from 'types/enums';
import { anyFailedResult, anyLoadingResult, opAllKeyedSkippedOrSuccess } from 'utils/apiResult';
import { getKeyedOperations, getOperations } from 'utils/operable';
import { usePrivilegeSelector } from 'utils/usePrivilegeSelector';

type Mode = 'prep' | 'upload' | 'add' | 'delete' | 'errorCheck' | 'done';

/**
 * The system smb blocklist view
 */
export const SMBBlocklist = (): JSX.Element => {
  const [uploadOperations, setUploadOperations] = useState<Operation[]>([]);
  const [csvRows, setCsvRows] = useState<Record<string, string>[]>([]);
  const [mode, setMode] = useState<Mode>('prep');

  const system = useAppSelector(selectDerivedSystem);
  const { smbDomains = [] } = system;

  const viewOperations = getOperations(system, 'smbDomains');
  const [smbBlockedDomainsOp] = viewOperations;

  const bulkContentOperations = getKeyedOperations(
    system,
    csvRows.map((x) => x.domain),
    ['addSmbDomain', 'deleteSmbDomain']
  ).flat();
  const contentOperations = mode === 'upload' ? uploadOperations : bulkContentOperations;

  const adminPrivilege = usePrivilegeSelector();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!smbBlockedDomainsOp) {
      dispatch(getSmbDomains());
    }
  }, [dispatch, smbBlockedDomainsOp]);

  const complete = useCallback(() => {
    dispatch(clearOperations({ opNames: ['addSmbDomain', 'deleteSmbDomain'] }));
    setMode('done');
  }, [dispatch]);

  useEffect(() => {
    if (mode === 'add') {
      bulkAddSmbDomain(dispatch, system, csvRows, () => setMode('errorCheck'));
    } else if (mode === 'delete') {
      bulkDeleteSmbDomain(
        dispatch,
        system,
        csvRows.map((x) => x.domain),
        () => setMode('errorCheck')
      );
    } else if (mode === 'errorCheck') {
      if (opAllKeyedSkippedOrSuccess(system)) {
        complete();
      }
    }
  }, [dispatch, system, mode, csvRows, complete]);

  const onUploadOperation = (ops: Operation[]) => {
    // If a single successful operation, then translate to [] (no operation)
    ops = ops.length === 1 && ops[0]?.status === 'succeeded' ? [] : ops;
    setUploadOperations(ops);
    setMode(anyLoadingResult(ops) || anyFailedResult(ops) ? 'upload' : 'prep');
  };

  const onSubmit = (mode: Mode, rows: Record<string, string>[]) => {
    let csvRows = rows;
    if (mode === 'add') {
      const existingDomains = smbDomains.map((x) => x.userId);
      csvRows = csvRows.filter((x) => !existingDomains.includes(x.domain));
    }
    setCsvRows(csvRows);
    setMode(mode);
  };

  const isProcessing =
    mode === 'upload' || mode === 'add' || mode === 'delete' || mode === 'errorCheck';
  const operationOnClose = mode === 'upload' ? () => setMode('prep') : complete;
  let operationMessage;
  switch (mode) {
    case 'upload':
      operationMessage = 'Uploading CSV';
      break;

    case 'add':
      operationMessage = 'Adding domains to allow/block list';
      break;

    case 'delete':
      operationMessage = 'Deleting domains from allow/block list';
      break;

    default:
      operationMessage = '';
      break;
  }

  return (
    <ViewFrame
      viewLoader={{
        message: 'Loading smb allowed and blocked domains list. This might take a minute.',
        viewOperations,
      }}
      header={
        <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'center', mb: 2 }}>
          <UploadDomainsCsv
            id="contained-add-button-file"
            operationType="add"
            onUploadOperation={onUploadOperation}
            onUploadSuccess={() => setMode('prep')}
            onSubmit={(rows: Record<string, string>[]) => onSubmit('add', rows)}
            disabled={adminPrivilege !== Privilege.All || isProcessing}
            csvColumns={['Domain', 'AllowStatus']}
            buttonText="Add Domains"
          />
          <Divider orientation="vertical" variant="middle" flexItem sx={{ ml: 2, mr: 2 }} />
          <UploadDomainsCsv
            id="contained-delete-button-file"
            operationType="delete"
            onUploadOperation={onUploadOperation}
            onUploadSuccess={() => setMode('prep')}
            onSubmit={(rows: Record<string, string>[]) => onSubmit('delete', rows)}
            disabled={adminPrivilege !== Privilege.All || isProcessing}
            buttonText="Delete Domains"
          />
        </Box>
      }
      contentLoader={{
        message: operationMessage,
        contentOperations,
        forceClose: !isProcessing,
        onClose: operationOnClose,
      }}
    >
      <StandardGrid
        dataSet={smbDomains}
        tipModel="SmbBlockedDomain"
        getRowId={(x) => x.id}
        filterPlaceholder="Filter the displayed domains"
        cols={[
          {
            name: 'Domain',
            valueProperty: 'userId',
            type: 'string',
          },
          {
            name: 'Allow Status',
            valueProperty: 'allowStatus',
            type: 'string',
          },
        ]}
        getDeleteAction={
          adminPrivilege === Privilege.All
            ? {
                action: (value) => {
                  const fieldName = (value as Record<string, string>)['userId'];
                  onSubmit('delete', [{ domain: fieldName }]);
                },
                description: 'Delete Domain',
                getDeleteInfo: (x) => ({ value: x, confirmName: x.userId }),
              }
            : undefined
        }
      />
    </ViewFrame>
  );
};
