import { getInactiveSmbUsers } from 'api/apiThunks';
import { selectDerivedSystem } from 'api/systemSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { StandardGrid } from 'components/grids/StandardGrid';
import { ViewFrame } from 'features/frame/ViewFrame';
import { useEffect } from 'react';
import { getOperations } from 'utils/operable';

/**
 * The system inactive smb users view
 */
export const SMBInactiveUsers = (): JSX.Element => {
  const system = useAppSelector(selectDerivedSystem);
  const { inactiveSmbUsers = [] } = system;

  const viewOperations = getOperations(system, 'inactiveSmbUsers');
  const [inactiveSmbUsersOp] = viewOperations;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!inactiveSmbUsersOp) {
      dispatch(getInactiveSmbUsers());
    }
  }, [dispatch, inactiveSmbUsersOp]);

  return (
    <ViewFrame
      viewLoader={{
        message: 'Loading inactive smb users. This might take a minute.',
        viewOperations,
      }}
    >
      <StandardGrid
        dataSet={inactiveSmbUsers}
        tipModel="INewUser"
        getRowId={(x) => x.id}
        getOpenAction={(x) => ({ email: x.email, type: 'NewUser' })}
        filterPlaceholder="Filter the displayed users"
        cols={[
          {
            name: 'Name',
            getValue: (x) => (x.firstName || x.lastName ? `${x.firstName} ${x.lastName}` : ''),
          },
          {
            name: 'Email',
            valueProperty: 'email',
            type: 'email',
          },
          {
            name: 'Activation Link',
            valueProperty: 'fullActivationLink',
          },
          {
            name: 'Date Created',
            description: 'The date the user was created',
            valueProperty: 'dateCreated',
            type: 'date',
          },
        ]}
      />
    </ViewFrame>
  );
};
