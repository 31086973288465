import { useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { StandardGrid } from 'components/grids/StandardGrid';
import { ViewFrame } from 'features/frame/ViewFrame';
import { getOperations } from 'utils/operable';
import { selectDerivedOrganization } from 'api/organizationsSlice';
import { getAtsRediscoveryLogs } from 'api/apiThunks';
import { Box, Button, Divider, FormHelperText } from '@mui/material';
import { DateSelector } from 'components/DateSelector';
import { Selector } from 'components/Selector';
import { Dayjs } from 'dayjs';

const timeSpanOptions = [
  {
    text: 'Past 12 hours',
    value: 'PT12H',
  },
  {
    text: 'Past 1 day',
    value: 'P1D',
  },
  {
    text: 'Past 1 day 12 hours',
    value: 'P1DT12H',
  },
  {
    text: 'Custom',
    value: 'custom',
  },
];

const queryTypeOptions = [
  {
    text: 'Reports Downloaded',
    value: 'downloaded',
  },
  {
    text: 'Reports Errored',
    value: 'errored',
  },
];

const query: Record<string, string> = {
  downloaded:
    "customEvents| where (toint(customDimensions.TotalCandidates) > 0 or toint(customDimensions.TotalAttachments) > 0 or toint(customDimensions.TotalJobs) > 0 )and customDimensions.OrgDomain == '{orgDomain}' and (name == 'atsDowloadReport' or name == 'atsDownloadReport')| order by timestamp desc| project timestamp, customDimensions.AverageResponseTime, customDimensions.StartTS,customDimensions.EndTS,customDimensions.OrgDomain,customDimensions.TotalCandidates,customDimensions.TotalAttachments,customDimensions.TotalJobs",
  errored:
    "customEvents| where isnotempty(customDimensions.Error) and (name == 'atsDowloadReport' or name == 'atsDownloadReport') and customDimensions.OrgDomain == '{orgDomain}' | order by timestamp desc| project timestamp,customDimensions.StartTS,customDimensions.EndTS,customDimensions.Error,customDimensions.OrgDomain,customDimensions.TotalCandidates,customDimensions.TotalAttachments,customDimensions.TotalJobs",
};

/**
 * The Ats Rediscovery logs view
 */
export const AtsRediscoveryLogs = (): JSX.Element => {
  const [timeSpan, setTimeSpan] = useState('');
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [queryType, setQueryType] = useState('');
  const [forceCloseLoader, setForceCloseLoader] = useState(true);

  const derivedOrg = useAppSelector(selectDerivedOrganization);
  const contentOperations = getOperations(derivedOrg, 'atsRediscoveryLogs');
  const { orgId, org, atsRediscoveryLogs = [] } = derivedOrg;

  const dispatch = useAppDispatch();

  const handleViewLogs = () => {
    const selectedTimeSpan =
      timeSpan === 'custom'
        ? `${startDate?.format('YYYY-MM-DD')}/${endDate?.format('YYYY-MM-DD')}`
        : timeSpan;
    const queryValue = query[queryType].replace('{orgDomain}', org?.domain || '');
    setForceCloseLoader(false);
    dispatch(
      getAtsRediscoveryLogs({
        orgId,
        resource: {
          query: queryValue,
          timeSpan: selectedTimeSpan,
        },
      })
    );
  };

  const isValidDateRange = useMemo(() => {
    if (!startDate || !endDate) return false;
    if (!startDate?.isValid() || !endDate?.isValid()) return false;
    const diffInDays = endDate.diff(startDate, 'day');
    return diffInDays > -1 && diffInDays < 31;
  }, [startDate, endDate]);
  const disableView =
    timeSpan === '' || (timeSpan === 'custom' && !isValidDateRange) || queryType === '';

  return (
    <ViewFrame
      header={
        <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'center' }}>
          <Selector
            label="Time Span"
            options={timeSpanOptions}
            value={timeSpan}
            setValue={(val) => setTimeSpan(val as string)}
            sx={{ minWidth: 210 }}
          />
          {timeSpan === 'custom' && (
            <>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ marginLeft: 2, marginRight: 2 }}
              />
              <Box sx={{ alignSelf: 'flex-start' }}>
                <DateSelector label="Start Date" value={startDate} setValue={setStartDate} />
                <DateSelector label="End Date" value={endDate} setValue={setEndDate} />
                <FormHelperText sx={{ textAlign: 'center' }}>
                  Max allowed time span: 1 month
                </FormHelperText>
              </Box>
            </>
          )}
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ marginLeft: 2, marginRight: 2 }}
          />
          <Selector
            label="Type of Query"
            options={queryTypeOptions}
            value={queryType}
            setValue={(val) => setQueryType(val as string)}
            sx={{ minWidth: 210 }}
          />
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ marginLeft: 2, marginRight: 2 }}
          />
          <Button variant="contained" disabled={disableView} onClick={handleViewLogs}>
            View Logs
          </Button>
        </Box>
      }
      contentLoader={{
        message: 'Loading ATS Rediscovery Logs',
        contentOperations,
        forceClose: forceCloseLoader,
        onClose: () => setForceCloseLoader(true),
      }}
    >
      <StandardGrid
        dataSet={atsRediscoveryLogs}
        getRowId={(x) => x.timestamp}
        filterPlaceholder="Filter the results"
        cols={[
          {
            name: 'Time Stamp',
            valueProperty: 'timestamp',
            type: 'date',
          },
          {
            name: 'Average Response Time',
            valueProperty: 'customDimensions_AverageResponseTime',
          },
          {
            name: 'StartTS',
            valueProperty: 'customDimensions_StartTS',
            type: 'date',
          },
          {
            name: 'EndTS',
            valueProperty: 'customDimensions_EndTS',
            type: 'date',
          },
          {
            name: 'Total Candidates',
            valueProperty: 'customDimensions_TotalCandidates',
          },
          {
            name: 'Total Attachments',
            valueProperty: 'customDimensions_TotalAttachments',
          },
          {
            name: 'Total Jobs',
            valueProperty: 'customDimensions_TotalJobs',
          },
        ]}
      />
    </ViewFrame>
  );
};
