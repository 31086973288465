import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#9e00f0',
    },
    secondary: {
      main: '#faf8f6',
    },
  },
  typography: {
    fontFamily: ['DM Sans', 'Rubik', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'].join(
      ','
    ),
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#21003B',
        },
      },
    },
  },
});
