import { useEffect, useState } from 'react';

import { addTag, getTags, deleteTag } from 'api/apiThunks';
import { selectDerivedOrganization } from 'api/organizationsSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { StandardGrid } from 'components/grids/StandardGrid';
import { TextControlHeader } from 'components/headers/TextControlHeader';
import { ViewFrame } from 'features/frame/ViewFrame';
import { getKeyedOperations, getOperations } from 'utils/operable';

/**
 * The Tags view
 */
export const Tags = (): JSX.Element => {
  const [operationType, setOperationType] = useState<OrgOpName>();
  const [operationKey, setOperationKey] = useState('');

  const org = useAppSelector(selectDerivedOrganization);
  const viewOperations = getOperations(org, 'tags');
  const [getTagsOp] = viewOperations;
  const [contentOperations] = operationType
    ? getKeyedOperations(org, [operationKey], operationType)
    : [];
  const { orgId, tags = [] } = org;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (orgId && !getTagsOp) {
      dispatch(getTags(orgId));
    }
  }, [dispatch, orgId, getTagsOp]);

  if (!orgId) return <></>;

  const operationDisplayName =
    operationType === 'addTag'
      ? `Adding ${operationKey} to Tag list`
      : `Removing ${tags.find((x) => x.id === operationKey)?.tagName} from Tag list`;

  return (
    <ViewFrame
      viewLoader={{ message: 'Loading tag information', viewOperations }}
      header={
        <TextControlHeader
          label="Tag Name"
          tooltip="The name of the tag to add"
          validationType="string"
          submitText="Add"
          onSubmit={(addTagText: string) => {
            setOperationType('addTag');
            setOperationKey(addTagText);
            dispatch(addTag({ orgId, value: addTagText }));
          }}
        />
      }
      contentLoader={{
        message: operationDisplayName,
        contentOperations,
        forceClose: !operationType,
        onClose: () => setOperationType(undefined),
      }}
    >
      <StandardGrid
        dataSet={tags}
        tipModel="RecruiterTag"
        getRowId={(x) => x.id}
        filterPlaceholder="Filter the displayed tags"
        cols={[
          {
            name: 'Name',
            valueProperty: 'tagName',
          },
          {
            name: 'Date Created',
            valueProperty: 'dateCreated',
            type: 'date',
          },
        ]}
        getDeleteAction={{
          action: (id) => {
            setOperationType('removeTag');
            setOperationKey(id as string);
            dispatch(deleteTag({ orgId, value: id as string }));
          },
          description: 'Remove the tag',
          getDeleteInfo: (x) => ({ value: x.id, confirmName: x.tagName }),
        }}
      />
    </ViewFrame>
  );
};
