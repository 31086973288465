import { Autocomplete, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { SxProps, Theme } from '@mui/system';
import { Tip } from 'components/Tip';

interface Props {
  label: string;
  options: readonly SelectOption[];
  value: string;
  setValue: (val: string) => void;
  isDisabled?: boolean;
  tooltip?: string;
  sx?: SxProps<Theme>;
  error?: boolean;
  additionalProps?: Record<string, unknown>;
}

/**
 * A standard autocomplete control
 */
export const AutoComplete = ({
  label,
  options,
  value,
  setValue,
  isDisabled,
  tooltip = '',
  sx,
  error,
  ...additionalProps
}: Props): JSX.Element => {
  const sxCopy: SxProps<Theme> = { padding: (theme) => theme.spacing(1), ...sx };

  return (
    <Tip placement="left" title={tooltip}>
      <FormControl disabled={isDisabled} sx={sxCopy} error={error}>
        <Autocomplete
          freeSolo
          options={options}
          getOptionLabel={(option) => {
            if (typeof option === 'string') return option;
            return option.text;
          }}
          renderInput={(params) => <TextField {...params} label={label} error={error} />}
          value={value}
          inputValue={value}
          onChange={(event, newValue, reason) => {
            if (reason === 'createOption' && typeof newValue === 'string') {
              // Enter key pressed in textbox
              setValue(newValue);
            } else if (reason === 'selectOption' && newValue && typeof newValue === 'object') {
              setValue(newValue.value);
            } else if (reason === 'clear') {
              setValue('');
            }
          }}
          onInputChange={(event, newInputValue) => {
            setValue(newInputValue);
          }}
          {...additionalProps}
        />
        <FormHelperText>{label}</FormHelperText>
      </FormControl>
    </Tip>
  );
};
