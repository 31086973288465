import { ChangeEvent } from 'react';
import Papa from 'papaparse';
import { createFailedOperation } from 'utils/apiResult';

type ParseResult = Papa.ParseResult<Record<string, string>>;

/**
 * Extract the text from a file uploaded to an <input>
 * @param e The <input>'s change event
 * @returns The file content
 */
export async function uploadTextFile(e: ChangeEvent<HTMLInputElement>) {
  if (e.target.files?.length) {
    const file = e.target.files[0];
    return await file.text();
  }
}

/**
 * Extract the content from a CSV file uploaded to an <input>
 * @param e The <input>'s change event
 * @param setOpList Callback to update the upload operation state
 * @param onSuccess Callback on completion with extracted content
 */
export async function uploadCsvFile(
  e: ChangeEvent<HTMLInputElement>,
  setOpList: (ops: Operation[]) => void,
  onSuccess: (columns: string[], rows: Record<string, string>[]) => void
) {
  try {
    // Was upload cancelled?
    if (!e.target.files?.length) return;

    setOpList([{ status: 'loading' }]);

    // Parse the uploaded file
    const file = e.target.files[0];
    const { data, meta, errors } = await new Promise<ParseResult>((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: resolve,
        error: reject,
      });
    });

    // Handle parsing errors
    if (errors.length) {
      const errorSet = new Set(
        errors
          .filter((x) => x.code !== 'UndetectableDelimiter')
          .map((x) => `Row ${x.row}: ${x.message}`)
      );

      if (errorSet.size) {
        return setOpList(Array.from(errorSet).map((x) => createFailedOperation(x)));
      }
    }

    setOpList([{ status: 'succeeded' }]);
    onSuccess(meta.fields ?? [], data);
  } catch (error) {
    const message = error instanceof Error ? error.message : `${error}`;
    setOpList([createFailedOperation(message)]);
  }
}

/**
 * Download a file to the user's machine
 * @param fileName The name of the file
 * @param content The text content of the file
 * @param type The MIME type of the file (eg. 'text/csv', 'text/xml', etc)
 */
export function downloadFile(fileName: string, content: string, type: string) {
  // Courtesy Stackoverflow
  const file = new Blob([content], { type: `${type};charset=utf-8;` });
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
}

/**
 * Convert data file to blob
 * @param file The data file
 */
export const fileToBlob = async (file: Blob) =>
  new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });

/**
 * Get upload file params
 * @param record The record e.g. organization, atsMeta etc
 * @param field The field name
 * @param fileId The file id
 */
export const getFileParams = async (
  record: Record<string, unknown>,
  field: string,
  fileId: string
) => {
  const fileList = record[field] as FileList;
  if (fileList && fileList.length > 0) {
    const blob = await fileToBlob(fileList[0]);
    return {
      fileId,
      fileData: blob,
    };
  }
};
