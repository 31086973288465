import { ActionReducerMapBuilder, AsyncThunk } from '@reduxjs/toolkit';

/**
 * Transforms your AsyncThunk and setter function into the standard reducers
 * @param fetchThunk The thunk made with createAsyncThunk which performs the API call
 * @param setter A function that sets the API result into the correct location(s) in the state
 */
export function addExtraReducers<StateType, LookupType, ApiType>(
  fetchThunk: AsyncThunk<ApiType, LookupType, Record<string, unknown>>,
  setter: (state: StateType, lookup: LookupType, result: ApiResult<ApiType>) => void,
  builder: ActionReducerMapBuilder<StateType>
): void {
  builder
    .addCase(fetchThunk.pending, (state, action) => {
      setter(state as StateType, action.meta.arg, { status: 'loading' });
    })
    .addCase(fetchThunk.fulfilled, (state, action) => {
      setter(state as StateType, action.meta.arg, {
        status: 'succeeded',
        data: action.payload,
      });
    })
    .addCase(fetchThunk.rejected, (state, action) => {
      setter(state as StateType, action.meta.arg, {
        status: 'failed',
        error: action.error,
      });
    });
}
