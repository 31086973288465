import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import contentTabs from 'images/ContentTabs.png';
import lookupTabs from 'images/LookupTabs.png';
import searchBar from 'images/SearchBar.png';
import welcome from 'images/WelcomeSeekOtters.png';

/**
 * The system summary view
 */
export const Summary = (): JSX.Element => {
  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Box sx={{ p: 2 }}>
        <img src={welcome} />
      </Box>
      <Box sx={{ p: 2, textAlign: 'left' }}>
        <Box sx={{ py: 2, borderBottom: 1 }}>
          <Typography>
            <strong>SeekOut Admin</strong> is an internal-only application for viewing and editing
            account details
          </Typography>
        </Box>
        <Box sx={{ py: 2, borderBottom: 1 }}>
          <Typography>
            {'References are available in Guru in the '}
            <Link
              href="https://app.getguru.com/boards/cMMoxMoi/Internal-Admin/?boardSectionId=c079fd77-6422-4191-b1ec-483882f0dc4b"
              target="_blank"
              rel="noopener"
            >
              Engineering \ Internal Admin
            </Link>
            {' section'}
          </Typography>
          <br />
          <Typography>
            {'If this is your first time here, the '}
            <Link
              href="https://app.getguru.com/card/TX4Lek8c/Welcome-to-New-Admin"
              target="_blank"
              rel="noopener"
            >
              Welcome Video
            </Link>
            {' in Guru may help you to get oriented'}
          </Typography>
          <br />
          <Typography>
            {'If you have questions or run into issues, please report them in the '}
            <Link
              href="https://seekout-hq.slack.com/archives/C0421BQH72P"
              target="_blank"
              rel="noopener"
            >
              #admin-support
            </Link>
            {' channel in Slack'}
          </Typography>
        </Box>
        <Box sx={{ py: 2 }}>
          <Typography>
            In the header, the <strong>search bar</strong> can be used to search for Organizations
            (via domain or id) or for Users (via email or id)
          </Typography>
          <Box sx={{ backgroundColor: 'gray', p: 0.5, m: 1, width: 'fit-content' }}>
            <img src={searchBar} />
          </Box>
          <br />
          <Typography>
            On the left, the <strong>lookup tabs</strong> can be used to navigate between the
            Organizations and Users you have opened
          </Typography>
          <Box sx={{ backgroundColor: 'gray', p: 0.5, m: 1, width: 'fit-content' }}>
            <img src={lookupTabs} />
          </Box>
          <br />
          <Typography>
            Below the header, the <strong>content tabs</strong> can be used to switch between
            different types of content (eg. Settings, Actions) for the selected lookup
          </Typography>
          <Box sx={{ backgroundColor: 'gray', p: 0.5, m: 1, width: 'fit-content' }}>
            <img src={contentTabs} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
