import { Box, Button } from '@mui/material';
import { FormField } from 'components/form/FormField';
import { ChangeEvent, useCallback, useMemo, useReducer, useState } from 'react';
import { requiredField } from 'utils/form';

const validationField = 'reason';

const nameField: FormFieldInfo = {
  name: 'reason',
  label: 'Reason',
};

type SubscriptionField = Record<string, unknown>;
interface UserFieldState extends FormState, Partial<SubscriptionField> {}

const userFieldReducer = (state: UserFieldState, newState: UserFieldState) => ({
  ...state,
  ...newState,
});

/**
 * A common form to cancel maxio subscription
 */
export const CancelSubscriptionForm = ({
  onSubmit,
  onCancel,
  sx,
  initialValues,
}: FormProps<SubscriptionField>): JSX.Element => {
  const [formErrors, setFormErrors] = useState({} as FormErrors);
  const [formInput, setFormInput] = useReducer(userFieldReducer, { ...(initialValues || {}) });

  const validateForm = useCallback(
    (fieldValues: Record<string, unknown>) => {
      formErrors[validationField] = requiredField(fieldValues, validationField);
      setFormErrors({ ...formErrors });
      return Object.values(formErrors).every((x) => x === '');
    },
    [formErrors]
  );

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const newValue = e.target.value;
    const fieldValue: Record<string, unknown> = { [name]: newValue };
    setFormInput(fieldValue);
    validateForm(fieldValue);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm(formInput)) return;
    onSubmit(formInput as SubscriptionField);
  };

  const isValidForm = useMemo(() => {
    return Object.values(formErrors).every((x) => x === '');
  }, [formErrors]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        width: '100%',
        margin: (theme) => theme.spacing(0, 'auto', 4, 'auto'),
        ...sx,
      }}
      autoComplete="off"
    >
      <FormField
        name={nameField.name}
        label={nameField.label}
        placeHolder={nameField.label}
        onChange={handleInput}
        {...(formErrors[nameField.name] && {
          error: true,
          helperText: formErrors[nameField.name],
        })}
        defaultValue={formInput[nameField.name as keyof SubscriptionField]}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <Button sx={{ mr: 2 }} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant={!isValidForm ? 'outlined' : 'contained'}
          color={!isValidForm ? 'error' : undefined}
          sx={{ width: 125 }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
