import { updateRecruiter } from 'api/apiThunks';
import { selectDerivedAuth } from 'api/authSlice';
import { selectDerivedUser } from 'api/usersSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RecruiterForm } from 'components/RecruiterForm';
import { ViewFrame } from 'features/frame/ViewFrame';
import { useState } from 'react';
import { asEndOfDay } from 'utils/convert';
import { getOperations } from 'utils/operable';

const dateFields = ['skuRolloverTimeEnd', 'skuExpiryTime', 'expertAllowTS', 'githubAllowTS'];

/**
 * The recruiter edit view
 */
export const Edit = (): JSX.Element => {
  const [operationType, setOperationType] = useState<UserOpName>();

  const { apiUser } = useAppSelector((state) => selectDerivedAuth(state, true));
  const derivedUser = useAppSelector(selectDerivedUser);
  const { userId, recruiter } = derivedUser;

  const contentOperations = operationType ? getOperations(derivedUser, operationType) : [];
  const dispatch = useAppDispatch();

  const saveRecruiter = (formInput: IRecruiter) => {
    const convertToEndOfDay = (field: keyof IRecruiter) => {
      if (formInput[field] && recruiter?.[field] !== formInput[field]) {
        formInput[field] = asEndOfDay(formInput[field]) as never;
      }
    };

    dateFields.forEach((field) => convertToEndOfDay(field as keyof IRecruiter));
    setOperationType('updateRecruiter');
    dispatch(updateRecruiter({ userId, resource: formInput }));
  };

  return (
    <ViewFrame
      contentLoader={{
        message: 'Updating Recruiter',
        contentOperations,
        forceClose: !operationType,
        onClose: () => setOperationType(undefined),
      }}
    >
      <RecruiterForm
        initialValues={{ ...recruiter } as Record<string, FormFieldValue>}
        showSelfFields={apiUser?.id === userId}
        freeTrialTime={recruiter?.sku?.freeTrialTime}
        onSubmit={saveRecruiter}
      />
    </ViewFrame>
  );
};
