import { useState } from 'react';
import { getEmailTrackingReport } from 'api/apiThunks';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { StandardGrid } from 'components/grids/StandardGrid';
import { ViewFrame } from 'features/frame/ViewFrame';
import { getOperations } from 'utils/operable';
import { selectDerivedSystem } from 'api/systemSlice';
import { Box, Button, Divider } from '@mui/material';
import { DateSelector } from 'components/DateSelector';
import dayjs from 'dayjs';
import { downloadFile } from 'utils/files';

/**
 * The Email Tracking logs view
 */
export const EmailTracking = (): JSX.Element => {
  const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day'));
  const [endDate, setEndDate] = useState(dayjs());
  const [forceCloseLoader, setForceCloseLoader] = useState(true);

  const system = useAppSelector(selectDerivedSystem);
  const contentOperations = getOperations(system, 'emailTrackings');
  const { emailTrackings = [] } = system;

  const dispatch = useAppDispatch();

  const fetcher = () => {
    const params = {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    };
    setForceCloseLoader(false);
    dispatch(getEmailTrackingReport(params));
  };

  const disableSubmit = !startDate.isValid() || !endDate.isValid();

  return (
    <ViewFrame
      header={
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <DateSelector label="Start Date" value={startDate} setValue={setStartDate} />
          <DateSelector label="End Date" value={endDate} setValue={setEndDate} />
          <Button variant="contained" disabled={disableSubmit} onClick={fetcher}>
            Generate
          </Button>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ marginLeft: 2, marginRight: 2 }}
          />
          <Button
            variant="contained"
            size="small"
            onClick={() =>
              downloadFile(
                `Email Tracking Report_${startDate.format('YYYY-MM-DD')}_${endDate.format(
                  'YYYY-MM-DD'
                )}.json`,
                JSON.stringify(emailTrackings),
                'text/json'
              )
            }
          >
            Download as JSON
          </Button>
        </Box>
      }
      contentLoader={{
        message: 'Generating Email Tracking Report',
        contentOperations,
        forceClose: forceCloseLoader,
        onClose: () => setForceCloseLoader(true),
      }}
    >
      <StandardGrid
        dataSet={emailTrackings}
        getRowId={(x) => x.emailSequenceUserId}
        filterPlaceholder="Filter the results"
        cols={[
          {
            name: 'Email Sequence UserId',
            valueProperty: 'emailSequenceUserId',
          },
          {
            name: 'To Email Address',
            valueProperty: 'toEmailAddress',
            type: 'email',
          },
          {
            name: 'Is User Uploaded',
            valueProperty: 'isUserUploaded',
            type: 'boolean',
          },
          {
            name: 'Date Sent',
            valueProperty: 'dateSent',
            type: 'date',
          },
          {
            name: 'Opened',
            valueProperty: 'opened',
            type: 'boolean',
          },
          {
            name: 'Replied',
            valueProperty: 'replied',
            type: 'boolean',
          },
          {
            name: 'Bounced',
            valueProperty: 'bounced',
            type: 'boolean',
          },
        ]}
      />
    </ViewFrame>
  );
};
