import { Box, Button } from '@mui/material';
import { FormFields } from 'components/form/FormFields';
import { ChangeEvent, useCallback, useMemo, useReducer, useState } from 'react';
import { isValidRolloverEndDate, requiredField } from 'utils/form';
import { ColumnContent } from './columns/ColumnContent';
import isEmail from 'validator/lib/isEmail';

const getToggleDefaultValue = ({ formInput, field = '' }: FormValueProps<RecruiterState>) => {
  return {
    defaultValue: formInput[field as keyof IRecruiter] || false,
  };
};

const validationFields = [
  'name',
  'primaryEmail',
  'title',
  'companyName',
  'role',
  'skuRolloverTimeEnd',
  'skuExpiryTime',
];
const column1Fields: FormFieldInfo[] = [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'primaryEmail',
    label: 'Email',
  },
  {
    name: 'title',
    label: 'Title',
  },
  {
    name: 'companyName',
    label: 'Company',
  },
  {
    name: 'role',
    label: 'Role',
  },
];

const column2Fields: FormFieldInfo[] = [
  {
    type: 'date',
    name: 'skuRolloverTimeEnd',
    label: 'Rollover End Date',
  },
  {
    type: 'date',
    name: 'skuExpiryTime',
    label: 'End Date',
  },
  {
    type: 'date',
    name: 'expertAllowTS',
    label: 'Expert Expiry Date',
  },
  {
    type: 'date',
    name: 'githubAllowTS',
    label: 'Github Expiry Date',
  },
  {
    type: 'number',
    name: 'maxSearchResults',
    label: 'Max Search Results',
  },
];

const column3Fields: FormFieldInfo[] = [
  {
    type: 'toggle',
    name: 'darkMode',
    label: 'Dark Mode',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'isDisabled',
    label: 'Disabled',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'isAlumniFilterEnabled',
    label: 'Alumni Filter Enabled',
    getDefaultValue: getToggleDefaultValue,
  },
];

const selfFields: FormFieldInfo[] = [
  {
    name: 'runtimeAPIEndpoint',
    label: 'Runtime API Endpoint',
  },
  {
    name: 'atsConnectionId',
    label: 'ATS Connection Id',
  },
];

const disableFreeTrialFields = ['skuRolloverTimeEnd', 'skuExpiryTime'];

interface RecruiterState extends FormState, Partial<IRecruiter> {}

interface Props extends FormProps<IRecruiter> {
  showSelfFields?: boolean;
  freeTrialTime?: number;
}

const recruiterReducer = (state: RecruiterState, newState: RecruiterState) => ({
  ...state,
  ...newState,
});

/**
 * A form to edit a recruiter
 */
export const RecruiterForm = ({
  onSubmit,
  sx,
  initialValues,
  showSelfFields,
  freeTrialTime,
}: Props): JSX.Element => {
  const [formErrors, setFormErrors] = useState({} as FormErrors);
  const [formInput, setFormInput] = useReducer(recruiterReducer, { ...(initialValues || {}) });

  const skuExpiryTime = useMemo(() => {
    return (initialValues?.skuExpiryTime || 0) as number;
  }, [initialValues?.skuExpiryTime]);

  const validateForm = useCallback(
    (fieldValues: Record<string, unknown>, isSubmit = false) => {
      const validateFields = (fields: string[]) => {
        fields.forEach((field) => {
          formErrors[field] = requiredField(fieldValues, field);
          if (field === 'primaryEmail' && formErrors[field] === '') {
            formErrors[field] = !isEmail(fieldValues[field] as string) ? 'Invalid email' : '';
          } else if (field === 'skuRolloverTimeEnd' && formErrors[field] === '') {
            formErrors[field] = isValidRolloverEndDate(fieldValues[field] as number, skuExpiryTime);
          }
        });
      };

      let fields: string[] = [];
      if (isSubmit) {
        // Do full form validation
        fields = validationFields;
      } else {
        fields = Object.entries(fieldValues)
          .filter(([key]) => validationFields.includes(key))
          .map(([key]) => key);
      }

      validateFields(fields);
      setFormErrors({ ...formErrors });
      return Object.values(formErrors).every((x) => x === '');
    },
    [formErrors, skuExpiryTime]
  );

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const fieldValue: Record<string, unknown> = {};
    let newValue;
    switch (e.target.type) {
      case 'number':
      case 'date':
        newValue = parseInt(e.target.value);
        break;

      case 'checkbox':
        newValue = e.target.checked;
        break;

      default:
        newValue = e.target.value;
        break;
    }

    if (Object.keys(fieldValue).length === 0) {
      fieldValue[name] = newValue;
    }
    setFormInput(fieldValue);
    validateForm(fieldValue);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm(formInput, true)) return;
    onSubmit(formInput as IRecruiter);
  };

  const columnFields = (formFields: FormFieldInfo[]) => {
    return FormFields<IRecruiter>({
      formFields,
      formInput: formInput as IRecruiter,
      formErrors: formErrors,
      handleInput: handleInput,
    });
  };

  const column2FieldsList = useMemo(() => {
    return column2Fields.map((formField) => {
      const additionalProps = formField.additionalProps || {};
      if (disableFreeTrialFields.includes(formField.name)) {
        additionalProps['disabled'] = !!freeTrialTime;
      }
      return {
        ...formField,
        additionalProps,
      };
    });
  }, [freeTrialTime]);

  const isValidForm = useMemo(() => {
    return Object.values(formErrors).every((x) => x === '');
  }, [formErrors]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        width: '100%',
        margin: (theme) => theme.spacing(0, 'auto', 4, 'auto'),
        ...sx,
      }}
      autoComplete="off"
    >
      <ColumnContent
        columns={[
          <>{columnFields(column1Fields)}</>,
          <>{columnFields(column2FieldsList)}</>,
          <>{columnFields(column3Fields)}</>,
        ]}
        growWidth
      />
      {showSelfFields && <Box sx={{ pl: 2, pr: 2 }}>{columnFields(selfFields)}</Box>}
      <Button
        type="submit"
        variant={!isValidForm ? 'outlined' : 'contained'}
        color={!isValidForm ? 'error' : undefined}
        sx={{ width: 162, alignSelf: 'center', mt: 5 }}
      >
        Save
      </Button>
    </Box>
  );
};
