import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import { Divider } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { getUserSearchHistory, deleteUserSearchHistory } from 'api/apiThunks';
import { selectDerivedUser } from 'api/usersSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { DateSelector } from 'components/DateSelector';
import { StandardGrid } from 'components/grids/StandardGrid';
import { ViewFrame } from 'features/frame/ViewFrame';
import { ConfirmModal } from 'components/modals/ConfirmModal';
import { getOperations } from 'utils/operable';

/**
 * The search history view
 */
export const SearchHistory = (): JSX.Element => {
  const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day'));
  const [endDate, setEndDate] = useState(dayjs());
  const [forceCloseLoader, setForceCloseLoader] = useState(true);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [operationType, setOperationType] = useState<UserOpName>();

  const user = useAppSelector(selectDerivedUser);
  const contentOperations = getOperations(user, operationType ? operationType : 'searchHistory');
  const { userId, searchHistory } = user;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (operationType) {
      if (userId && !contentOperations[0]) {
        dispatch(deleteUserSearchHistory(userId));
      }
      if (contentOperations[0]?.status === 'succeeded') {
        setOperationType(undefined);
        setForceCloseLoader(true);
      }
    }
  }, [dispatch, userId, operationType, contentOperations]);

  if (!userId) return <></>;

  const disableSubmit = !startDate.isValid() || !endDate.isValid();

  // Each searchHistory item maybe represent multiple queries
  // Flatten them out such that historyItem.queryList always contains a single entry
  const data =
    searchHistory?.flatMap((historyItem) =>
      historyItem.queryList.map((query, index) => ({
        ...historyItem,
        id: `${historyItem.id}-${index}`,
        queryList: [query],
      }))
    ) ?? [];

  const fetcher = () => {
    const params = {
      userId,
      startDate: startDate.startOf('day').toISOString(),
      endDate: endDate.endOf('day').toISOString(),
    };

    setForceCloseLoader(false);
    dispatch(getUserSearchHistory(params));
  };

  return (
    <ViewFrame
      header={
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <DateSelector label="Start Date" value={startDate} setValue={setStartDate} />
          <DateSelector label="End Date" value={endDate} setValue={setEndDate} />
          <Button variant="contained" disabled={disableSubmit} onClick={fetcher}>
            Search
          </Button>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ marginLeft: 2, marginRight: 2 }}
          />
          <Button variant="contained" onClick={() => setConfirmModalOpen(true)} size="small">
            Delete All
          </Button>
        </Box>
      }
      contentLoader={{
        message: operationType ? 'Deleting Search History' : 'Getting Search History',
        contentOperations,
        forceClose: forceCloseLoader,
        onClose: () => setForceCloseLoader(true),
      }}
    >
      <StandardGrid
        dataSet={data}
        tipModel="SearchHistory"
        getRowId={(x) => x.id}
        filterPlaceholder="Filter the results"
        cols={[
          {
            name: 'Type',
            valueProperty: 'searchType',
            relativeWidth: 0.5,
          },
          {
            name: 'Search',
            valueProperty: 'name',
          },
          {
            name: 'Description',
            valueProperty: 'subTitle',
            relativeWidth: 3,
          },
          {
            name: 'Url',
            getValue: (x) => `https://app.seekout.io/${x.queryList[0]}`,
            relativeWidth: 3,
          },
          {
            name: 'Date',
            valueProperty: 'dateCreated',
            type: 'date',
          },
        ]}
      />
      <ConfirmModal
        open={confirmModalOpen}
        prompt="Delete all user's search history?"
        onClose={() => setConfirmModalOpen(false)}
        onAccept={() => setOperationType('deleteSearchHistory')}
      />
    </ViewFrame>
  );
};
