import { Box, Button } from '@mui/material';
import { ChangeEvent, useReducer } from 'react';
import { FormFields } from 'components/form/FormFields';

const getFieldDefaultValue = ({ formInput, field = '' }: FormValueProps<OrganizationState>) => {
  const fieldValue = formInput[field as keyof ISearchFiltersOrder];
  let defaultValue = '';
  if (Array.isArray(fieldValue)) {
    defaultValue = fieldValue.join(',');
  } else {
    defaultValue = (fieldValue || '') as string;
  }
  return { defaultValue };
};

const formFields: FormFieldInfo[] = [
  {
    name: 'public',
    label: 'Public',
    getDefaultValue: getFieldDefaultValue,
  },
  {
    name: 'github',
    label: 'GitHub',
    getDefaultValue: getFieldDefaultValue,
  },
  {
    name: 'expert',
    label: 'Expert',
    getDefaultValue: getFieldDefaultValue,
  },
  {
    name: 'internal',
    label: 'Internal',
    getDefaultValue: getFieldDefaultValue,
  },
];

interface OrganizationState extends FormState, Partial<ISearchFiltersOrder> {}

const organizationReducer = (state: OrganizationState, newState: OrganizationState) => ({
  ...state,
  ...newState,
});

export const SearchFiltersOrder = ({ initialValues, onSubmit }: FormProps<ISearchFiltersOrder>) => {
  const [formInput, setFormInput] = useReducer(organizationReducer, { ...(initialValues || {}) });

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const fieldValue: Record<string, unknown> = {
      [e.target.name]: e.target.value,
    };
    setFormInput(fieldValue);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const record = formInput as Record<string, unknown>;
    const getValue = (field: string) => {
      if (!record[field]) return [];
      if (Array.isArray(record[field])) {
        return record[field] as string[];
      }
      return (record[field] as string).split(',');
    };
    onSubmit({
      public: getValue('public'),
      github: getValue('github'),
      expert: getValue('expert'),
      internal: getValue('internal'),
    });
  };

  const columnFields = (formFields: FormFieldInfo[]) => {
    return FormFields<IOrganization>({
      formFields,
      formInput: formInput as IOrganization,
      formErrors: {},
      handleInput: handleInput,
    });
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        width: '100%',
        margin: (theme) => theme.spacing(0, 'auto', 4, 'auto'),
      }}
      autoComplete="off"
    >
      <Box sx={{ pl: 2, pr: 2 }}>{columnFields(formFields)}</Box>
      <Button
        type="submit"
        variant="contained"
        sx={{
          display: 'block',
          margin: (theme) => theme.spacing(5, 'auto', 0, 'auto'),
          width: 162,
          mt: 5,
        }}
      >
        Save
      </Button>
    </Box>
  );
};
