import { SxProps, Theme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useState } from 'react';

interface AsyncMultiSelectDropdownProps {
  placeholder: string;
  suggestions: SelectOption[];
  onChange: (values: SelectOption[]) => void;
  onInputChange?: (value: string) => void;
  onClear?: () => void;
  label?: string;
  size?: 'small' | 'medium';
  disableSubmitReset?: boolean;
  value?: SelectOption[];
  noOptionsText?: string;
  sx?: SxProps<Theme>;
}

export const AsyncMultiSelectDropdown = ({
  placeholder,
  suggestions,
  onChange,
  onInputChange,
  onClear,
  label,
  size = 'medium',
  disableSubmitReset,
  value,
  noOptionsText,
  sx,
}: AsyncMultiSelectDropdownProps): JSX.Element => {
  const [inputValue, setInputValue] = useState('');

  return (
    <Autocomplete
      size={size}
      multiple
      options={suggestions}
      getOptionLabel={(option) => option.text}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue, reason) => {
        if (reason === 'reset' && disableSubmitReset) return;
        setInputValue(newInputValue);
        if (onInputChange) {
          onInputChange(newInputValue);
        }
        if (reason === 'clear' && onClear) {
          onClear();
        }
      }}
      onChange={(_event, newValue, reason) => {
        setInputValue('');
        if (
          reason === 'selectOption' ||
          (reason === 'removeOption' && typeof newValue === 'object')
        ) {
          onChange(newValue);
        } else if (reason === 'clear' && onClear) {
          onClear();
        }
      }}
      renderInput={(params) => <TextField {...params} label={label} placeholder={placeholder} />}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      value={value}
      noOptionsText={noOptionsText}
      sx={sx}
    />
  );
};
