import { useState } from 'react';

import { addAdminUser, deleteAdminUser, updateAdminUserPrivilege } from 'api/apiThunks';
import { selectDerivedSystem } from 'api/systemSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { StandardGrid } from 'components/grids/StandardGrid';
import { TextControlHeader } from 'components/headers/TextControlHeader';
import { ViewFrame } from 'features/frame/ViewFrame';
import { getOperations } from 'utils/operable';
import { getAdminPrivilegeOptions } from 'utils/selectorOptions';
import { Privilege } from 'types/enums';
import { usePrivilegeSelector } from 'utils/usePrivilegeSelector';

/**
 * The system admin permissions' view
 */
export const AdminPermissions = (): JSX.Element => {
  const [operationType, setOperationType] = useState<SystemOpName>();
  const [operationKey, setOperationKey] = useState('');

  const system = useAppSelector(selectDerivedSystem);
  const adminPrivilege = usePrivilegeSelector();
  const viewOperations = getOperations(system, 'admins');
  const contentOperations = operationType ? getOperations(system, operationType) : [];
  const { admins = [] } = system;

  const dispatch = useAppDispatch();

  const adminPrivilegeOptions = getAdminPrivilegeOptions();

  let operationDisplayName;
  switch (operationType) {
    case 'addAdmin':
      operationDisplayName = `Adding ${operationKey} to Admin list`;
      break;

    case 'updateAdmin':
      operationDisplayName = `Updating ${
        admins.find((x) => x.actualUserId === operationKey)?.emailAddress
      } in Admin list`;
      break;

    case 'removeAdmin':
      operationDisplayName = `Removing ${
        admins.find((x) => x.id === operationKey)?.emailAddress
      } from Admin list`;
      break;

    default:
      operationDisplayName = '';
      break;
  }

  return (
    <ViewFrame
      viewLoader={{ message: 'Loading admin user information', viewOperations }}
      header={
        <TextControlHeader
          label="Email"
          tooltip="Grant admin privileges to the user with this email address"
          validationType="email"
          submitText="Add"
          onSubmit={(addEmailText: string) => {
            setOperationType('addAdmin');
            setOperationKey(addEmailText);
            dispatch(addAdminUser(addEmailText));
          }}
        />
      }
      contentLoader={{
        message: operationDisplayName,
        contentOperations,
        forceClose: !operationType,
        onClose: () => setOperationType(undefined),
      }}
    >
      <StandardGrid
        dataSet={admins}
        tipModel="AdminUser"
        filterPlaceholder="Filter the displayed users"
        getRowId={(x) => x.actualUserId}
        getOpenAction={(x) => ({ id: x.actualUserId, type: 'User' })}
        cols={[
          {
            name: 'Email',
            valueProperty: 'emailAddress',
            type: 'email',
          },
          {
            name: 'UserId',
            valueProperty: 'actualUserId',
          },
          {
            name: 'Admin Since',
            valueProperty: 'dateCreated',
            type: 'date',
          },
          {
            name: 'Privilege',
            valueProperty: 'privilege',
            getValue: (x) => x.privilege ?? Privilege.All,
            editOptions: adminPrivilegeOptions,
          },
        ]}
        getDeleteAction={
          adminPrivilege === Privilege.All
            ? {
                action: (id) => {
                  setOperationType('removeAdmin');
                  setOperationKey(id as string);
                  dispatch(deleteAdminUser(id as string));
                },
                description: 'Remove admin permissions',
                getDeleteInfo: (x) => ({ value: x.id, confirmName: x.emailAddress }),
              }
            : undefined
        }
        getEditAction={
          adminPrivilege === Privilege.All
            ? (oldRow, newRow) => {
                if (oldRow['Privilege'] !== newRow['Privilege']) {
                  const id = newRow['id'] as string;
                  const email = newRow['Email'] as string;
                  const privilege = newRow['Privilege'] as Privilege;
                  setOperationType('updateAdmin');
                  setOperationKey(id);
                  dispatch(updateAdminUserPrivilege({ actualUserId: id, email, privilege }));
                }
              }
            : undefined
        }
      />
    </ViewFrame>
  );
};
