import { useEffect, useMemo, useState } from 'react';

import {
  createOrUpdateOrgPowerFilter,
  deleteOrgPowerFilter,
  getOrgPowerFilters,
} from 'api/apiThunks';
import { selectDerivedOrganization } from 'api/organizationsSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { StandardGrid } from 'components/grids/StandardGrid';
import { ViewFrame } from 'features/frame/ViewFrame';
import { getOperations } from 'utils/operable';
import { Button } from '@mui/material';
import { Modal } from 'components/modals/Modal';
import { PowerFiltersForm } from 'components/PowerFiltersForm';
import { enumFromValue, PowerFiltersSearchType } from 'types/enums';

/**
 * The Power Filters view
 */
export const PowerFilters = (): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  const [editPowerFilter, setEditPowerFilter] = useState<Partial<IRecipe> | undefined>();
  const [action, setAction] = useState('');
  const [operationType, setOperationType] = useState<OrgOpName>();
  const [operationKey, setOperationKey] = useState('');
  const org = useAppSelector(selectDerivedOrganization);
  const viewOperations = getOperations(org, 'powerFilters');
  const contentOperations = useMemo(() => {
    return operationType ? getOperations(org, operationType) : [];
  }, [org, operationType]);
  const [getOrgPowerFiltersOp] = viewOperations;
  const { orgId, powerFilters = [] } = org;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (orgId && !getOrgPowerFiltersOp) {
      dispatch(getOrgPowerFilters(orgId));
    }
  }, [dispatch, orgId, getOrgPowerFiltersOp]);

  useEffect(() => {
    if (!!operationType && contentOperations[0]?.status === 'succeeded') {
      if (operationType === 'updatePowerFilter') {
        setEditPowerFilter(undefined);
      } else if (operationType === 'deletePowerFilter') {
        setOperationKey('');
      }
      setAction('');
      setOperationType(undefined);
    }
  }, [operationType, contentOperations]);

  useEffect(() => {
    if (!modalOpen && editPowerFilter) {
      setEditPowerFilter(undefined);
    }
  }, [modalOpen, editPowerFilter]);

  if (!orgId) return <></>;

  const handleOnClose = () => setModalOpen(false);

  const handleOnSubmit = async (formInput: Partial<IRecipe>) => {
    setAction(formInput.id ? 'update' : 'create');
    setOperationType('updatePowerFilter');
    dispatch(createOrUpdateOrgPowerFilter({ orgId, resource: formInput }));
    handleOnClose();
  };

  let operationDisplayName = '';
  if (action === 'create') {
    operationDisplayName = 'Creating';
  } else {
    operationDisplayName =
      action === 'update'
        ? 'Updating'
        : `Deleting "${powerFilters.find((x) => x.id === operationKey)?.name}"`;
  }

  return (
    <ViewFrame
      viewLoader={{ message: 'Loading Power Filters', viewOperations }}
      contentLoader={{
        message: `${operationDisplayName} Power Filter`,
        contentOperations,
        forceClose: !operationType,
        onClose: () => {
          setAction('');
          setOperationType(undefined);
        },
      }}
      header={
        <>
          <Button
            variant="contained"
            size="small"
            onClick={() => setModalOpen(true)}
            sx={{ my: 1 }}
            disabled={!!operationType}
          >
            Create
          </Button>
        </>
      }
    >
      <StandardGrid
        dataSet={powerFilters}
        tipModel="Recipe"
        getRowId={(x) => x.id}
        filterPlaceholder="Filter the results"
        cols={[
          {
            name: 'Category',
            valueProperty: 'category',
          },
          {
            name: 'Name',
            valueProperty: 'name',
          },
          {
            name: 'Description',
            valueProperty: 'description',
          },
          {
            name: 'Search',
            valueProperty: 'search',
            relativeWidth: 2,
          },
          {
            name: 'Created By',
            valueProperty: 'createdBy',
          },
          {
            name: 'Search Type',
            valueProperty: 'searchType',
            getValue: (x) => enumFromValue(x.searchType, PowerFiltersSearchType),
          },
        ]}
        getCustomEditAction={{
          action: (value) => {
            setEditPowerFilter(value);
            setModalOpen(true);
          },
        }}
        getDeleteAction={{
          action: (id) => {
            setAction('delete');
            setOperationType('deletePowerFilter');
            setOperationKey(id as string);
            dispatch(deleteOrgPowerFilter({ orgId, value: id as string }));
          },
          description: 'Delete Power Filter',
          getDeleteInfo: (x) => ({ value: x.id, confirmName: x.name }),
        }}
      />
      <Modal
        title={`${editPowerFilter ? 'Edit' : 'Create'} Power Filter`}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        additionalProps={{
          fullWidth: true,
          maxWidth: 'sm',
          PaperProps: { sx: { display: 'flex', flexDirection: 'column', height: '100%' } },
        }}
        noActions
      >
        <PowerFiltersForm
          initialValues={{ ...editPowerFilter } as Record<string, FormFieldValue>}
          onSubmit={handleOnSubmit}
          onCancel={handleOnClose}
        />
      </Modal>
    </ViewFrame>
  );
};
