import { useEffect } from 'react';

import { getATSReports } from 'api/apiThunks';
import { selectDerivedSystem } from 'api/systemSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { StandardGrid } from 'components/grids/StandardGrid';
import { ViewFrame } from 'features/frame/ViewFrame';
import { asTimeDeltaDisplayString } from 'utils/convert';
import { getOperations } from 'utils/operable';

/**
 * The system ATS report view
 */
export const AtsReport = (): JSX.Element => {
  const system = useAppSelector(selectDerivedSystem);
  const viewOperations = getOperations(system, 'atsReports');
  const [atsReportOp] = viewOperations;
  const { atsReports = [] } = system;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!atsReportOp) {
      dispatch(getATSReports());
    }
  }, [dispatch, atsReportOp]);

  return (
    <ViewFrame viewLoader={{ message: 'Loading ATS Reports', viewOperations }}>
      <StandardGrid
        dataSet={atsReports}
        tipModel="ATSReport"
        getRowId={(x) => `${x.id}_${x.userId}`}
        getOpenAction={(x) => ({ id: x.userId, type: 'Organization' })}
        filterPlaceholder="Filter the reports..."
        cols={[
          {
            name: 'Organization',
            description: "The Organization's domain",
            valueProperty: 'orgDomain',
            relativeWidth: 2,
          },
          {
            name: 'ATS',
            description: 'The Applicant Tracking System (ATS)',
            valueProperty: 'ats',
            relativeWidth: 2,
          },
          {
            name: 'Full Download',
            description: 'Is this record for a full ATS download?',
            valueProperty: 'isFullDownload',
            type: 'boolean',
          },
          {
            name: 'Downloaded',
            description: 'The number of downloaded candidates',
            valueProperty: 'totalDownloadedCandidates',
            type: 'number',
          },
          {
            name: 'Indexed',
            description: 'The number of indexed candidates',
            valueProperty: 'totalIndexedCandidates',
            type: 'number',
          },
          {
            name: 'Matched',
            description: 'The number of matched candidates',
            valueProperty: 'totalMatchedCandidates',
            type: 'number',
          },
          {
            name: 'Jobs',
            valueProperty: 'totalJobs',
            type: 'number',
          },
          {
            name: 'Attachments',
            valueProperty: 'totalAttachments',
            type: 'number',
          },
          {
            name: 'Response Time',
            description: 'The average response time',
            valueProperty: 'averageResponseTime',
            getValue: (x) => x.averageResponseTime.toFixed(2),
            type: 'number',
          },
          {
            name: 'Last Download Start',
            description: 'Last download start time',
            valueProperty: 'startTS',
            type: 'date',
            relativeWidth: 2,
          },
          {
            name: 'Last Download Duration',
            description: 'Last download timed duration',
            getValue: (x) => asTimeDeltaDisplayString(x.startTS, x.endTS),
          },
          {
            name: 'Status',
            description: 'Status of last ATS download',
            getValue: (x) =>
              !x.error || Object.keys(x.error).length === 0 ? 'Completed' : 'Failed',
          },
          {
            name: 'Error',
            description: 'The error encountered, if any',
            valueProperty: 'message',
            relativeWidth: 3,
          },
        ]}
      />
    </ViewFrame>
  );
};
