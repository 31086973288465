import { useEffect, useMemo, useState } from 'react';

import {
  deleteExportFormatField,
  getAllFieldData,
  getExportFormatFields,
  getOrgExportFormatFields,
  getOrgExportFormats,
  updateExportFormatField,
} from 'api/apiThunks';
import { selectDerivedOrganization } from 'api/organizationsSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { StandardGrid } from 'components/grids/StandardGrid';
import { ViewFrame } from 'features/frame/ViewFrame';
import { getOperations } from 'utils/operable';
import { selectDerivedSystem } from 'api/systemSlice';
import { ExportFormatsHeader } from 'components/headers/ExportFormatsHeader';

/**
 * The Export Formats view
 */
export const ExportFormats = (): JSX.Element => {
  const [operationType, setOperationType] = useState<OrgOpName>();
  const [selectedExportFormatId, setSelectedExportFormatId] = useState('');
  const [deleteFieldName, setDeleteFieldName] = useState('');

  const system = useAppSelector(selectDerivedSystem);
  const org = useAppSelector(selectDerivedOrganization);
  const viewOperations = [
    ...getOperations(system, 'exportFormatFields'),
    ...getOperations(org, 'exportFormatFields'),
    ...getOperations(org, 'exportFormats'),
    ...getOperations(org, 'configurableFields'),
    ...getOperations(org, 'internalSavedFields'),
  ];
  const contentOperations = useMemo(() => {
    return operationType ? getOperations(org, operationType) : [];
  }, [org, operationType]);
  const [
    exportFormatFieldsOp,
    orgExportFormatFieldsOp,
    exportFormatsOp,
    configurableFieldssOp,
    internalSavedFieldsOp,
  ] = viewOperations;
  const { orgId, exportFormatFields = [], exportFormats = [], internalSavedFields = [] } = org;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (orgId && !exportFormatFieldsOp) {
      dispatch(getExportFormatFields());
    }
    if (orgId && org.org?.domain && !orgExportFormatFieldsOp) {
      dispatch(getOrgExportFormatFields({ orgId, value: org.org?.domain }));
    }
    if (orgId && !exportFormatsOp) {
      dispatch(getOrgExportFormats(orgId));
    }
  }, [
    dispatch,
    orgId,
    org.org?.domain,
    exportFormatFieldsOp,
    orgExportFormatFieldsOp,
    exportFormatsOp,
  ]);

  useEffect(() => {
    if (orgId && !internalSavedFieldsOp) {
      dispatch(getAllFieldData(orgId));
    }
  }, [dispatch, orgId, org.org?.domain, configurableFieldssOp, internalSavedFieldsOp]);

  const getInternalSavedField = (fieldName: string) => {
    const name = fieldName.replace('configurable_data/', '');
    return internalSavedFields.find((f) => f.name === name);
  };

  useEffect(() => {
    if (!!operationType && contentOperations[0]?.status === 'succeeded') {
      if (operationType === 'deleteExportFormat') {
        setSelectedExportFormatId('');
      } else if (operationType === 'deleteExportFormatField') {
        setDeleteFieldName('');
      }
      setOperationType(undefined);
    }
  }, [operationType, contentOperations]);

  const gridData = useMemo(() => {
    const filteredExportFormats =
      selectedExportFormatId.length > 0
        ? exportFormats.filter((exportFormat) => exportFormat.id === selectedExportFormatId)
        : exportFormats;
    return filteredExportFormats
      .map((exportFormat) => {
        return Object.keys(exportFormat.fields).map((key, idx) => {
          return {
            // need to uniquely generate row ids for each key-value pair
            id: `${exportFormat.id}_${idx}`,
            name: exportFormat.name,
            key: key,
            value: exportFormat.fields[key],
          };
        });
      })
      .flat();
  }, [exportFormats, selectedExportFormatId]);

  if (!orgId) return <></>;

  let contentLoaderMessage;
  switch (operationType) {
    case 'addExportFormat':
      contentLoaderMessage = 'Adding export format';
      break;

    case 'deleteExportFormat':
      contentLoaderMessage = `Deleting export format ${
        exportFormats.find((o) => o.id === selectedExportFormatId)?.name
      }`;
      break;

    case 'updateExportFormatField':
      contentLoaderMessage = 'Saving export format field';
      break;

    case 'deleteExportFormatField':
      contentLoaderMessage = `Deleting export format field ${deleteFieldName}`;
      break;

    default:
      contentLoaderMessage = '';
      break;
  }

  return (
    <ViewFrame
      viewLoader={{ message: 'Loading Export Formats and Fields', viewOperations }}
      contentLoader={{
        message: contentLoaderMessage,
        contentOperations,
        forceClose: !operationType,
        onClose: () => setOperationType(undefined),
      }}
      header={
        <ExportFormatsHeader
          orgId={orgId}
          orgExportFormatFields={exportFormatFields}
          exportFormats={exportFormats}
          operationType={operationType}
          selectedExportFormatId={selectedExportFormatId}
          setSelectedExportFormatId={(value) => setSelectedExportFormatId(value)}
          setOperationType={(operationType: ExportFormatOpName) =>
            setOperationType(operationType as OrgOpName)
          }
        />
      }
    >
      <StandardGrid
        dataSet={gridData}
        getRowId={(x) => x.id}
        filterPlaceholder="Filter the results"
        cols={[
          {
            name: 'Export Format',
            getValue: (x) => x.name,
          },
          {
            name: 'Field Name',
            getValue: (x) => x.key,
          },
          {
            name: 'Field Label',
            getValue: (x) => x.value,
            isEditable: true,
          },
          {
            name: 'Excel Export Format',
            getValue: (x) => getInternalSavedField(x.key)?.excelExportFormat || '',
          },
        ]}
        getEditAction={(oldRow, newRow) => {
          if (oldRow['Field Label'] !== newRow['Field Label']) {
            setOperationType('updateExportFormatField');
            dispatch(
              updateExportFormatField({
                orgId,
                exportFormatId: (newRow['id'] as string).substring(0, 36),
                fieldName: newRow['Field Name'] as string,
                fieldLabel: newRow['Field Label'] as string,
              })
            );
          }
        }}
        getDeleteAction={{
          action: (value) => {
            const fieldName = (value as Record<string, string>)['key'];
            setOperationType('deleteExportFormatField');
            setDeleteFieldName(fieldName);
            dispatch(
              deleteExportFormatField({
                orgId,
                exportFormatId: (value as Record<string, string>)['id'].substring(0, 36),
                fieldName,
              })
            );
          },
          description: 'Delete Field',
          getDeleteInfo: (x) => ({ value: x, confirmName: x.key }),
        }}
      />
    </ViewFrame>
  );
};
