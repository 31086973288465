import { Box, Button } from '@mui/material';
import { StandardGrid } from 'components/grids/StandardGrid';
import { Selector } from 'components/Selector';
import { ViewFrame } from 'features/frame/ViewFrame';
import { useEffect, useMemo, useState } from 'react';
import { enumFromValue, SearchIndex } from 'types/enums';
import { IndexSetting } from 'types/server/common/enums';
import { getIndexSettingOptions, getSearchIndexOptions } from 'utils/selectorOptions';

interface DiversitySetting {
  indexName: string;
  indexSetting: number;
}

interface Props {
  data?: Record<string, number>;
  onSave: (indexName: string, indexSetting: IndexSetting) => void;
  onRemove: (indexName: string) => void;
  operationType?: OrgOpName;
  contentOperations: Operation[];
  onContentLoderClose: () => void;
}

export const DiversitySettings = ({
  data = {},
  onSave,
  onRemove,
  operationType,
  contentOperations,
  onContentLoderClose,
}: Props) => {
  const [indexName, setIndexName] = useState('');
  const [indexSetting, setIndexSetting] = useState<number>(IndexSetting.Off);
  const indexSettingOptions = getIndexSettingOptions();

  useEffect(() => {
    if (data[indexName]) {
      setIndexName('');
    }
  }, [data, indexName]);

  const gridData: DiversitySetting[] = useMemo(() => {
    return Object.entries(data).map(([key, value]) => ({
      indexName: key,
      indexSetting: value,
    }));
  }, [data]);
  const existingSettings = gridData.map((o) => o.indexName);
  const indexOptions = getSearchIndexOptions().filter((o) => !existingSettings.includes(o.value));

  return (
    <ViewFrame
      contentLoader={{
        message: 'Saving Change',
        contentOperations,
        forceClose: !operationType,
        onClose: onContentLoderClose,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'baseline', my: 2 }}>
          <Selector
            label="Index"
            options={indexOptions}
            value={indexName}
            setValue={(val) => setIndexName(val as string)}
            sx={{ padding: 0, ml: 2, minWidth: 150 }}
          />
          <Selector
            label="Setting"
            options={indexSettingOptions}
            value={indexSetting.toString()}
            setValue={(val) => setIndexSetting(parseInt(val as string))}
            sx={{ padding: 0, ml: 2, minWidth: 105 }}
          />
          <Button
            variant="contained"
            size="small"
            disabled={!indexName.length}
            onClick={() => onSave(indexName, indexSetting)}
            sx={{ ml: 2 }}
          >
            Add
          </Button>
        </Box>
        <Box sx={{ flexGrow: 1, width: '100%' }}>
          <StandardGrid
            dataSet={gridData}
            getRowId={(x) => x.indexName}
            filterPlaceholder="Filter the results"
            cols={[
              {
                name: 'Index',
                getValue: (x) => enumFromValue(x.indexName, SearchIndex),
              },
              {
                name: 'Setting',
                getValue: (x) => (x.indexSetting as IndexSetting).toString(),
                editOptions: indexSettingOptions,
              },
            ]}
            getEditAction={(oldRow, newRow) => {
              const indexName = oldRow['id'] as string;
              if (oldRow['Setting'] !== newRow['Setting']) {
                onSave(indexName, newRow['Setting'] as IndexSetting);
              }
            }}
            getDeleteAction={{
              action: (value) => onRemove(value as string),
              description: 'Delete Index Name',
              getDeleteInfo: (x) => ({
                value: x.indexName,
                confirmName: enumFromValue(x.indexName, SearchIndex),
              }),
            }}
          />
        </Box>
      </Box>
    </ViewFrame>
  );
};
