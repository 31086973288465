import { useEffect, useState } from 'react';

import { getAtsConnections, getOrgATSExportHistory } from 'api/apiThunks';
import { selectDerivedOrganization } from 'api/organizationsSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { StandardGrid } from 'components/grids/StandardGrid';
import { ViewFrame } from 'features/frame/ViewFrame';
import { getOperations } from 'utils/operable';
import { Box, Button } from '@mui/material';
import { Selector } from 'components/Selector';
import { ATSType } from 'types/server/common/enums';

const AtsDisplayMap = Object.fromEntries(Object.entries(ATSType).map(([key, val]) => [val, key]));

/**
 * The Organization's ATS Export History view
 */
export const AtsExportHistory = (): JSX.Element => {
  const [forceCloseLoader, setForceCloseLoader] = useState(true);
  const [selectedAtsType, setSelectedAtsType] = useState('');

  const derivedOrg = useAppSelector(selectDerivedOrganization);
  const viewOperations = getOperations(derivedOrg, 'atsConnections');
  const contentOperations = getOperations(derivedOrg, 'atsExportHistory');
  const { orgId, atsConnections = [], atsExportHistory = [] } = derivedOrg;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (orgId && !viewOperations[0]) {
      dispatch(getAtsConnections(orgId));
    }
  }, [dispatch, orgId, viewOperations]);

  useEffect(() => {
    if (atsConnections.length > 0) {
      setSelectedAtsType(atsConnections[0].atsType);
    }
  }, [atsConnections]);

  const atsTypes = Array.from(new Set(atsConnections.map((x) => x.atsType)));
  const selectorOptions = atsTypes.map((x) => ({
    text: AtsDisplayMap[x] ?? x,
    value: x,
  }));

  return (
    <ViewFrame
      viewLoader={{ message: 'Loading ATS Connections', viewOperations }}
      header={
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline' }}>
          <Selector
            label="ATS Connection"
            options={selectorOptions}
            value={selectedAtsType}
            setValue={(val) => setSelectedAtsType(val as string)}
          />
          <Button
            variant="contained"
            disabled={!selectedAtsType}
            onClick={() => {
              setForceCloseLoader(false);
              dispatch(getOrgATSExportHistory({ orgId, value: selectedAtsType }));
            }}
            sx={{ margin: 1 }}
          >
            View
          </Button>
        </Box>
      }
      contentLoader={{
        message: `Fetching ATS Export History`,
        contentOperations,
        forceClose: forceCloseLoader,
        onClose: () => setForceCloseLoader(true),
      }}
    >
      <StandardGrid
        dataSet={atsExportHistory}
        tipModel="ATSExportLogFromDB"
        getRowId={(x) => x.id}
        cols={[
          {
            name: 'On Behalf Of',
            valueProperty: 'onBehalfOf',
          },
          {
            name: 'ATS',
            description: 'The ATS receiving the export',
            valueProperty: 'ats',
          },
          {
            name: 'Project',
            description: 'The project exported from, if any',
            valueProperty: 'projectName',
          },
          {
            name: 'Name',
            description: 'The name of the exported candidate',
            valueProperty: 'name',
          },
          {
            name: 'Candidate ID',
            description: 'The ID of the exproted candidate',
            valueProperty: 'key',
          },
          {
            name: 'URL',
            description: 'The URL endpoint',
            valueProperty: 'url',
            sx: { overflow: 'hidden' },
          },
          {
            name: 'Success?',
            description: 'Was the export successful?',
            getValue: (x) => x.result === 'Success',
            type: 'boolean',
            relativeWidth: 0.5,
          },
          {
            name: 'Error Details',
            description: 'The error specifics, if any',
            valueProperty: 'result',
            getValue: (x) => {
              if (!x.result || x.result === 'Success') return {};

              try {
                return JSON.parse(x.result);
              } catch (error) {
                return { result: x.result };
              }
            },
            type: 'blob',
          },
          {
            name: 'Headers',
            description: 'Request payload',
            valueProperty: 'exportData',
            getValue: (x) => {
              if (!x.headers || x.headers === 'null') {
                return {};
              }
              return { data: x.headers };
            },
            type: 'blob',
          },
          {
            name: 'Export Data',
            description: 'Response data',
            valueProperty: 'exportData',
            getValue: (x) => {
              if (!x.exportData || x.exportData === 'null') {
                return {};
              }
              return { data: x.exportData };
            },
            type: 'blob',
          },
          {
            name: 'Body Response',
            description: 'Body Response',
            valueProperty: 'bodyResponse',
            getValue: (x) => {
              if (!x.bodyResponse || x.bodyResponse === 'null') {
                return {};
              }
              return { data: x.bodyResponse };
            },
            type: 'blob',
          },
          {
            name: 'Timestamp',
            description: 'The time the activity occurred',
            valueProperty: 'timestamp',
            type: 'date',
          },
        ]}
      />
    </ViewFrame>
  );
};
