import { Box, Button, TextField } from '@mui/material';
import { deleteUserInfo, getUserInfo } from 'api/apiThunks';
import { selectDerivedSystem } from 'api/systemSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { NoPermission } from 'components/NoPermission';
import { StandardGrid } from 'components/grids/StandardGrid';
import { ConfirmModal } from 'components/modals/ConfirmModal';
import { ViewFrame } from 'features/frame/ViewFrame';
import { useEffect, useMemo, useState } from 'react';
import { Privilege } from 'types/enums';
import { isEmail } from 'utils/convert';
import { getOperations } from 'utils/operable';
import { usePrivilegeSelector } from 'utils/usePrivilegeSelector';

interface UserData {
  type: string;
  id: string;
  data: IAccountInfo | INewUser | IRecruiter;
  dateCreated?: Date;
}

enum DataType {
  Account = 'account',
  NewUser = 'newUser',
  Recruiter = 'recruiter',
}

/**
 * The System Delete User view
 */
export const DeleteUser = (): JSX.Element => {
  const [operationType, setOperationType] = useState<SystemOpName>();
  const [userEmail, setUserEmail] = useState('');
  const [isUserEmailValid, setIsUserEmailValid] = useState(true);
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);

  const system = useAppSelector(selectDerivedSystem);
  const contentOperations = useMemo(() => {
    return operationType ? getOperations(system, operationType) : [];
  }, [system, operationType]);
  const { userInfo } = system;
  const adminPrivilege = usePrivilegeSelector();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!!operationType && contentOperations[0]?.status === 'succeeded') {
      setOperationType(undefined);
    }
  }, [operationType, contentOperations]);

  const fetcher = async () => {
    const isUserEmailValid = isEmail(userEmail);

    setIsUserEmailValid(isUserEmailValid);

    if (isUserEmailValid) {
      setOperationType('userInfo');
      dispatch(getUserInfo(userEmail));
    }
  };

  const userData = useMemo(() => {
    if (!userInfo) return [];

    const data: UserData[] = [];
    if (userInfo.accountInfo) {
      data.push({
        type: DataType.Account,
        id: userInfo.accountInfo.userId,
        data: userInfo.accountInfo,
        dateCreated: userInfo.accountInfo.timestamp,
      });
    }
    if (userInfo.newUser) {
      data.push({
        type: DataType.NewUser,
        id: userInfo.newUser.id,
        data: userInfo.newUser,
        dateCreated: userInfo.newUser.dateCreated,
      });
    }
    if (userInfo.recruiter) {
      data.push({
        type: DataType.Recruiter,
        id: userInfo.recruiter.id,
        data: userInfo.recruiter,
        dateCreated: userInfo.recruiter.dateCreated,
      });
    }
    return data;
  }, [userInfo]);

  const isProcessing = !!operationType;
  const disableFetch = isProcessing || !userEmail.length;
  const dataTypes = userData.map((x) => x.type);
  const canDelete = dataTypes.includes(DataType.Account) && !dataTypes.includes(DataType.Recruiter);
  const contentMessage =
    operationType === 'deleteUserInfo' ? 'Deleting account entry' : 'Fetching user information';

  if (adminPrivilege !== Privilege.All) {
    return (
      <ViewFrame>
        <NoPermission />
      </ViewFrame>
    );
  }

  return (
    <ViewFrame
      header={
        <Box
          sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'center', mt: 2, mb: 2 }}
        >
          <TextField
            label="User Email"
            placeholder="User Email"
            size="medium"
            onChange={(e) => setUserEmail(e.target.value)}
            value={userEmail}
            error={!isUserEmailValid}
            helperText={!isUserEmailValid ? 'Please enter valid email address' : ''}
            sx={{ ml: 2, width: 340 }}
          />
          <Button variant="contained" disabled={disableFetch} sx={{ ml: 2 }} onClick={fetcher}>
            Fetch
          </Button>
          <Button
            variant="contained"
            disabled={!canDelete}
            sx={{ ml: 2 }}
            onClick={() => setDeleteConfirmModalOpen(true)}
          >
            Delete
          </Button>
        </Box>
      }
      contentLoader={{
        message: contentMessage,
        contentOperations,
        forceClose: !operationType,
        onClose: () => setOperationType(undefined),
      }}
    >
      <StandardGrid
        dataSet={userData}
        getRowId={(x) => x.id}
        getOpenAction={(x) => {
          if ((x.data as INewUser).fullActivationLink) {
            return { email: (x.data as INewUser).email, type: 'NewUser' };
          }
          return { id: x.data.userId, type: 'User' };
        }}
        filterPlaceholder="Filter the results"
        cols={[
          {
            name: 'Type',
            valueProperty: 'type',
          },
          {
            name: 'Id',
            valueProperty: 'id',
          },
          {
            name: 'Object',
            getValue: (x) => x.data,
            type: 'blob',
          },
          {
            name: 'Date Created',
            valueProperty: 'dateCreated',
            type: 'date',
          },
        ]}
      />
      <ConfirmModal
        open={deleteConfirmModalOpen}
        prompt="Permanently delete account entry?"
        onClose={() => setDeleteConfirmModalOpen(false)}
        onAccept={() => {
          setOperationType('deleteUserInfo');
          dispatch(deleteUserInfo(userEmail));
        }}
      />
    </ViewFrame>
  );
};
