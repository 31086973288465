import { StandardGrid } from 'components/grids/StandardGrid';
import { getReasonPhrase } from 'http-status-codes';

function removeTargets(queryStrings: Record<string, string>) {
  const { userId, orgId, organizationId, ...rest } = queryStrings;
  return rest;
}

interface Props {
  monitorLogs: MonitorLog[];
}

/**
 * A filterable DataGrid that displays Monitor history
 */
export const MonitorGrid = ({ monitorLogs }: Props): JSX.Element => {
  const hideActingUser = !monitorLogs.some((x) => x.customDimensions.auth?.userId);
  const hideTargetUser = !monitorLogs.some((x) => x.customDimensions.target?.userId);
  const hideTargetOrg = !monitorLogs.some((x) => x.customDimensions.target?.orgId);
  const hideQueryString = !monitorLogs.some(
    (x) => Object.keys(removeTargets(x.querystrings)).length
  );
  const hideCustomProperty = !monitorLogs.some(
    (x) => Object.keys(x.customDimensions.properties ?? {}).length
  );

  const sortedMonitorLogs = [...monitorLogs].sort((a, b) =>
    a.timestamp > b.timestamp ? -1 : a.timestamp < b.timestamp ? 1 : 0
  );

  return (
    <StandardGrid
      dataSet={sortedMonitorLogs}
      tipModel="MonitorLog"
      getRowId={(x) => x.timestamp}
      cols={[
        {
          name: 'Start Time',
          description: 'When the action was initiated',
          valueProperty: 'timestamp',
          type: 'date',
        },
        {
          name: 'Duration',
          description: 'How long it took the action to be processed (ms)',
          valueProperty: 'duration',
          relativeWidth: 0.5,
        },
        {
          name: 'Request Name',
          description: 'Method and URL path of the action request',
          valueProperty: 'name',
        },
        {
          name: 'Actor Name',
          description: 'Name of the user who performed the action',
          getValue: (x) => x.customDimensions?.auth?.name,
          getTooltip: (x) => x.customDimensions?.auth?.userId,
          hide: hideActingUser,
        },
        {
          name: 'Target User ID',
          description: 'ID of the user targeted by the action',
          getValue: (x) => x.customDimensions?.target?.userId,
          relativeWidth: 1,
          hide: hideTargetUser,
        },
        {
          name: 'Target Org ID',
          description: 'ID of the organization targeted by the action',
          getValue: (x) => x.customDimensions?.target?.orgId,
          relativeWidth: 1,
          hide: hideTargetOrg,
        },
        {
          name: 'Query Strings',
          description: 'Non-target query parameters in the action request URL',
          valueProperty: 'querystrings',
          getValue: (x) => removeTargets(x.querystrings),
          type: 'blob',
          hide: hideQueryString,
        },
        {
          name: 'Custom Properties',
          description: 'Additional data logged with the action',
          getValue: (x) => x.customDimensions?.properties ?? {},
          type: 'blob',
          hide: hideCustomProperty,
        },
        {
          name: 'Result Code',
          description: 'HTTP status code indicating how the action request was handled',
          valueProperty: 'resultCode',
          getValue: (x) => `${x.resultCode}: ${getReasonPhrase(x.resultCode)}`,
        },
        {
          name: 'Success',
          description: 'Whether the action was successful',
          valueProperty: 'success',
          getValue: (x) => x.success === 'True',
          type: 'boolean',
          relativeWidth: 0.5,
        },
      ]}
    />
  );
};
