import { selectDerivedUser } from 'api/usersSlice';
import { useAppSelector } from 'app/hooks';
import { SettingsGrid } from 'components/grids/SettingsGrid';

/**
 * The user settings view
 */
export const Settings = (): JSX.Element => {
  const { user, recruiter, quota } = useAppSelector(selectDerivedUser);

  if (!user) return <></>;

  return (
    <SettingsGrid
      dataSet={{
        User: user,
        Recruiter: recruiter,
        Quota: quota,
      }}
    />
  );
};
