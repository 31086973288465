import { Box, Button } from '@mui/material';
import { FormFields } from 'components/form/FormFields';
import { ChangeEvent, useCallback, useMemo, useReducer, useState } from 'react';
import { requiredField } from 'utils/form';

const validateFields = ['name'];

type IUserField = Record<string, unknown>;

interface UserFieldState extends FormState, Partial<IUserField> {}

interface Props extends FormProps<IUserField> {
  fieldInfo: FormFieldInfo;
}

const userFieldReducer = (state: UserFieldState, newState: UserFieldState) => ({
  ...state,
  ...newState,
});

/**
 * A common form to edit different user fields
 */
export const UserFieldForm = ({
  fieldInfo,
  onSubmit,
  onCancel,
  sx,
  initialValues,
}: Props): JSX.Element => {
  const [formErrors, setFormErrors] = useState({} as FormErrors);
  const [formInput, setFormInput] = useReducer(userFieldReducer, { ...(initialValues || {}) });

  const validateForm = useCallback(
    (fieldValues: Record<string, unknown>) => {
      if (validateFields.includes(fieldInfo.name)) {
        formErrors[fieldInfo.name] = requiredField(fieldValues, fieldInfo.name);
      }
      setFormErrors({ ...formErrors });
      return Object.values(formErrors).every((x) => x === '');
    },
    [formErrors, fieldInfo.name]
  );

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const newValue = e.target.value;
    const fieldValue: Record<string, unknown> = { [name]: newValue };
    setFormInput(fieldValue);
    validateForm(fieldValue);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm(formInput)) return;
    onSubmit(formInput as IUserField);
  };

  const columnFields = (formFields: FormFieldInfo[]) => {
    return FormFields<IUserField>({
      formFields,
      formInput: formInput as IUserField,
      formErrors: formErrors,
      handleInput: handleInput,
    });
  };

  const isValidForm = useMemo(() => {
    return Object.values(formErrors).every((x) => x === '');
  }, [formErrors]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        width: '100%',
        margin: (theme) => theme.spacing(0, 'auto', 4, 'auto'),
        ...sx,
      }}
      autoComplete="off"
    >
      <Box sx={{ pl: 2, pr: 2 }}>{columnFields([fieldInfo])}</Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <Button sx={{ mr: 2 }} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant={!isValidForm ? 'outlined' : 'contained'}
          color={!isValidForm ? 'error' : undefined}
          sx={{ width: 125 }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
