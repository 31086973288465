import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import { authReducer } from 'api/authSlice';
import { organizationsReducer } from 'api/organizationsSlice';
import { newUsersReducer } from 'api/newUsersSlice';
import { systemReducer } from 'api/systemSlice';
import { usersReducer } from 'api/usersSlice';
import { appViewReducer } from 'features/frame/appViewSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    appView: appViewReducer,
    newUsers: newUsersReducer,
    users: usersReducer,
    system: systemReducer,
    organizations: organizationsReducer,
  },
});

export function getTestStore(
  testState: Record<string, unknown>
): ReturnType<typeof configureStore> {
  return configureStore({
    reducer: {
      auth: authReducer,
      appView: appViewReducer,
      newUsers: newUsersReducer,
      users: usersReducer,
      system: systemReducer,
      organizations: organizationsReducer,
    },
    preloadedState: testState,
  });
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
