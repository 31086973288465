import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  getSSOHistory,
  getATSExportHistory,
  getEmailSequences,
  getMailboxConfigs,
  getMessagingHistory,
  getQuotaHistory,
  getRecruiter,
  getUserSearchHistory,
  deleteUserSearchHistory,
  getSubscriptionHistory,
  getUser as getUserInfo,
  deleteUser as deleteUserThunk,
  updateSFLicense,
  getPasswordResetUrl,
  getLoginHistory,
  updateOrgAdmin,
  updateOnlySSO,
  updateSFFeature,
  verifyUser,
  updateRecruiter,
  applySkuOrAddon,
  cancelSubscription,
  getTransactionHistory,
} from 'api/apiThunks';
import { addExtraReducers } from 'api/slice';
import { RootState } from 'app/store';
import { selectCurrentLookupTab } from 'features/frame/appViewSlice';
import { lookupFriendlyError } from 'utils/lookup';
import { createOperable, setKeyedOperation, setOperation } from 'utils/operable';

/* Selectors */

/**
 * Select data, operation state, and precalculated state for the current lookup user
 * @param state The root redux state
 * @returns The derived user data and operation state
 */
export function selectDerivedUser(state: RootState): DerivedUser {
  const lookup = selectCurrentLookupTab(state);
  const user = getUser(state.users, lookup.id) || getUser(state.users, lookup.email);

  return {
    ...user,
    operationMap: user?.operationMap ?? {},
    keyedOperationMap: user?.keyedOperationMap ?? {},
    userId: user?.user?.id,
    email: user?.recruiter?.primaryEmail,
  };
}

/* Reducers */

type StateType = Record<string, User>;

export const usersSlice = createSlice({
  name: 'users',
  initialState: {} as StateType,
  reducers: {
    clearOperations: (state, action: PayloadAction<{ userId: string; opNames: UserOpName[] }>) => {
      const { userId, opNames } = action.payload;
      const user = state[userId] as User;

      opNames.forEach((opName) => {
        user.operationMap[opName] = undefined;
        user.keyedOperationMap[opName] = undefined;
      });
    },
    clearUserCache: (state, action: PayloadAction<Lookup>) => {
      clearUser(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    addExtraReducers(getUserInfo, setUser, builder);
    addExtraReducers(getRecruiter, setRecruiter, builder);
    addExtraReducers(updateRecruiter, setUpdateRecruiter, builder);
    addExtraReducers(getMailboxConfigs, setMailboxConfigs, builder);
    addExtraReducers(getEmailSequences, setEmailSequences, builder);
    addExtraReducers(getUserSearchHistory, setSearchHistory, builder);
    addExtraReducers(deleteUserSearchHistory, setDeleteSearchHistory, builder);
    addExtraReducers(getMessagingHistory, setMessagingHistory, builder);
    addExtraReducers(getQuotaHistory, setQuotaHistory, builder);
    addExtraReducers(getSubscriptionHistory, setSubscriptionHistory, builder);
    addExtraReducers(getSSOHistory, setSSOHistory, builder);
    addExtraReducers(getLoginHistory, setLoginHistory, builder);
    addExtraReducers(getATSExportHistory, setATSExportHistory, builder);
    addExtraReducers(getTransactionHistory, setTransactionHistory, builder);
    addExtraReducers(updateSFLicense, setUpdateSFLicense, builder);
    addExtraReducers(updateSFFeature, setUpdateSFFeature, builder);
    addExtraReducers(deleteUserThunk, setDeleteUser, builder);
    addExtraReducers(getPasswordResetUrl, setPasswordResetUrl, builder);
    addExtraReducers(applySkuOrAddon, setApplySkuOrAddon, builder);
    addExtraReducers(updateOrgAdmin, setOrgAdmin, builder);
    addExtraReducers(updateOnlySSO, setOnlySSO, builder);
    addExtraReducers(verifyUser, updateUserState, builder);
    addExtraReducers(cancelSubscription, setUpdateSubscription, builder);
  },
});

export const { clearOperations, clearUserCache } = usersSlice.actions;

export const usersReducer = usersSlice.reducer;

/* Helpers */

function getUser(users: StateType, userId?: string) {
  return userId ? users[userId] : undefined;
}

function clearUser(users: StateType, lookup: Lookup) {
  const { id, email } = lookup;

  if (id) {
    delete users[id];
  }

  if (email) {
    delete users[email];
  }
}

function setUser(users: StateType, lookup: Lookup, result: ApiResult<IUser>) {
  const user: User = getUser(users, lookup.id) ?? getUser(users, lookup.email) ?? createOperable();

  const description = `Get User with ${lookupFriendlyError(lookup)}`;
  user.user = setOperation(user, 'user', result, description);

  const id = user.user?.id || lookup.id;
  const email = lookup.email;

  if (id) users[id] = user;
  if (email) users[email] = user;
}

function updateUserState(users: StateType, userId: string, result: ApiResult<string>) {
  const user: User = getUser(users, userId) ?? createOperable();
  setOperation(user, 'verifyUser', result, 'Update User Settings');

  if (result.status === 'succeeded' && user.user) {
    user.user.userStates.isEmailVerified = true;
    user.user.userStates.isPhoneVerified = true;
  }
}

function setRecruiter(users: StateType, { id: userId }: Lookup, result: ApiResult<IRecruiterInfo>) {
  const user = getUser(users, userId);
  if (!user) return;

  const recruiterInfo = setOperation(user, 'recruiter', result, 'Get Recruiter Settings');

  if (recruiterInfo) {
    user.recruiter = recruiterInfo.recruiter;
    user.quota = recruiterInfo.quota;
    user.smbSubscription = recruiterInfo.smbSubscription;
  }
}

function setUpdateRecruiter(
  users: StateType,
  { userId }: ResourceParams<IRecruiter>,
  result: ApiResult<IRecruiter>
) {
  const user = getUser(users, userId);
  if (!user) return;

  const recruiter = setOperation(user, 'updateRecruiter', result, 'Update Recruiter');

  if (recruiter) {
    user.recruiter = recruiter;
  }
}

function setMailboxConfigs(users: StateType, userId: string, result: ApiResult<IMailboxConfig[]>) {
  const user = getUser(users, userId);
  if (!user) return;

  user.mailboxConfigs = setOperation(user, 'mailboxConfigs', result, 'Get Mailbox Configs');
}

function setEmailSequences(users: StateType, userId: string, result: ApiResult<IEmailSequence[]>) {
  const user = getUser(users, userId);
  if (!user) return;

  user.emailSequences = setOperation(
    user,
    'emailSequences',
    result,
    'Get Messaging Email Sequences'
  );
}

function setSearchHistory(
  users: StateType,
  { userId }: DateRangeParams,
  result: ApiResult<ISearchHistory[]>
) {
  const user = getUser(users, userId);
  if (!user) return;

  user.searchHistory = setOperation(user, 'searchHistory', result, 'Get Search History');
}

function setDeleteSearchHistory(users: StateType, userId: string, result: ApiResult<boolean>) {
  const user = getUser(users, userId);
  if (!user) return;

  const success = setOperation(user, 'deleteSearchHistory', result, 'Delete Search History');

  if (success) {
    user.searchHistory = undefined;
  }
}

function setMessagingHistory(
  users: StateType,
  { userId }: ValueParams,
  result: ApiResult<IEmailActivity[]>
) {
  const user = getUser(users, userId);
  if (!user) return;

  user.messagingHistory = setOperation(user, 'messagingHistory', result, 'Get Messaging History');
}

function setQuotaHistory(users: StateType, userId: string, result: ApiResult<IQuotaHistory[]>) {
  const user = getUser(users, userId);
  if (!user) return;

  user.quotaHistory = setOperation(user, 'quotaHistory', result, 'Get Quota History');
}

function setSubscriptionHistory(
  users: StateType,
  userId: string,
  result: ApiResult<ISubscriptionHistory[]>
) {
  const user = getUser(users, userId);
  if (!user) return;

  user.subscriptionHistory = setOperation(
    user,
    'subscriptionHistory',
    result,
    'Get Subscription History'
  );
}

function setSSOHistory(
  users: StateType,
  { userId, value: email }: ValueParams,
  result: ApiResult<ISSOHistory[]>
) {
  const user = getUser(users, userId);
  if (!user) return;

  user.ssoHistory = setOperation(user, 'ssoHistory', result, `Get SSO History (${email})`);
}

function setLoginHistory(
  users: StateType,
  { userId, value: email }: ValueParams,
  result: ApiResult<ILoginHistory[]>
) {
  const user = getUser(users, userId);
  if (!user) return;

  user.loginHistory = setOperation(user, 'loginHistory', result, `Get Login History (${email})`);
}

function setATSExportHistory(
  users: StateType,
  { userId, value: email }: ValueParams,
  result: ApiResult<IATSExportLogFromDB[]>
) {
  const user = getUser(users, userId);
  if (!user) return;

  user.atsExportHistory = setOperation(
    user,
    'atsExportHistory',
    result,
    `Get ATS Export History (${email})`
  );
}

function setTransactionHistory(
  users: StateType,
  { userId, value: subscriptionId }: ValueParams,
  result: ApiResult<Transaction[]>
) {
  const user = getUser(users, userId);
  if (!user) return;

  user.maxioTransactionHistory = setOperation(
    user,
    'maxioTransactionHistory',
    result,
    `Get Maxio Transaction History (${subscriptionId})`
  );
}

function setUpdateSFLicense(
  users: StateType,
  { userId, isRemove, orderItemId }: SFOrderItemAssignParams,
  result: ApiResult<boolean>
) {
  const user = getUser(users, userId);
  if (!user) return;

  const success = setOperation(user, 'updateLicense', result, 'Update Salesforce License');

  if (success && user.recruiter) {
    if (isRemove) {
      user.recruiter.sfLicense = undefined;
    } else {
      user.recruiter.sfLicense = {
        orderItemId,
        // We don't have the info for a full update
        // For now, just note that for the user
        sku: '<Unknown. Refresh lookup tab to get latest.>',
      } as ISFOrderEntity;
    }
  }
}

function setUpdateSFFeature(
  users: StateType,
  { userId, isRemove, featureId }: SFFeatureAssignParams,
  result: ApiResult<void>
) {
  const user = getUser(users, userId);
  if (!user) return;

  const success = setKeyedOperation(
    user,
    'updateFeature',
    featureId,
    result,
    `Update Salesforce Feature ${featureId}`
  );

  if (success && user.recruiter) {
    const features = user.recruiter.features;

    if (features && isRemove) {
      const index = features.indexOf(featureId);

      if (index >= 0) {
        features.splice(index, 1);
      }
    } else if (features && !isRemove) {
      features.push(featureId);
    } else if (!isRemove) {
      user.recruiter.features = [featureId];
    }
  }
}

function setDeleteUser(users: StateType, { userId }: ParamsBase, result: ApiResult<boolean>) {
  const user = getUser(users, userId);
  if (!user) return;

  const success = setOperation(user, 'delete', result, `Delete User (${userId})`);

  if (success) {
    const email = user.recruiter?.primaryEmail;

    if (email) {
      delete users[email];
    }

    if (userId) {
      delete users[userId];
    }
  }
}

function setPasswordResetUrl(users: StateType, { userId }: ValueParams, result: ApiResult<string>) {
  const user = getUser(users, userId);
  if (!user) return;

  user.passwordResetUrl = setOperation(user, 'passwordResetUrl', result, 'Get Password Reset URL');
}

function setApplySkuOrAddon(
  users: StateType,
  { userId, skuId, skuName, isAddon }: SkuParams,
  result: ApiResult<IRecruiter>
) {
  const user = getUser(users, userId);
  if (!user) return;

  const update = setOperation(
    user,
    'applySkuOrAddon',
    result,
    `Apply ${isAddon ? 'Addon' : 'Sku'}`
  );

  if (update) {
    const sku: ISku | undefined = user.recruiter?.sku;
    if (isAddon) {
      const quota = user.quota;
      const updateQuota = (stats?: QuotaStats, oldValue = 0, newValue?: number) => {
        if (stats && newValue != undefined) {
          stats.addOn = newValue;
          stats.available += newValue - oldValue;
        }
      };
      updateQuota(quota?.exports, user.recruiter?.addOnExports, update.addOnExports);
      updateQuota(quota?.emails, user.recruiter?.addOnEmails, update.addOnEmails);
      updateQuota(quota?.cseQueries, user.recruiter?.addOnCSEQueries, update.addOnCSEQueries);
    } else if (sku) {
      // update sku id and name manually as applySkuOrAddon API
      // doesn't return sku object as part of recruiter
      sku.id = skuId;
      sku.name = skuName;
    }

    user.recruiter = { ...update, sku };
  }
}

function setOrgAdmin(
  users: StateType,
  { userId, flag: isOrgAdmin }: FlagParams,
  result: ApiResult<INewUser | IRecruiter>
) {
  const user = getUser(users, userId);
  if (!user) return;

  const success = setOperation(user, 'updateOrgAdmin', result, 'Update Org Admin');

  if (success && user.recruiter) {
    user.recruiter.isOrgAdmin = isOrgAdmin;
    user.recruiter.userType = isOrgAdmin ? 'admin' : 'standard';
  }
}

function setOnlySSO(
  users: StateType,
  { userId, flag: isOnlySSO }: FlagParams,
  result: ApiResult<IUser>
) {
  const user = getUser(users, userId);
  if (!user) return;

  const success = setOperation(user, 'updateOnlySSO', result, 'Update Require SSO Logins');

  if (success && user.user) {
    user.user.onlyAllowSSOLogins = isOnlySSO;
  }
}

function setUpdateSubscription(
  users: StateType,
  { userId }: ValueParams,
  result: ApiResult<Record<string, string>>
) {
  const user = getUser(users, userId);
  if (!user) return;

  const response = setOperation(user, 'cancelSubscription', result, 'Cancel Subscription');
  if (response?.message && user.smbSubscription) {
    user.smbSubscription.cancel_at_end_of_period = true;
  }
}
