import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { SxProps, Theme } from '@mui/material';

interface ModalProps {
  title: string;
  actions?: JSX.Element;
  onClose?: () => void;
  open: boolean;
  additionalProps?: Record<string, unknown>;
  noActions?: boolean;
  buttonText?: string;
  dialogContentSx?: SxProps<Theme>;
}

export const Modal = ({
  title,
  children,
  actions,
  onClose,
  open,
  additionalProps,
  noActions,
  buttonText,
}: React.PropsWithChildren<ModalProps>) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" {...additionalProps}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      {!noActions && (
        <DialogActions>
          {actions || (
            <Button onClick={onClose} autoFocus>
              {buttonText || 'Ok'}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};
