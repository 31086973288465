import { useEffect } from 'react';

import { getSSOHistory } from 'api/apiThunks';
import { selectDerivedUser } from 'api/usersSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { SSOHistoryGrid } from 'components/grids/SSOHistoryGrid';
import { ViewFrame } from 'features/frame/ViewFrame';
import { getOperations } from 'utils/operable';

/**
 * The User SSO History view
 */
export const SSOHistory = (): JSX.Element => {
  const user = useAppSelector(selectDerivedUser);
  const viewOperations = getOperations(user, ['recruiter', 'ssoHistory']);
  const [, ssoHistoryOp] = viewOperations;
  const { userId, email, ssoHistory = [] } = user;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userId && email && !ssoHistoryOp) {
      dispatch(getSSOHistory({ userId, value: email }));
    }
  }, [dispatch, userId, email, ssoHistoryOp]);

  if (!userId) return <></>;

  return (
    <ViewFrame viewLoader={{ message: 'Loading SSO History', viewOperations }}>
      <SSOHistoryGrid dataSet={ssoHistory} />
    </ViewFrame>
  );
};
