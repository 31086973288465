import { Box, Button } from '@mui/material';
import { FormFields } from 'components/form/FormFields';
import { ChangeEvent, useCallback, useMemo, useReducer, useState } from 'react';
import { requiredField } from 'utils/form';
import { getPowerFiltersSearchTypeOptions } from 'utils/selectorOptions';
import { ColumnContent } from './columns/ColumnContent';

const validationFields = [
  'name',
  'description',
  'category',
  'count',
  'friendlyName',
  'createdBy',
  'searchType',
];
const column1Fields: FormFieldInfo[] = [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'description',
    label: 'Description',
  },
  {
    name: 'category',
    label: 'Category',
  },
  {
    type: 'number',
    name: 'count',
    label: 'Count',
  },
];

const column2Fields: FormFieldInfo[] = [
  {
    name: 'friendlyName',
    label: 'Friendly Name',
  },
  {
    name: 'createdBy',
    label: 'CreatedBy',
  },
  {
    type: 'dropdown',
    name: 'searchType',
    label: 'Search Type',
    options: getPowerFiltersSearchTypeOptions(),
    getDefaultValue: ({ formInput }: FormValueProps<PowerFiltersState>) => {
      return {
        defaultValue: `${formInput.searchType || ''}`,
      };
    },
  },
];

const column3Fields: FormFieldInfo[] = [
  {
    name: 'search',
    label: 'Search',
    additionalProps: {
      multiline: true,
      maxRows: 12,
    },
  },
  {
    name: 'filter',
    label: 'Filter',
    additionalProps: {
      multiline: true,
      maxRows: 12,
    },
  },
];

interface PowerFiltersState extends FormState, Partial<IRecipe> {}

const atsMetaReducer = (state: PowerFiltersState, newState: PowerFiltersState) => ({
  ...state,
  ...newState,
});

/**
 * A form to create/edit power filter
 */
export const PowerFiltersForm = ({
  onSubmit,
  onCancel,
  sx,
  initialValues,
}: FormProps<IRecipe>): JSX.Element => {
  const [formErrors, setFormErrors] = useState({} as FormErrors);
  const [formInput, setFormInput] = useReducer(atsMetaReducer, { ...(initialValues || {}) });

  const validateForm = useCallback(
    (fieldValues: Record<string, unknown>, isSubmit = false) => {
      if (isSubmit) {
        // Do full form validation
        validationFields.forEach((field) => {
          formErrors[field] = requiredField(fieldValues, field);
        });
      } else {
        Object.entries(fieldValues)
          .filter(([key]) => validationFields.includes(key))
          .forEach(([key]) => {
            formErrors[key] = requiredField(fieldValues, key);
          });
      }

      setFormErrors({ ...formErrors });
      return Object.values(formErrors).every((x) => x === '');
    },
    [formErrors]
  );

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const newValue = e.target.type === 'number' ? parseInt(e.target.value) : e.target.value;
    const fieldValue = { [name]: newValue };
    setFormInput(fieldValue);
    validateForm(fieldValue);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm(formInput, true)) return;
    onSubmit(formInput as IRecipe);
  };

  const columnFields = (formFields: FormFieldInfo[]) => {
    return FormFields<IRecipe>({
      formFields,
      formInput: formInput as IRecipe,
      formErrors: formErrors,
      handleInput: handleInput,
    });
  };

  const isValidForm = useMemo(() => {
    return Object.values(formErrors).every((x) => x === '');
  }, [formErrors]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        width: '100%',
        margin: (theme) => theme.spacing(0, 'auto', 4, 'auto'),
        ...sx,
      }}
      autoComplete="off"
    >
      <ColumnContent
        columns={[<>{columnFields(column1Fields)}</>, <>{columnFields(column2Fields)}</>]}
        growWidth
      />
      <Box sx={{ pl: 2, pr: 2 }}>{columnFields(column3Fields)}</Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <Button sx={{ mr: 2 }} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant={!isValidForm ? 'outlined' : 'contained'}
          color={!isValidForm ? 'error' : undefined}
          sx={{ width: 125 }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
