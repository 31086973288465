import { useEffect } from 'react';

import { getAtsConnections } from 'api/apiThunks';
import { selectDerivedSystem } from 'api/systemSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { StandardGrid } from 'components/grids/StandardGrid';
import { ViewFrame } from 'features/frame/ViewFrame';
import { getOperations } from 'utils/operable';

/**
 * The system ATS Configured view
 */
export const AtsConfigured = (): JSX.Element => {
  const system = useAppSelector(selectDerivedSystem);
  const viewOperations = getOperations(system, 'atsConnections');
  const [atsConnectionsOp] = viewOperations;
  const { atsConnections = [] } = system;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!atsConnectionsOp) {
      dispatch(getAtsConnections());
    }
  }, [dispatch, atsConnectionsOp]);

  return (
    <ViewFrame viewLoader={{ message: 'Loading Configured ATS List', viewOperations }}>
      <StandardGrid
        dataSet={atsConnections}
        tipModel="ATSConnection"
        getRowId={(x) => `${x.id}_${x.userId}`}
        getOpenAction={(x) => ({ id: x.userId, type: 'Organization' })}
        cols={[
          {
            name: 'Organization',
            description: 'The Organization ATS owner',
            valueProperty: 'userId',
            getValue: (x) => x.orgDomain || x.userId,
          },
          {
            name: 'ATS',
            description: 'The ATS name (or id)',
            valueProperty: 'atsType',
          },
          {
            name: 'Features',
            description: 'The list of enabled features',
            valueProperty: 'featureList',
          },
          {
            name: 'Status',
            description: 'The status of the integration',
            valueProperty: 'validationStatus',
          },
          {
            name: 'Date Created',
            valueProperty: 'dateCreated',
            type: 'date',
          },
          {
            name: 'Properties',
            getValue: (x) => x,
            type: 'blob',
          },
        ]}
      />
    </ViewFrame>
  );
};
