import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { ReactElement, useCallback, useEffect, useState } from 'react';

import { bulkAssignLicense } from 'api/apiMetaThunks';
import { clearOperations, selectDerivedOrganization } from 'api/organizationsSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ConfirmModal } from 'components/modals/ConfirmModal';
import { Selector } from 'components/Selector';
import { StandardGrid } from 'components/grids/StandardGrid';
import { ViewFrame } from 'features/frame/ViewFrame';
import { opAllKeyedSkippedOrSuccess } from 'utils/apiResult';
import { getOrgUserLicenseId, getSFOrderItemsOperations } from 'utils/organization';
import { getLicenseSelectionOptions } from 'utils/selectorOptions';
import { getKeyedOperations, getOperations } from 'utils/operable';
import { uploadCsvFile } from 'utils/files';
import { UploadCsvModal } from 'components/organization/UploadCsvModal';

const Input = styled('input')({ display: 'none' });
const Label = styled('label')({ alignSelf: 'center' });

type Row = Record<string, string>;

/**
 * The organization bulk edit license view
 */
export const EditLicense = (): JSX.Element => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalTitle, setConfirmModalTitle] = useState('');
  const [mode, setMode] = useState('load' as 'load' | 'assign' | 'errorCheck' | 'done');
  const [fromOrderItemId, setFromOrderItemId] = useState('');
  const [toOrderItemId, setToOrderItemId] = useState('');
  const [processingUserIds, setProcessingUserIds] = useState([] as string[]);
  const [orgLicenseMap, setOrgLicenseMap] = useState<Record<string, License>>();
  const [uploadOperations, setUploadOperations] = useState<Operation[]>([]);
  const [csvHasEmailColumn, setCsvHasEmailColumn] = useState(false);
  const [csvValidEmails, setCsvValidEmails] = useState<string[]>([]);
  const [csvInvalidEmails, setCsvInvalidEmails] = useState<string[]>([]);
  const [csvModalOpen, setCsvModalOpen] = useState<boolean>(false);

  const org = useAppSelector(selectDerivedOrganization);
  const viewOperations = [...getOperations(org, 'users'), ...getSFOrderItemsOperations(org)];
  const contentOperations = [
    ...uploadOperations,
    ...getKeyedOperations(org, processingUserIds, ['assignLicense', 'removeLicense']).flat(),
  ];
  const { orgId, users = [], licenses = [], licenseMap = {} } = org;

  const dispatch = useAppDispatch();

  const complete = useCallback(() => {
    if (orgId) {
      dispatch(clearOperations({ orgId, opNames: ['removeLicense', 'assignLicense'] }));
    }
    setMode('done');
  }, [dispatch, orgId]);

  useEffect(() => {
    if (mode === 'assign') {
      bulkAssignLicense(
        dispatch,
        org,
        processingUserIds,
        toOrderItemId,
        fromOrderItemId === 'none',
        () => setMode('errorCheck'),
        orgLicenseMap
      );
    } else if (mode === 'errorCheck') {
      if (opAllKeyedSkippedOrSuccess(org)) {
        complete();
      }
    }
  }, [
    dispatch,
    mode,
    org,
    fromOrderItemId,
    toOrderItemId,
    processingUserIds,
    orgLicenseMap,
    complete,
  ]);

  if (!orgId) return <></>;

  const licenseOptions = getLicenseSelectionOptions(licenses);

  const isPreProcessing = mode === 'load';
  const isProcessing = (!isPreProcessing && mode !== 'done') || !!uploadOperations.length;

  const orgUsers = users.filter((orgUser) => {
    if (!isPreProcessing) return processingUserIds.includes(orgUser.userId);
    if (fromOrderItemId === 'all') return true;
    if (fromOrderItemId === 'csv')
      return orgUser.emailAddress && csvValidEmails.includes(orgUser.emailAddress);
    if (fromOrderItemId === 'none') return !licenseMap[orgUser?.userId];
    return getOrgUserLicenseId(org, orgUser?.userId) === fromOrderItemId;
  });

  const onUploadOperation = (ops: Operation[]) => {
    // If a single successful operation, then translate to [] (no operation)
    ops = ops.length === 1 && ops[0]?.status === 'succeeded' ? [] : ops;
    setUploadOperations(ops);
  };

  const onUploadSuccess = (newColumns: string[], newRows: Row[]) => {
    const emailColumn = newColumns.find((c) => c.includes('Email'));
    if (!emailColumn) {
      setCsvHasEmailColumn(false);
      setCsvModalOpen(true);
      return;
    }
    const csvEmails = newRows.map((r) => r[emailColumn]?.trim());
    const validEmails = users
      .map((orgUser) => orgUser.emailAddress)
      .filter((emailAddress) => {
        return emailAddress && csvEmails.includes(emailAddress);
      }) as string[];
    const invalidEmails = csvEmails.filter((emailAddress) => !validEmails.includes(emailAddress));
    setCsvHasEmailColumn(true);
    setCsvValidEmails(validEmails);
    setCsvInvalidEmails(invalidEmails);
    if (invalidEmails.length > 0) {
      setCsvModalOpen(true);
    }
    (document.getElementById('contained-button-file') as HTMLInputElement).value = '';
  };

  const quantityLeft = licenses.find(
    (license) => license.orderItemId === toOrderItemId
  )?.quantityLeft;
  const insufficient = quantityLeft != undefined && quantityLeft < orgUsers.length;

  const enableSubmit = mode === 'load' && fromOrderItemId && toOrderItemId && !insufficient;
  const operationDisplayName = "Assigning (We'll make this faster, we promise)";
  const contentMessage = uploadOperations.length ? 'Uploading CSV' : operationDisplayName;

  let message: ReactElement | undefined;

  if (fromOrderItemId && toOrderItemId && insufficient) {
    message = (
      <Typography>
        This transfer requires {orgUsers.length} licenses, but only {quantityLeft} are available
      </Typography>
    );
  } else if (fromOrderItemId && toOrderItemId) {
    message = (
      <Typography>
        {quantityLeft != undefined
          ? `This will use ${orgUsers.length} of the ${quantityLeft} remaining licenses`
          : `This will apply to ${orgUsers.length} users`}
      </Typography>
    );
  }

  return (
    <ViewFrame
      viewLoader={{
        message: 'Loading users and licenses for the organization. This might take a minute.',
        viewOperations,
      }}
      header={
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Selector
              label="Find users with this license"
              options={[
                { value: 'all', text: 'All Users' },
                { value: 'csv', text: 'From CSV' },
              ].concat(licenseOptions)}
              value={fromOrderItemId}
              setValue={(val) => {
                setFromOrderItemId(val as string);
                setMode('load');
              }}
              isDisabled={isProcessing}
              sx={{ minWidth: 300 }}
            />
            <Selector
              label="Migrate them to this license"
              options={licenseOptions}
              value={toOrderItemId}
              setValue={(val) => {
                setToOrderItemId(val as string);
                setMode('load');
              }}
              isDisabled={isProcessing}
              sx={{ minWidth: 300 }}
            />
          </Box>

          {fromOrderItemId === 'csv' && (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
              <Label htmlFor="contained-button-file">
                <Input
                  accept="text/csv"
                  id="contained-button-file"
                  type="file"
                  onChange={(e) => uploadCsvFile(e, onUploadOperation, onUploadSuccess)}
                />
                <Button variant="contained" component="span" sx={{ minWidth: 125 }}>
                  Upload CSV
                </Button>
              </Label>
            </Box>
          )}

          <Button
            variant="contained"
            disabled={!enableSubmit}
            onClick={() => {
              setConfirmModalTitle(`Edit license for ${orgUsers.length} users?`);
              setConfirmModalOpen(true);
            }}
          >
            Submit
          </Button>

          {message}
        </>
      }
      contentLoader={{
        message: contentMessage,
        contentOperations,
        forceClose: !isProcessing,
        onClose: () => {
          complete();
          setUploadOperations([]);
          setCsvValidEmails([]);
          setCsvInvalidEmails([]);
        },
      }}
    >
      <StandardGrid
        dataSet={orgUsers}
        tipModel="OrgUser"
        getRowId={(x) => x.userId}
        getOpenAction={(x) =>
          x.newUserId ? { email: x.emailAddress, type: 'NewUser' } : { id: x.userId, type: 'User' }
        }
        filterPlaceholder="Filter the displayed users. (Warning: filtered-out users will still be transfered)"
        cols={[
          {
            name: 'Name',
            valueProperty: 'fullName',
          },
          {
            name: 'Email',
            valueProperty: 'emailAddress',
            type: 'email',
          },
          {
            name: 'Team',
            valueProperty: 'assignedTeam',
            getValue: (x) => x.assignedTeam?.name,
          },
          {
            name: 'License',
            getValue: (x) => {
              const license = licenseMap[x.userId];
              if (license) {
                return `${license.orderNumber}: ${license.skuName} (${license.endDate})`;
              }
              if (x.currentSkuName) return `None (${x.currentSkuName})`;
              return 'None';
            },
          },
        ]}
      />

      <ConfirmModal
        open={confirmModalOpen}
        prompt={confirmModalTitle}
        onClose={() => setConfirmModalOpen(false)}
        onAccept={() => {
          setOrgLicenseMap(org.licenseMap);
          setProcessingUserIds(orgUsers.map((orgUser) => orgUser.userId));
          setMode('assign');
        }}
      />

      <UploadCsvModal
        onClose={() => setCsvModalOpen(false)}
        open={csvModalOpen}
        csvHasEmailColumn={csvHasEmailColumn}
        csvInvalidEmails={csvInvalidEmails}
      />
    </ViewFrame>
  );
};
