import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Grid from '@mui/material/Grid';

import { ErrorFallback } from 'ErrorFallback';
import { ContentTabs } from 'features/frame/ContentTabs';
import { Header } from 'features/frame/Header';
import { LookupTabs } from 'features/frame/LookupTabs';
import { ViewManager } from 'features/frame/ViewManager';

export const App = (): JSX.Element => {
  const [error, setError] = useState(false);

  const handleClick = () => {
    if (error) {
      setError(false);
    }
  };

  return (
    <div className="App">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Grid
          container
          direction="column"
          sx={{
            height: '100vh',
            width: '100vw',
            margin: '-8px',
            position: 'fixed',
            textAlign: 'center',
            backgroundColor: 'secondary',
          }}
        >
          <Grid item>
            <Header onClick={handleClick} />
          </Grid>

          <Grid container item xs sx={{ height: '90%' }}>
            <LookupTabs onClick={handleClick} />
            <Grid container item direction="column" xs>
              <ContentTabs onClick={handleClick} />
              <Grid item xs sx={{ overflow: 'auto' }}>
                <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                  onError={() => setError(true)}
                  resetKeys={[error]}
                >
                  <ViewManager />
                </ErrorBoundary>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ErrorBoundary>
    </div>
  );
};
