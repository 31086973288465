import { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import {
  copyCreditInfo,
  deleteOrganization,
  deleteOrgFeature,
  updateDiversitySettings,
  updateOrganization,
  updateOrgFeature,
  updateSearchFiltersOrders,
} from 'api/apiThunks';
import { selectDerivedOrganization } from 'api/organizationsSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ColumnContent } from 'components/columns/ColumnContent';
import { MapTable } from 'components/columns/MapTable';
import { Selector } from 'components/Selector';
import { Toggle } from 'components/Toggle';
import { ViewFrame } from 'features/frame/ViewFrame';
import { DiversitySetting, Privilege } from 'types/enums';
import { getOperations } from 'utils/operable';
import { getLicenseSummary, getOrderSummary } from 'utils/organization';
import { Overwriter } from 'utils/overwriter';
import { getDiversityFilterOptions, getFeatureOptions } from 'utils/selectorOptions';
import { OrgFeatureSelector } from 'components/OrgFeatureSelector';
import { Modal } from 'components/modals/Modal';
import { DiversitySettings } from 'components/organization/DiversitySettings';
import { SearchFiltersOrder } from 'components/organization/SearchFiltersOrder';
import { SensitivitySettings } from 'components/organization/SensitivitySettings';
import { usePrivilegeSelector } from 'utils/usePrivilegeSelector';
import { Feature, FeatureAccess } from 'types/server/common/enums';
import { Tip } from 'components/Tip';
import { ConfirmModal } from 'components/modals/ConfirmModal';
import { IconButton } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DeleteOrganization } from 'components/organization/DeleteOrganization';

const operationTypes = ['updateSettings', 'copyCreditInfo', 'deleteOrganization'];

export const Summary = (): JSX.Element => {
  const [operationType, setOperationType] = useState<OrgOpName>();
  const [{ edits }, setEditWrapper] = useState<{ edits?: Overwriter<IOrganization> }>({});
  const [diversitySettingsModalOpen, setDiversitySettingsModalOpen] = useState(false);
  const [searchFiltersOrderModalOpen, setSearchFiltersOrderModalOpen] = useState(false);
  const [featuresModalOpen, setFeaturesModalOpen] = useState(false);
  const [sensitivitySettingsOpen, setSensitivitySettingsOpen] = useState(false);
  const [confirmCopyCreditInfoModalOpen, setConfirmCopyCreditInfoModalOpen] = useState(false);
  const [confirmDeleteOrganizationModalOpen, setConfirmDeleteOrganizationModalOpen] =
    useState(false);
  const [deleteOrganizationModalOpen, setDeleteOrganizationModalOpen] = useState(false);
  const [password, setPassword] = useState('');

  const orgState = useAppSelector(selectDerivedOrganization);
  const adminPrivilege = usePrivilegeSelector();
  const contentOperations = useMemo(() => {
    return operationType ? getOperations(orgState, operationType) : [];
  }, [orgState, operationType]);
  const { org, sfOrders = [], licenses = [] } = orgState;
  let featureOptions = getFeatureOptions(adminPrivilege === Privilege.Intermediate, true);

  if (adminPrivilege !== Privilege.All) {
    featureOptions = featureOptions.filter((x) => x.value !== Feature.Impersonate);
  }

  const orderSummary = useMemo(() => getOrderSummary(sfOrders), [sfOrders]);
  const licenseSummary = useMemo(() => getLicenseSummary(licenses), [licenses]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const orgChanged = edits?.original?.id !== org?.id;
    const updateComplete =
      operationType &&
      operationTypes.includes(operationType) &&
      contentOperations[0]?.status === 'succeeded';
    if (org && (orgChanged || updateComplete)) {
      setEditWrapper({ edits: new Overwriter({}, {}, [], org) });
      setOperationType(undefined);
    }
  }, [setEditWrapper, setOperationType, edits, org, operationType, contentOperations]);

  if (!org) {
    return <></>;
  }

  const updateFeature = (id: string, fAccess: FeatureAccess) => {
    setOperationType('updateSettings');
    if (fAccess === FeatureAccess.InheritSku) {
      dispatch(deleteOrgFeature({ orgId: org.id, resource: { id } }));
    } else {
      dispatch(updateOrgFeature({ orgId: org.id, resource: { id, fAccess } }));
    }
  };

  const getFeatureValue = (id: string) =>
    org.features?.find((f) => f.id === id)?.fAccess ?? FeatureAccess.InheritSku;
  const updateEdits = () => setEditWrapper({ edits });
  const isEdited = edits?.isEdited();
  let operationDisplayName;
  switch (operationType) {
    case 'deleteOrganization':
      operationDisplayName = 'Initiating delete organization request';
      break;

    case 'copyCreditInfo':
      operationDisplayName = 'Copying users credit info to pooled credits';
      break;

    default:
      operationDisplayName = 'Saving Change';
      break;
  }

  return (
    <ViewFrame
      header={
        <>
          <Box sx={{ mt: 1, display: 'inline-flex' }}>
            <Typography variant="h4">{org.name}</Typography>
            {adminPrivilege === Privilege.All ? (
              <IconButton
                data-testid="deleteOrgButton"
                color="error"
                onClick={() => setDeleteOrganizationModalOpen(true)}
                disabled={!!operationType}
              >
                <Tip title="Delete organization and it's data">
                  <DeleteForeverIcon />
                </Tip>
              </IconButton>
            ) : null}
          </Box>
          <Box sx={{ mt: 1 }}>
            <Button
              variant="contained"
              size="small"
              onClick={() => setDiversitySettingsModalOpen(true)}
              disabled={!!operationType}
            >
              Diversity Settings
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => setSearchFiltersOrderModalOpen(true)}
              sx={{ ml: 1 }}
              disabled={!!operationType}
            >
              Search Filters Order
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => setFeaturesModalOpen(true)}
              sx={{ ml: 1 }}
              disabled={!!operationType}
            >
              Features
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => setConfirmCopyCreditInfoModalOpen(true)}
              sx={{ ml: 1 }}
              disabled={!!operationType}
            >
              <Tip title="Copy users credit info to pooled credits">
                <span>Copy credit info</span>
              </Tip>
            </Button>
          </Box>
        </>
      }
      contentLoader={{
        message: operationDisplayName,
        contentOperations: featuresModalOpen ? [] : contentOperations,
        forceClose: !operationType,
        onClose: () => setOperationType(undefined),
      }}
    >
      <ColumnContent
        columns={[
          <>
            <MapTable
              title="Identity"
              defaultModel="Organization"
              defaultValue={org}
              data={{
                Domain: 'domain',
                'Index Safe Domain': 'indexSafeDomain',
                'SSO Domain 1': 'ssoDomain1',
                'SSO Domain 2': 'ssoDomain2',
                'Date Created': 'dateCreated',
                'Organization ID': 'id',
                'Is Infosec': 'isInfosec',
                'SeekOut Assist Opt Out': 'seekoutAssistOptOut',
                'Date Churned': 'dateChurned',
              }}
            />
            {!!sfOrders.length && <MapTable title="Orders" data={orderSummary} />}
            {!!licenses.length && <MapTable title="Licenses" data={licenseSummary} />}
          </>,
          <MapTable
            title="Pooled Credits"
            defaultModel="Organization"
            defaultValue={org}
            data={{
              'Pooling Enabled': 'poolingEnabled',
              'Pooled Emails Quota': 'pooledEmailsQuota',
              'Pooled Emails Used': 'pooledEmailsUsed',
              'Pooled Add-On Emails': 'pooledAddOnEmails',
              'Pooled Exports Quota': 'pooledExportQuota',
              'Pooled Exports Used': 'pooledExportsUsed',
              'Pooled Add-On Exports': 'pooledAddOnExports',
              'Pooled CSE Queries Quota': 'pooledCSEQueryQuota',
              'Pooled CSE Queries Used': 'pooledCSEQueriesUsed',
              'Pooled Add-On CSE Queries': 'pooledAddOnCSEQueries',
              'Pooled Snail Mail Quota': 'pooledSnailMailQuota',
              'Pooled Snail Mails Used': 'pooledSnailMailUsed',
              'Pooled Add-On Snail Mails': 'pooledAddOnSnailMail',
            }}
          />,
          <>
            <MapTable
              title="Org Credits"
              defaultModel="Organization"
              defaultValue={org}
              data={{
                'Org Emails Quota': 'orgEmailsQuota',
                'Org Add-On Emails': 'additionalEmailsQuota',
                'Org Exports Quota': 'orgExportQuota',
                'Org Add-On Exports': 'additionalExportQuota',
                'Org CSE Queries Quota': 'orgCSEQueryQuota',
                'Org Add-On CSE Queries': 'additionalCSQQueryQuota',
                'Org Snail Mail Quota': 'orgSnailMailQuota',
              }}
            />
            <Box sx={{ display: 'flex', mt: 3, mb: 2 }}>
              <Typography variant="h6">Settings</Typography>
              <Button
                variant="contained"
                size="small"
                disabled={!isEdited}
                onClick={() => setEditWrapper({})}
                sx={{ mx: 1 }}
              >
                Reset
              </Button>
              <Button
                variant="contained"
                size="small"
                disabled={!isEdited}
                onClick={() => {
                  if (edits) {
                    setOperationType('updateSettings');
                    dispatch(
                      updateOrganization({
                        orgId: org.id,
                        resource: { ...edits.getMerged(), real: true },
                      })
                    );
                  }
                }}
              >
                Save
              </Button>
            </Box>
            <Toggle
              label="Salary Info"
              value={edits?.get('salaryInfo') ?? false}
              onChange={(value) => {
                edits?.set('salaryInfo', value, true);
                updateEdits();
              }}
              tipModel="Organization"
              tipProperty="salaryInfo"
            />
            <Selector
              label="Diversity Filters"
              options={getDiversityFilterOptions()}
              value={`${edits?.get('diversitySetting') ?? DiversitySetting.Off}`}
              setValue={(val) => {
                edits?.set('diversitySetting', parseInt(val as string), true);
                updateEdits();
              }}
              sx={{ borderTop: 1, borderStyle: 'groove' }}
            />
            {org.notice && (
              <Box sx={{ mt: 3, mb: 2 }}>
                <Typography variant="h6">Notice</Typography>
                <Typography sx={{ mt: 1 }}>{org.notice}</Typography>
              </Box>
            )}
          </>,
        ]}
      />
      <Modal
        title="Sensitivity Settings"
        open={sensitivitySettingsOpen}
        onClose={() => setSensitivitySettingsOpen(false)}
        additionalProps={{
          fullWidth: true,
          maxWidth: 'lg',
          PaperProps: { sx: { display: 'flex', flexDirection: 'column', height: '100%' } },
        }}
        buttonText="Close"
      >
        <SensitivitySettings />
      </Modal>
      <Modal
        title="Diversity Settings"
        open={diversitySettingsModalOpen}
        onClose={() => setDiversitySettingsModalOpen(false)}
        additionalProps={{
          fullWidth: true,
          maxWidth: 'sm',
          PaperProps: { sx: { display: 'flex', flexDirection: 'column', height: '100%' } },
        }}
        buttonText="Close"
      >
        <DiversitySettings
          data={org.diversitySettings}
          onSave={(indexName, indexSetting) => {
            setOperationType('updateSettings');
            dispatch(
              updateDiversitySettings({ orgId: org.id, key: indexName, value: indexSetting })
            );
          }}
          onRemove={(indexName) => {
            setOperationType('updateSettings');
            dispatch(updateDiversitySettings({ orgId: org.id, key: indexName, remove: true }));
          }}
          operationType={operationType}
          contentOperations={contentOperations}
          onContentLoderClose={() => setOperationType(undefined)}
        />
      </Modal>
      <Modal
        title="Search Filters Order"
        open={searchFiltersOrderModalOpen}
        onClose={() => setSearchFiltersOrderModalOpen(false)}
        additionalProps={{
          fullWidth: true,
          maxWidth: 'sm',
          PaperProps: { sx: { display: 'flex', flexDirection: 'column' } },
        }}
        noActions
      >
        <SearchFiltersOrder
          initialValues={{
            ...(org.searchFiltersOrder || {}),
          }}
          onSubmit={(formInput) => {
            setSearchFiltersOrderModalOpen(false);
            setOperationType('updateSettings');
            dispatch(updateSearchFiltersOrders({ orgId: org.id, key: '', value: formInput }));
          }}
        />
      </Modal>
      <Modal
        title="Features"
        open={featuresModalOpen}
        onClose={() => setFeaturesModalOpen(false)}
        additionalProps={{
          fullWidth: true,
          maxWidth: 'lg',
        }}
        noActions
      >
        <ViewFrame
          contentLoader={{
            message: 'Saving Change',
            contentOperations,
            forceClose: !operationType,
            onClose: () => setOperationType(undefined),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: (theme) => `${theme.spacing(1)} ${theme.spacing(8)}`,
            }}
          >
            {featureOptions.map((skuFeature, index) => (
              <OrgFeatureSelector
                key={skuFeature.value}
                label={skuFeature.text}
                value={getFeatureValue(skuFeature.value)}
                onSave={(value) => updateFeature(skuFeature.value, value)}
                sx={{ mt: index > 0 ? 1 : 0 }}
                setSensitivitySettingsOpen={setSensitivitySettingsOpen}
                disabled={adminPrivilege !== Privilege.All}
              />
            ))}
          </Box>
        </ViewFrame>
      </Modal>
      <ConfirmModal
        open={confirmCopyCreditInfoModalOpen}
        prompt="Copy credit info of all users of this organization to pooled credits?"
        onClose={() => setConfirmCopyCreditInfoModalOpen(false)}
        onAccept={() => {
          setOperationType('copyCreditInfo');
          dispatch(copyCreditInfo(org.id));
        }}
      />
      <Modal
        title="Delete Organization"
        open={deleteOrganizationModalOpen}
        onClose={() => setDeleteOrganizationModalOpen(false)}
        additionalProps={{
          fullWidth: true,
          maxWidth: 'sm',
          PaperProps: { sx: { display: 'flex', flexDirection: 'column' } },
        }}
        noActions
      >
        <DeleteOrganization
          name={org.name}
          onSubmit={(password) => {
            setPassword(password);
            setDeleteOrganizationModalOpen(false);
            setConfirmDeleteOrganizationModalOpen(true);
          }}
          onCancel={() => setDeleteOrganizationModalOpen(false)}
        />
      </Modal>
      <ConfirmModal
        open={confirmDeleteOrganizationModalOpen}
        prompt={'Are you sure you want to delete this organization? This action is irreversible.'}
        onClose={() => setConfirmDeleteOrganizationModalOpen(false)}
        onAccept={() => {
          setConfirmDeleteOrganizationModalOpen(false);
          setOperationType('deleteOrganization');
          dispatch(deleteOrganization({ orgId: org.id, value: password }));
        }}
      />
    </ViewFrame>
  );
};
