import { Box, DialogContentText } from '@mui/material';
import { InvalidEmails } from './InvalidEmails';
import { Modal } from 'components/modals/Modal';

interface ModalProps {
  onClose?: () => void;
  open: boolean;
  csvHasEmailColumn: boolean;
  csvInvalidEmails: string[];
}

export const UploadCsvModal = ({
  onClose,
  open,
  csvHasEmailColumn,
  csvInvalidEmails,
}: ModalProps) => {
  return (
    <Modal
      title="Upload CSV"
      open={open}
      onClose={onClose}
      additionalProps={{
        fullWidth: true,
        maxWidth: 'sm',
      }}
    >
      {!csvHasEmailColumn ? (
        <DialogContentText>
          Could not process the file. The CSV file must have an Email column in it.
        </DialogContentText>
      ) : (
        <>
          <DialogContentText>
            Could not find the matching users for the following email addresses. You can still
            migrate the matching users to desired license.
          </DialogContentText>
          <Box
            sx={{
              marginTop: '20px',
              maxHeight: '325px',
              overflow: 'auto',
            }}
          >
            <InvalidEmails emails={csvInvalidEmails} />
          </Box>
        </>
      )}
    </Modal>
  );
};
