import { useEffect } from 'react';

import { getLoginHistory } from 'api/apiThunks';
import { selectDerivedNewUser } from 'api/newUsersSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ViewFrame } from 'features/frame/ViewFrame';
import { LoginHistoryGrid } from 'components/grids/LoginHistoryGrid';
import { getOperations } from 'utils/operable';

/**
 * The NewUser Login History view
 */
export const LoginHistory = (): JSX.Element => {
  const newUser = useAppSelector(selectDerivedNewUser);
  const viewOperations = getOperations(newUser, 'loginHistory');
  const [loginHistoryOp] = viewOperations;
  const { newUserId, email, loginHistory = [] } = newUser;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (newUserId && email && !loginHistoryOp) {
      dispatch(getLoginHistory({ newUserId, value: email }));
    }
  }, [dispatch, newUserId, email, loginHistoryOp]);

  if (!newUserId) return <></>;

  return (
    <ViewFrame viewLoader={{ message: 'Loading login history', viewOperations }}>
      <LoginHistoryGrid loginHistory={loginHistory} />
    </ViewFrame>
  );
};
