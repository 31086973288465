import { SxProps, Theme } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import { MapTableRow } from 'components/columns/MapTableRow';

type RowProps = Omit<Parameters<typeof MapTableRow>[0], 'name'>;

interface Props<T> {
  title?: string;
  defaultModel?: string;
  defaultValue?: T;
  data: Record<string, RowProps | Extract<keyof T, string>>;
  valueSxProps?: Record<string, SxProps<Theme> | undefined>;
  editabledFields?: string[];
  onEditField?: (info: UserFieldInfo) => void;
  fieldActions?: Record<string, JSX.Element | undefined>;
}

/**
 * A key/value table display
 */
export function MapTable<T>({
  data,
  defaultModel,
  defaultValue,
  title,
  valueSxProps,
  editabledFields,
  onEditField,
  fieldActions,
}: Props<T>): JSX.Element {
  return (
    <>
      {title && <Typography variant="h6">{title}</Typography>}
      <TableContainer>
        <Table>
          <TableBody>
            {Object.entries(data).map(([key, property]) => {
              if (typeof property === 'object') {
                return (
                  <MapTableRow
                    {...property}
                    key={key}
                    name={property.title ?? key}
                    keyModel={property.keyModel ?? defaultModel}
                    valueSx={valueSxProps?.[key]}
                    onEdit={
                      editabledFields?.includes(`${property.keyModel}.${property.keyProperty}`)
                        ? onEditField
                        : undefined
                    }
                    fieldAction={fieldActions?.[`${property.keyModel}.${property.keyProperty}`]}
                  />
                );
              }

              return (
                <MapTableRow
                  key={key}
                  name={key}
                  value={defaultValue?.[property]}
                  keyModel={defaultModel}
                  keyProperty={property}
                  valueSx={valueSxProps?.[key]}
                  onEdit={editabledFields?.includes(property as string) ? onEditField : undefined}
                  fieldAction={fieldActions?.[property as string]}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
