import { postTooltip } from 'api/apiThunks';
import { selectDerivedSystem } from 'api/systemSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { StandardGrid } from 'components/grids/StandardGrid';
import { ViewFrame } from 'features/frame/ViewFrame';
import { getOperations } from 'utils/operable';

/**
 * The system Tooltips view
 */
export const Tooltips = (): JSX.Element => {
  const system = useAppSelector(selectDerivedSystem);
  const viewOperations = getOperations(system, 'tooltips');
  const { tooltips = [] } = system;

  const dispatch = useAppDispatch();

  const tipProperties = tooltips.flatMap((tooltip) =>
    Object.entries(tooltip.properties).map(([property, value]) => ({
      ...value,
      id: `${tooltip.id}-${property}`,
      model: tooltip.model,
      property,
    }))
  );

  return (
    <ViewFrame viewLoader={{ message: 'Loading Tooltips', viewOperations }}>
      <StandardGrid
        dataSet={tipProperties}
        getRowId={(x) => x.id}
        cols={[
          {
            name: 'Model',
            getValue: (x) => x.model,
          },
          {
            name: 'Property',
            getValue: (x) => x.property,
          },
          {
            name: 'Last Modified By',
            getValue: (x) => x.lastModifiedByName ?? x.lastModifiedById,
          },
          {
            name: 'Last Modified Time',
            getValue: (x) => x.lastModifiedAt,
            type: 'date',
          },
          {
            name: 'Text',
            getValue: (x) => x.value,
            isEditable: true,
          },
        ]}
        getEditAction={(oldRow, newRow) => {
          if (oldRow['Text'] !== newRow['Text']) {
            dispatch(
              postTooltip({
                // Pull only the Model ID
                id: (newRow['id'] as string).substring(0, 36),
                model: newRow['Model'] as string,
                property: newRow['Property'] as string,
                value: newRow['Text'] as string,
              })
            );
          }
        }}
      />
    </ViewFrame>
  );
};
