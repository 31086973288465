import { Feature } from 'types/server/common/enums';

// Old API (frontend) Cookie
export const COOKIE_TOKEN = 'nextio.com';
export const COOKIE_SIG = 'nextio.com.sig';
export const CSRF_TOKEN = 'XSRF-TOKEN';
export const CSRF_HEADER = 'x-csrf-token';

// New API (api-admin) Cookie
export const NEW_COOKIE_TOKEN = 'adminUser';
export const NEW_COOKIE_SIG = 'adminUser.sig';
export const NEW_CSRF_TOKEN = 'XSRF-TOKEN-API-ADMIN';
export const NEW_CSRF_HEADER = 'x-csrf-token-api-admin';

export const IS_UNIT_TEST = process.env.NODE_ENV === 'test';

export const CSE_LIST =
  process.env.CSE_LIST ||
  'Facebook, Twitter, Instagram, Meetup, Indeed, Pinterest, Quora, WayUp, StackOverflow, Kaggle, Doximity, GoogleScholar, Patents, Healthgrades, Ning, Angel, Crunchbase, Coderwall, VisualCV, Codeproject, Reddit, Freelancer, Guru, Topcoder, Hackerrank, Loop, Archinect, Carbonmade, Dribbble, Behance, Ratemds, Npino, Zocdoc, Slideshare, AboutMe, Academia, GooglePlus';
export const NoLicenseSku = { id: 'no-license', name: 'No License' };
export const NoTeam = { id: 'not_on_a_team', name: 'Not Team' };

export const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;

export const FEATURES_WHITELIST = [
  Feature.ChatGPTSearchMessage,
  Feature.DiversityBoost,
  Feature.Expert,
  Feature.GitHub,
  Feature.Healthcare,
  Feature.Hotjar,
  Feature.Inbound,
  Feature.IntegrationConnect,
  Feature.IntegrationExport,
  Feature.IntegrationFullRediscovery,
  Feature.InternalTalent,
  Feature.InternaltalentNewView,
  Feature.Messaging,
  Feature.OtherNetworks,
  Feature.PeopleInsights,
  Feature.PipelineInsights,
  Feature.SSO,
  Feature.Search,
];

export const pageSizes = [10, 25, 50, 100];
