import { IntegrationSetting } from 'types/enums';
import { getKeyedOperations, getOperations } from 'utils/operable';
import { Overwriter } from './overwriter';
import { getFileParams } from './files';

/**
 * Find an OrgUser within an Organization object
 * @param org The Organization
 * @param userId The userId, if any
 * @param newUserId The newUserId, if any
 * @returns The OrgUser, or undefined if not found
 */
export function findOrgUser(
  org: Organization,
  userId?: string,
  newUserId?: string
): IOrgUser | undefined {
  const index = findUserIndex(org, userId, newUserId);
  return org.users?.[index];
}

/**
 * Return a ParamsBase object for making API calls with an org user
 * @param org The Organization
 * @param userId The userId, if any
 * @param newUserId The newUserId, if any
 * @returns The ParamsBase, or undefined if not found
 */
export function getOrgUserParamsBase(
  org: Organization,
  userId?: string,
  newUserId?: string
): ParamsBase | undefined {
  const user = findOrgUser(org, userId, newUserId);
  return user
    ? {
        orgId: org.org?.id,
        userId: user?.userId,
        newUserId: user?.newUserId,
      }
    : undefined;
}

/**
 * Remove an OrgUser from a local Organization object
 * @param org The Organization
 * @param userId The userId, if any
 * @param newUserId The newUserId, if any
 */
export function removeOrgUser(org: Organization, userId?: string, newUserId?: string) {
  const index = findUserIndex(org, userId, newUserId);

  if (index >= 0) {
    org.users?.splice(index, 1);
  }
}

export function getOrgUserLicenseId(org: DerivedOrganization, userId?: string) {
  if (userId) {
    return org.licenseMap?.[userId]?.orderItemId;
  }
}

function findUserIndex(org: Organization, userId?: string, newUserId?: string): number {
  return (
    org.users?.findIndex((user) => {
      if (userId) return user.userId === userId;
      if (newUserId) return user.newUserId === newUserId;
    }) ?? -1
  );
}

/**
 * Get the operation group for salesforce items
 */
export function getSFOrderItemsOperations(org: DerivedOrganization): Operation[] {
  if (!org.org?.sfAccountId) return [];
  const orderIds = org?.sfOrders?.map((order) => order.Id) ?? [];
  return [
    ...getOperations(org, 'sfOrders'),
    ...getKeyedOperations(org, orderIds, 'sfOrderItems').flat(),
  ];
}

/**
 * Get the simplifed integration setting from the multiple ATS settings on IOrganization
 */
export function getIntegrationSetting(org?: Overwriter<IOrganization>): IntegrationSetting {
  if (!org?.get('enableAtsIntegrations')) return IntegrationSetting.None;
  if (!org.get('enableAtsRediscovery')) return IntegrationSetting.Export;
  return org.get('rediscoveryType') === 'Full'
    ? IntegrationSetting.Full
    : IntegrationSetting.Connect;
}

/**
 * Set the multiple ATS settings on IOrganization for the provided simplified integration setting
 */
export function setIntegrationSetting(
  org?: Overwriter<IOrganization>,
  setting = IntegrationSetting.None
) {
  if (!org) return;
  const isFull = setting === IntegrationSetting.Full;
  org.set('enableAtsIntegrations', setting !== IntegrationSetting.None, true);
  org.set('enableAtsRediscovery', setting === IntegrationSetting.Connect || isFull, true);
  org.set('rediscoveryType', isFull ? 'Full' : 'ConnectOnly', true);
}

export function getOrderSummary(sfOrders: IActiveOrder[]) {
  return Object.fromEntries(
    sfOrders.map((x) => {
      const name = x.OrderNumber;
      return [
        name,
        {
          value: `(${x.Contract.StartDate} - ${x.Contract.EndDate})`,
          keyTooltip: `${name}: ${x.Id} (${x.Status})`,
        },
      ];
    })
  );
}

export function getLicenseSummary(licenses: License[]) {
  return Object.fromEntries(
    licenses.map((x) => {
      const quantityTaken = x.userIds.filter((z) => !!z).length;
      const total = x.quantity >= 999999 ? '∞' : x.quantity;
      const name = `${x.orderNumber}: ${x.skuName} (${x.endDate})`;
      return [
        x.orderItemId,
        {
          title: name,
          value: `${quantityTaken} / ${total}`,
          keyTooltip: `${name} - ${x.orderItemId}`,
        },
      ];
    })
  );
}

export async function getOrgParams(formInput: IOrganization) {
  const orgRecord = formInput as unknown as Record<string, unknown>;
  const params: OrgParams = { resource: { ...formInput, real: true } };
  const field = 'iconFile';
  const param = await getFileParams(orgRecord, field, 'icon_file');
  if (formInput.id) {
    params.orgId = formInput.id;
  }
  if (param) {
    params.iconFile = param;
  }
  delete orgRecord[field];
  return params;
}
