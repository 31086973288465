import { Box, Button, Divider } from '@mui/material';
import { createOrUpdateExportFormat, deleteExportFormat } from 'api/apiThunks';
import { selectDerivedSystem } from 'api/systemSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { AddExportFormatForm } from 'components/exportFormat/AddExportFormatForm';
import { AddFieldForm } from 'components/exportFormat/AddFieldForm';
import { ConfirmModal } from 'components/modals/ConfirmModal';
import { Selector } from 'components/Selector';
import React, { useMemo, useState } from 'react';

interface Props {
  orgId?: string;
  orgExportFormatFields?: string[];
  exportFormats: IExportFormat[];
  operationType?: ExportFormatOpName;
  selectedExportFormatId: string;
  setSelectedExportFormatId: (value: string) => void;
  setOperationType: (operationType: ExportFormatOpName) => void;
}

/**
 * A header component for adding export formats and fields
 */
export const Header = ({
  orgId,
  orgExportFormatFields = [],
  exportFormats,
  operationType,
  selectedExportFormatId,
  setSelectedExportFormatId,
  setOperationType,
}: Props): JSX.Element => {
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);

  const system = useAppSelector(selectDerivedSystem);
  const { exportFormatFields: globalExportFormatFields = [] } = system;
  const exportFormatFields = useMemo(() => {
    return [...globalExportFormatFields, ...orgExportFormatFields];
  }, [globalExportFormatFields, orgExportFormatFields]);

  const dispatch = useAppDispatch();

  const exportFormatOptions: SelectOption[] = useMemo(() => {
    return exportFormats.map((exportFormat) => ({
      value: exportFormat.id,
      text: exportFormat.name,
    }));
  }, [exportFormats]);

  const remainingFields = useMemo(() => {
    const selectedExportFormat = exportFormats.find(
      (exportFormat) => exportFormat.id === selectedExportFormatId
    );
    const existingFields = selectedExportFormat ? Object.keys(selectedExportFormat.fields) : [];
    return exportFormatFields.filter((f) => !existingFields.includes(f));
  }, [exportFormatFields, exportFormats, selectedExportFormatId]);

  const handleOnAdd = (formInput: Partial<IExportFormat>) => {
    setOperationType('addExportFormat');
    dispatch(createOrUpdateExportFormat({ orgId, resource: { ...formInput, userId: orgId } }));
  };

  const handleOnDelete = () => {
    setDeleteConfirmModalOpen(false);
    setOperationType('deleteExportFormat');
    dispatch(deleteExportFormat({ orgId, value: selectedExportFormatId }));
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline', mt: 1, mb: 1 }}>
        <Selector
          label="Export Formats"
          options={exportFormatOptions}
          value={selectedExportFormatId}
          setValue={(val) => setSelectedExportFormatId(val as string)}
          isDisabled={!!operationType}
          sx={{ minWidth: 300 }}
        />
        <Button
          variant="contained"
          onClick={() => setDeleteConfirmModalOpen(true)}
          sx={{ ml: 1 }}
          disabled={!!operationType || !selectedExportFormatId}
        >
          Delete
        </Button>
        <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 2 }} />
        <AddExportFormatForm
          onSubmit={handleOnAdd}
          exportFormatNames={exportFormatOptions.map((o) => o.text.toLowerCase())}
          disabled={!!operationType}
        />
        <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 2 }} />
        <AddFieldForm
          orgId={orgId}
          exportFormatId={selectedExportFormatId}
          exportFormatFields={remainingFields}
          onSubmit={() => setOperationType('updateExportFormatField')}
          disabled={!!operationType || !selectedExportFormatId}
        />
      </Box>
      <ConfirmModal
        open={deleteConfirmModalOpen}
        prompt="Permanently delete the selected export format?"
        onClose={() => setDeleteConfirmModalOpen(false)}
        onAccept={handleOnDelete}
      />
    </>
  );
};

function areEqual(
  prevProps: React.PropsWithChildren<Props>,
  nextProps: React.PropsWithChildren<Props>
) {
  return (
    nextProps.exportFormats.length === prevProps.exportFormats.length &&
    nextProps.operationType === prevProps.operationType &&
    nextProps.selectedExportFormatId === prevProps.selectedExportFormatId
  );
}

export const ExportFormatsHeader = React.memo(Header, areEqual);
