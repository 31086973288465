type Valid<T> = Partial<Record<keyof T, boolean>>;
type Key<T> = keyof T;

/**
 * Overwriter allows for tracking edits that are made to a blob pulled from the API.
 * Due to the way React state hooks work, it is expected that the individual properties are
 * saved into state and this object is recreated on component update.
 */
export class Overwriter<T> {
  original: T | undefined;
  edited: Partial<T>;
  valid: Valid<T>;
  required: Key<T>[];

  /**
   * Contruct an Overwriter object with the provided properties
   * @param edited A (potentially empty) object containing edits
   * @param valid A (potentially empty) object tracking which edits have been validated
   * @param required A (potentially empty) list of properties which must exist to be valid
   * @param original The original object, or undefined if none
   */
  constructor(edited: Partial<T>, valid: Valid<T>, required: Key<T>[], original?: T) {
    this.edited = edited;
    this.valid = valid;
    this.required = required;
    this.original = original;
  }

  /**
   * Get the latest value of the provided key, or undefined if none
   */
  get<K extends Key<T>>(key: K): T[K] | undefined {
    if (this.edited[key] !== undefined) return this.edited[key];
    if (this.original) return this.original[key];
  }

  /**
   * Get the latest object by merging properties
   */
  getMerged(): Partial<T> {
    return { ...this.original, ...this.edited };
  }

  /**
   * Set the key with an edited value and validation state
   */
  set<K extends Key<T>>(key: K, val: T[K], isValid: boolean): void {
    this.edited[key] = val;

    if (isValid !== undefined) {
      this.valid[key] = isValid;
    }
  }

  /**
   * Merge the edited keys in, marking them as valid
   */
  setMany(edited: Partial<T>): void {
    this.edited = { ...this.edited, ...edited };

    Object.keys(edited).forEach((key) => {
      this.valid[key as Key<T>] = true;
    });
  }

  /**
   * Return true if there are are edits
   */
  isEdited(): boolean {
    const edits = Object.entries(this.edited);
    return (
      edits.length > 0 &&
      edits.some(([key, val]) => {
        return val !== undefined && (!this.original || this.original[key as Key<T>] !== val);
      })
    );
  }

  /**
   * Return true if all required fields and edits are marked as valid
   */
  isValid(): boolean {
    let keysToCheck = Object.keys(this.edited).filter(
      (key) => this.edited[key as Key<T>] !== undefined
    ) as Key<T>[];

    if (!this.original) {
      keysToCheck = keysToCheck.concat(this.required);
    }

    return !keysToCheck.length || keysToCheck.every((key) => this.valid[key as Key<T>]);
  }
}
