import { Box, Button, Chip } from '@mui/material';
import { FormFields } from 'components/form/FormFields';
import { ChangeEvent, useCallback, useMemo, useReducer, useState } from 'react';
import { SkuType } from 'types/enums';
import { minCharactersField, requiredField } from 'utils/form';
import { getEmailQualityOptions, getSkuTypeOptions } from 'utils/selectorOptions';
import { ColumnContent } from '../columns/ColumnContent';
import { ITEM_HEIGHT, ITEM_PADDING_TOP } from 'constant';
import { ConfirmModal } from 'components/modals/ConfirmModal';

const getToggleDefaultValue = ({ formInput, field }: FormValueProps<SkuV1MetaState>) => {
  return {
    defaultValue: formInput[field as keyof ISku] || false,
  };
};

const validationFields = ['skuId', 'name', 'couponCode'];

const column1Fields: FormFieldInfo[] = [
  {
    name: 'skuId',
    label: 'Sku Id',
  },
  {
    name: 'parentSkuId',
    label: 'Parent Sku Id',
  },
  {
    name: 'name',
    label: 'Name',
  },
  {
    type: 'number',
    name: 'priceOneTime',
    label: 'Price One Time',
  },
  {
    type: 'number',
    name: 'pricePerMonth',
    label: 'Price Per Month',
  },
  {
    type: 'number',
    name: 'pricePerYear',
    label: 'Price Per Year',
  },
  {
    type: 'number',
    name: 'emailsPerMonth',
    label: 'Emails Per Month',
  },
  {
    type: 'number',
    name: 'projectsAllowed',
    label: 'Projects Allowed',
  },
  {
    type: 'number',
    name: 'exportPerMonth',
    label: 'Export Per Month',
  },
  {
    type: 'number',
    name: 'recommendationsPerDay',
    label: 'Recommendations Per Day',
  },
  {
    type: 'number',
    name: 'exportPerInstance',
    label: 'Export Per Instance',
  },
  {
    name: 'couponCode',
    label: 'Coupon Code',
  },
  {
    type: 'number',
    name: 'freeTrialTime',
    label: 'Free Trial Time',
  },
  {
    type: 'dropdown',
    name: 'skuType',
    label: 'Sku Type',
    options: getSkuTypeOptions(),
    getDefaultValue: ({ formInput }: FormValueProps<SkuV1MetaState>) => {
      return {
        defaultValue: `${formInput.skuType || SkuType.Sku}`,
      };
    },
  },
  {
    type: 'number',
    name: 'cseQueryLimit',
    label: 'CSE Query Limit',
  },
  {
    type: 'number',
    name: 'snailMailLimit',
    label: 'Snail Mail Limit',
  },
  {
    type: 'date',
    name: 'expiryDate',
    label: 'Expiry Date',
  },
];

const column2Fields: FormFieldInfo[] = [
  {
    type: 'toggle',
    name: 'positionMagnet',
    label: 'Position Magnet',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'intelligence',
    label: 'Intelligence',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'gitHub',
    label: 'GitHub',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'diversityFilters',
    label: 'Diversity Filters',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'integrations',
    label: 'Integrations',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'sharedWorkflow',
    label: 'Shared Workflow',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'sharedProjects',
    label: 'Shared Projects',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'dedicatedAccountSupport',
    label: 'Dedicated Account Support',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'powerFiltersEnabled',
    label: 'Power Filters Enabled',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'countryOfOriginEnabled',
    label: 'Country Of Origin Enabled',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'expertLifeScience',
    label: 'Expert Life Science',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'expertEngineering',
    label: 'Expert Engineering',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'botAllowed',
    label: 'Bot Allowed',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'likelyToMove',
    label: 'Likely To Move',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'disableSearch',
    label: 'Disable Search',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'enterpriseProjectVisibility',
    label: 'Enterprise Project Visibility',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'isEnterpriseSku',
    label: 'Is Enterprise Sku',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'isStripeEnabled',
    label: 'Is Stripe Enabled',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'isActive',
    label: 'Is Active',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'chatGPTSearchAndMessage',
    label: 'Chat GPT Search And Message',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'dropdown',
    name: 'emailQualityLevel',
    label: 'Email Quality Level',
    options: getEmailQualityOptions(),
    getDefaultValue: ({ formInput }: FormValueProps<SkuV1MetaState>) => {
      return {
        defaultValue: formInput.emailQualityLevel || '',
      };
    },
  },
  {
    type: 'dropdown',
    name: 'cseAllowed',
    label: 'CSE Allowed',
    getDefaultValue: ({ formInput }: FormValueProps<SkuV1MetaState>) => {
      return {
        defaultValue: formInput.cseAllowed || [],
      };
    },
    additionalProps: {
      sx: { width: '100%', padding: 0, mt: 1 },
      multiple: true,
      renderValue: (selected: string[]) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selected.map((value) => (
            <Chip key={value} label={value} />
          ))}
        </Box>
      ),
      MenuProps: {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
          },
        },
      },
    },
  },
];

interface Props extends FormProps<ISku> {
  cseList: SelectOption[];
  disabled?: boolean;
}

interface SkuV1MetaState extends FormState, Partial<ISku> {}

const skuV1Reducer = (state: SkuV1MetaState, newState: SkuV1MetaState) => ({
  ...state,
  ...newState,
});

/**
 * A form to create/edit v1 sku
 */
export const SkuV1Form = ({
  onSubmit,
  onCancel,
  sx,
  initialValues,
  cseList,
  disabled,
}: Props): JSX.Element => {
  const [formErrors, setFormErrors] = useState({} as FormErrors);
  const [formInput, setFormInput] = useReducer(skuV1Reducer, { ...(initialValues || {}) });
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const validate = (fieldValues: Record<string, unknown>, field: string) => {
    return field === 'couponCode'
      ? minCharactersField(fieldValues, field, 3)
      : requiredField(fieldValues, field);
  };

  const validateForm = useCallback(
    (fieldValues: Record<string, unknown>, isSubmit = false) => {
      if (isSubmit) {
        // Do full form validation
        validationFields.forEach((field) => {
          formErrors[field] = validate(fieldValues, field);
        });
      } else {
        Object.entries(fieldValues)
          .filter(([key]) => validationFields.includes(key))
          .forEach(([key]) => {
            formErrors[key] = validate(fieldValues, key);
          });
      }

      setFormErrors({ ...formErrors });
      return Object.values(formErrors).every((x) => x === '');
    },
    [formErrors]
  );

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const fieldValue: Record<string, unknown> = {};
    let newValue;
    switch (e.target.type) {
      case 'dropdown':
        if (name === 'cseAllowed') {
          newValue = e.target.value as unknown as string[];
        } else {
          newValue = e.target.value;
        }
        break;

      case 'number':
      case 'date':
        newValue = parseInt(e.target.value);
        break;

      case 'checkbox':
        newValue = e.target.checked;
        break;

      default:
        newValue = e.target.value;
        break;
    }

    if (Object.keys(fieldValue).length === 0) {
      fieldValue[name] = newValue;
    }
    setFormInput(fieldValue);
    validateForm(fieldValue);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm(formInput, true)) return;
    onSubmit(formInput as ISku);
  };

  const columnFields = (formFields: FormFieldInfo[]) => {
    return FormFields<ISku>({
      formFields,
      formInput: formInput as ISku,
      formErrors: formErrors,
      handleInput: handleInput,
    });
  };

  const isValidForm = useMemo(() => {
    return Object.values(formErrors).every((x) => x === '');
  }, [formErrors]);

  const col1Fields = useMemo(() => {
    const fields = [...column1Fields];
    const index = fields.findIndex((o) => o.name === 'skuId');
    if (index > -1) {
      fields[index].additionalProps = { disabled: !!initialValues?.id };
    }
    return fields;
  }, [initialValues]);

  const col2Fields = useMemo(() => {
    const fields = [...column2Fields];
    if (cseList.length > 0) {
      const index = fields.findIndex((o) => o.name === 'cseAllowed');
      if (index > -1) {
        fields[index].options = cseList;
      }
    }
    return fields;
  }, [cseList]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        width: '100%',
        margin: (theme) => theme.spacing(0, 'auto', 4, 'auto'),
        ...sx,
      }}
      autoComplete="off"
    >
      <ColumnContent
        columns={[<>{columnFields(col1Fields)}</>, <>{columnFields(col2Fields)}</>]}
        growWidth
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <Button sx={{ mr: 2 }} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant={!isValidForm ? 'outlined' : 'contained'}
          color={!isValidForm ? 'error' : undefined}
          sx={{ width: 125 }}
          disabled={disabled}
        >
          Save
        </Button>
        {initialValues?.id ? (
          <Button
            variant={'outlined'}
            sx={{ ml: 2 }}
            disabled={disabled}
            onClick={() => setConfirmModalOpen(true)}
          >
            Upgrade to V3
          </Button>
        ) : null}
      </Box>
      <ConfirmModal
        open={confirmModalOpen}
        prompt={`Are you sure you want to upgrade "${initialValues?.name}" Sku to V3 Sku? This action is irreversible.`}
        onClose={() => setConfirmModalOpen(false)}
        onAccept={() => {
          const input = {
            ...formInput,
            upgrade: true,
          };
          setConfirmModalOpen(false);
          onSubmit(input as ISku);
        }}
      />
    </Box>
  );
};
