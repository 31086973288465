import { ChangeEvent } from 'react';
import { FormField } from './FormField';

interface Props<T> {
  formFields: FormFieldInfo[];
  formInput: T;
  formErrors: FormErrors;
  handleInput: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const FormFields = <T,>({ formFields, formInput, formErrors, handleInput }: Props<T>) => {
  return formFields.map((formField) => (
    <FormField
      key={formField.name}
      type={formField.type}
      name={formField.name}
      label={formField.label}
      placeHolder={formField.placeHolder}
      onChange={handleInput}
      options={formField.options}
      {...formField.additionalProps}
      {...(formErrors[formField.name] && {
        error: true,
        helperText: formErrors[formField.name],
      })}
      defaultValue={formInput[formField.name as keyof T]}
      {...formField.getDefaultValue?.({ field: formField.name, formInput })}
    />
  ));
};
