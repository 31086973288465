import { useOrganizationFetch, useSystemFetch, useUserFetch } from 'api/apiMetaThunks';
import { selectDerivedNewUser } from 'api/newUsersSlice';
import { selectDerivedOrganization } from 'api/organizationsSlice';
import { selectDerivedSystem } from 'api/systemSlice';
import { selectDerivedUser } from 'api/usersSlice';
import { useAppSelector } from 'app/hooks';
import { Loader } from 'components/Loader';
import { selectCurrentLookupTab } from 'features/frame/appViewSlice';
import { anyFailedResult, anyLoadingResult } from 'utils/apiResult';
import { getView } from 'utils/contentTabs';
import { getOperations } from 'utils/operable';

/**
 * Manages the data fetching for the selected lookup and acts as a switch for the correct view
 */
export const ViewManager = (): JSX.Element => {
  const currentLookupTab = useAppSelector(selectCurrentLookupTab);
  const system = useAppSelector(selectDerivedSystem);
  const user = useAppSelector(selectDerivedUser);
  const newUser = useAppSelector(selectDerivedNewUser);
  const org = useAppSelector(selectDerivedOrganization);

  const tabType = currentLookupTab.type;
  const userOperations = getOperations(user, ['user', 'recruiter']);
  const newUserOperations = getOperations(newUser, 'newUser');
  const orgOperations = getOperations(org, 'org');

  // Ensure we have all the necessary data for the lookup we are in
  useSystemFetch(system, tabType);
  useUserFetch(user, newUser, currentLookupTab);
  useOrganizationFetch(org, currentLookupTab);

  if (tabType !== 'SYSTEM') {
    let operations: Operation[];

    if (tabType === 'User') {
      operations = userOperations;
    } else if (tabType === 'NewUser') {
      operations = newUserOperations;
    } else if (tabType === 'Organization') {
      operations = orgOperations;
    } else {
      // We don't know what type this is yet, and failures might be expected
      const allResults = [...userOperations, ...newUserOperations, ...orgOperations];

      if (allResults.some((x) => x?.status === 'loading')) {
        // If anything is actively loading, show loading
        operations = [{ status: 'loading' }];
      } else {
        operations = allResults;
      }
    }

    // If initial loading is in progress or failed, cut to a loader with no underlying content
    if (anyLoadingResult(operations) || anyFailedResult(operations)) {
      return <Loader message={`Loading ${tabType || ''} Information`} operations={operations} />;
    }
  }

  // Render the correct view for the lookup
  return getView(currentLookupTab);
};
