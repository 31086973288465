import { StandardGrid } from 'components/grids/StandardGrid';
import { safelyParseJSON } from 'utils/parse';

interface Props {
  dataSet?: ISSOHistory[];
}

/**
 * A filterable DataGrid that displays an SSO history objects
 */
export const SSOHistoryGrid = ({ dataSet }: Props): JSX.Element => {
  return (
    <StandardGrid
      dataSet={dataSet ?? []}
      tipModel="SSOHistory"
      getRowId={(x) => x.id}
      filterPlaceholder="Filter the results"
      cols={[
        {
          name: 'Timestamp',
          description: 'The time the activity occurred',
          valueProperty: 'dateCreated',
          type: 'date',
          relativeWidth: 0.5,
        },
        {
          name: 'Result',
          description: 'The result of the SSO operation',
          valueProperty: 'result',
        },
        {
          name: 'Organization Id',
          description: 'The id of the Organization the user was in',
          valueProperty: 'orgId',
        },
        {
          name: 'Profile',
          description: 'The bag of profile properties logged with the event',
          valueProperty: 'profile',
          getValue: (x) => {
            if (!x.profile) return {};
            return safelyParseJSON(x.profile, { result: x.profile });
          },
          type: 'blob',
        },
      ]}
    />
  );
};
