import Grid from '@mui/material/Grid';

interface Props {
  columns: JSX.Element[];

  /** If true, columns are expanded to fill the full available width */
  growWidth?: boolean;
}

/**
 * A content area with multiple content columns
 */
export const ColumnContent = ({ columns, growWidth }: Props): JSX.Element => {
  const maxAllotted = `${100 / columns.length}%`;
  const columnWidth = growWidth ? maxAllotted : 'auto';

  return (
    <Grid
      container
      sx={{
        textAlign: 'left',
        justifyContent: 'space-evenly',
      }}
    >
      {columns.map((col, index) => (
        <Grid
          key={index}
          container
          direction="column"
          sx={{ width: columnWidth, maxWidth: maxAllotted, padding: (theme) => theme.spacing(2) }}
        >
          {col}
        </Grid>
      ))}
    </Grid>
  );
};
