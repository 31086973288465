import { useEffect, useMemo, useState } from 'react';
import {
  createOrgROIReport,
  downloadROIReport,
  getOrgROIReports,
  getWorkflowStatus,
  terminateWorkflow,
} from 'api/apiThunks';
import { selectDerivedOrganization } from 'api/organizationsSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ViewFrame } from 'features/frame/ViewFrame';
import { getOperations } from 'utils/operable';
import { StandardGrid } from 'components/grids/StandardGrid';
import { Alert, Button, Snackbar, TextField } from '@mui/material';
import RefreshIcon from '@mui/icons-material/RefreshOutlined';
import StopIcon from '@mui/icons-material/StopCircleOutlined';
import DownloadIcon from '@mui/icons-material/DownloadOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownloadOutlined';
import { Modal } from 'components/modals/Modal';
import { CreateRoiReportForm } from 'components/CreateRoiReportForm';
import { createFailedOperation } from 'utils/apiResult';
import { Tip } from 'components/Tip';

/**
 * The Organization ROI Report view
 */
export const RoiReport = (): JSX.Element => {
  const [operationType, setOperationType] = useState<OrgOpName>();
  const [createReportModalOpen, setCreateReportModalOpen] = useState(false);
  const [terminateModalOpen, setTerminateModalOpen] = useState(false);
  const [terminateWorkflowId, setTerminateWorkflowId] = useState('');
  const [terminateReason, setTerminateReason] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [operations, setOperations] = useState<Operation[]>([]);

  const derivedOrg = useAppSelector(selectDerivedOrganization);
  const viewOperations = getOperations(derivedOrg, 'roiReports');
  const [getRoiReportsOp] = viewOperations;
  const { orgId, org, roiReports = [] } = derivedOrg;
  const contentOperations = useMemo(() => {
    return operationType ? getOperations(derivedOrg, operationType) : [];
  }, [derivedOrg, operationType]);
  const allOperations: Operation[] = [...contentOperations, ...operations];

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!getRoiReportsOp) {
      dispatch(getOrgROIReports(orgId || ''));
    }
  }, [dispatch, getRoiReportsOp, orgId]);

  useEffect(() => {
    if (!!operationType && contentOperations[0]?.status === 'succeeded') {
      if (operationType === 'createROIReport') {
        dispatch(getOrgROIReports(orgId || ''));
      } else if (operationType === 'terminateWorkflow') {
        setSnackbarOpen(true);
      }
      setOperationType(undefined);
    }
  }, [operationType, contentOperations, dispatch, orgId]);

  const terminateItemIds = useMemo(() => {
    return roiReports.filter((o) => o.status === 'running').map((o) => o.workflowid);
  }, [roiReports]);

  const handleCreateReportClose = () => setCreateReportModalOpen(false);
  const handleTerminateModalClose = () => setTerminateModalOpen(false);

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleDownload = async (reportId: string | undefined) => {
    setOperationType('download');
    setOperations([{ status: 'loading' }]);

    try {
      if (!reportId) return;
      const response = await downloadROIReport(reportId);
      if (response.sasUrl) {
        window.open(response.sasUrl, '_blank');
      }
      setOperationType(undefined);
      setOperations([]);
    } catch (error) {
      const message = error instanceof Error ? error.message : `${error}`;
      setOperations([createFailedOperation(`Download report failed: ${message}`)]);
    }
  };

  if (!orgId) return <></>;

  let contentMessage;
  switch (operationType) {
    case 'createROIReport':
      contentMessage = 'Creating roi report';
      break;

    case 'getWorkflowStatus':
      contentMessage = 'Fetching workflow status';
      break;

    case 'terminateWorkflow':
      contentMessage = 'Terminating workflow';
      break;

    case 'download':
      contentMessage = 'Downloading report';
      break;

    default:
      contentMessage = '';
      break;
  }

  const disableCreate = roiReports.some((o) => o.status === 'running');

  return (
    <ViewFrame
      viewLoader={{ message: 'Loading ROI Reports', viewOperations }}
      header={
        <Button
          variant="contained"
          sx={{ alignSelf: 'center', margin: 1 }}
          onClick={() => setCreateReportModalOpen(true)}
          disabled={disableCreate}
        >
          Create New Report
        </Button>
      }
      contentLoader={{
        message: contentMessage,
        contentOperations: allOperations,
        forceClose: !operationType,
        onClose: () => setOperationType(undefined),
      }}
    >
      <StandardGrid
        dataSet={roiReports}
        tipModel="OrgUser"
        getRowId={(x) => x.workflowid}
        filterPlaceholder="Filter the displayed reports..."
        cols={[
          {
            name: 'Workflow Id',
            valueProperty: 'workflowid',
          },
          {
            name: 'ROI ReportId',
            valueProperty: 'roireportid',
          },
          {
            name: 'Seekout Activity ReportId',
            valueProperty: 'seekoutactivityreportid',
          },
          {
            name: 'Candidates ReportId',
            valueProperty: 'candidatesreportid',
          },
          {
            name: 'Start',
            valueProperty: 'starttime',
            type: 'date',
          },
          {
            name: 'End',
            valueProperty: 'endtime',
            type: 'date',
          },
          {
            name: 'Status',
            valueProperty: 'status',
          },
        ]}
        getCustomActions={{
          getId: (x) => x.workflowid,
          actionItems: (row) => {
            const items: ActionItemProps<IROIReport>[] = [
              {
                icon: <RefreshIcon />,
                label: 'Refresh',
                color: 'primary',
              },
            ];
            if (terminateItemIds.includes(row.workflowid)) {
              items.push({
                icon: <StopIcon />,
                label: 'Terminate',
                color: 'error',
              });
            }
            if (row.status === 'completed') {
              if (row.roireportid) {
                items.push({
                  icon: (
                    <Tip title="ROI Report">
                      <DownloadIcon />
                    </Tip>
                  ),
                  label: 'Download ROI Report',
                  color: 'primary',
                });
              }
              if (row.seekoutactivityreportid) {
                items.push({
                  icon: (
                    <Tip title="SeekOut Activity Report">
                      <FileDownloadIcon />
                    </Tip>
                  ),
                  label: 'Download SeekOut Activity Report',
                  color: 'primary',
                });
              }
              if (row.candidatesreportid) {
                items.push({
                  icon: (
                    <Tip title="Candidates Report">
                      <DownloadIcon />
                    </Tip>
                  ),
                  label: 'Download Candidates Report',
                  color: 'primary',
                });
              }
            }
            return items;
          },
          action: (label, value) => {
            switch (label) {
              case 'Terminate':
                setTerminateWorkflowId(value.workflowid);
                setTerminateModalOpen(true);
                break;

              case 'Download ROI Report':
                handleDownload(value.roireportid);
                break;

              case 'Download SeekOut Activity Report':
                handleDownload(value.seekoutactivityreportid);
                break;

              case 'Download Candidates Report':
                handleDownload(value.candidatesreportid);
                break;

              default:
                setOperationType('getWorkflowStatus');
                dispatch(getWorkflowStatus({ orgId, value: value.workflowid }));
                break;
            }
          },
        }}
      />
      <Modal
        title="Create Report"
        open={createReportModalOpen}
        onClose={handleCreateReportClose}
        additionalProps={{ fullWidth: true }}
        noActions
      >
        <CreateRoiReportForm
          onSubmit={(formInput) => {
            const roiReportRecord = formInput as unknown as Record<string, unknown>;
            const fileList = roiReportRecord.hireDataFiles as FileList;
            if (fileList && fileList.length > 0) {
              formInput.hireDataFile = fileList[0];
              formInput.hireDataFileName = `${new Date().getTime()}_${fileList[0].name}`;
              delete roiReportRecord.hireDataFiles;
            }
            handleCreateReportClose();
            setOperationType('createROIReport');
            dispatch(
              createOrgROIReport({
                orgId,
                resource: {
                  ...formInput,
                  orgId,
                  domain: org?.domain,
                },
              })
            );
          }}
          onCancel={handleCreateReportClose}
        />
      </Modal>
      <Modal
        title={`Terminate Workflow: ${terminateWorkflowId}?`}
        open={terminateModalOpen}
        onClose={handleTerminateModalClose}
        additionalProps={{ fullWidth: true }}
        actions={
          <>
            <Button onClick={handleTerminateModalClose}>Cancel</Button>
            <Button
              disabled={!terminateReason.length}
              onClick={() => {
                handleTerminateModalClose();
                setOperationType('terminateWorkflow');
                dispatch(
                  terminateWorkflow({
                    orgId,
                    resource: { workflowId: terminateWorkflowId, reson: terminateReason },
                  })
                );
                setTerminateWorkflowId('');
                setTerminateReason('');
              }}
            >
              Terminate
            </Button>
          </>
        }
      >
        <TextField
          label="Reason"
          placeholder="Reason"
          size="medium"
          sx={{ width: '100%', mt: 2 }}
          onChange={(e) => setTerminateReason(e.target.value)}
          value={terminateReason}
        />
      </Modal>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="warning" sx={{ width: '100%' }}>
          The workflow termination doesn't happen immediately so please wait for some time before
          trying to create a new report.
        </Alert>
      </Snackbar>
    </ViewFrame>
  );
};
