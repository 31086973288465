import { Box, Button, Chip, SxProps, Theme } from '@mui/material';
import { FormFields } from 'components/form/FormFields';
import { ChangeEvent, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { DiversitySetting, SearchType, enumFromValue } from 'types/enums';
import { requiredField } from 'utils/form';
import { getDiversityFilterOptions, getSearchTypeOptions } from 'utils/selectorOptions';
import { ColumnContent } from '../columns/ColumnContent';
import { UsageGoals } from './UsageGoals';
import { ITEM_HEIGHT, ITEM_PADDING_TOP } from 'constant';

const validationFields = ['name', 'domain'];
const column1Fields: FormFieldInfo[] = [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'domain',
    label: 'Domain',
  },
  {
    name: 'ssoDomain1',
    label: 'SSO Domain1',
  },
  {
    name: 'ssoDomain2',
    label: 'SSO Domain2',
  },
  {
    name: 'icon',
    label: 'Icon URL',
  },
  {
    type: 'file',
    name: 'iconFile',
    label: 'Choose Icon',
  },
  {
    name: 'sfAccountId',
    label: 'SF AccountId',
  },
  {
    type: 'date',
    name: 'rollOverDate',
    label: 'Pooling RollOver Date',
  },
  {
    name: 'levelsDomain',
    label: 'Levels Domain',
  },
  {
    type: 'dropdown',
    name: 'diversitySetting',
    label: 'Diversity Filters',
    options: getDiversityFilterOptions(),
    getDefaultValue: ({ formInput }: FormValueProps<OrganizationState>) => {
      return {
        defaultValue: `${formInput.diversitySetting || DiversitySetting.Off}`,
      };
    },
  },
  {
    type: 'dropdown',
    name: 'disableExportToATSIndexes',
    label: 'Disable Export to ATS Indexes',
    options: getSearchTypeOptions(),
    getDefaultValue: ({ formInput }: FormValueProps<OrganizationState>) => {
      return {
        defaultValue: formInput.disableExportToATSIndexes || [],
      };
    },
    additionalProps: {
      sx: { width: '100%', padding: 0, mt: 1 },
      multiple: true,
      renderValue: (selected: string[]) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selected.map((value) => (
            <Chip key={value} label={enumFromValue(value, SearchType)} />
          ))}
        </Box>
      ),
      MenuProps: {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
          },
        },
      },
    },
  },
  {
    name: 'sessionTimeOutInMins',
    label: 'Session TimeOut in Mins',
    type: 'number',
  },
  {
    name: 'atsConnectionId',
    label: 'ATS Connection Id',
  },
];

const column2Fields: FormFieldInfo[] = [
  {
    name: 'pooledEmailsQuota',
    label: 'Pooled Emails Quota',
    type: 'number',
  },
  {
    name: 'pooledAddOnEmails',
    label: 'Pooled AddOn Emails',
    type: 'number',
  },
  {
    name: 'orgEmailsQuota',
    label: 'Org Emails Quota',
    type: 'number',
  },
  {
    name: 'pooledExportQuota',
    label: 'Pooled Export Quota',
    type: 'number',
  },
  {
    name: 'pooledAddOnExports',
    label: 'Pooled AddOn Exports',
    type: 'number',
  },
  {
    name: 'orgExportQuota',
    label: 'Org Export Quota',
    type: 'number',
  },
  {
    name: 'pooledCSEQueryQuota',
    label: 'Pooled CSE Query Quota',
    type: 'number',
  },
  {
    name: 'pooledAddOnCSEQueries',
    label: 'Pooled AddOn CSE Queries',
    type: 'number',
  },
  {
    name: 'orgCSEQueryQuota',
    label: 'Org CSE Query Quota',
    type: 'number',
  },
  {
    name: 'pooledSnailMailQuota',
    label: 'Pooled Snail Mail Quota',
    type: 'number',
  },
  {
    name: 'pooledAddOnSnailMail',
    label: 'Pooled AddOn Snail Mails',
    type: 'number',
  },
  {
    name: 'orgSnailMailQuota',
    label: 'Org Snail Mail Quota',
    type: 'number',
  },
  {
    name: 'additionalCSQQueryQuota',
    label: 'Additional CSQ Query Quota',
    type: 'number',
  },
  {
    name: 'additionalEmailsQuota',
    label: 'Additional Emails Quota',
    type: 'number',
  },
  {
    name: 'additionalExportQuota',
    label: 'Additional Export Quota',
    type: 'number',
  },
];

const column3Fields: FormFieldInfo[] = [
  {
    type: 'toggle',
    name: 'clientReporting',
    label: 'Client Reporting',
  },
  {
    type: 'toggle',
    name: 'hidePremiumFeatures',
    label: 'Hide Premium Features',
  },
  {
    type: 'toggle',
    name: 'exportToExcelDisabled',
    label: 'Disable Export to Excel',
  },
  {
    type: 'toggle',
    name: 'otherNetworksDisabled',
    label: 'Disable Other Networks',
  },
  {
    type: 'toggle',
    name: 'projectExportDisabled',
    label: 'Disable Project Export',
  },
  {
    type: 'toggle',
    name: 'disableNotesAndComments',
    label: 'Disable Notes and Comments',
  },
  {
    type: 'toggle',
    name: 'disableSocialLinks',
    label: 'Disable Social Links',
  },
  {
    type: 'toggle',
    name: 'salaryInfo',
    label: 'Salary Info',
  },
  {
    type: 'toggle',
    name: 'exportToGoogleSheets',
    label: 'Export to Google Sheets',
  },
  {
    type: 'toggle',
    name: 'hideSystemExportFormats',
    label: 'Hide System Export Formats',
  },
];

const bottomFields: FormFieldInfo[] = [
  {
    name: 'powerFilters',
    label: 'Power Filters',
    placeHolder: 'Comma separated power filter ids',
    additionalProps: {
      multiline: true,
      minRows: 3,
      maxRows: 3,
    },
  },
  {
    name: 'profileCardFieldsOrder',
    label: 'Profile Card Fields Order',
    placeHolder: 'Profile Card Fields Order',
    additionalProps: {
      multiline: true,
      minRows: 3,
      maxRows: 3,
    },
  },
  {
    name: 'notice',
    label: 'Notice',
    placeHolder: 'Notice',
    additionalProps: {
      multiline: true,
      minRows: 3,
      maxRows: 3,
    },
  },
];

interface OrganizationState extends FormState, Partial<IOrganization> {}

const organizationReducer = (state: OrganizationState, newState: OrganizationState) => ({
  ...state,
  ...newState,
});

/**
 * A form to create/edit an organization
 */
export const OrganizationForm = ({
  onSubmit,
  sx,
  initialValues,
}: FormProps<IOrganization>): JSX.Element => {
  const [formErrors, setFormErrors] = useState({} as FormErrors);
  const [formInput, setFormInput] = useReducer(organizationReducer, { ...(initialValues || {}) });

  useEffect(() => {
    if (initialValues?.icon !== formInput.icon) {
      setFormInput({ icon: initialValues?.icon } as Record<string, unknown>);
    }
  }, [initialValues?.icon, formInput.icon]);

  const poolingEnabled = (formValues: Record<string, unknown>) => {
    return (
      !!formValues['pooledEmailsQuota'] ||
      !!formValues['pooledExportQuota'] ||
      !!formValues['pooledCSEQueryQuota'] ||
      !!formValues['pooledSnailMailQuota']
    );
  };

  const validateForm = useCallback(
    (fieldValues: Record<string, unknown>, isSubmit = false) => {
      if (isSubmit) {
        // Do full form validation
        validationFields.forEach((field) => {
          formErrors[field] = requiredField(fieldValues, field);
        });
        if (poolingEnabled(fieldValues) && !fieldValues['rollOverDate']) {
          formErrors['rollOverDate'] = 'Pooling is enabled. Please select Pooling RollOver Date';
        }
      } else {
        Object.entries(fieldValues)
          .filter(([key]) => validationFields.includes(key))
          .forEach(([key]) => {
            formErrors[key] = requiredField(fieldValues, key);
          });
        if (fieldValues['rollOverDate']) {
          formErrors['rollOverDate'] = '';
        }
      }

      setFormErrors({ ...formErrors });
      return Object.values(formErrors).every((x) => x === '');
    },
    [formErrors]
  );

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const fieldValue: Record<string, unknown> = {};
    let newValue;
    switch (e.target.type) {
      case 'file':
        newValue = e.target.files;
        break;

      case 'dropdown':
        if (name === 'disableExportToATSIndexes') {
          newValue = e.target.value as unknown as string[];
        } else if (name === 'diversitySetting') {
          newValue = parseInt(e.target.value);
        } else {
          newValue = e.target.value;
        }
        break;

      case 'number':
      case 'date':
        newValue = parseInt(e.target.value);
        break;

      case 'checkbox':
        newValue = e.target.checked;
        break;

      case 'object':
        newValue = JSON.parse(e.target.value || '{}');
        break;

      default:
        newValue = e.target.value;
        break;
    }

    if (Object.keys(fieldValue).length === 0) {
      fieldValue[name] = newValue;
    }
    setFormInput(fieldValue);
    validateForm(fieldValue);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm(formInput, true)) return;
    onSubmit(formInput as IOrganization);
  };

  const columnFields = (formFields: FormFieldInfo[]) => {
    return FormFields<IOrganization>({
      formFields,
      formInput: formInput as IOrganization,
      formErrors: formErrors,
      handleInput: handleInput,
    });
  };

  const usageGoalsFields = (name: string, sx?: SxProps<Theme>) => {
    return (
      <UsageGoals
        name={name}
        onChange={handleInput}
        sx={sx}
        defaultValue={formInput[name as keyof IOrganization]}
      />
    );
  };

  const isValidForm = useMemo(() => {
    return Object.values(formErrors).every((x) => x === '');
  }, [formErrors]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        width: '100%',
        margin: (theme) => theme.spacing(0, 'auto', 4, 'auto'),
        ...sx,
      }}
      autoComplete="off"
    >
      <ColumnContent
        columns={[
          <>{columnFields(column1Fields)}</>,
          <>{columnFields(column2Fields)}</>,
          <>{columnFields(column3Fields)}</>,
          <>
            {usageGoalsFields('orgUsageWeeklyGoals')}
            {usageGoalsFields('orgUsageMonthlyGoals', { mt: 2 })}
          </>,
        ]}
        growWidth
      />
      <Box sx={{ pl: 2, pr: 2 }}>{columnFields(bottomFields)}</Box>
      <Button
        type="submit"
        variant={!isValidForm ? 'outlined' : 'contained'}
        color={!isValidForm ? 'error' : undefined}
        sx={{ width: 162, alignSelf: 'center', mt: 5 }}
      >
        Save
      </Button>
    </Box>
  );
};
