import { SxProps, Theme } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { Tip } from 'components/Tip';
import { FeatureAccess } from 'types/server/common/enums';
import { FeatureRadioGroup } from './FeatureRadioGroup';

interface Props {
  label: string;
  value?: FeatureAccess;
  onChange: (val: FeatureAccess) => void;
  tipModel?: string;
  tipProperty?: string;
  sx?: SxProps<Theme>;
}

/**
 * A standard FeatureAccess selection control
 */
export const FeatureSelector = ({
  label,
  value,
  onChange,
  tipModel,
  tipProperty,
  sx,
}: Props): JSX.Element => {
  return (
    <FormControl sx={{ borderBottom: 1, borderStyle: 'groove', ...sx }}>
      <Tip model={tipModel} property={tipProperty}>
        <FormLabel sx={{ color: 'inherit', '&.Mui-focused': { color: 'inherit' } }}>
          {label}
        </FormLabel>
      </Tip>
      <FeatureRadioGroup value={value} onChange={onChange} />
    </FormControl>
  );
};
