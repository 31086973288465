import { useCallback, useState } from 'react';
import CancelIcon from '@mui/icons-material/CloseOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { postTooltip } from 'api/apiThunks';
import { selectDerivedSystem } from 'api/systemSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { asTooltipStrings } from 'utils/convert';

const defaultEnterDelay = 500;

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

interface Props {
  /** The value to show if a model/property is not provided or found */
  title?: unknown;
  /** The name of the relevent tooltip defintion model */
  model?: string;
  /** The name of the property on the tooltip definition */
  property?: string;
  children: JSX.Element;
}

/**
 * A standard tooltip
 */
export const Tip = ({
  title: text,
  model,
  property,
  children,
  ...muiProps
}: Props & Partial<Omit<Parameters<typeof Tooltip>[0], 'title'>>): JSX.Element => {
  // Text-only tooltip
  if (!model || !property) {
    return (
      <BasicTip title={text} {...muiProps}>
        {children}
      </BasicTip>
    );
  }

  return (
    <ComplexTip title={text} model={model} property={property} {...muiProps}>
      {children}
    </ComplexTip>
  );
};

const BasicTip = ({
  title: text,
  children,
  ...muiProps
}: Props & Partial<Omit<Parameters<typeof Tooltip>[0], 'title'>>): JSX.Element => {
  const strings = asTooltipStrings(text);
  return (
    <StyledTooltip
      {...muiProps}
      enterDelay={defaultEnterDelay}
      enterNextDelay={defaultEnterDelay}
      title={
        strings.length ? (
          <>
            {strings.map((x) => (
              <Typography key={`${x}`} sx={{ paddingRight: 1 }}>
                {x}
              </Typography>
            ))}
          </>
        ) : (
          ''
        )
      }
    >
      {children}
    </StyledTooltip>
  );
};

const ComplexTip = ({
  title: text,
  model,
  property,
  children,
  ...muiProps
}: Props & Partial<Omit<Parameters<typeof Tooltip>[0], 'title'>>): JSX.Element => {
  const [editText, setEditText] = useState<string>();

  // Usually we don't want a reusable component to directly access state
  // This is an exception to the rule, passing the state in would be far too troublesome
  const { tooltipMap } = useAppSelector(selectDerivedSystem);

  const dispatch = useAppDispatch();

  const submitEdit = useCallback(
    (id: string, value: string) => {
      dispatch(
        postTooltip({
          id,
          model,
          property: property ?? '',
          value,
        })
      );
      setEditText(undefined);
    },
    [dispatch, model, property, setEditText]
  );

  // Should never happen
  if (!property || !model) {
    return <></>;
  }

  const editMode = editText !== undefined;
  const tooltipDef = tooltipMap[model];
  const tooltipVal = tooltipDef?.properties[property]?.value;
  const displayText = tooltipVal ? tooltipVal : asTooltipStrings(text).join(', ');
  const enterDelay = displayText ? defaultEnterDelay : 5 * defaultEnterDelay;

  return (
    <StyledTooltip
      {...muiProps}
      enterDelay={enterDelay}
      enterNextDelay={enterDelay}
      leaveDelay={editMode ? 60000 : 500}
      title={
        <Box display="flex">
          {!editMode && (
            <>
              <Typography sx={{ paddingRight: 1 }}>{displayText || 'Add a tooltip -->'}</Typography>
              <IconButton
                sx={{ p: 0 }}
                color="secondary"
                onClick={() => setEditText(displayText ?? '')}
              >
                <EditIcon />
              </IconButton>
            </>
          )}

          {editMode && (
            <>
              <TextField
                size="small"
                color="secondary"
                value={editText}
                onChange={(e) => setEditText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    submitEdit(tooltipDef?.id, editText);
                  }
                }}
                InputProps={{ sx: { color: (theme) => theme.palette.secondary.main } }}
                autoFocus
              />
              <IconButton
                sx={{ p: 0 }}
                color="secondary"
                onClick={() => submitEdit(tooltipDef?.id, editText)}
              >
                <SaveIcon />
              </IconButton>
              <IconButton sx={{ p: 0 }} color="secondary" onClick={() => setEditText(undefined)}>
                <CancelIcon />
              </IconButton>
            </>
          )}
        </Box>
      }
    >
      {children}
    </StyledTooltip>
  );
};
