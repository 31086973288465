import startCase from 'lodash/startCase';
import { StandardGrid } from 'components/grids/StandardGrid';
import { getDisplayType } from 'utils/convert';

interface Props {
  dataSet?: Record<string, INewUser | IOrganization | IQuota | IRecruiter | IUser | undefined>;
}

const omit = ['userId', 'rel', '_etag', '_links', '_embedded', 'sessionIds', 'sessionIdMap'];

interface Setting {
  model: string;
  path: string;
  key: string;
  displayKey: string;
  value: unknown;
}

function collapseSettings(model: string, path: string, settings: object = {}): Setting[] {
  return Object.entries(settings)
    .filter(([key]) => !omit.includes(key))
    .flatMap(([key, value]) => {
      const displayKey = startCase(key);
      if (
        value == undefined ||
        typeof value !== 'object' ||
        value instanceof Date ||
        Array.isArray(value)
      ) {
        return { model, path, key, displayKey, value };
      }

      return collapseSettings(model, `${path} \\ ${displayKey}`, value);
    });
}

/**
 * A filterable DataGrid that displays an object full of settings
 */
export const SettingsGrid = ({ dataSet = {} }: Props): JSX.Element => {
  const settings = Object.entries(dataSet).flatMap(([model, obj]) =>
    collapseSettings(model, model, obj)
  );

  return (
    <StandardGrid
      dataSet={settings}
      getRowId={(x) => `${x.path} \\ ${x.key}`}
      filterPlaceholder='Filter the displayed settings (try "date" or "email")'
      cols={[
        {
          name: 'Category',
          description: 'The category of the setting, for organizational purposes only',
          relativeWidth: 2,
          getValue: (x) => x.path,
        },
        {
          name: 'Name',
          description: 'The name of the setting',
          relativeWidth: 2,
          getValue: (x) => x.displayKey,
          getTipData: (x) => ({ model: x.model, property: x.key }),
        },
        {
          name: 'Value',
          description: 'The value of the setting',
          relativeWidth: 6,
          getValue: (x) => x.value,
        },
        {
          name: 'Type',
          description: 'The type of the setting, for filtering or validating edits',
          getValue: (x) => getDisplayType(x.value),
        },
      ]}
    />
  );
};
