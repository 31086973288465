import { useEffect } from 'react';

import { getSubscriptionHistory } from 'api/apiThunks';
import { selectDerivedUser } from 'api/usersSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { StandardGrid } from 'components/grids/StandardGrid';
import { ViewFrame } from 'features/frame/ViewFrame';
import { getOperations } from 'utils/operable';

/**
 * The Subscription History view
 */
export const SubscriptionHistory = (): JSX.Element => {
  const user = useAppSelector(selectDerivedUser);
  const viewOperations = getOperations(user, 'subscriptionHistory');
  const [subscriptionHistoryOp] = viewOperations;
  const { userId, subscriptionHistory = [] } = user;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userId && !subscriptionHistoryOp) {
      dispatch(getSubscriptionHistory(userId));
    }
  }, [dispatch, userId, subscriptionHistoryOp]);

  if (!userId) return <></>;

  return (
    <ViewFrame viewLoader={{ message: 'Loading Subscription History', viewOperations }}>
      <StandardGrid
        dataSet={subscriptionHistory}
        tipModel="SubscriptionHistory"
        getRowId={(x) => x.id}
        filterPlaceholder="Filter the results"
        cols={[
          {
            name: 'Timestamp',
            description: 'The time the activity occurred',
            valueProperty: 'dateCreated',
            type: 'date',
          },
          {
            name: 'Category',
            description: 'The category of the activity',
            valueProperty: 'tokenId',
          },
          {
            name: 'Activity',
            description: 'The operation being performed',
            valueProperty: 'status',
          },
          {
            name: 'Target',
            description: 'The target of the operation',
            valueProperty: 'emailAddress',
          },
          {
            name: 'Sku',
            description: 'The sku in use',
            valueProperty: 'planId',
          },
          {
            name: 'Sku Start',
            description: 'The start date of the sku in use',
            valueProperty: 'current_period_start_time',
            type: 'date',
          },
          {
            name: 'Sku End',
            description: 'The end date of the sku in use',
            valueProperty: 'current_period_end_time',
            type: 'date',
          },
        ]}
      />
    </ViewFrame>
  );
};
