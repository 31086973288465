import Box from '@mui/material/Box';
import React from 'react';

import { Loader } from 'components/Loader';

interface Props {
  /** The loader for data required to use the view */
  viewLoader?: {
    message: string;
    viewOperations: Operation[];
  };

  /** The header element */
  header?: JSX.Element;

  /** The loader for content that changes while using the view */
  contentLoader?: {
    message: string;
    contentOperations: Operation[];
    forceClose: boolean;
    onClose: () => void;
  };

  /** The content element */
  children?: React.ReactNode;
}

/**
 * A standard view with header, content, and loaders
 */
export const ViewFrame = ({ viewLoader, header, contentLoader, children }: Props): JSX.Element => {
  const content = contentLoader
    ? Loader({ ...contentLoader, operations: contentLoader.contentOperations, children })
    : children;

  const view = (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box>{header}</Box>

      <Box sx={{ flexGrow: 1 }}>{content}</Box>
    </Box>
  );

  return viewLoader
    ? Loader({ ...viewLoader, operations: viewLoader.viewOperations, children: view })
    : view;
};
