import { selectDerivedOrganization } from 'api/organizationsSlice';
import { useAppSelector } from 'app/hooks';
import { SettingsGrid } from 'components/grids/SettingsGrid';

/**
 * The organization settings view
 */
export const Settings = (): JSX.Element => {
  const { org } = useAppSelector(selectDerivedOrganization);

  if (!org) return <></>;

  return <SettingsGrid dataSet={{ Organization: org }} />;
};
