import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { deleteNewUser } from 'api/apiMetaThunks';
import { selectDerivedNewUser } from 'api/newUsersSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ColumnContent } from 'components/columns/ColumnContent';
import { ConfirmModal } from 'components/modals/ConfirmModal';
import { OpenLookupButton } from 'components/OpenLookupButton';
import { MapTable } from 'components/columns/MapTable';
import { addLookupTab } from 'features/frame/appViewSlice';
import { ViewFrame } from 'features/frame/ViewFrame';
import { asFullName } from 'utils/convert';
import { getOperations } from 'utils/operable';

/**
 * The user summary view
 */
export const Summary = (): JSX.Element => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [operationKey, setOperationKey] = useState('');

  const derivedNewUser = useAppSelector(selectDerivedNewUser);
  const contentOperations = getOperations(derivedNewUser, 'delete');
  const { newUserId, newUser } = derivedNewUser;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (operationKey) {
      if (derivedNewUser && operationKey === newUserId) {
        deleteNewUser(dispatch, derivedNewUser);
      } else {
        setOperationKey('');
      }
    }
  }, [dispatch, operationKey, newUserId, derivedNewUser]);

  if (!newUserId) return <></>;

  const userName = asFullName(newUser?.firstName, newUser?.lastName);
  const orgId = newUser?.orgId;
  const companyName = newUser?.company;

  return (
    <ViewFrame
      header={
        <>
          <Typography variant="h4" sx={{ marginTop: (theme) => theme.spacing(1) }}>
            {userName}
          </Typography>

          <>
            <Typography variant="h5" display="inline">
              {orgId && (
                <OpenLookupButton
                  openLookup={() => dispatch(addLookupTab({ id: orgId, type: 'Organization' }))}
                  tooltip="Open lookup tab for this new user's organization"
                />
              )}
              {companyName}
            </Typography>
            <Button
              variant="contained"
              size="small"
              disabled={!!operationKey}
              onClick={() => setConfirmModalOpen(true)}
              sx={{ marginLeft: 5 }}
            >
              Delete User
            </Button>
          </>
        </>
      }
      contentLoader={{
        message: `Deleting user ${userName}`,
        contentOperations,
        forceClose: !operationKey,
        onClose: () => setOperationKey(''),
      }}
    >
      <ColumnContent
        columns={[
          <MapTable
            title="Not Activated"
            defaultModel="NewUser"
            defaultValue={newUser}
            data={{
              Email: 'email',
              'Date Created': 'dateCreated',
              Status: 'status',
              'Activation Link': {
                value: newUser?.fullActivationLink,
                keyProperty: 'fullActivationLink',
                showCopy: true,
              },
              'Organization Id': 'orgId',
            }}
          />,
        ]}
      />

      <ConfirmModal
        open={confirmModalOpen}
        prompt="Permanently delete this user and all associated data?"
        onClose={() => setConfirmModalOpen(false)}
        onAccept={() => setOperationKey(newUserId || '')}
      />
    </ViewFrame>
  );
};
