import { useEffect, useState } from 'react';

import { createOrUpdateAtsMeta, deleteAtsMeta, getATSSupported } from 'api/apiThunks';
import { selectDerivedSystem } from 'api/systemSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { StandardGrid } from 'components/grids/StandardGrid';
import { ViewFrame } from 'features/frame/ViewFrame';
import { getOperations } from 'utils/operable';
import { Modal } from 'components/modals/Modal';
import { AtsMetaForm } from 'components/AtsMetaForm';
import { Button } from '@mui/material';
import { getFileParams } from 'utils/files';

const atsMetaFiles: FileInfo[] = [
  {
    field: 'logoFile',
    fileId: 'logo_file',
  },
  {
    field: 'tutorialFile',
    fileId: 'tutorial_file',
  },
];

interface FileInfo {
  field: string;
  fileId: string;
}

/**
 * The system ATS Supported view
 */
export const AtsSupported = (): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  const [editAtsMeta, setEditAtsMeta] = useState<Partial<IATSMeta> | undefined>({});
  const [action, setAction] = useState('');
  const [operationType, setOperationType] = useState<SystemOpName>();
  const [operationKey, setOperationKey] = useState('');
  const system = useAppSelector(selectDerivedSystem);
  const viewOperations = getOperations(system, 'atsSupported');
  const contentOperations = operationType ? getOperations(system, operationType) : [];
  const [atsSupportedOp] = viewOperations;
  const { atsSupported = [] } = system;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!atsSupportedOp) {
      dispatch(getATSSupported());
    }
  }, [dispatch, atsSupportedOp]);

  const handleOnClose = () => setModalOpen(false);

  const handleOnSubmit = async (formInput: Partial<IATSMeta>) => {
    setAction(formInput.id ? 'update' : 'create');
    setOperationType('updateATSMeta');

    const atsMetaRecord = formInput as unknown as Record<string, unknown>;
    const params: AtsMetaParams = { resource: formInput, files: [] };
    for (const atsMetaFile of atsMetaFiles) {
      const param = await getFileParams(atsMetaRecord, atsMetaFile.field, atsMetaFile.fileId);
      if (param) {
        params.files?.push(param);

        // Set logoUrl to empty string to supress an API error
        if (atsMetaFile.fileId === atsMetaFiles[0].fileId && !formInput.logoUrl) {
          formInput.logoUrl = '';
        }
      }
    }
    delete atsMetaRecord[atsMetaFiles[0].field];
    delete atsMetaRecord[atsMetaFiles[1].field];

    dispatch(createOrUpdateAtsMeta(params));
    handleOnClose();
  };

  let operationDisplayName = '';
  if (action === 'create') {
    operationDisplayName = 'Creating';
  } else {
    operationDisplayName =
      action === 'update'
        ? 'Updating'
        : `Deleting "${atsSupported.find((x) => x.id === operationKey)?.name}"`;
  }

  return (
    <ViewFrame
      viewLoader={{ message: 'Loading Supported ATS List', viewOperations }}
      contentLoader={{
        message: `${operationDisplayName} ATS Meta`,
        contentOperations,
        forceClose: !operationType,
        onClose: () => {
          setAction('');
          setOperationType(undefined);
        },
      }}
      header={
        <>
          <Button
            variant="contained"
            onClick={() => {
              setEditAtsMeta({ isActive: false });
              setModalOpen(true);
            }}
            sx={{ mt: 1, mb: 1 }}
          >
            Create
          </Button>
        </>
      }
    >
      <StandardGrid
        dataSet={atsSupported}
        tipModel="ATSMeta"
        getRowId={(x) => x.id}
        cols={[
          {
            name: 'Name',
            valueProperty: 'name',
          },
          {
            name: 'ATS',
            valueProperty: 'atsType',
          },
          {
            name: 'Style',
            valueProperty: 'integrationType',
          },
          {
            name: 'Auth',
            valueProperty: 'authType',
          },
          {
            name: 'Is Active?',
            valueProperty: 'isActive',
            type: 'boolean',
            relativeWidth: 0.5,
          },
        ]}
        getCustomEditAction={{
          action: (value) => {
            setEditAtsMeta(value);
            setModalOpen(true);
          },
        }}
        getDeleteAction={{
          action: (id) => {
            setAction('delete');
            setOperationType('deleteATSMeta');
            setOperationKey(id as string);
            dispatch(deleteAtsMeta({ value: id as string }));
          },
          description: 'Delete ATS Meta',
          getDeleteInfo: (x) => ({ value: x.id, confirmName: x.name }),
        }}
      />

      <Modal
        title={`${editAtsMeta?.id ? 'Edit' : 'Create'} ATS Meta`}
        open={modalOpen}
        onClose={handleOnClose}
        additionalProps={{
          fullWidth: true,
          maxWidth: 'md',
        }}
        noActions
      >
        <AtsMetaForm
          initialValues={{ ...editAtsMeta } as Record<string, FormFieldValue>}
          onSubmit={handleOnSubmit}
          onCancel={handleOnClose}
        />
      </Modal>
    </ViewFrame>
  );
};
