import { useEffect } from 'react';

import { getATSExportHistory } from 'api/apiThunks';
import { selectDerivedUser } from 'api/usersSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { StandardGrid } from 'components/grids/StandardGrid';
import { ViewFrame } from 'features/frame/ViewFrame';
import { createFailedOperation } from 'utils/apiResult';
import { getOperations } from 'utils/operable';

/**
 * The User's ATS Export History view
 */
export const AtsExportHistory = (): JSX.Element => {
  const user = useAppSelector(selectDerivedUser);
  const viewOperations = getOperations(user, ['recruiter', 'atsExportHistory']);
  const [recruiterOp, atsExportHistoryOp] = viewOperations;
  const { userId, email, atsExportHistory = [] } = user;

  // Block the view on email not found
  if (recruiterOp?.status === 'succeeded' && !email) {
    viewOperations.push(createFailedOperation('No primary email found for this User'));
  }

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userId && email && !atsExportHistoryOp) {
      dispatch(getATSExportHistory({ userId, value: email }));
    }
  }, [dispatch, userId, email, atsExportHistoryOp]);

  if (!userId) return <></>;

  return (
    <ViewFrame viewLoader={{ message: 'Loading ATS Export History', viewOperations }}>
      <StandardGrid
        dataSet={atsExportHistory}
        tipModel="ATSExportLogFromDB"
        getRowId={(x) => x.id}
        cols={[
          {
            name: 'On Behalf Of',
            valueProperty: 'onBehalfOf',
          },
          {
            name: 'ATS',
            description: 'The ATS receiving the export',
            valueProperty: 'ats',
          },
          {
            name: 'Project',
            description: 'The project exported from, if any',
            valueProperty: 'projectName',
          },
          {
            name: 'Name',
            description: 'The name of the exported candidate',
            valueProperty: 'name',
          },
          {
            name: 'Candidate ID',
            description: 'The ID of the exproted candidate',
            valueProperty: 'key',
          },
          {
            name: 'URL',
            description: 'The URL endpoint',
            valueProperty: 'url',
            sx: { overflow: 'hidden' },
          },
          {
            name: 'Success?',
            description: 'Was the export successful?',
            getValue: (x) => x.result === 'Success',
            type: 'boolean',
            relativeWidth: 0.5,
          },
          {
            name: 'Error Details',
            description: 'The error specifics, if any',
            valueProperty: 'result',
            getValue: (x) => {
              if (!x.result || x.result === 'Success') return {};

              try {
                return JSON.parse(x.result);
              } catch (error) {
                return { result: x.result };
              }
            },
            type: 'blob',
          },
          {
            name: 'Headers',
            description: 'Request payload',
            valueProperty: 'exportData',
            getValue: (x) => {
              if (!x.headers || x.headers === 'null') {
                return {};
              }
              return { data: x.headers };
            },
            type: 'blob',
          },
          {
            name: 'Export Data',
            description: 'Response data',
            valueProperty: 'exportData',
            getValue: (x) => {
              if (!x.exportData || x.exportData === 'null') {
                return {};
              }
              return { data: x.exportData };
            },
            type: 'blob',
          },
          {
            name: 'Body Response',
            description: 'Body Response',
            valueProperty: 'bodyResponse',
            getValue: (x) => {
              if (!x.bodyResponse || x.bodyResponse === 'null') {
                return {};
              }
              return { data: x.bodyResponse };
            },
            type: 'blob',
          },
          {
            name: 'Timestamp',
            description: 'The time the activity occurred',
            valueProperty: 'timestamp',
            type: 'date',
          },
        ]}
      />
    </ViewFrame>
  );
};
