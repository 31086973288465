import { useEffect } from 'react';

import { getSSOHistory } from 'api/apiThunks';
import { selectDerivedNewUser } from 'api/newUsersSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { SSOHistoryGrid } from 'components/grids/SSOHistoryGrid';
import { ViewFrame } from 'features/frame/ViewFrame';
import { getOperations } from 'utils/operable';

/**
 * The NewUser SSO History view
 */
export const SSOHistory = (): JSX.Element => {
  const newUser = useAppSelector(selectDerivedNewUser);
  const viewOperations = getOperations(newUser, 'ssoHistory');
  const [ssoHistoryOp] = viewOperations;
  const { newUserId, email, ssoHistory = [] } = newUser;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (newUserId && email && !ssoHistoryOp) {
      dispatch(getSSOHistory({ newUserId, value: email }));
    }
  }, [dispatch, newUserId, email, ssoHistoryOp]);

  if (!newUserId) return <></>;

  return (
    <ViewFrame viewLoader={{ message: 'Loading SSO History', viewOperations }}>
      <SSOHistoryGrid dataSet={ssoHistory} />
    </ViewFrame>
  );
};
