import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import dayjs, { Dayjs } from 'dayjs';
import { SxProps, Theme } from '@mui/material';

interface Props {
  label: string;
  value: Dayjs | null;
  setValue: (val: Dayjs) => void;
  sx?: SxProps<Theme>;
  error?: boolean;
  helperText?: string;
  additionalProps?: Record<string, unknown>;
}

/**
 * A standard date selector control
 */
export const DateSelector = ({
  label,
  value,
  setValue,
  sx,
  error,
  helperText,
  ...additionalProps
}: Props): JSX.Element => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={value}
        onChange={(newValue) => setValue(newValue || dayjs(''))}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ margin: (theme) => theme.spacing(1), ...sx }}
            error={error}
            helperText={helperText}
          />
        )}
        {...additionalProps}
      />
    </LocalizationProvider>
  );
};
