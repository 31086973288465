import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import { getAuthStatus, getLogout } from 'api/apiThunks';
import { addExtraReducers } from 'api/slice';
import { RootState } from 'app/store';
import { COOKIE_SIG, COOKIE_TOKEN, NEW_COOKIE_SIG, NEW_COOKIE_TOKEN } from 'constant';
import { isGuid } from 'utils/convert';
import { fetchWrapper } from 'utils/fetchWrapper';
import { createOperable, setOperation } from 'utils/operable';

/* Selectors */

/**
 * Select data for the specified logged-in user
 * @param state The root redux state
 * @param isNewApi True to select the login data for the new API, false for old API
 * @returns The derived logged-in user data
 */
export function selectDerivedAuth(state: RootState, isNewApi: boolean): DerivedAuth {
  const auth = isNewApi ? state.auth.newApi : state.auth.api;
  const { apiUser } = auth;
  const isAuthenticated = isGuid(apiUser?.id);

  return {
    ...auth,
    isAuthenticated,
    isAuthorized: isAuthenticated && !!apiUser?.isAdmin,
    displayString: apiUser ? `"${apiUser.name}" (${apiUser.id})` : '',
  };
}

/* Reducers */

interface StateType {
  api: Auth;
  newApi: Auth;
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    api: createOperable(),
    newApi: createOperable(),
  } as StateType,
  reducers: {
    clearAuth: (state) => {
      clearAuthCookies();
      state.api = createOperable();
      state.newApi = createOperable();
    },
  },
  extraReducers: (builder) => {
    addExtraReducers(getAuthStatus, setAuthStatus, builder);
    addExtraReducers(getLogout, setLogout, builder);
  },
});

export const { clearAuth } = authSlice.actions;

export const authReducer = authSlice.reducer;

function clearAuthCookies() {
  Cookies.remove(COOKIE_TOKEN);
  Cookies.remove(COOKIE_SIG);
  Cookies.remove(NEW_COOKIE_TOKEN);
  Cookies.remove(NEW_COOKIE_SIG);
}

function setAuthStatus(
  state: StateType,
  isNewApi: boolean | undefined,
  result: ApiResult<IPassportUser>
) {
  const auth = isNewApi ? state.newApi : state.api;

  const message = `Get Login Status ${isNewApi ? ' (new API)' : ''}`;
  const user = setOperation(auth, 'apiUser', result, message);

  if (user) {
    auth.apiUser = user;
  }

  if (result.status === 'failed') {
    runSSO(isNewApi);
  }
}

function setLogout() {
  clearAuthCookies();
  const siteUrl = 'https://seekout.com';
  window.location.replace(siteUrl);
}

async function runSSO(isNewApi?: boolean) {
  const siteUrl = window.location.origin;
  const api = isNewApi ? 'api-new' : 'api';
  const { redirectUrl } = await fetchWrapper(
    `/${api}/auth/sso2?siteUrl=${siteUrl}&domain=seekout.com`,
    {
      method: 'GET',
    }
  );
  window.location.href = redirectUrl.replace('/api/', `/${api}/`);
}
