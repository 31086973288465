import { Box, Button, Menu, MenuItem } from '@mui/material';
import { useSkuFetch } from 'api/apiMetaThunks';
import { createOrUpdateSkuV1, createOrUpdateSkuV2, deleteSkuV1, deleteSkuV2 } from 'api/apiThunks';
import { selectDerivedSystem } from 'api/systemSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { StandardGrid } from 'components/grids/StandardGrid';
import { Modal } from 'components/modals/Modal';
import { SkuV1Form } from 'components/sku/SkuV1Form';
import { SkuV2Form } from 'components/sku/SkuV2Form';
import { SkuV3Form } from 'components/sku/SkuV3Form';
import { CSE_LIST } from 'constant';
import { ViewFrame } from 'features/frame/ViewFrame';
import { useEffect, useMemo, useState } from 'react';
import { Privilege } from 'types/enums';
import { getOperations } from 'utils/operable';
import { usePrivilegeSelector } from 'utils/usePrivilegeSelector';

/**
 * The system SKU list view
 */
export const Sku = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [editSku, setEditSku] = useState<Partial<ISku> | Partial<ISkuV2>>({});
  const [deleteSkuName, setDeleteSkuName] = useState('');
  const [operationType, setOperationType] = useState<SystemOpName>();

  const system = useAppSelector(selectDerivedSystem);
  const adminPrivilege = usePrivilegeSelector();
  const viewOperations = getOperations(system, ['skuV1', 'skuV2']);
  const contentOperations = useMemo(() => {
    return operationType ? getOperations(system, operationType) : [];
  }, [system, operationType]);
  const { skuV1 = [], skuV2 = [] } = system;

  const dispatch = useAppDispatch();

  useSkuFetch(system);

  useEffect(() => {
    if (!!operationType && contentOperations[0]?.status === 'succeeded') {
      setOperationType(undefined);
      if (operationType === 'deleteSku') {
        setDeleteSkuName('');
      }
    }
  }, [operationType, contentOperations]);

  const cseList = CSE_LIST.split(',')
    .sort()
    .map((x) => ({ text: x, value: x }));

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRefreshSalesforceTokenClick = () => {
    window.open(
      `/api/newUsers/salesforce-auth-start?redirectUrl=${window.location.origin}`,
      '_blank'
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnClose = () => setModalOpen(false);

  const handleOnSkuV1Submit = async (formInput: ISku) => {
    setOperationType('saveSku');
    dispatch(createOrUpdateSkuV1({ resource: formInput }));
    handleOnClose();
  };

  const handleOnSkuV2Submit = async (formInput: ISkuV2) => {
    const skuInput = { ...formInput };
    if (skuInput.index === '') {
      delete skuInput.index;
    }
    if (typeof skuInput.maxPageSize === 'string') {
      skuInput.maxPageSize = parseInt(skuInput.maxPageSize);
    }
    setOperationType('saveSku');
    dispatch(createOrUpdateSkuV2({ resource: skuInput }));
    handleOnClose();
  };

  const renderSkuForm = () => {
    if ((editSku as ISkuV2).isNew) {
      const SkuForm = (editSku as ISkuV2).features ? SkuV3Form : SkuV2Form;
      return (
        <SkuForm
          initialValues={{ ...editSku } as Record<string, FormFieldValue>}
          onSubmit={handleOnSkuV2Submit}
          onCancel={handleOnClose}
          disabled={adminPrivilege !== Privilege.All}
        />
      );
    }
    return (
      <SkuV1Form
        initialValues={{ ...editSku } as Record<string, FormFieldValue>}
        cseList={cseList || []}
        onSubmit={handleOnSkuV1Submit}
        onCancel={handleOnClose}
        disabled={adminPrivilege !== Privilege.All}
      />
    );
  };

  const operationDisplayName =
    operationType === 'deleteSku' ? `Deleting "${deleteSkuName}"` : 'Saving';

  return (
    <ViewFrame
      viewLoader={{ message: 'Loading Sku Lists', viewOperations }}
      contentLoader={{
        message: `${operationDisplayName} Sku`,
        contentOperations,
        forceClose: !operationType,
        onClose: () => setOperationType(undefined),
      }}
      header={
        <Box sx={{ mt: 1, mb: 1 }}>
          <Button
            id="create-button"
            aria-controls={open ? 'sku-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            onClick={handleClick}
            disabled={adminPrivilege !== Privilege.All}
          >
            Create
          </Button>
          <Button variant="outlined" onClick={handleRefreshSalesforceTokenClick} sx={{ ml: 2 }}>
            Refresh Salesforce Token
          </Button>
          <Menu
            id="sku-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'create-button',
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                setEditSku({});
                setModalOpen(true);
              }}
            >
              Sku (V1)
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                setEditSku({ isNew: true });
                setModalOpen(true);
              }}
            >
              Sku (V2)
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                setEditSku({ isNew: true, features: [] });
                setModalOpen(true);
              }}
            >
              Sku (V3)
            </MenuItem>
          </Menu>
        </Box>
      }
    >
      <StandardGrid
        dataSet={[...skuV1, ...skuV2]}
        tipModel="Sku"
        getRowId={(x) => x.id}
        cols={[
          {
            name: 'Name',
            valueProperty: 'name',
          },
          {
            name: 'MSRP',
            description: "Manufacturer's Suggested Retail Price",
            getValue: (x) => {
              if ('msrp' in x) return `$${x.msrp} per year`;
              if (x.pricePerYear) return `$${x.pricePerYear} per year`;
              if (x.pricePerMonth) return `$${x.pricePerMonth} per month`;
              if (x.priceOneTime) return `$${x.priceOneTime} one time`;
              if (x.freeTrialTime) return `Free (${x.freeTrialTime} days)`;
              if (x.priceOneTime === 0 || x.pricePerMonth === 0 || x.pricePerYear === 0)
                return 'Free';
            },
            getTooltip: (x) => {
              if ('msrp' in x) return `msrp: ${x.msrp}`;
              const tips: string[] = [];
              if (x.pricePerYear != undefined) tips.push(`pricePerYear: ${x.pricePerYear}`);
              if (x.pricePerMonth != undefined) tips.push(`pricePerMonth: ${x.pricePerMonth}`);
              if (x.priceOneTime != undefined) tips.push(`priceOneTime: ${x.priceOneTime}`);
              if (x.freeTrialTime != undefined) return `freeTrialTime: ${x.freeTrialTime}`;
              return tips;
            },
          },
          {
            name: 'Emails Per Month',
            valueProperty: 'emailsPerMonth',
            type: 'number',
          },
          {
            name: 'Exports Per Month',
            valueProperty: 'exportPerMonth',
            type: 'number',
          },
          {
            name: 'CSE Query Limit',
            description: 'Custom Search Engine query limit',
            valueProperty: 'cseQueryLimit',
            type: 'number',
          },
          {
            name: 'Properties',
            description: 'The full set of properties on the sku',
            innerModel: 'Sku',
            getValue: (x) => x,
            type: 'blob',
          },
        ]}
        getCustomEditAction={{
          action: (value) => {
            setEditSku({
              ...value,
              skuId: value.id,
            });
            setModalOpen(true);
          },
        }}
        getDeleteAction={
          adminPrivilege === Privilege.All
            ? {
                action: (value) => {
                  setOperationType('deleteSku');
                  if ((value as ISkuV2).isNew) {
                    setDeleteSkuName((value as ISkuV2).name);
                    dispatch(deleteSkuV2((value as ISkuV2).id));
                  } else {
                    setDeleteSkuName((value as ISku).name);
                    dispatch(deleteSkuV1({ resource: value as ISku }));
                  }
                },
                description: 'Delete Sku',
                getDeleteInfo: (x) => ({ value: x, confirmName: x.name }),
              }
            : undefined
        }
      />
      <Modal
        title={`${editSku?.id ? 'Edit' : 'Create'} Sku (V${
          (editSku as ISkuV2).isNew ? ((editSku as ISkuV2).features ? 3 : 2) : 1
        })`}
        open={modalOpen}
        onClose={handleOnClose}
        additionalProps={{
          fullWidth: true,
          maxWidth: 'md',
        }}
        noActions
      >
        {renderSkuForm()}
      </Modal>
    </ViewFrame>
  );
};
