import { Box, Button } from '@mui/material';
import { updateExportFormatField } from 'api/apiThunks';
import { useAppDispatch } from 'app/hooks';
import { FormFields } from 'components/form/FormFields';
import { ChangeEvent, useCallback, useMemo, useReducer, useState } from 'react';
import { requiredField } from 'utils/form';

const validationFields = ['fieldName', 'fieldLabel'];

const formFields: FormFieldInfo[] = [
  {
    type: 'dropdown',
    name: 'fieldName',
    label: 'Field Name',
    getDefaultValue: ({ formInput }: FormValueProps<AddFieldState>) => {
      return { defaultValue: formInput.fieldName || '' };
    },
    additionalProps: {
      sx: { minWidth: 200, maxWidth: 220, padding: 0 },
    },
  },
  {
    name: 'fieldLabel',
    label: 'Field Label',
    getDefaultValue: ({ formInput }: FormValueProps<AddFieldState>) => {
      return { defaultValue: formInput.fieldLabel || '' };
    },
    additionalProps: {
      sx: { mt: 0, ml: 2, flexShrink: 0 },
    },
  },
];

interface AddFieldState extends FormState, Partial<IExportFormatField> {}

const addFieldReducer = (state: AddFieldState, newState: AddFieldState) => ({
  ...state,
  ...newState,
});

interface Props extends FormProps<IExportFormatField> {
  orgId?: string;
  exportFormatId: string;
  exportFormatFields: string[];
  disabled?: boolean;
  onSubmit: () => void;
}

/**
 * A form to create export format field
 */
export const AddFieldForm = ({
  onSubmit,
  sx,
  initialValues,
  orgId,
  exportFormatId,
  exportFormatFields,
  disabled,
}: Props): JSX.Element => {
  const [formErrors, setFormErrors] = useState({} as FormErrors);
  const [formInput, setFormInput] = useReducer(addFieldReducer, { ...(initialValues || {}) });

  const dispatch = useAppDispatch();

  const fieldOptions: SelectOption[] = useMemo(() => {
    return exportFormatFields.map((exportFormatField) => ({
      value: exportFormatField,
      text: exportFormatField,
    }));
  }, [exportFormatFields]);

  const formFieldList = useMemo(() => {
    return formFields.map((formField) => {
      if (formField.name === formFields[0].name) {
        formField.options = fieldOptions;
      }
      const additionalProps = formField.additionalProps || {};
      additionalProps['disabled'] = disabled;
      return {
        ...formField,
        additionalProps,
      };
    });
  }, [fieldOptions, disabled]);

  const validateForm = useCallback(
    (fieldValues: Record<string, unknown>, isSubmit = false) => {
      if (isSubmit) {
        // Do full form validation
        validationFields.forEach((field) => {
          formErrors[field] = requiredField(fieldValues, field);
        });
      } else {
        Object.entries(fieldValues)
          .filter(([key]) => validationFields.includes(key))
          .forEach(([key]) => {
            formErrors[key] = requiredField(fieldValues, key);
          });
      }

      setFormErrors({ ...formErrors });
      return Object.values(formErrors).every((x) => x === '');
    },
    [formErrors]
  );

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const fieldValue = { [name]: e.target.value };
    setFormInput(fieldValue);
    validateForm(fieldValue);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm(formInput, true)) return;
    dispatch(
      updateExportFormatField({ orgId, exportFormatId, ...(formInput as IExportFormatField) })
    );
    onSubmit();
    setFormInput({ fieldLabel: undefined, fieldName: undefined });
  };

  const isValidForm = useMemo(() => {
    return Object.values(formErrors).every((x) => x === '');
  }, [formErrors]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        alignItems: 'baseline',
        ...sx,
      }}
      autoComplete="off"
    >
      {FormFields<IExportFormatField>({
        formFields: formFieldList,
        formInput: formInput as IExportFormatField,
        formErrors: formErrors,
        handleInput: handleInput,
      })}
      <Button
        type="submit"
        variant={!isValidForm ? 'outlined' : 'contained'}
        color={!isValidForm ? 'error' : undefined}
        sx={{ height: 'fit-content', ml: 2, flexShrink: 0 }}
        disabled={disabled}
      >
        Add Field
      </Button>
    </Box>
  );
};
