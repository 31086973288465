import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CopyIcon from '@mui/icons-material/ContentCopyOutlined';

import { getAtsConnections, updateAtsConnection } from 'api/apiThunks';
import { selectDerivedOrganization } from 'api/organizationsSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ColumnContent } from 'components/columns/ColumnContent';
import { Selector } from 'components/Selector';
import { Toggle } from 'components/Toggle';
import { ViewFrame } from 'features/frame/ViewFrame';
import { ATSType, IntegrationType } from 'types/server/common/enums';
import { createFailedOperation } from 'utils/apiResult';
import { asDisplayString, isJson } from 'utils/convert';
import { getKeyedOperations, getOperations } from 'utils/operable';
import { Tip } from 'components/Tip';

interface Editable<T> {
  value: T;
  isEdited?: boolean;
  invalid?: boolean;
}

class Edits {
  id: string;
  featureSet: Editable<Set<IntegrationType>>;
  authData: Editable<string>;
  rediscoverySchema: Editable<string>;

  constructor(connection?: IATSConnection) {
    const { id, featureList, userAuthData, atsRediscoverySchema } = connection ?? {};
    this.id = id ?? '';
    this.featureSet = { value: new Set(featureList) };
    this.authData = { value: JSON.stringify(userAuthData, null, 2) ?? '' };
    this.rediscoverySchema = { value: JSON.stringify(atsRediscoverySchema, null, 2) ?? '' };
  }

  hasFeature(feature: IntegrationType) {
    return this.featureSet.value.has(feature);
  }

  editFeature(feature: IntegrationType, include: boolean) {
    if (include) {
      this.featureSet.value.add(feature);
    } else {
      this.featureSet.value.delete(feature);
    }
    this.featureSet.isEdited = true;
  }

  setAuthData(data: string) {
    this.authData = {
      value: data,
      isEdited: true,
      invalid: !isJson(data),
    };
  }

  setSchema(data: string) {
    this.rediscoverySchema = {
      value: data,
      isEdited: true,
      invalid: !isJson(data),
    };
  }

  createInput(): Partial<AtsConnectionInput> {
    const result: Partial<AtsConnectionInput> = { id: this.id };

    if (this.featureSet.isEdited) {
      result.featureList = Array.from(this.featureSet.value);
    }

    if (this.authData.isEdited && !this.authData.invalid) {
      result.userAuthData = JSON.parse(this.authData.value);
    }

    if (this.rediscoverySchema.isEdited && !this.rediscoverySchema.invalid) {
      result.atsRediscoverySchema = JSON.parse(this.rediscoverySchema.value);
    }

    return result;
  }

  isEdited() {
    return this.featureSet.isEdited || this.authData.isEdited || this.rediscoverySchema.isEdited;
  }

  isValid() {
    return !this.authData.invalid && !this.rediscoverySchema.invalid;
  }
}

const AtsDisplayMap = Object.fromEntries(Object.entries(ATSType).map(([key, val]) => [val, key]));

/**
 * The Organization ATS Connections view
 */
export const AtsConnections = (): JSX.Element => {
  const [selected, setSelected] = useState('');
  const [submitId, setSubmitId] = useState('');
  const [editWrapper, setEditWrapper] = useState({ edits: new Edits() });

  const org = useAppSelector(selectDerivedOrganization);
  const viewOperations = getOperations(org, 'atsConnections');
  const [contentOperations] = getKeyedOperations(org, [submitId], 'updateAtsConnection');
  const { orgId, atsConnections = [] } = org;
  const connection = selected ? atsConnections.find((x) => x.id === selected) : atsConnections[0];

  const dispatch = useAppDispatch();

  const { edits } = editWrapper;
  const updateEdits = () => setEditWrapper({ edits });

  useEffect(() => {
    if (orgId && !viewOperations[0]) {
      dispatch(getAtsConnections(orgId));
    }
  }, [dispatch, orgId, viewOperations]);

  useEffect(() => {
    // Reset the selection if the lookup was changed
    if (selected && !atsConnections.some((x) => x.id === selected)) {
      setSelected('');
      setSubmitId('');
    }
  }, [setSelected, setSubmitId, atsConnections, selected]);

  useEffect(() => {
    // Reset the Edits if the selected connection changed
    if (connection && edits.id !== connection.id) {
      setEditWrapper({ edits: new Edits(connection) });
      setSubmitId('');
    }
  }, [setEditWrapper, setSubmitId, edits, connection]);

  if (!orgId) return <></>;

  // Block the view on no ATS connections found
  if (viewOperations[0]?.status === 'succeeded' && !atsConnections.length) {
    viewOperations.push(createFailedOperation('No ATS Connections found for this Organization'));
  }

  const selectorOptions = atsConnections.map((x) => ({
    text: AtsDisplayMap[x.atsType] ?? x.atsType,
    value: x.id,
  }));
  const enableReset = edits.isEdited();
  const enableSave = enableReset && edits.isValid();

  return (
    <ViewFrame
      viewLoader={{ message: 'Loading ATS Connections', viewOperations }}
      header={
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {atsConnections.length > 1 && (
            <Selector
              label="ATS Connection"
              options={selectorOptions}
              value={selected || atsConnections[0]?.id}
              setValue={(val) => setSelected(val as string)}
            />
          )}
          <Button
            variant="contained"
            disabled={!enableReset}
            onClick={() => setEditWrapper({ edits: new Edits(connection) })}
            sx={{ alignSelf: 'center', margin: 1 }}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            disabled={!enableSave}
            onClick={() => {
              setSubmitId(edits?.id ?? '');
              dispatch(
                updateAtsConnection({
                  orgId,
                  resource: {
                    ...edits.createInput(),
                    orgId: connection?.userId,
                  },
                })
              );
            }}
            sx={{ alignSelf: 'center', margin: 1 }}
          >
            Save
          </Button>
        </Box>
      }
      contentLoader={{
        message: `Updating ATS Connection config`,
        contentOperations,
        forceClose: !submitId,
        onClose: () => setSubmitId(''),
      }}
    >
      {connection && (
        <ColumnContent
          growWidth
          columns={[
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6">{`${
                  AtsDisplayMap[connection.atsType] ?? connection.atsType
                } Connection`}</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', ml: 2 }}>
                  <Tip title="Connection Id">
                    <Typography>{connection.id}</Typography>
                  </Tip>
                  <IconButton
                    title="Copy"
                    aria-label="Copy"
                    size="small"
                    sx={{ py: 0 }}
                    onClick={() => {
                      navigator.clipboard.writeText(connection.id);
                    }}
                  >
                    <CopyIcon />
                  </IconButton>
                </Box>
              </Box>
              {connection.validationStatus && (
                <Typography>
                  {`Status: ${connection.validationStatus} on ${asDisplayString(
                    connection.lastValidationDate
                  )}`}
                </Typography>
              )}
              {connection.candidateQueueName && (
                <Typography>{`Candidate Queue Name: ${connection.candidateQueueName}`}</Typography>
              )}
              <Toggle
                label="Analytics"
                value={edits.hasFeature(IntegrationType.Analytics)}
                onChange={(value) => {
                  edits.editFeature(IntegrationType.Analytics, value);
                  updateEdits();
                }}
              />
              <Toggle
                label="Export"
                value={edits.hasFeature(IntegrationType.Export)}
                onChange={(value) => {
                  edits.editFeature(IntegrationType.Export, value);
                  updateEdits();
                }}
              />
              <Toggle
                label="Inbound"
                value={edits.hasFeature(IntegrationType.Inbound)}
                onChange={(value) => {
                  edits.editFeature(IntegrationType.Inbound, value);
                  updateEdits();
                }}
              />
              <Toggle
                label="Rediscovery"
                value={edits.hasFeature(IntegrationType.Rediscovery)}
                onChange={(value) => {
                  edits.editFeature(IntegrationType.Rediscovery, value);
                  updateEdits();
                }}
              />
              <TextField
                label="User Auth Data"
                value={edits.authData.value}
                error={edits.authData.invalid}
                onChange={(e) => {
                  edits.setAuthData(e.target.value);
                  updateEdits();
                }}
                multiline
                sx={{ my: 2 }}
              />
            </>,
            <TextField
              label="Rediscovery Schema"
              value={edits.rediscoverySchema.value}
              error={edits.rediscoverySchema.invalid}
              onChange={(e) => {
                edits.setSchema(e.target.value);
                updateEdits();
              }}
              multiline
              maxRows={25}
            />,
            <TextField
              label="Permission Status"
              value={JSON.stringify(connection.permissionStatusList, null, 2) ?? ''}
              multiline
              disabled
              maxRows={25}
            />,
          ]}
        />
      )}
    </ViewFrame>
  );
};
