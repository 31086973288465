import {
  COOKIE_SIG,
  COOKIE_TOKEN,
  CSRF_HEADER,
  CSRF_TOKEN,
  NEW_COOKIE_SIG,
  NEW_COOKIE_TOKEN,
  NEW_CSRF_HEADER,
  NEW_CSRF_TOKEN,
} from 'constant';
import Cookies from 'js-cookie';
import defaultsDeep from 'lodash/defaultsDeep';

const defaultConfig = {
  method: 'POST',
  mode: 'same-origin',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeOut: 5000,
};

const LoopMax = 5;
let loopGuard = {
  url: '',
  method: '',
  body: '',
  count: 0,
};

/**
 * A simple low-level loop detector.
 * Return true if the API signature has been fetched many times in a row
 */
function isLoop(url: string, { method = '', body }: RequestInit) {
  const { url: savedUrl, method: savedMethod, body: savedBody, count } = loopGuard;
  const stringBody = (body as string) ?? '';

  if (savedUrl === url && savedMethod === method && savedBody === stringBody) {
    if (count > LoopMax) {
      return true;
    }
    loopGuard.count++;
  } else {
    loopGuard = {
      url,
      method,
      body: stringBody,
      count: 1,
    };
  }
}

/**
 * Creates a wrapper function around the HTML5 Fetch API that provides
 * default arguments to fetch(...) and is intended to reduce the amount
 * of boilerplate code in the application.
 * https://developer.mozilla.org/docs/Web/API/Fetch_API/Using_Fetch
 */
// Getting rid of this any really complicates things in slice.ts, leaving for now
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchWrapper = async (url: string, options: RequestInit = {}): Promise<any> => {
  if (!url) {
    return Promise.reject('Empty Url');
  }
  if (isLoop(url, options)) {
    return Promise.reject('API Loop Detected');
  }

  const headers: Record<string, unknown> = {};

  // Auth cookie
  addCookieHeader(headers, COOKIE_TOKEN);
  addCookieHeader(headers, COOKIE_SIG);
  addCookieHeader(headers, NEW_COOKIE_TOKEN);
  addCookieHeader(headers, NEW_COOKIE_SIG);

  // CSRF Token
  if (['POST', 'DELETE', 'PUT'].includes(options.method as string)) {
    const csrfToken = Cookies.get(CSRF_TOKEN);
    const newCsrfToken = Cookies.get(NEW_CSRF_TOKEN);

    if (csrfToken) {
      headers[CSRF_HEADER] = csrfToken;
    }

    if (newCsrfToken) {
      headers[NEW_CSRF_HEADER] = newCsrfToken;
    }
  }

  // Cache headers
  if (options.method !== 'OPTIONS') {
    headers['Cache-Control'] = 'no-cache, no-store, must-revalidate'; // HTTP 1.1.
    headers.Pragma = 'no-cache'; // HTTP 1.0.
    headers.Expires = '0'; // Proxies.
  }

  // If the initial '/' was omitted from an API url, we need to add it
  // ADMIN-66 If we don't, it will include extra URL content, eg. the /n/ in [ADMIN]/n/
  // ADMIN-62 If we don't, it won't have the correct cookies applied
  if (url.startsWith('api/') || url.startsWith('api-new/') || url.startsWith('api-recent/')) {
    url = '/' + url;
  }

  const isForm = options.body instanceof FormData;
  const isApiCall =
    url.startsWith('/api/') || url.startsWith('/api-new/') || url.startsWith('/api-recent/');
  const fullOptions = defaultsDeep(
    {},
    options,
    isApiCall ? { headers } : {},
    isForm ? {} : defaultConfig
  );
  const response = await fetch(url, fullOptions);

  const getResult = async () => {
    const responseText = await response.text();
    try {
      return JSON.parse(responseText);
    } catch (err) {
      return responseText;
    }
  };

  const result = await getResult();
  if (response.status < 200 || response.status >= 300) {
    throw new Error(result.message || response.statusText);
  }
  return result;
};

function addCookieHeader(headers: Record<string, unknown>, name: string) {
  headers[`x-auth-${name.replace(/\./g, '-')}`] = Cookies.get(name);
}
