import CopyIcon from '@mui/icons-material/ContentCopyOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Tip } from 'components/Tip';
import { asDisplayString, isGuid } from 'utils/convert';

interface Props {
  name: string;
  title?: string;
  value?: unknown;
  keyTooltip?: string;
  keyModel?: string;
  keyProperty?: string;
  showCopy?: boolean;
  valueSx?: SxProps<Theme>;
  onEdit?: (info: UserFieldInfo) => void;
  fieldAction?: JSX.Element;
}

/**
 * A key/value table row
 */
export const MapTableRow = ({
  name,
  value,
  keyTooltip,
  keyModel,
  keyProperty,
  showCopy,
  valueSx,
  onEdit,
  fieldAction,
}: Props): JSX.Element => {
  const displayValue = asDisplayString(value);
  const showTooltip = `${value}` !== displayValue;
  const hasCopy = showCopy || isGuid(displayValue);

  return (
    <TableRow>
      <TableCell>
        <Tip title={keyTooltip ?? ''} model={keyModel} property={keyProperty}>
          <Typography>{name}</Typography>
        </Tip>
      </TableCell>
      <TableCell>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Tip title={showTooltip ? value : ''}>
            <Typography sx={valueSx}>{displayValue}</Typography>
          </Tip>
          {hasCopy && (
            <IconButton
              title="Copy"
              aria-label="Copy"
              size="small"
              sx={{ py: 0 }}
              onClick={() => {
                navigator.clipboard.writeText(displayValue);
              }}
            >
              <CopyIcon />
            </IconButton>
          )}
          {fieldAction ||
            (onEdit && (
              <IconButton
                title="Edit"
                aria-label="Edit"
                size="small"
                sx={{ py: 0 }}
                onClick={() => onEdit({ label: name, keyModel, keyProperty, value })}
              >
                <EditIcon />
              </IconButton>
            ))}
        </Box>
      </TableCell>
    </TableRow>
  );
};
