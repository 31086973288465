import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useState } from 'react';

import { DialogContentText, TextField } from '@mui/material';
import { addUserToOrg, getOrganizationUsers, getUsers } from 'api/apiThunks';
import { createFailedOperation } from 'utils/apiResult';
import { Modal } from 'components/modals/Modal';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ConfirmModal } from 'components/modals/ConfirmModal';
import { selectDerivedAuth } from 'api/authSlice';

const fetchingUsers = 'Fetching User Information';
const addingUsers = 'Adding Users';

interface Props {
  orgId: string;
  orgName?: string;
  currentOperation: string;
  setOperation: (message: string, ops: Operation[]) => void;
}

/**
 * A header component for adding users to an organization
 */
export const AddUsersToOrgHeader = ({
  orgId,
  orgName,
  currentOperation,
  setOperation,
}: Props): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  const [emails, setEmails] = useState('');
  const [matchingUsers, setMatchingUsers] = useState<IUser[]>([]);
  const [addMeConfirmModalOpen, setAddMeConfirmModalOpen] = useState(false);

  const { apiUser } = useAppSelector((state) => selectDerivedAuth(state, true));

  const dispatch = useAppDispatch();

  const handleModalClose = (updateOp = false) => {
    setModalOpen(false);
    if (updateOp) {
      setOperation('', [{ status: 'succeeded' }]);
    }
  };

  const handleOnAdd = async () => {
    if (emails.length === 0) return;

    setOperation(fetchingUsers, [{ status: 'loading' }]);

    try {
      const users = await getUsers(emails.split(','));
      setOperation(fetchingUsers, [{ status: 'succeeded' }]);
      setMatchingUsers(users);
      setModalOpen(true);
    } catch (error) {
      const message = error instanceof Error ? error.message : `${error}`;
      setOperation(fetchingUsers, [createFailedOperation(message)]);
    }
  };

  const handleAddUsers = async () => {
    handleModalClose();
    setOperation(addingUsers, [{ status: 'loading' }]);

    try {
      for (const user of matchingUsers) {
        try {
          const result = await addUserToOrg(orgId, user.userId);
          if (!result) {
            throw new Error();
          }
        } catch (error) {
          const message = error instanceof Error ? ` - ${error.message}` : '';
          throw new Error(`Failed to add the user ${user.email}${message}`);
        }
      }
      setEmails('');
      setOperation('', [{ status: 'succeeded' }]);
      dispatch(getOrganizationUsers(orgId));
    } catch (error) {
      const message = error instanceof Error ? error.message : `${error}`;
      setOperation(addingUsers, [createFailedOperation(message)]);
    }
  };

  const handleAddMe = async () => {
    setAddMeConfirmModalOpen(false);
    setOperation(addingUsers, [{ status: 'loading' }]);

    try {
      const result = await addUserToOrg(orgId, apiUser?.id || '');
      if (!result) {
        throw new Error(`Failed to add you to the organization ${orgName}`);
      }
      setOperation('', [{ status: 'succeeded' }]);
      dispatch(getOrganizationUsers(orgId));
    } catch (error) {
      const message = error instanceof Error ? error.message : `${error}`;
      setOperation(addingUsers, [createFailedOperation(message)]);
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '31%' }}>
        <TextField
          label="Comma separated Emails"
          placeholder="Comma separated Emails"
          size="medium"
          sx={{ flexGrow: 1 }}
          multiline
          minRows={1}
          value={emails}
          onChange={(e) => setEmails(e.target.value)}
        />
        <Button
          variant="contained"
          disabled={emails.length === 0 || !!currentOperation.length}
          onClick={handleOnAdd}
          sx={{ alignSelf: 'center', width: 100, ml: 1 }}
        >
          Add
        </Button>
        <Button
          variant="contained"
          disabled={!!currentOperation.length}
          onClick={() => setAddMeConfirmModalOpen(true)}
          sx={{ alignSelf: 'center', width: 100, ml: 1 }}
        >
          Add Me
        </Button>
      </Box>
      <Modal
        title="Add Users"
        open={modalOpen}
        onClose={() => handleModalClose(true)}
        additionalProps={{
          fullWidth: true,
          maxWidth: 'sm',
        }}
        actions={
          matchingUsers.length > 0 ? (
            <Box sx={{ display: 'flex' }}>
              <Button onClick={() => handleModalClose(true)} sx={{ mr: 2 }}>
                No
              </Button>
              <Button onClick={handleAddUsers} autoFocus>
                Yes
              </Button>
            </Box>
          ) : undefined
        }
      >
        {matchingUsers.length > 0 ? (
          <DialogContentText>
            Found matching users for: {matchingUsers.map((u) => u.email || '').join(', ')}
            <br />
            Do you want to proceed with these emails?
          </DialogContentText>
        ) : (
          <DialogContentText>Could not find any matching users for: {emails}</DialogContentText>
        )}
      </Modal>
      <ConfirmModal
        open={addMeConfirmModalOpen}
        prompt={`Are you sure you want to add yourself to "${orgName}" organization?`}
        onClose={() => setAddMeConfirmModalOpen(false)}
        onAccept={handleAddMe}
      />
    </>
  );
};
