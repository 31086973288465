import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { FeatureAccess } from 'types/server/common/enums';
import { Tip } from './Tip';

interface Props {
  value?: FeatureAccess;
  onChange: (val: FeatureAccess) => void;
  disabled?: boolean;
}

export const FeatureRadioGroup = ({ value, onChange, disabled }: Props) => {
  return (
    <Tip
      placement="left"
      title={{
        'Inherit Sku': 'Features is null or not defined',
        On: 'Feature enabled for all members',
        Off: 'Feature disabled for all members',
      }}
    >
      <RadioGroup
        value={
          value === undefined || value === FeatureAccess.Partial ? FeatureAccess.InheritSku : value
        }
        onChange={(e) => onChange(parseInt(e.target.value) as FeatureAccess)}
        row
      >
        <FormControlLabel
          value={FeatureAccess.InheritSku}
          control={<Radio size="small" disabled={disabled} />}
          label="Inherit Sku"
        />
        <FormControlLabel
          value={FeatureAccess.Full}
          control={<Radio size="small" disabled={disabled} />}
          label="On"
        />
        <FormControlLabel
          value={FeatureAccess.Off}
          control={<Radio size="small" disabled={disabled} />}
          label="Off"
        />
      </RadioGroup>
    </Tip>
  );
};
