import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  addTag,
  updateSFLicense,
  deleteSSOConfig,
  getSFActiveOrders,
  getAtsCandidateStatus,
  getSFOrderItems,
  getOrganization,
  getOrganizationUsers,
  getOrgSearchHistory,
  getSSOConfig,
  getTags,
  deleteTag,
  updateOrganization,
  updateSSOConfig,
  deleteUser as deleteUserThunk,
  getOrganizationTeams,
  createNewUser,
  sendActivationEmail,
  getOrgPowerFilters,
  getOrgExportFormats,
  getCandidateStatuses,
  addCandidateStatus,
  deleteCandidateStatus,
  assignTeam,
  removeTeam,
  updateOrgAdmin,
  updateOnlySSO,
  getAtsConnections,
  updateAtsConnection,
  updateSFFeature,
  createOrUpdateExportFormat,
  deleteExportFormat,
  updateExportFormatField,
  deleteExportFormatField,
  updateOrgFeature,
  deleteOrgFeature,
  createOrUpdateOrgPowerFilter,
  deleteOrgPowerFilter,
  updateDiversitySettings,
  getOrgROIReports,
  getWorkflowStatus,
  terminateWorkflow,
  createOrgROIReport,
  getAtsRediscoveryLogs,
  updateSearchFiltersOrders,
  addTeam,
  deleteTeam,
  getOrgATSExportHistory,
  getOrgExportFormatFields,
  updateInternalSavedField,
  getAllFieldData,
  saveNewInternalFields,
  copyCreditInfo,
  deleteInternalField,
  deleteOrganization,
  getAdditionalOrganizations,
} from 'api/apiThunks';
import { addExtraReducers } from 'api/slice';
import { RootState } from 'app/store';
import { asFullName } from 'utils/convert';
import { lookupFriendlyError } from 'utils/lookup';
import { findOrgUser, removeOrgUser } from 'utils/organization';
import { createOperable, setKeyedOperation, setOperation } from 'utils/operable';
import { selectCurrentLookupTab } from 'features/frame/appViewSlice';
import { NoLicenseSku } from 'constant';

/* Selectors */

/**
 * Select data, operation state, and precalculated state for the current lookup organization
 * @param state The root redux state
 * @returns The derived organization data and operation state
 */
export function selectDerivedOrganization(state: RootState): DerivedOrganization {
  const lookup = selectCurrentLookupTab(state);
  const org = getOrg(state.organizations, lookup.id) || getOrg(state.organizations, lookup.domain);
  const licenses = deriveLicenses(org);

  return {
    ...org,
    operationMap: org?.operationMap ?? {},
    keyedOperationMap: org?.keyedOperationMap ?? {},
    orgId: org?.org?.id,
    licenses,
    licenseMap: Object.fromEntries(
      licenses.map((license) => license.userIds.map((userId) => [userId, license])).flat()
    ),
  };
}

function deriveLicenses(org?: Organization): License[] {
  if (!org?.sfOrders || !org.sfOrderItems) return [];

  return (
    org.sfOrders
      .map((order) => {
        const base = {
          orderId: order.Id,
          orderNumber: order.OrderNumber,
          endDate: order.Contract.EndDate,
        };

        const orderItems = org.sfOrderItems?.[order.Id];

        if (!orderItems) return [];

        return orderItems.map((orderItem) => {
          const assets = orderItem.Assets ?? [];

          const license: License = {
            orderItemId: orderItem.Id,
            skuName: orderItem.Product2.Name,
            quantity: orderItem.Quantity,
            quantityLeft: orderItem.QuantityLeft,
            userIds: assets.map((asset) => asset.SeekOutUserId__c),
            ...base,
          };

          return license;
        });
      })
      .flat() ?? []
  );
}

/* Reducers */

type StateType = Record<string, Organization>;

export const organizationsSlice = createSlice({
  name: 'organizations',
  initialState: {} as StateType,
  reducers: {
    clearOperations: (state, action: PayloadAction<{ orgId: string; opNames: OrgOpName[] }>) => {
      const { orgId, opNames } = action.payload;
      const org = state[orgId] as Organization;

      opNames.forEach((opName) => {
        org.operationMap[opName] = undefined;
        org.keyedOperationMap[opName] = {};
      });
    },
    clearUserOperations: (
      state,
      action: PayloadAction<{ orgId: string; userId: string; opNames: OrgOpName[] }>
    ) => {
      const { orgId, userId, opNames } = action.payload;
      const org = state[orgId] as Organization;

      opNames.forEach((opName) => {
        const opMap = org.keyedOperationMap[opName];
        if (opMap) {
          opMap[userId] = undefined;
        }
      });
    },
    clearOrganizationCache: (state, action: PayloadAction<Lookup>) => {
      clearOrg(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    addExtraReducers(getOrganization, setOrg, builder);
    addExtraReducers(updateOrganization, setUpdateOrg, builder);
    addExtraReducers(deleteOrganization, setDeleteOrganization, builder);
    addExtraReducers(getOrganizationUsers, setOrgUsers, builder);
    addExtraReducers(getOrganizationTeams, setOrgTeams, builder);
    addExtraReducers(getSFActiveOrders, setActiveOrders, builder);
    addExtraReducers(getSFOrderItems, setOrderItems, builder);
    addExtraReducers(getSSOConfig, setSSOConfig, builder);
    addExtraReducers(updateSSOConfig, setUpdateSSOConfig, builder);
    addExtraReducers(deleteSSOConfig, setDeleteSSOConfig, builder);
    addExtraReducers(getOrgSearchHistory, setSearchHistory, builder);
    addExtraReducers(updateSFLicense, setUpdateSFLicense, builder);
    addExtraReducers(updateSFFeature, setUpdateSFFeature, builder);
    addExtraReducers(getTags, setTags, builder);
    addExtraReducers(addTag, setAddTag, builder);
    addExtraReducers(deleteTag, setDeleteTag, builder);
    addExtraReducers(getCandidateStatuses, setCandidateStatuses, builder);
    addExtraReducers(addCandidateStatus, setAddCandidateStatus, builder);
    addExtraReducers(deleteCandidateStatus, setDeleteCandidateStatus, builder);
    addExtraReducers(getAtsCandidateStatus, setAtsCandidateStatus, builder);
    addExtraReducers(deleteUserThunk, setDeleteUser, builder);
    addExtraReducers(createNewUser, setCreateUser, builder);
    addExtraReducers(sendActivationEmail, setSendActivationEmail, builder);
    addExtraReducers(getOrgPowerFilters, setOrgPowerFilters, builder);
    addExtraReducers(createOrUpdateOrgPowerFilter, setUpdateOrgPowerFilter, builder);
    addExtraReducers(deleteOrgPowerFilter, setDeleteOrgPowerFilter, builder);
    addExtraReducers(getOrgExportFormatFields, setOrgExpotFormatFields, builder);
    addExtraReducers(getOrgExportFormats, setOrgExportFormats, builder);
    addExtraReducers(createOrUpdateExportFormat, setUpdateOrgExportFormat, builder);
    addExtraReducers(deleteExportFormat, setDeleteOrgExportFormat, builder);
    addExtraReducers(updateExportFormatField, setUpdateExportFormatField, builder);
    addExtraReducers(deleteExportFormatField, setDeleteExportFormatField, builder);
    addExtraReducers(addTeam, setAddTeam, builder);
    addExtraReducers(deleteTeam, setDeleteTeam, builder);
    addExtraReducers(assignTeam, setAssignTeam, builder);
    addExtraReducers(removeTeam, setRemoveTeam, builder);
    addExtraReducers(updateOrgAdmin, setOrgAdmin, builder);
    addExtraReducers(updateOnlySSO, setOnlySSO, builder);
    addExtraReducers(getAtsConnections, setAtsConnections, builder);
    addExtraReducers(updateAtsConnection, setUpdateAtsConnection, builder);
    addExtraReducers(updateOrgFeature, setUpdateOrgFeature, builder);
    addExtraReducers(deleteOrgFeature, setUpdateOrgFeature, builder);
    addExtraReducers(updateDiversitySettings, setUpdateDiversitySettings, builder);
    addExtraReducers(updateSearchFiltersOrders, setUpdateDiversitySettings, builder);
    addExtraReducers(copyCreditInfo, setCopyCreditInfo, builder);
    addExtraReducers(getOrgROIReports, setOrgROIReports, builder);
    addExtraReducers(createOrgROIReport, setCreateROIReport, builder);
    addExtraReducers(getWorkflowStatus, setWorkflowStatus, builder);
    addExtraReducers(terminateWorkflow, setUpdateWorkflowStatus, builder);
    addExtraReducers(getAtsRediscoveryLogs, setAtsRedisvoceryLogs, builder);
    addExtraReducers(getOrgATSExportHistory, setATSExportHistory, builder);
    addExtraReducers(getAdditionalOrganizations, setAdditionalOrganizations, builder);
    addExtraReducers(updateInternalSavedField, setInternalSavedField, builder);
    addExtraReducers(getAllFieldData, setConfigurableFields, builder);
    addExtraReducers(saveNewInternalFields, setNewInternalSavedFields, builder);
    addExtraReducers(deleteInternalField, setDeleteInternalField, builder);
  },
});

export const { clearOperations, clearUserOperations, clearOrganizationCache } =
  organizationsSlice.actions;

export const organizationsReducer = organizationsSlice.reducer;

/* Helpers */

function getOrg(orgs: StateType, orgId?: string) {
  return orgId ? orgs[orgId] : undefined;
}

function clearOrg(orgs: StateType, lookup: Lookup) {
  const { id, domain } = lookup;

  if (id) {
    delete orgs[id];
  }

  if (domain) {
    delete orgs[domain];
  }
}

function setOrg(orgs: StateType, lookup: Lookup, result: ApiResult<IOrganization>) {
  const org: Organization =
    getOrg(orgs, lookup.id) ?? getOrg(orgs, lookup.domain) ?? createOperable();

  if (result.status === 'succeeded' && !result.data) {
    // This endpoint returns success when it doesn't find an org
    result.status = 'failed';
  }

  const description = `Get Organization with ${lookupFriendlyError(lookup)}`;
  org.org = setOperation(org, 'org', result, description);

  const orgId = org.org?.id || lookup.id;
  const domain = org.org?.domain || lookup.domain;

  if (orgId) orgs[orgId] = org;
  if (domain) orgs[domain] = org;
}

function setUpdateOrg(orgs: StateType, { orgId }: OrgParams, result: ApiResult<IOrganization>) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  const settings = setOperation(org, 'updateSettings', result, 'Update Organization');

  // Only overwrite on success
  if (settings) {
    org.org = settings;
  }
}

function setOrgUsers(orgs: StateType, orgId: string, result: ApiResult<IOrgUser[]>) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  org.users = setOperation(org, 'users', result, 'Get Users');
}

function setOrgTeams(orgs: StateType, orgId: string, result: ApiResult<IOrgTeam[]>) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  org.teams = setOperation(org, 'teams', result, 'Get Teams');
}

function setActiveOrders(
  orgs: StateType,
  { orgId }: SFOrderParams,
  result: ApiResult<IActiveOrder[]>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  org.sfOrders = setOperation(org, 'sfOrders', result, 'Get Salesforce Active Orders');
}

function setOrderItems(
  orgs: StateType,
  { orgId, orderId }: SFOrderItemParams,
  result: ApiResult<IOrderItem[]>
) {
  const org = getOrg(orgs, orgId);
  if (!org || !orderId) return;

  const orderItems = setKeyedOperation(
    org,
    'sfOrderItems',
    orderId,
    result,
    'Get Salesforce Order Items'
  );

  if (orderItems) {
    if (!org.sfOrderItems) {
      org.sfOrderItems = {};
    }

    org.sfOrderItems[orderId] = orderItems;
  }
}

function setSSOConfig(orgs: StateType, orgId: string, result: ApiResult<IssoConfig>) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  org.ssoConfig = setOperation(org, 'ssoConfig', result, 'Get SSO Config');
}

function setUpdateSSOConfig(
  orgs: StateType,
  { orgId }: ResourceParams<IssoConfig>,
  result: ApiResult<IssoConfig>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  const ssoConfig = setOperation(org, 'ssoConfigAction', result, 'Update SSO Config');

  // Only overwrite on success
  if (ssoConfig) {
    org.ssoConfig = ssoConfig;
  }
}

function setDeleteSSOConfig(orgs: StateType, orgId: string, result: ApiResult<boolean>) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  const success = setOperation(org, 'ssoConfigAction', result, 'Delete SSO Config');

  // Only overwrite on success
  if (success) {
    org.ssoConfig = undefined;
  }
}

function setSearchHistory(
  orgs: StateType,
  { orgId }: DateRangeParams,
  result: ApiResult<ISearchHistory[]>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  org.searchHistory = setOperation(org, 'searchHistory', result, 'Get Search History');
}

function setUpdateSFLicense(
  orgs: StateType,
  { orgId, userId, isRemove, orderItemId }: SFOrderItemAssignParams,
  result: ApiResult<boolean>
) {
  const org = getOrg(orgs, orgId);
  if (!org || !userId) return;

  const opType = isRemove ? 'removeLicense' : 'assignLicense';

  const success = setKeyedOperation(org, opType, userId, result, 'Update Salesforce License');

  if (success) {
    if (orderItemId === NoLicenseSku.id && isRemove) {
      // This is an edge case where user has no salesforce license
      // but has active sku and we want to assign them no-license sku
      const user = findOrgUser(org, userId);
      if (user) {
        user.currentSkuId = NoLicenseSku.id;
        user.currentSkuName = NoLicenseSku.name;
        user.currentSkuExpirationTS = '';
      }
    } else if (org.sfOrderItems) {
      const orderItem = Object.values(org.sfOrderItems)
        .flat()
        .find((x) => x.Id === orderItemId);

      if (orderItem) {
        if (orderItem.QuantityLeft) {
          orderItem.QuantityLeft += isRemove ? 1 : -1;
        }

        if (isRemove) {
          const assetIndex =
            orderItem.Assets?.findIndex((x) => x.SeekOutUserId__c === userId) ?? -1;
          if (assetIndex >= 0) {
            orderItem.Assets?.splice(assetIndex, 1);
          }
        } else if (orderItem.Assets) {
          orderItem.Assets.push({ SeekOutUserId__c: userId });
        }

        const order = org.sfOrders?.find((x) => x.Id === orderItem.OrderId);
        const user = findOrgUser(org, userId);

        if (user) {
          user.currentSkuId = isRemove ? NoLicenseSku.id : orderItem.Product2.ProductCode;
          user.currentSkuName = isRemove ? '' : orderItem.Product2.Name;
          user.currentSkuExpirationTS = isRemove ? '' : order?.Contract.EndDate ?? '';
        }
      }
    }
  }
}

function setUpdateSFFeature(
  orgs: StateType,
  { orgId, userId, isRemove, featureId }: SFFeatureAssignParams,
  result: ApiResult<void>
) {
  const org = getOrg(orgs, orgId);
  if (!org || !userId) return;

  const opType = isRemove ? 'removeFeature' : 'assignFeature';

  const success = setKeyedOperation(
    org,
    opType,
    `${userId}-${featureId}`,
    result,
    `Update Salesforce Feature: ${featureId}`
  );

  if (success) {
    const user = findOrgUser(org, userId);
    const features = user?.features;

    if (features && isRemove) {
      const index = features.indexOf(featureId);

      if (index >= 0) {
        features.splice(index, 1);
      }
    } else if (features && !isRemove) {
      features.push(featureId);
    } else if (user && !isRemove) {
      user.features = [featureId];
    }
  }
}

function setTags(orgs: StateType, orgId: string, result: ApiResult<IRecruiterTag[]>) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  org.tags = setOperation(org, 'tags', result, 'Get Tags');
}

function setAddTag(
  orgs: StateType,
  { orgId, value: tagName }: ValueParams,
  result: ApiResult<IRecruiterTag>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  const tag = setKeyedOperation(org, 'addTag', tagName, result, `Add Tag "${tagName}"`);

  if (org.tags && tag) {
    org.tags.push(tag);
  }
}

function setDeleteTag(
  orgs: StateType,
  { orgId, value: tagId }: ValueParams,
  result: ApiResult<boolean>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  const success = setKeyedOperation(org, 'removeTag', tagId, result, 'Delete Tag');

  if (org.tags && success) {
    const index = org.tags.findIndex((x) => x.id === tagId);

    if (index >= 0) {
      org.tags.splice(index, 1);
    }
  }
}

function setCandidateStatuses(
  orgs: StateType,
  orgId: string,
  result: ApiResult<IRecruiterStatus[]>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  org.candidateStatuses = setOperation(org, 'candidateStatuses', result, 'Get Candidate Statuses');
}

function setAddCandidateStatus(
  orgs: StateType,
  { orgId, value: status }: ValueParams,
  result: ApiResult<IRecruiterStatus>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  const candidateStatus = setKeyedOperation(
    org,
    'addCandidateStatus',
    status,
    result,
    `Add Candidate Status "${status}"`
  );

  if (org.candidateStatuses && candidateStatus) {
    org.candidateStatuses.push(candidateStatus);
  }
}

function setDeleteCandidateStatus(
  orgs: StateType,
  { orgId, value: statusId }: ValueParams,
  result: ApiResult<boolean>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  const success = setKeyedOperation(
    org,
    'deleteCandidateStatus',
    statusId,
    result,
    'Delete Candidate Status'
  );

  if (org.candidateStatuses && success) {
    const index = org.candidateStatuses.findIndex((x) => x.id === statusId);

    if (index >= 0) {
      org.candidateStatuses.splice(index, 1);
    }
  }
}

function setAtsCandidateStatus(
  orgs: StateType,
  { orgId, candidateId }: AtsParams,
  result: ApiResult<IATSCandidateStatus>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  if (result.status === 'succeeded' && !result.data) {
    // This endpoint returns success when it doesn't find an candidate
    result.status = 'failed';
    result.error = { message: 'Not Found' };
  }

  const description = `Get Candidate Status for ${candidateId}`;
  org.atsCandidateStatus = setOperation(org, 'atsCandidateStatus', result, description);
}

function setDeleteUser(
  orgs: StateType,
  { orgId, userId, newUserId }: ParamsBase,
  result: ApiResult<boolean>
) {
  const org = getOrg(orgs, orgId);
  const id = userId || newUserId;
  if (!org || !id) return;

  const success = setKeyedOperation(org, 'deleteUser', id, result, `Delete User (${id})`);

  if (success) {
    removeOrgUser(org, userId, newUserId);
  }
}

function setCreateUser(
  orgs: StateType,
  { orgId, newUserId, resource }: ResourceParams<CreateUserDef>,
  result: ApiResult<ICreateUserResult>
) {
  const org = getOrg(orgs, orgId);
  if (!org || !newUserId) return;

  const description = `Create User (${asFullName(resource.firstName, resource.lastName)})`;
  const user = setKeyedOperation(org, 'createUser', newUserId, result, description);

  if (!org.createdUsers) {
    org.createdUsers = {};
  }

  org.createdUsers[newUserId] = {
    orderItemId: resource.orderItemId,
    sendActivationEmail: resource.sendActivationEmail,
    ...user,
  };

  if (user) {
    // Set with the real server-generated newUserId
    setKeyedOperation(org, 'createUser', user.id, result, description);

    if (org.users) {
      // Do a really rough conversion to IOrgUser
      org.users.push({
        userId: user.id,
        dateCreated: user.dateCreated,
        isOrgAdmin: user.userType === 'admin',

        emailAddress: user.email,
        fullName: asFullName(user.firstName, user.lastName) ?? '',
        // INewUser.userType comes from frontend models.
        // We shouldn't update it, so it stays 'string' and is cast here
        userType: user.userType as IOrgUser['userType'],

        teamId: user.assignedTeam?.id,
        assignedTeam: user.assignedTeam,

        newUserId: user.id,
        fullActivationLink: user.fullActivationLink,
        shortId: user.shortId,

        // These will be filled when the license is assigned
        currentSkuExpirationTS: '',
        currentSkuName: user.skuId ?? '',
      });
    }
  }
}

function setSendActivationEmail(
  orgs: StateType,
  { orgId, newUserId }: ParamsBase,
  result: ApiResult<void>
) {
  const org = getOrg(orgs, orgId);
  if (!org || !newUserId) return;

  const description = `Send Activation Email for ${newUserId}`;
  setKeyedOperation(org, 'sendActivationEmail', newUserId, result, description);
}

function setOrgPowerFilters(orgs: StateType, orgId: string, result: ApiResult<IRecipe[]>) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  org.powerFilters = setOperation(org, 'powerFilters', result, 'Get Power Filters');
}

function setUpdateOrgPowerFilter(
  orgs: StateType,
  { orgId }: ResourceParams<IRecipe>,
  result: ApiResult<IRecipe>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  const powerFilter = setOperation(org, 'updatePowerFilter', result, 'Update Power Filter');

  if (org.powerFilters && powerFilter) {
    const index = org.powerFilters.findIndex((x) => x.id === powerFilter.id);
    if (index > -1) {
      org.powerFilters[index] = powerFilter;
    } else {
      org.powerFilters.push(powerFilter);
    }
  }
}

function setDeleteOrgPowerFilter(
  orgs: StateType,
  { orgId, value: recipeId }: ValueParams,
  result: ApiResult<boolean>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  const success = setOperation(org, 'deletePowerFilter', result, 'Delete Power Filter');

  if (org.powerFilters && success) {
    const index = org.powerFilters.findIndex((x) => x.id === recipeId);
    if (index > -1) {
      org.powerFilters.splice(index, 1);
    }
  }
}

function setInternalSavedField(
  orgs: StateType,
  { orgId }: SavedFieldParams,
  result: ApiResult<InternalSavedField>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  const internalSavedField = setOperation(
    org,
    'internalSavedFields',
    result,
    'Update Internal Saved Field'
  );

  if (org.internalSavedFields && internalSavedField) {
    const index = org.internalSavedFields.findIndex((x) => x.id === internalSavedField.id);
    if (index > -1) {
      org.internalSavedFields[index] = internalSavedField;
    } else {
      org.internalSavedFields.push(internalSavedField);
    }
  }
}

function setNewInternalSavedFields(
  orgs: StateType,
  { orgId }: SavedFieldParams,
  result: ApiResult<InternalSavedField[]>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  const internalSavedFields = setOperation(
    org,
    'internalSavedFields',
    result,
    'Update Internal Saved Field'
  );
  if (org.internalSavedFields && internalSavedFields) {
    org.internalSavedFields = [...org.internalSavedFields, ...internalSavedFields];
  }
}

function setDeleteInternalField(
  orgs: StateType,
  { orgId, value: fieldId }: ValueParams,
  result: ApiResult<boolean>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  const internalSavedFields = setOperation(
    org,
    'deleteInternalField',
    result,
    'Delete Internal Field'
  );

  if (org.internalSavedFields && internalSavedFields) {
    const index = org.internalSavedFields.findIndex((x) => x.id === fieldId);
    if (index > -1) {
      org.internalSavedFields.splice(index, 1);
    }
  }
}

function setConfigurableFields(orgs: StateType, orgId: string, result: ApiResult<AllFieldData>) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  if (result.status === 'failed') {
    org.internalSavedFields = setOperation(
      org,
      'internalSavedFields',
      { status: result.status, error: result.error } as ApiResult<InternalSavedField[]>,
      'Get Internal Saved Fields'
    );
    org.configurableFields = setOperation(
      org,
      'configurableFields',
      { status: result.status, error: result.error } as ApiResult<CustomSimpleField[]>,
      'Get Configurable Fields'
    );
    return;
  }

  if (!result.data) return;

  const saveFields = {
    data: result.data.savedFields,
    status: result.status,
    error: result.error,
  };

  const configurableFields = {
    data: result.data.configurableFields,
    status: result.status,
    error: result.error,
  };

  org.internalSavedFields = setOperation(
    org,
    'internalSavedFields',
    saveFields,
    'Get Internal Saved Fields'
  );

  org.configurableFields = setOperation(
    org,
    'configurableFields',
    configurableFields,
    'Get Configurable Fields'
  );
}

function setOrgExpotFormatFields(
  orgs: StateType,
  { orgId }: ValueParams,
  result: ApiResult<string[]>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  org.exportFormatFields = setOperation(
    org,
    'exportFormatFields',
    result,
    'Get export format fields'
  );
}

function setOrgExportFormats(orgs: StateType, orgId: string, result: ApiResult<IExportFormat[]>) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  org.exportFormats = setOperation(org, 'exportFormats', result, 'Get Export Formats');
}

function setUpdateOrgExportFormat(
  orgs: StateType,
  { orgId }: ResourceParams<IExportFormat>,
  result: ApiResult<IExportFormat>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  const exportFormat = setOperation(org, 'addExportFormat', result, 'Add Export Format');

  if (org.exportFormats && exportFormat) {
    const index = org.exportFormats.findIndex((x) => x.id === exportFormat.id);
    if (index > -1) {
      org.exportFormats[index] = exportFormat;
    } else {
      org.exportFormats.push(exportFormat);
    }
  }
}

function setDeleteOrgExportFormat(
  orgs: StateType,
  { orgId, value: exportFormatId }: ValueParams,
  result: ApiResult<boolean>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  const success = setOperation(org, 'deleteExportFormat', result, 'Delete Export Format');

  if (org.exportFormats && success) {
    const index = org.exportFormats.findIndex((x) => x.id === exportFormatId);
    if (index > -1) {
      org.exportFormats.splice(index, 1);
    }
  }
}

function setUpdateExportFormatField(
  orgs: StateType,
  { orgId }: ExportFormatFieldParams,
  result: ApiResult<IExportFormat>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  const exportFormat = setOperation(
    org,
    'updateExportFormatField',
    result,
    'Update Export Format Field'
  );

  if (org.exportFormats && exportFormat) {
    const index = org.exportFormats.findIndex((x) => x.id === exportFormat.id);
    if (index > -1) {
      org.exportFormats[index] = exportFormat;
    }
  }
}

function setDeleteExportFormatField(
  orgs: StateType,
  { orgId, exportFormatId }: Partial<ExportFormatFieldParams>,
  result: ApiResult<IExportFormat>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  const exportFormat = setOperation(
    org,
    'deleteExportFormatField',
    result,
    'Delete Export Format Field'
  );

  if (org.exportFormats && exportFormat) {
    const index = org.exportFormats.findIndex((x) => x.id === exportFormatId);
    if (index > -1) {
      org.exportFormats[index] = exportFormat;
    }
  }
}

function setAddTeam(orgs: StateType, { orgId }: KeyValueParams, result: ApiResult<IOrgTeam>) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  const orgTeam = setOperation(org, 'addTeam', result, 'Add team');

  if (orgTeam && org.teams) {
    const index = org.teams.findIndex((x) => x.id === orgTeam.id);
    if (index === -1) {
      org.teams.push(orgTeam);
    }
  }
}

function setDeleteTeam(
  orgs: StateType,
  { orgId, value: teamId }: ValueParams,
  result: ApiResult<boolean>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  const success = setOperation(org, 'deleteTeam', result, 'Delete team');

  if (success && org.teams) {
    const index = org.teams.findIndex((x) => x.id === teamId);
    if (index > -1) {
      org.teams.splice(index, 1);

      // remove assigned team
      const assignedTeamUsers = org.users?.filter((user) => user.assignedTeam?.id === teamId);
      assignedTeamUsers?.forEach((user) => {
        user.assignedTeam = undefined;
      });
    }
  }
}

function setAssignTeam(
  orgs: StateType,
  { orgId, userId, value: teamId }: ValueParams,
  result: ApiResult<ITeamRecruiter>
) {
  const org = getOrg(orgs, orgId);
  if (!org || !userId) return;

  // The choice of return object is useless here, we'll have to use the data in the params
  const success = setKeyedOperation(org, 'assignTeam', userId, result, 'Assign team');

  if (success) {
    const user = findOrgUser(org, userId);

    if (user && org.teams) {
      const team = org.teams.find((x) => x.id === teamId);
      user.assignedTeam = team;
    }
  }
}

function setRemoveTeam(
  orgs: StateType,
  { orgId, userId }: ValueParams,
  result: ApiResult<boolean>
) {
  const org = getOrg(orgs, orgId);
  if (!org || !userId) return;

  const success = setKeyedOperation(org, 'removeTeam', userId, result, 'Remove team');

  if (success) {
    const user = findOrgUser(org, userId);

    if (user) {
      user.assignedTeam = undefined;
    }
  }
}

function setOrgAdmin(
  orgs: StateType,
  { orgId, userId, flag: isOrgAdmin }: FlagParams,
  result: ApiResult<INewUser | IRecruiter>
) {
  const org = getOrg(orgs, orgId);
  if (!org || !userId) return;

  const success = setKeyedOperation(org, 'updateOrgAdmin', userId, result, 'Update Org Admin');

  if (success) {
    const user = findOrgUser(org, userId);

    if (user) {
      user.isOrgAdmin = !!isOrgAdmin;
    }
  }
}

function setOnlySSO(
  orgs: StateType,
  { orgId, userId, flag: isOnlySSO }: FlagParams,
  result: ApiResult<IUser>
) {
  const org = getOrg(orgs, orgId);
  if (!org || !userId) return;

  const success = setKeyedOperation(
    org,
    'updateOnlySSO',
    userId,
    result,
    'Update Require SSO Logins'
  );

  if (success) {
    const user = findOrgUser(org, userId);

    if (user) {
      user.onlyAllowSSOLogins = !!isOnlySSO;
    }
  }
}

function setAtsConnections(
  orgs: StateType,
  orgId: string | undefined,
  result: ApiResult<IATSConnection[]>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  org.atsConnections = setOperation(org, 'atsConnections', result, 'Get ATS Connection configs');
}

function setUpdateAtsConnection(
  orgs: StateType,
  { orgId, resource: { id } }: ResourceParams<AtsConnectionInput>,
  result: ApiResult<IATSConnection>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  const atsConnection = setKeyedOperation(
    org,
    'updateAtsConnection',
    id ?? '',
    result,
    'Update ATS Connection config'
  );

  // Only overwrite on success
  if (atsConnection && org.atsConnections) {
    const index = org.atsConnections.findIndex((x) => x.id === atsConnection.id);
    if (index >= 0) {
      org.atsConnections[index] = {
        ...atsConnection,
        // Entity is an extra thing not recalculated during update
        rediscoveryEntity: org.atsConnections[index].rediscoveryEntity,
      };
    }
  }
}

function setUpdateOrgFeature(
  orgs: StateType,
  { orgId }: ResourceParams<IOrgFeature>,
  result: ApiResult<IOrganization>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  const settings = setOperation(org, 'updateSettings', result, 'Update Organization');

  // Only overwrite on success
  if (settings) {
    org.org = settings;
  }
}

function setUpdateDiversitySettings(
  orgs: StateType,
  { orgId }: KeyValueParams,
  result: ApiResult<IOrganization>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  const settings = setOperation(org, 'updateSettings', result, 'Update Organization');

  // Only overwrite on success
  if (settings) {
    org.org = settings;
  }
}

function setCopyCreditInfo(orgs: StateType, orgId: string, result: ApiResult<IOrganization>) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  const settings = setOperation(org, 'copyCreditInfo', result, 'Copy credit info');

  // Only overwrite on success
  if (settings) {
    org.org = settings;
  }
}

function setDeleteOrganization(
  orgs: StateType,
  { orgId }: ValueParams,
  result: ApiResult<unknown>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  setOperation(org, 'deleteOrganization', result, 'Delete Organization');
}

function setOrgROIReports(orgs: StateType, orgId: string, result: ApiResult<IROIReport[]>) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  org.roiReports = setOperation(org, 'roiReports', result, 'Get ROI Reports');
}

function setCreateROIReport(
  orgs: StateType,
  { orgId }: ResourceParams<IROIReportInput>,
  result: ApiResult<Record<string, string>>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  setOperation(org, 'createROIReport', result, 'Create ROI Report');
}

function setWorkflowStatus(
  orgs: StateType,
  { orgId, value: workflowId }: ValueParams,
  result: ApiResult<IROIReport>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  const report = setOperation(org, 'getWorkflowStatus', result, 'Get Workflow Status');

  if (org.roiReports && report) {
    const index = org.roiReports.findIndex((x) => x.workflowid === workflowId);
    if (index > -1) {
      org.roiReports[index].status = report.status;
    }
  }
}

function setUpdateWorkflowStatus(
  orgs: StateType,
  { orgId, resource }: ResourceParams<Record<string, unknown>>,
  result: ApiResult<Record<string, unknown>>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  const report = setOperation(org, 'terminateWorkflow', result, 'Terminate Workflow');

  if (org.roiReports && (report?.results as Record<string, unknown>)?.status === 200) {
    const index = org.roiReports.findIndex((x) => x.workflowid === resource.workflowid);
    if (index > -1) {
      org.roiReports[index].status = 'terminated';
    }
  }
}

function setAtsRedisvoceryLogs(
  orgs: StateType,
  { orgId }: ResourceParams<Record<string, string>>,
  result: ApiResult<IAtsRediscoveryLog[]>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  org.atsRediscoveryLogs = setOperation(
    org,
    'atsRediscoveryLogs',
    result,
    'Get Ats Rediscovery Logs'
  );
}

function setATSExportHistory(
  orgs: StateType,
  { orgId }: ValueParams,
  result: ApiResult<IATSExportLogFromDB[]>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  org.atsExportHistory = setOperation(org, 'atsExportHistory', result, 'Get Ats Export History');
}

function setAdditionalOrganizations(
  orgs: StateType,
  { orgId }: ValueParams,
  result: ApiResult<IOrganization[]>
) {
  const org = getOrg(orgs, orgId);
  if (!org) return;

  if (result.status === 'succeeded') {
    org.additionalOrganizations = result.data;
  }
}
