import { Box, Button, TextField, Typography } from '@mui/material';
import { useState } from 'react';

interface DeleteOrganizationProps {
  name: string;
  onSubmit: (password: string) => void;
  onCancel: () => void;
}

export const DeleteOrganization = ({ name, onSubmit, onCancel }: DeleteOrganizationProps) => {
  const [password, setPassword] = useState('');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography component="p">
        Delete <b>{name}</b> organization and its associated data? The data will be deleted in
        background and you will be notified by email once it's done.
        <br />
        Enter the password to confirm deletion of this organization.
      </Typography>
      <TextField
        label="Password"
        placeholder="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        sx={{ mt: 2 }}
      />
      <Box sx={{ margin: (theme) => theme.spacing(5, 'auto', 1, 'auto') }}>
        <Button
          variant="contained"
          sx={{ width: 162 }}
          disabled={!password}
          onClick={() => onSubmit(password)}
        >
          Submit
        </Button>
        <Button variant="outlined" sx={{ ml: 2 }} onClick={onCancel}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};
