import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { allErrorMessages, anyFailedResult, getLoadingCount } from 'utils/apiResult';

interface Props {
  message: string | JSX.Element;
  operations?: Operation[];
  forceClose?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
}

/**
 * A standard loader overlay
 */
export const Loader = ({
  message,
  operations,
  forceClose,
  onClose,
  children,
}: Props): JSX.Element => {
  const loadingCount = getLoadingCount(operations);
  const isLoading = loadingCount > 0;
  const isError = anyFailedResult(operations);
  const errors = allErrorMessages(operations);
  const totalCount = operations?.length || 0;
  const progressValue = (100 * (totalCount - loadingCount)) / totalCount;

  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
      <Backdrop
        open={!forceClose && (isLoading || isError)}
        sx={{ position: 'absolute', height: '100%', width: '100%', zIndex: 999 }}
      >
        <Box
          sx={{
            background: (theme) => theme.palette.secondary.main,
            borderRadius: 2,
            padding: 2,
          }}
        >
          {isLoading && (
            <CircularProgress
              variant={totalCount > 5 ? 'determinate' : 'indeterminate'}
              value={progressValue}
              sx={{ padding: 2 }}
            />
          )}

          <Typography>{message}</Typography>

          {isError && (
            <Alert
              severity="warning"
              variant="outlined"
              onClose={onClose}
              sx={{ textAlign: 'left', maxHeight: '450px' }}
            >
              {errors.map((x, i) => (
                <Typography key={`${x}-${i}`} sx={{ whiteSpace: 'pre-line' }}>
                  {x}
                </Typography>
              ))}
            </Alert>
          )}
        </Box>
      </Backdrop>
      {children}
    </Box>
  );
};
