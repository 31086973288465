import { Box, Button, TextField } from '@mui/material';
import { getCartUsers } from 'api/apiThunks';
import { StandardGrid } from 'components/grids/StandardGrid';
import { ViewFrame } from 'features/frame/ViewFrame';
import { useState } from 'react';
import { createFailedOperation } from 'utils/apiResult';
import { isGuid } from 'utils/convert';

/**
 * The System Profile Keys view
 */
export const ProfileKeys = (): JSX.Element => {
  const [action, setAction] = useState<string | undefined>();
  const [operations, setOperations] = useState<Operation[]>([]);
  const [projectId, setProjectId] = useState('');
  const [isValidProjectId, setIsValidProjectId] = useState(true);
  const [cartUsers, setCartUsers] = useState<IUsersCart[]>([]);

  const fetcher = async () => {
    const isProjectIdValid = isGuid(projectId);

    setIsValidProjectId(isProjectIdValid);

    if (isProjectIdValid) {
      setAction('fetch');
      setOperations([{ status: 'loading' }]);

      try {
        const results = await getCartUsers(projectId);
        setAction(undefined);
        setOperations([]);
        setCartUsers(results);
      } catch (error) {
        const message = error instanceof Error ? error.message : `${error}`;
        setOperations([createFailedOperation(`Operation failed: ${message}`)]);
      }
    }
  };

  const isProcessing = !!action || !!operations.length;
  const disableFetch = isProcessing || !projectId.length;

  return (
    <ViewFrame
      header={
        <Box
          sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'center', mt: 2, mb: 2 }}
        >
          <TextField
            label="Project Id"
            placeholder="Project Id"
            size="medium"
            onChange={(e) => setProjectId(e.target.value)}
            value={projectId}
            error={!isValidProjectId}
            helperText={!isValidProjectId ? 'Please enter valid guid' : ''}
            sx={{ ml: 2, width: 340 }}
          />
          <Button variant="contained" disabled={disableFetch} sx={{ ml: 2 }} onClick={fetcher}>
            Fetch
          </Button>
        </Box>
      }
      contentLoader={{
        message: 'Fetching profile keys',
        contentOperations: operations,
        forceClose: !isProcessing,
        onClose: () => {
          setAction('');
          setOperations([]);
        },
      }}
    >
      <StandardGrid
        dataSet={cartUsers}
        getRowId={(x) => x.id}
        filterPlaceholder="Filter the results"
        cols={[
          {
            name: 'Profile Key',
            valueProperty: 'addedUserId',
          },
          {
            name: 'Source',
            valueProperty: 'source',
          },
          {
            name: 'Date Created',
            valueProperty: 'dateCreated',
            type: 'date',
          },
        ]}
      />
    </ViewFrame>
  );
};
