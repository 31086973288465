import { selectDerivedAuth } from 'api/authSlice';
import { selectDerivedSystem } from 'api/systemSlice';
import { useAppSelector } from 'app/hooks';
import { useMemo } from 'react';
import { Privilege } from 'types/enums';

export function usePrivilegeSelector(): Privilege {
  const { apiUser } = useAppSelector((state) => selectDerivedAuth(state, true));
  const { admins = [] } = useAppSelector((state) => selectDerivedSystem(state));

  const privilege = useMemo(() => {
    return admins.find((admin) => admin.actualUserId === apiUser?.id)?.privilege;
  }, [apiUser, admins]);

  return privilege || Privilege.All;
}
