import { AsyncThunk } from '@reduxjs/toolkit';
import { useEffect, useMemo } from 'react';

import {
  deleteUser as deleteUserThunk,
  getAdminUsers,
  getNewUser,
  getOrganization,
  getOrganizationUsers,
  getRecruiter,
  getSFActiveOrders,
  getSFOrderItems,
  getUser,
  updateSFLicense,
  getOrganizationTeams,
  getSkuV1,
  getSkuV2,
  removeTeam,
  assignTeam,
  getAuthStatus,
  updateOrgAdmin,
  updateOnlySSO,
  getTooltips,
  updateSFFeature,
  addSmbDomain,
  deleteSmbDomain,
} from 'api/apiThunks';
import { clearOperations, clearUserOperations } from 'api/organizationsSlice';
import { useAppDispatch } from 'app/hooks';
import { getKeyedOperations, getOperations } from 'utils/operable';
import { findOrgUser, getOrgUserLicenseId, getOrgUserParamsBase } from 'utils/organization';
import { NoLicenseSku, NoTeam } from 'constant';

/*
 * This file contains all the MetaThunks - collections of calls to different thunks
 * This allows us to abstract the API structure further from the view logic
 * Eg: From the user's (and view's) perspective, deleting a user is a single operation
 *     But multiple API calls may need to be made to complete it
 */

type Dispatch = ReturnType<typeof useAppDispatch>;
type Thunk<In, Out> = AsyncThunk<Out, In, Record<string, unknown>>;

/**
 * Wrapper around a useEffect to reset component action state on a trigger
 * @param setAction The component state setter (from React.useState)
 * @param trigger When true, triggers the reset action
 */
export function useClearAction(
  setAction: (value: React.SetStateAction<Record<string, unknown>>) => void,
  trigger?: boolean
): void {
  useEffect(() => {
    if (trigger) {
      setAction({});
    }
  }, [setAction, trigger]);
}

/**
 * Run the necessary fetches to load the logged-in user data for both APIs
 * @param auth The current old API login data, if any
 * @param authNew The current new API login data, if any
 */
export function useAuthFetch(
  { operationMap: { apiUser: apiUserOp } }: DerivedAuth,
  { operationMap: { apiUser: apiUserNewOp } }: DerivedAuth
): void {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatchHelper(dispatch, getAuthStatus, false, apiUserOp);

    if (apiUserOp?.status === 'succeeded') {
      dispatchHelper(dispatch, getAuthStatus, true, apiUserNewOp);
    }
  }, [dispatch, apiUserOp, apiUserNewOp]);
}

/**
 * Run the necessary fetches to load the full package of system data
 * @param system The current system data, if any
 * @param lookupType The lookup type (to make sure it is system-type)
 */
export function useSystemFetch(system: DerivedSystem, lookupType?: LookupType): void {
  const [adminsOp, tooltipsOp, skuFeaturesOp] = getOperations(system, ['admins', 'tooltips']);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (lookupType === 'SYSTEM') {
      dispatchHelper(dispatch, getAdminUsers, undefined, adminsOp);
      dispatchHelper(dispatch, getTooltips, undefined, tooltipsOp);
    }
  }, [dispatch, lookupType, adminsOp, tooltipsOp, skuFeaturesOp]);
}

/**
 * Run the necessary fetches to load the system Sku data
 * @param system The current system data
 * @returns The operation state
 */
export function useSkuFetch({ operationMap }: DerivedSystem): Operation[] {
  const dispatch = useAppDispatch();

  const skuV1Op = operationMap.skuV1;
  const skuV2Op = operationMap.skuV2;

  useEffect(() => {
    dispatchHelper(dispatch, getSkuV1, undefined, skuV1Op);
    dispatchHelper(dispatch, getSkuV2, undefined, skuV2Op);
  }, [dispatch, skuV1Op, skuV2Op]);

  return [skuV1Op, skuV2Op];
}

/**
 * Run the necessary fetches to load the full package of user data
 * @param user The current user data, if any
 * @param newUser The current NewUser data, if any
 * @param lookup The lookup indicating the user
 */
export function useUserFetch(user: DerivedUser, newUser: DerivedNewUser, lookup: Lookup): void {
  const {
    userId,
    operationMap: { user: userOp, recruiter: recruiterOp },
  } = user;
  const {
    operationMap: { newUser: newUserOp },
  } = newUser;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!lookup.type || lookup.type === 'User') {
      dispatchHelper(dispatch, getUser, lookup, userOp);
    }

    if (lookup.email && (!lookup.type || lookup.type === 'NewUser')) {
      dispatchHelper(dispatch, getNewUser, lookup, newUserOp);
    }

    if (userOp?.status === 'succeeded' && userId && lookup.newUserStatus !== 'loading') {
      dispatchHelper(dispatch, getRecruiter, { ...lookup, id: userId }, recruiterOp);
    }
  }, [dispatch, userId, lookup, userOp, newUserOp, recruiterOp]);
}

/**
 * Run the necessary fetches to load the full package of organization data
 * @param org The current organization data, if any
 * @param lookup The lookup indicating the organization
 */
export function useOrganizationFetch(org: DerivedOrganization, lookup: Lookup): void {
  const {
    orgId,
    org: orgData,
    sfOrders = [],
    operationMap: { org: orgOp, users: usersOp, teams: teamsOp, sfOrders: sfOrdersOp },
  } = org;
  const sfAccountId = orgData?.sfAccountId;
  const orderIds = sfOrders.map((order) => order.Id);
  const sfOrderItemsOps = getKeyedOperations(org, orderIds, 'sfOrderItems');
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!lookup.type || lookup.type === 'Organization') {
      dispatchHelper(dispatch, getOrganization, lookup, orgOp);
    }

    if (orgOp?.status === 'succeeded' && orgId) {
      dispatchHelper(dispatch, getOrganizationUsers, orgId, usersOp);
      dispatchHelper(dispatch, getOrganizationTeams, orgId, teamsOp);

      if (sfAccountId) {
        dispatchHelper(
          dispatch,
          getSFActiveOrders,
          {
            orgId,
            sfAccountId,
          },
          sfOrdersOp
        );

        orderIds.forEach((id, index) => {
          const [sfOrderItemOp] = sfOrderItemsOps[index];
          dispatchHelper(
            dispatch,
            getSFOrderItems,
            {
              orgId,
              orderId: id,
              sfAccountId,
            },
            sfOrderItemOp
          );
        });
      }
    }
  }, [
    dispatch,
    orgId,
    sfAccountId,
    orderIds,
    lookup,
    orgOp,
    usersOp,
    teamsOp,
    sfOrdersOp,
    sfOrderItemsOps,
  ]);
}

/**
 * Run the necessary calls to delete a new user
 * @param dispatch The dispatcher loaded by useAppDispatch() in the react component
 * @param newUser The current newUser data
 */
export function deleteNewUser(dispatch: Dispatch, { newUser, operationMap }: DerivedNewUser): void {
  const deleteOp = operationMap.delete;

  if (newUser) {
    dispatchHelper(
      dispatch,
      deleteUserThunk,
      {
        orgId: newUser.orgId,
        newUserId: newUser.id,
      },
      deleteOp
    );
  }
}

/**
 * Run the necessary calls to delete a user
 * @param dispatch The dispatcher loaded by useAppDispatch() in the react component
 * @param userData The result of a User data selector
 */
export function deleteUser(
  dispatch: Dispatch,
  { userId, recruiter, operationMap }: DerivedUser
): void {
  const licenseOp = operationMap.updateLicense;
  const deleteOp = operationMap.delete;

  if (recruiter?.sfLicense) {
    dispatchHelper(
      dispatch,
      updateSFLicense,
      {
        orgId: recruiter.organizationId,
        userId: userId,
        orderItemId: recruiter.sfLicense.orderItemId,
        isRemove: true,
      },
      licenseOp
    );
  } else if (recruiter && !recruiter.sfLicense) {
    dispatchHelper(
      dispatch,
      deleteUserThunk,
      {
        orgId: recruiter.organizationId,
        userId: userId,
        flag: true,
      },
      deleteOp
    );
  }
}

/**
 * Run the necessary calls to perform an org user edit
 * @param org The current organization data, if any
 * @param userId The user to edit
 * @param editDef The details of what needs to change
 * @param trigger Top-level flag to trigger the behavior in this hook
 * @returns The operation state
 */
export function useEditOrgUser(
  org: DerivedOrganization,
  userId = '',
  {
    orderItemId,
    teamId,
    isOrgAdmin,
    onlyAllowSSOLogins,
    featureAdd = [],
    featureRemove = [],
  }: EditOrgUserDef = {},
  trigger = false
): Operation[] {
  const { orgId, licenseMap } = org;
  const baseParams = getOrgUserParamsBase(org, userId);
  const user = findOrgUser(org, userId);

  const basicOpNames: OrgOpName[] = useMemo(
    () => [
      'removeLicense',
      'assignLicense',
      'removeTeam',
      'assignTeam',
      'updateOrgAdmin',
      'updateOnlySSO',
    ],
    []
  );
  const opNames: OrgOpName[] = useMemo(
    () => basicOpNames.concat(['removeFeature', 'assignFeature']),
    [basicOpNames]
  );

  const [potentialOperations] = getKeyedOperations(org, [userId], basicOpNames);
  const [
    removeLicenseOp,
    assignLicenseOp,
    removeTeamOp,
    assignTeamOp,
    updateOrgAdminOp,
    updateOnlySsoOp,
  ] = potentialOperations;

  const featureOpExists = (ids: string[], opName: OrgOpName) =>
    getKeyedOperations(
      org,
      ids.map((id) => `${userId}-${id}`),
      opName
    )
      .flat()
      .some((x) => x !== undefined);
  const operationExists =
    potentialOperations.some((x) => x !== undefined) ||
    featureOpExists(featureRemove, 'removeFeature') ||
    featureOpExists(featureAdd, 'assignFeature');
  const operations: Operation[] = [];

  // Note: We can't do multiple salesforce operations in parallel
  let sfOperationRequired = false;

  let removeLicenseParams: SFOrderItemAssignParams | undefined;
  let assignLicenseParams: SFOrderItemAssignParams | undefined;

  if (trigger && baseParams && licenseMap && orderItemId) {
    const license = licenseMap[userId];
    if (license && license.orderItemId !== orderItemId) {
      removeLicenseParams = {
        ...baseParams,
        orderItemId: license.orderItemId,
        isRemove: true,
      };
      operations.push(removeLicenseOp);
      sfOperationRequired = true;
    } else if (!license && orderItemId === 'none' && user?.currentSkuId !== NoLicenseSku.id) {
      // This is an edge case where user has no license but has
      // active sku and we want to assign them no-license sku
      removeLicenseParams = {
        ...baseParams,
        orderItemId: NoLicenseSku.id,
        isRemove: true,
      };
      operations.push(removeLicenseOp);
      sfOperationRequired = true;
    } else if (!license && orderItemId !== 'none') {
      assignLicenseParams = {
        ...baseParams,
        orderItemId,
      };
      operations.push(assignLicenseOp);
      sfOperationRequired = true;
    }
  }

  // These are more variable, as there may be multiple per user
  let removeFeatureOp: Operation | undefined;
  let removeFeatureParams: SFFeatureAssignParams | undefined;

  if (trigger && baseParams && user && featureRemove.length && !sfOperationRequired) {
    const currentFeatures = user.features ?? [];
    const toRemove = featureRemove.find((x) => currentFeatures.includes(x));
    if (toRemove) {
      removeFeatureParams = {
        ...baseParams,
        featureId: toRemove,
        isRemove: true,
      };
      removeFeatureOp = getKeyedOperations(org, [`${userId}-${toRemove}`], 'removeFeature')[0][0];
      operations.push(removeFeatureOp);
      sfOperationRequired = true;
    }
  }

  // These are more variable, as there may be multiple per user
  let assignFeatureOp: Operation | undefined;
  let assignFeatureParams: SFFeatureAssignParams | undefined;

  if (trigger && baseParams && user && featureAdd.length && !sfOperationRequired) {
    const currentFeatures = user.features ?? [];
    const toAdd = featureAdd.find((x) => !currentFeatures.includes(x));

    if (toAdd) {
      assignFeatureParams = {
        ...baseParams,
        featureId: toAdd,
      };
      assignFeatureOp = getKeyedOperations(org, [`${userId}-${toAdd}`], 'assignFeature')[0][0];
      operations.push(assignFeatureOp);
      sfOperationRequired = true;
    }
  }

  let removeTeamParams: ValueParams | undefined;
  let assignTeamParams: ValueParams | undefined;

  if (trigger && baseParams && user && teamId) {
    if (user.assignedTeam && user.assignedTeam.id !== teamId) {
      removeTeamParams = {
        ...baseParams,
        value: user.assignedTeam.id,
      };
      operations.push(removeTeamOp);
    } else if (!user.assignedTeam && teamId !== 'not_on_a_team') {
      assignTeamParams = {
        ...baseParams,
        value: teamId,
      };
      operations.push(assignTeamOp);
    }
  }

  let updateOrgAdminParams: FlagParams | undefined;

  if (
    trigger &&
    baseParams &&
    !sfOperationRequired &&
    isOrgAdmin !== undefined &&
    user?.isOrgAdmin !== isOrgAdmin
  ) {
    updateOrgAdminParams = {
      ...baseParams,
      flag: isOrgAdmin,
    };
    operations.push(updateOrgAdminOp);
    sfOperationRequired = true;
  }

  let updateOnlySSOParams: FlagParams | undefined;
  if (
    trigger &&
    baseParams &&
    onlyAllowSSOLogins !== undefined &&
    user?.onlyAllowSSOLogins !== onlyAllowSSOLogins
  ) {
    updateOnlySSOParams = {
      ...baseParams,
      flag: onlyAllowSSOLogins,
    };
    operations.push(updateOnlySsoOp);
  }

  const dispatch = useAppDispatch();
  const shouldClearOperations = trigger && !operations.length && operationExists;

  useDispatchHelper(dispatch, updateSFLicense, removeLicenseParams, removeLicenseOp);
  useDispatchHelper(dispatch, updateSFLicense, assignLicenseParams, assignLicenseOp);
  useDispatchHelper(dispatch, updateSFFeature, removeFeatureParams, removeFeatureOp);
  useDispatchHelper(dispatch, updateSFFeature, assignFeatureParams, assignFeatureOp);
  useDispatchHelper(dispatch, removeTeam, removeTeamParams, removeTeamOp);
  useDispatchHelper(dispatch, assignTeam, assignTeamParams, assignTeamOp);
  useDispatchHelper(dispatch, updateOrgAdmin, updateOrgAdminParams, updateOrgAdminOp);
  useDispatchHelper(dispatch, updateOnlySSO, updateOnlySSOParams, updateOnlySsoOp);

  useEffect(() => {
    if (shouldClearOperations && orgId) {
      dispatch(clearOperations({ orgId, opNames }));
    }
  }, [dispatch, orgId, userId, shouldClearOperations, opNames]);

  return operations;
}

/**
 * Run the necessary calls to delete a user
 * @param org The current organization data, if any
 * @param userId The user to delete
 * @param trigger Top-level flag to trigger the behavior in this hook
 * @returns The operation state
 */
export function useDeleteOrgUser(
  org: DerivedOrganization,
  userId = '',
  trigger = false
): Operation[] {
  const { orgId, licenseMap, keyedOperationMap } = org;
  const baseParams = getOrgUserParamsBase(org, userId);
  const user = findOrgUser(org, userId);

  const removeLicenseOp = keyedOperationMap.removeLicense?.[userId];
  const deleteUserOp = keyedOperationMap.deleteUser?.[userId];

  const operations: Operation[] = [];
  let removeLicenseParams: SFOrderItemAssignParams | undefined;
  let deleteUserParams: FlagParams | undefined;

  if (trigger && baseParams && licenseMap) {
    const license = licenseMap[userId];
    if (license) {
      removeLicenseParams = {
        ...baseParams,
        orderItemId: license.orderItemId,
        isRemove: true,
      };
      operations.push(removeLicenseOp);
    } else {
      deleteUserParams = {
        ...baseParams,
        flag: !!user?.recruiterId,
      };
      operations.push(deleteUserOp);
    }
  }

  const dispatch = useAppDispatch();
  const clearOperations = trigger && !operations.length && (removeLicenseOp || deleteUserOp);

  useDispatchHelper(dispatch, updateSFLicense, removeLicenseParams, removeLicenseOp);
  useDispatchHelper(dispatch, deleteUserThunk, deleteUserParams, deleteUserOp);

  useEffect(() => {
    if (clearOperations && orgId) {
      dispatch(clearUserOperations({ orgId, userId, opNames: ['removeLicense', 'deleteUser'] }));
    }
  }, [dispatch, orgId, userId, clearOperations]);

  return operations;
}

/**
 * Run the necessary calls to assign a license for a set of users
 * @param dispatch The dispatcher loaded by useAppDispatch() in the react component
 * @param org The current organization data, if any
 * @param userIds The list of users to update
 * @param orderItemId The orderItemId to add, or 'none'
 * @param skipRemove True to skip the remove license step (perf optimization)
 * @param done A callback on completion
 * @param licenseMap An unmodified org licenseMap for remove license step
 */
export function bulkAssignLicense(
  dispatch: Dispatch,
  org: DerivedOrganization,
  userIds: string[],
  orderItemId: string,
  skipRemove: boolean,
  done: () => void,
  licenseMap?: Record<string, License>
): void {
  const orgId = org.orgId;

  if (!orgId || !userIds.length || !orderItemId) return done();

  const steps = [];

  // Step 1: Remove Existing License
  if (!skipRemove) {
    steps.push(
      removeLicenseBulkParam({ ...org, licenseMap: licenseMap || org.licenseMap }, orderItemId)
    );
  }

  // Step 2: Assign New License
  if (orderItemId !== 'none') {
    steps.push(assignLicenseBulkParam(org, orderItemId));
  }

  if (!bulkHelper(dispatch, userIds, steps as BulkParam<unknown, unknown>[])) {
    // Yield if it isn't done
    return;
  }

  done();
}

/**
 * Run the necessary calls to assign a team for a set of users
 * @param dispatch The dispatcher loaded by useAppDispatch() in the react component
 * @param org The current organization data, if any
 * @param userIds The list of users to update
 * @param fromTeamId The fromTeamId to add, or 'none'
 * @param skipRemove True to skip the remove team step (perf optimization)
 * @param done A callback on completion
 */
export function bulkAssignTeam(
  dispatch: Dispatch,
  org: DerivedOrganization,
  userIds: string[],
  teamId: string,
  skipRemove: boolean,
  done: () => void,
  orgUserTeamMap?: Map<string, string | undefined>
): void {
  const orgId = org.orgId;

  if (!orgId || !userIds.length || !teamId) return done();

  const steps = [];

  // Step 1: Remove Existing Team
  if (!skipRemove) {
    steps.push(removeTeamBulkParam(org, orgUserTeamMap, teamId));
  }

  // Step 2: Assign New Team
  if (teamId !== NoTeam.id) {
    steps.push(assignTeamBulkParam(org, teamId));
  }

  if (!bulkHelper(dispatch, userIds, steps as BulkParam<unknown, unknown>[])) {
    // Yield if it isn't done
    return;
  }

  done();
}

/**
 * Run the necessary calls to delete a set of users
 * @param dispatch The dispatcher loaded by useAppDispatch() in the react component
 * @param org The current organization data, if any
 * @param userIds The list of users to update
 * @param done A callback on completion
 */
export function bulkDeleteUser(
  dispatch: Dispatch,
  org: DerivedOrganization,
  userIds: string[],
  done: () => void
): void {
  const orgId = org.orgId;

  if (!orgId || !userIds.length) return done();

  const steps = [removeLicenseBulkParam(org), deleteUserBulkParam(org)];

  if (!bulkHelper(dispatch, userIds, steps as BulkParam<unknown, unknown>[])) {
    // Yield if it isn't done
    return;
  }

  done();
}

interface BulkParam<In, Out> {
  /** A map of val -> Operation to check for existing dispatches */
  hasStarted: (val: string) => Operation;
  /** A function that takes a val and returns (the thunk and its param) or undefined to skip */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getParam: (val: any) => (In & { thunk: Thunk<In, Out> }) | undefined;
  /** The maximum number of parallel dispatches to make, default 1 */
  parallelMax?: number;
}

/**
 * Run the necessary calls to add a set of domains to smb blocklist
 * @param dispatch The dispatcher loaded by useAppDispatch() in the react component
 * @param system The current system data, if any
 * @param domains The list of domains to add
 * @param done A callback on completion
 */
export function bulkAddSmbDomain(
  dispatch: Dispatch,
  system: DerivedSystem,
  domains: Record<string, string>[],
  done: () => void
): void {
  if (!domains.length) return done();

  const domainNames = domains.map((x) => x.domain);
  const allowedStatuses: Map<string, string> = new Map();
  domains.forEach((domain) => {
    allowedStatuses.set(domain.domain, domain.allowStatus);
  });

  const steps = [addSmbDomainBulkParam(system, allowedStatuses)];

  if (!bulkHelper(dispatch, domainNames, steps as BulkParam<unknown, unknown>[])) {
    // Yield if it isn't done
    return;
  }

  done();
}

/**
 * Run the necessary calls to delete a set of domains from smb blocklist
 * @param dispatch The dispatcher loaded by useAppDispatch() in the react component
 * @param system The current system data, if any
 * @param domains The list of domains to delete
 * @param done A callback on completion
 */
export function bulkDeleteSmbDomain(
  dispatch: Dispatch,
  system: DerivedSystem,
  domains: string[],
  done: () => void
): void {
  if (!domains.length) return done();

  const steps = [deleteSmbDomainBulkParam(system)];

  if (!bulkHelper(dispatch, domains, steps as BulkParam<unknown, unknown>[])) {
    // Yield if it isn't done
    return;
  }

  done();
}

function removeLicenseBulkParam(
  org: DerivedOrganization,
  orderItemId?: string
): BulkParam<SFOrderItemAssignParams, boolean> {
  return {
    hasStarted: (userId: string) => org.keyedOperationMap.removeLicense?.[userId],
    getParam: (userId: string) => {
      const user = findOrgUser(org, userId);
      let currentOrderItemId = getOrgUserLicenseId(org, userId);
      if (orderItemId === 'none' && !currentOrderItemId && user?.currentSkuId !== NoLicenseSku.id) {
        // This is an edge case where user has no salesforce license
        // but has active sku and we want to assign them no-license sku
        currentOrderItemId = NoLicenseSku.id;
      }
      return currentOrderItemId
        ? {
            thunk: updateSFLicense,
            orgId: org.org?.id,
            userId,
            orderItemId: currentOrderItemId,
            isRemove: true,
          }
        : undefined;
    },
    parallelMax: 5,
  };
}

function assignLicenseBulkParam(
  org: DerivedOrganization,
  orderItemId: string
): BulkParam<SFOrderItemAssignParams, boolean> {
  return {
    hasStarted: (userId: string) => org.keyedOperationMap.assignLicense?.[userId],
    getParam: (userId: string) => {
      const user = findOrgUser(org, userId);
      const currentOrderItemId = getOrgUserLicenseId(org, userId);
      return user && !currentOrderItemId
        ? {
            thunk: updateSFLicense,
            orgId: org.org?.id,
            userId,
            newUserId: user.newUserId,
            orderItemId: orderItemId,
          }
        : undefined;
    },
    // Only 1 at a time - otherwise the change is often lost with no indicator in the response
    parallelMax: 1,
  };
}

function removeTeamBulkParam(
  org: DerivedOrganization,
  orgUserTeamMap?: Map<string, string | undefined>,
  fromTeamId?: string
): BulkParam<ValueParams, boolean> {
  return {
    hasStarted: (userId: string) => org.keyedOperationMap.removeTeam?.[userId],
    getParam: (userId: string) => {
      const user = findOrgUser(org, userId);
      let currentTeamId = orgUserTeamMap?.get(userId);
      if (fromTeamId === NoTeam.id && !currentTeamId && user?.teamId !== NoTeam.id) {
        // This is an edge case where user is not part of any team
        // but has teamId and we want to move them to No Team
        currentTeamId = user?.teamId;
      }
      return currentTeamId
        ? {
            thunk: removeTeam,
            orgId: org.org?.id,
            userId,
            value: currentTeamId,
          }
        : undefined;
    },
    parallelMax: 5,
  };
}

function assignTeamBulkParam(
  org: DerivedOrganization,
  teamId: string
): BulkParam<ValueParams, ITeamRecruiter> {
  return {
    hasStarted: (userId: string) => org.keyedOperationMap.assignTeam?.[userId],
    getParam: (userId: string) => {
      const user = findOrgUser(org, userId);
      const currentTeamId = user?.assignedTeam?.id;
      return user && !currentTeamId
        ? {
            thunk: assignTeam,
            orgId: org.org?.id,
            userId,
            value: teamId,
          }
        : undefined;
    },
    // Only 1 at a time - otherwise the change is often lost with no indicator in the response
    parallelMax: 1,
  };
}

function deleteUserBulkParam(org: DerivedOrganization): BulkParam<ParamsBase, boolean> {
  return {
    hasStarted: (userId: string) => org.keyedOperationMap.deleteUser?.[userId],
    getParam: (userId: string) => {
      const user = findOrgUser(org, userId);
      if (user) {
        return {
          orgId: org.org?.id,
          userId,
          newUserId: user.newUserId,
          thunk: deleteUserThunk,
        };
      }
    },
    parallelMax: 5,
  };
}

function addSmbDomainBulkParam(
  system: DerivedSystem,
  allowedStatuses: Map<string, string>
): BulkParam<ResourceParams<ISmbDomain>, ISmbDomain> {
  return {
    hasStarted: (domain: string) => system.keyedOperationMap.addSmbDomain?.[domain],
    getParam: (domain: string) => {
      const smbDomain = system.smbDomains?.find((x) => x.userId === domain);
      return !smbDomain
        ? {
            thunk: addSmbDomain,
            resource: { userId: domain, allowStatus: allowedStatuses.get(domain) },
          }
        : undefined;
    },
    // Only 1 at a time - otherwise the change is often lost with no indicator in the response
    parallelMax: 1,
  };
}

function deleteSmbDomainBulkParam(system: DerivedSystem): BulkParam<ValueParams, boolean> {
  return {
    hasStarted: (domain: string) => system.keyedOperationMap.deleteSmbDomain?.[domain],
    getParam: (domain: string) => {
      const smbDomain = system.smbDomains?.find((x) => x.userId === domain);
      return smbDomain
        ? {
            thunk: deleteSmbDomain,
            value: domain,
          }
        : undefined;
    },
    parallelMax: 5,
  };
}

/**
 * A helper to standardize the bulkization of single-operation API sets
 * @param dispatch The dispatcher
 * @param vals The list of values, usually ids, to operate over
 * @param operations The list of operation definitions to run for each val
 * @returns True if all expected dispatches have completed
 */
function bulkHelper(dispatch: Dispatch, vals: string[], operations: BulkParam<unknown, unknown>[]) {
  if (!vals || !operations) return;

  const start = firstDispatchIndex(vals, operations);

  if (start === -1) {
    // Nothing remaining
    return true;
  }

  const halt = new Set();

  // The largest parallelMax creates the current window of action
  const maxParallelMax = Math.max(...operations.map((op) => op.parallelMax ?? 1));
  const windowLength = Math.min(vals.length, start + maxParallelMax);

  for (const op of operations) {
    let parallelCount = 0;

    // Pass through the window, noting status of existing calls
    for (let i = start; i < windowLength; i++) {
      if (!halt.has(i)) {
        const status = op.hasStarted(vals[i])?.status;

        if (status === 'loading') {
          // Increment the active operation parallel count
          parallelCount++;
        }

        if (status === 'loading' || status === 'failed') {
          // Halt further operations with this index
          halt.add(i);
        }
      }
    }

    // Pass through the window, making new dispatches, only if we're under the parallel call limit
    if (parallelCount < (op.parallelMax ?? 1)) {
      for (let i = start; i < windowLength; i++) {
        if (!halt.has(i)) {
          const val = vals[i];
          const status = op.hasStarted(val)?.status;

          if (!status) {
            const param = op.getParam(val);

            if (param) {
              // Dispatch, halt further operations with this index,
              //   and increment the active operation parallel count
              dispatchHelper(dispatch, param.thunk, param, op.hasStarted(val));
              halt.add(i);
              parallelCount++;

              // If we've hit the parallel call limit for this operation, move to next operation
              if (parallelCount >= (op.parallelMax ?? 1)) {
                break;
              }
            }
          }
        }
      }
    }
  }
}

/**
 * Find the index of the first value to consider in a bulk operation
 * @param vals The list of values (usually ids) being operated on
 * @param operations The list of operations being applied
 * @returns The index of the first value waiting to be dispatched or for response, or -1 if none
 */
function firstDispatchIndex(vals: string[], operations: BulkParam<unknown, unknown>[]) {
  let index = 0;

  while (index < vals.length) {
    const val = vals[index];

    for (const operation of operations) {
      const status = operation.hasStarted(val)?.status;

      // We have dispatched, but it is not yet complete
      if (status === 'loading') return index;

      // We have not dispatched and there are params to do so
      if (!status && !!operation.getParam(val)) return index;
    }

    index++;
  }

  return -1;
}

/**
 * A helper to standardize the format and check around dispatching a thunk
 * @param dispatch The dispatcher
 * @param thunk The thunk to dispatch
 * @param params The params to pass to the thunk
 * @param operation The Operation from a previous dispatch, if it exists
 */
function useDispatchHelper<In, Out>(
  dispatch: Dispatch,
  thunk: Thunk<In, Out>,
  params?: In,
  operation?: Operation
) {
  useEffect(() => {
    if (params && !operation) {
      dispatch(thunk(params));
    }
  }, [dispatch, thunk, params, operation]);
}

/**
 * A helper to standardize the format and check around dispatching a thunk
 * @param dispatch The dispatcher
 * @param thunk The thunk to dispatch
 * @param params The params to pass to the thunk
 * @param operation The Operation from a previous dispatch, if it exists
 */
function dispatchHelper<In, Out>(
  dispatch: Dispatch,
  thunk: Thunk<In, Out>,
  params: In,
  operation: Operation
) {
  if (!operation) {
    dispatch(thunk(params));
  }
}
