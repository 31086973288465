import { Box, DialogContentText } from '@mui/material';
import { Modal } from 'components/modals/Modal';
import { CancelSubscriptionForm } from './CancelSubscriptionForm';
import { useState } from 'react';
import { ConfirmModal } from 'components/modals/ConfirmModal';
import { asDateISOString } from 'utils/convert';

interface CancelSubscriptionModalProps {
  onClose: () => void;
  open: boolean;
  userName?: string;
  subscriptionEndTS?: Date;
  onSubmit: (formData: Record<string, unknown>) => void;
}

export const CancelSubscriptionModal = ({
  onClose,
  open,
  userName,
  subscriptionEndTS,
  onSubmit,
}: CancelSubscriptionModalProps) => {
  const [formData, setFormData] = useState<Record<string, unknown>>({});
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  return (
    <>
      <Modal
        title="Cancel Maxio Subscription"
        open={open}
        onClose={onClose}
        additionalProps={{
          fullWidth: true,
          maxWidth: 'sm',
        }}
        noActions
      >
        <DialogContentText>
          {`The subscription will be cancelled at the end of user's current billing cycle on ${asDateISOString(
            subscriptionEndTS
          )}.`}
        </DialogContentText>
        <Box
          sx={{
            marginTop: '20px',
            maxHeight: '325px',
            overflow: 'auto',
          }}
        >
          <CancelSubscriptionForm
            onSubmit={(formInput) => {
              setFormData(formInput);
              onClose();
              setConfirmModalOpen(true);
            }}
            onCancel={onClose}
          />
        </Box>
      </Modal>
      <ConfirmModal
        open={confirmModalOpen}
        prompt={`Are you sure you want to cancel the subscription of "${userName}"? This action is irreversible.`}
        onClose={() => setConfirmModalOpen(false)}
        onAccept={() => {
          onSubmit(formData);
          setConfirmModalOpen(false);
        }}
      />
    </>
  );
};
