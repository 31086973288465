import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Tip } from 'components/Tip';

interface Props {
  label: string;
  value?: boolean;
  onChange: (val: boolean) => void;
  tooltip?: string;
  tipModel?: string;
  tipProperty?: string;
}

/**
 * A standard setting toggle control
 */
export const Toggle = ({
  label,
  value,
  onChange,
  tooltip,
  tipModel,
  tipProperty,
}: Props): JSX.Element => {
  return (
    <Tip title={tooltip} model={tipModel} property={tipProperty}>
      <FormControlLabel
        control={<Switch checked={value} onChange={(e) => onChange(e.target.checked)} />}
        label={label}
        sx={{ width: 'fit-content' }}
      />
    </Tip>
  );
};
