import { useEffect } from 'react';

import { getQuotaHistory } from 'api/apiThunks';
import { selectDerivedUser } from 'api/usersSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { StandardGrid } from 'components/grids/StandardGrid';
import { ViewFrame } from 'features/frame/ViewFrame';
import { getOperations } from 'utils/operable';

/**
 * The Quota History view
 */
export const QuotaHistory = (): JSX.Element => {
  const user = useAppSelector(selectDerivedUser);
  const viewOperations = getOperations(user, 'quotaHistory');
  const [quotaHistoryOp] = viewOperations;
  const { userId, quotaHistory = [] } = user;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userId && !quotaHistoryOp) {
      dispatch(getQuotaHistory(userId));
    }
  }, [dispatch, userId, quotaHistoryOp]);

  if (!userId) return <></>;

  return (
    <ViewFrame viewLoader={{ message: 'Loading Quota History', viewOperations }}>
      <StandardGrid
        dataSet={quotaHistory}
        tipModel="QuotaHistory"
        getRowId={(x) => x.id}
        filterPlaceholder="Filter the results"
        cols={[
          {
            name: 'Timestamp',
            description: 'The time the activity occurred',
            valueProperty: 'dateCreated',
            type: 'date',
          },
          {
            name: 'Activity',
            description: 'The operation being performed',
            valueProperty: 'actvityType',
          },
          {
            name: 'Sku',
            description: 'The sku in use',
            valueProperty: 'skuId',
          },
          {
            name: 'Used',
            description: 'The amount of quota used in this action',
            valueProperty: 'activityCount',
          },
          {
            name: 'Quota Remaining',
            description: 'The amount of quota remaining after the action',
            valueProperty: 'totalQuotaLeft',
          },
          {
            name: 'Add-on Quota Remaining',
            description: 'The amount of add-on quota remaining after the action',
            valueProperty: 'addOnQuotaLeft',
          },
        ]}
      />
    </ViewFrame>
  );
};
