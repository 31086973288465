import { Box, Button } from '@mui/material';
import { FormField } from 'components/form/FormField';
import { ChangeEvent, useCallback, useMemo, useReducer, useState } from 'react';
import { requiredField } from 'utils/form';

const validationField = 'name';

const nameField: FormFieldInfo = {
  name: 'name',
  label: 'Team Name',
};

interface OrgTeamState extends FormState, Partial<IOrgTeam> {}

const orgTeamReducer = (state: OrgTeamState, newState: OrgTeamState) => ({
  ...state,
  ...newState,
});

interface Props extends FormProps<IOrgTeam> {
  orgTeamNames: string[];
  disabled?: boolean;
}

/**
 * A form to create an org team
 */
export const AddOrgTeamForm = ({
  onSubmit,
  sx,
  initialValues,
  orgTeamNames,
  disabled,
}: Props): JSX.Element => {
  const [formErrors, setFormErrors] = useState({} as FormErrors);
  const [formInput, setFormInput] = useReducer(orgTeamReducer, { ...(initialValues || {}) });

  const validateForm = useCallback(
    (fieldValues: Record<string, unknown>, isSubmit = false) => {
      const isDuplicate = (name: string) => {
        return orgTeamNames.includes(name.toLowerCase())
          ? 'Team with same name already exists'
          : '';
      };

      if (isSubmit) {
        // Do full form validation
        formErrors[validationField] = requiredField(fieldValues, validationField);
        if (!formErrors[validationField]) {
          formErrors[validationField] = isDuplicate(fieldValues[validationField] as string);
        }
      } else {
        Object.entries(fieldValues).forEach(([key]) => {
          formErrors[key] = requiredField(fieldValues, key);
          if (!formErrors[key]) {
            formErrors[key] = isDuplicate(fieldValues[key] as string);
          }
        });
      }

      setFormErrors({ ...formErrors });
      return Object.values(formErrors).every((x) => x === '');
    },
    [formErrors, orgTeamNames]
  );

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const fieldValue = { [name]: e.target.value };
    setFormInput(fieldValue);
    validateForm(fieldValue);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm(formInput, true)) return;
    onSubmit(formInput as IOrgTeam);
    setFormInput({ name: undefined });
  };

  const isValidForm = useMemo(() => {
    return Object.values(formErrors).every((x) => x === '');
  }, [formErrors]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        alignItems: 'baseline',
        ...sx,
      }}
      autoComplete="off"
    >
      <FormField
        name={nameField.name}
        label={nameField.label}
        placeHolder={nameField.label}
        onChange={handleInput}
        {...(formErrors[nameField.name] && {
          error: true,
          helperText: formErrors[nameField.name],
        })}
        defaultValue={formInput[nameField.name as keyof IOrgTeam]}
      />
      <Button
        type="submit"
        variant={!isValidForm ? 'outlined' : 'contained'}
        color={!isValidForm ? 'error' : undefined}
        sx={{ width: 125, height: 'fit-content', ml: 2 }}
        disabled={disabled}
      >
        Add
      </Button>
    </Box>
  );
};
