import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Tip } from 'components/Tip';

interface Props {
  openLookup: () => void;
  tooltip?: string;
}

/**
 * A button for opening lookup tabs
 */
export const OpenLookupButton = ({ openLookup, tooltip = '' }: Props): JSX.Element => {
  return (
    <Tip title={tooltip}>
      <IconButton color="primary" onClick={openLookup}>
        <ArrowBackIcon />
        <AddCircleOutlineIcon />
      </IconButton>
    </Tip>
  );
};
