import { Box, Typography } from '@mui/material';

/**
 * A standard control to display user doesn't have permission to access this page
 */
export const NoPermission = (): JSX.Element => {
  return (
    <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <Typography variant="h5" sx={{ color: (theme) => theme.palette.error.dark }}>
        You do not have permission to access this page
      </Typography>
    </Box>
  );
};
