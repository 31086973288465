import { Box, Button } from '@mui/material';
import { FormFields } from 'components/form/FormFields';
import { ChangeEvent, useCallback, useMemo, useReducer, useState } from 'react';
import { requiredField } from 'utils/form';
import {
  getAtsTypeOptions,
  getAuthTypeOptions,
  getIntegrationTypeOptions,
} from 'utils/selectorOptions';
import { ColumnContent } from './columns/ColumnContent';

const getDropdownDefaultValue = ({ formInput, field }: FormValueProps<AtsMetaState>) => {
  return {
    defaultValue: `${formInput[field as keyof IATSMeta] || ''}`,
  };
};

const validationFields = ['name', 'logoUrl', 'atsType', 'integrationType', 'authType'];
const column1Fields: FormFieldInfo[] = [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'description',
    label: 'Description',
  },
  {
    name: 'tutorialUrl',
    label: 'Tutorial Url',
  },
  {
    type: 'file',
    name: 'tutorialFile',
    label: 'Choose Tutorial File',
  },
  {
    name: 'logoUrl',
    label: 'Logo Url',
  },
  {
    type: 'file',
    name: 'logoFile',
    label: 'Choose Logo',
  },
];

const column2Fields: FormFieldInfo[] = [
  {
    type: 'dropdown',
    name: 'integrationType',
    label: 'Integration Type',
    options: getIntegrationTypeOptions(),
    getDefaultValue: getDropdownDefaultValue,
  },
  {
    type: 'dropdown',
    name: 'authType',
    label: 'Auth Type',
    options: getAuthTypeOptions(),
    getDefaultValue: getDropdownDefaultValue,
  },
  {
    type: 'autocomplete',
    name: 'atsType',
    label: 'ATS Type',
    options: getAtsTypeOptions(),
    getDefaultValue: getDropdownDefaultValue,
  },
  {
    type: 'toggle',
    name: 'isActive',
    label: 'Is Active',
  },
];

const authSchemaField: FormFieldInfo = {
  name: 'authSchema',
  label: 'Auth Schema',
  additionalProps: {
    multiline: true,
    maxRows: 12,
  },
};

interface AtsMetaState extends FormState, Partial<IATSMeta> {}

const atsMetaReducer = (state: AtsMetaState, newState: AtsMetaState) => ({
  ...state,
  ...newState,
});

/**
 * A form to create/edit ats meta
 */
export const AtsMetaForm = ({
  onSubmit,
  onCancel,
  sx,
  initialValues,
}: FormProps<IATSMeta>): JSX.Element => {
  const [formErrors, setFormErrors] = useState({} as FormErrors);
  const [formInput, setFormInput] = useReducer(atsMetaReducer, { ...(initialValues || {}) });

  const validateForm = useCallback(
    (fieldValues: Record<string, unknown>, isSubmit = false) => {
      if (isSubmit) {
        // Do full form validation
        validationFields.forEach((field) => {
          formErrors[field] = requiredField(fieldValues, field);
        });
      } else {
        Object.entries(fieldValues)
          .filter(([key]) => validationFields.includes(key))
          .forEach(([key]) => {
            formErrors[key] = requiredField(fieldValues, key);
          });
      }

      if (fieldValues['logoFile']) {
        formErrors['logoUrl'] = '';
      }

      setFormErrors({ ...formErrors });
      return Object.values(formErrors).every((x) => x === '');
    },
    [formErrors]
  );

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const fieldValue: Record<string, unknown> = {};
    let newValue;
    switch (e.target.type) {
      case 'file':
        newValue = e.target.files;
        break;

      case 'checkbox':
        newValue = e.target.checked;
        break;

      default:
        newValue = e.target.value;
        break;
    }

    if (Object.keys(fieldValue).length === 0) {
      fieldValue[name] = newValue;
    }
    setFormInput(fieldValue);
    validateForm(fieldValue);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm(formInput, true)) return;
    onSubmit(formInput as IATSMeta);
  };

  const columnFields = (formFields: FormFieldInfo[]) => {
    return FormFields<IATSMeta>({
      formFields,
      formInput: formInput as IATSMeta,
      formErrors: formErrors,
      handleInput: handleInput,
    });
  };

  const isValidForm = useMemo(() => {
    return Object.values(formErrors).every((x) => x === '');
  }, [formErrors]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        width: '100%',
        margin: (theme) => theme.spacing(0, 'auto', 4, 'auto'),
        ...sx,
      }}
      autoComplete="off"
    >
      <ColumnContent
        columns={[<>{columnFields(column1Fields)}</>, <>{columnFields(column2Fields)}</>]}
        growWidth
      />
      <Box sx={{ pl: 2, pr: 2 }}>{columnFields([authSchemaField])}</Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <Button sx={{ mr: 2 }} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant={!isValidForm ? 'outlined' : 'contained'}
          color={!isValidForm ? 'error' : undefined}
          sx={{ width: 125 }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
