import { FEATURES_WHITELIST, pageSizes } from 'constant';
import {
  AuthType,
  DiversitySetting,
  IntegrationSetting,
  SearchType,
  RoiReportType,
  SearchIndex,
  SkuType,
  EmailQuality,
  PowerFiltersSearchType,
  Privilege,
} from 'types/enums';
import { ATSType, Feature, IndexSetting, IntegrationType } from 'types/server/common/enums';

const diversityNameMap: Record<number, string> = {
  [DiversitySetting.EuropeAllOff]: 'Europe index: Off',
  [DiversitySetting.EuropeRaceOff]: 'Europe index: Only Women filter',
  [DiversitySetting.InternalTalentOff]: 'Internal Talent index: Off',
};

/**
 * Get a list of licenses for a selectors
 */
export function getLicenseSelectionOptions(
  licenses: License[],
  customNone?: SelectOption
): SelectOption[] {
  const none = customNone ?? { value: 'none', text: 'No License' };
  return [
    none,
    ...licenses
      .map((x) => ({
        value: x.orderItemId,
        text: `${x.orderNumber}: ${x.skuName} (${x.endDate})${
          x.quantityLeft ? ` (${x.quantityLeft})` : ''
        }`,
      }))
      .sort(sorter),
  ];
}

/**
 * Get a list of free-trial skus for a selector
 */
export function getFreeTrialSkuSelectionOptions(skuV1: ISku[], skuV2: ISkuV2[]): SelectOption[] {
  return [...skuV1, ...skuV2]
    .filter((x) => x.freeTrialTime || x.id === 'chrome-ext-users')
    .map((x) => ({
      value: x.id,
      text: `Free Trial: ${x.name} (${x.freeTrialTime} days)`,
    }))
    .sort(sorter);
}

/**
 * Get a list of free-trial and no-license skus for a selector
 */
export function getSkuSelectionOptions(skuV1: ISku[], skuV2: ISkuV2[]): SelectOption[] {
  const freeTrialSkus = getFreeTrialSkuSelectionOptions(skuV1, skuV2);
  const filteredSkus = [...skuV1, ...skuV2]
    .filter((x) => ['supervisor_sku', 'no-license'].includes(x.id))
    .map((x) => ({
      value: x.id,
      text: x.name,
    }));
  return [...freeTrialSkus, ...filteredSkus].sort(sorter);
}

/**
 * Get a list of addon skus for a selector
 */
export function getSkuAddonSelectionOptions(skuV1: ISku[]): SelectOption[] {
  return skuV1
    .filter((x) => x.skuType === 'addon')
    .map((x) => ({ value: x.id, text: x.name }))
    .sort(sorter);
}

/**
 * Get a list of teams for a selector
 */
export function getTeamOptions(teams: IOrgTeam[], includeAll?: boolean): SelectOption[] {
  const options = [{ value: 'not_on_a_team', text: 'No Team' }];
  if (includeAll) {
    options.unshift({ value: 'all', text: 'All Teams' });
  }
  return [...options, ...teams.map((x) => ({ value: x.id, text: x.name })).sort(sorter)];
}

/**
 * Get a list of monitor categories for a selector
 */
export function getMonitorCategoryOptions(categories: MonitorCategories): SelectOption[] {
  const all = { value: 'all', text: 'All' };
  const cats = Object.keys(categories).sort();
  return [all, ...cats.map((x) => ({ value: x, text: x }))];
}

/**
 * Get a list of internal admins for a selector
 */
export function getAdminOptions(admins: IAdminUser[]): SelectOption[] {
  return admins.map((x) => ({ text: x.emailAddress, value: x.actualUserId })).sort(sorter);
}

/**
 * Get a list of admin privilege for a selector
 */
export function getAdminPrivilegeOptions(): SelectOption[] {
  return Object.values(Privilege).map((val) => ({ text: val, value: val }));
}

/**
 * Get a list of user features for a selector
 */
export function getFeatureOptions(
  whitelist = false,
  grouped = false,
  addDivider = false
): SelectOption[] {
  let featureOptions = Object.entries(Feature)
    .map(([key, val]) => ({ text: key, value: val }))
    .sort(sorter);
  if (whitelist) {
    featureOptions = featureOptions.filter((x) => FEATURES_WHITELIST.includes(x.value));
  } else if (grouped) {
    const whiteListedFeatures = featureOptions.filter((x) => FEATURES_WHITELIST.includes(x.value));
    const nonWhiteListedFeatures = featureOptions.filter(
      (x) => !FEATURES_WHITELIST.includes(x.value)
    );
    featureOptions = [...whiteListedFeatures, ...nonWhiteListedFeatures];
    if (addDivider) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      featureOptions.splice(whiteListedFeatures.length, 0, { text: '-', value: 'Divider' } as any);
    }
  }
  return featureOptions;
}

/**
 * Get a list of diversity filters for a selector
 */
export function getDiversityFilterOptions(): SelectOption[] {
  return (
    Object.entries(DiversitySetting)
      // Numerical enums are double-mapped, and we only want the main mapping
      .filter((x) => typeof x[1] === 'number')
      // enum order
      .sort((a, b) => (a[1] as number) - (b[1] as number))
      .map(([key, val]) => ({ text: diversityNameMap[val as number] ?? key, value: `${val}` }))
  );
}

/**
 * Get a list of simplified search index for a selector
 */
export function getSearchIndexOptions(): SelectOption[] {
  return Object.entries(SearchIndex).map(([key, val]) => ({ text: key, value: val }));
}

/**
 * Get a list of simplified index setting for a selector
 */
export function getIndexSettingOptions(): SelectOption[] {
  return (
    Object.entries(IndexSetting)
      // Numerical enums are double-mapped, and we only want the main mapping
      .filter((x) => typeof x[1] === 'number')
      .map(([key, val]) => ({ text: key, value: `${val}` }))
  );
}

/**
 * Get a list of simplified integration settings for a selector
 */
export function getIntegrationSettingOptions(): SelectOption[] {
  return Object.values(IntegrationSetting).map((val) => ({ text: val, value: val }));
}

/**
 * Get a list of simplified integration types for a selector
 */
export function getIntegrationTypeOptions(): SelectOption[] {
  const intergationTypes = [IntegrationType.Export, IntegrationType.Rediscovery];
  return Object.entries(IntegrationType)
    .filter((x) => intergationTypes.includes(x[1]))
    .map(([key, val]) => ({ text: key, value: val }));
}

/**
 * Get a list of simplified auth types for a selector
 */
export function getAuthTypeOptions(): SelectOption[] {
  return Object.entries(AuthType).map(([key, val]) => ({ text: key, value: val }));
}

/**
 * Get a list of simplified ats types for a selector
 */
export function getAtsTypeOptions(): SelectOption[] {
  return Object.values(ATSType).map((val) => ({ text: val, value: val }));
}

/**
 * Get a list of downloaded org reports ats types for a selector
 */
export function getDownloadedAtsTypeOptions(orgAtsReports: IATSReport[]): SelectOption[] {
  const atsTypes = Array.from(new Set(orgAtsReports.map((x) => x.ats)));
  return atsTypes.map((x) => ({ text: x, value: x }));
}

/**
 * Get a list of simplified search types for a selector
 */
export function getSearchTypeOptions(): SelectOption[] {
  return Object.entries(SearchType).map(([key, val]) => ({ text: key, value: val }));
}

/**
 * Get a list of simplified search types for a selector
 */
export function getPowerFiltersSearchTypeOptions(): SelectOption[] {
  return Object.entries(PowerFiltersSearchType).map(([key, val]) => ({ text: key, value: val }));
}

/**
 * Get a list of simplified sku types for a selector
 */
export function getSkuTypeOptions(): SelectOption[] {
  return Object.entries(SkuType).map(([key, val]) => ({ text: key, value: val }));
}

/**
 * Get a list of simplified email quality for a selector
 */
export function getEmailQualityOptions(): SelectOption[] {
  return Object.entries(EmailQuality).map(([key, val]) => ({ text: key, value: val }));
}

/**
 * Get a list of simplified roi report types for a selector
 */
export function getRoiReportTypeOptions(): SelectOption[] {
  return Object.entries(RoiReportType).map(([key, val]) => ({ text: key, value: val }));
}

/**
 * Get a list of page sizes for a selector
 */
export function getPageSizesOptions(): SelectOption[] {
  return pageSizes.map((x) => ({ value: x.toString(), text: x.toString() }));
}

function sorter(a: SelectOption, b: SelectOption) {
  return a.text > b.text ? 1 : b.text > a.text ? -1 : 0;
}
