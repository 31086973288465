import { useState } from 'react';
import { ViewFrame } from 'features/frame/ViewFrame';
import { Selector } from 'components/Selector';
import { StandardGrid } from 'components/grids/StandardGrid';
import { Box, Button, DialogContentText, Divider, TextField } from '@mui/material';
import { createFailedOperation } from 'utils/apiResult';
import {
  deleteAllLIProfiles,
  deleteLIProfile,
  findDeletedLIProfileByEmail,
  findDeletedLIProfileById,
} from 'api/apiThunks';
import { ConfirmModal } from 'components/modals/ConfirmModal';
import { Modal } from 'components/modals/Modal';

enum SearchField {
  Email = 'Email',
  Handle = 'Handle',
}
const seachFieldOptions = Object.entries(SearchField).map(([key, val]) => ({
  text: key,
  value: val,
}));

/**
 * A view to delete LI profiles
 */
export const LIProfiles = (): JSX.Element => {
  const [searchField, setSearchField] = useState(SearchField.Email);
  const [fieldValue, setFieldValue] = useState('');
  const [deletedLIProfiles, setDeletedLIProfiles] = useState<IDeletedLIProfile[]>([]);
  const [operations, setOperations] = useState<Operation[]>([]);
  const [action, setAction] = useState<string | undefined>();
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
  const [deleteAllProfilesModalOpen, setDeleteAllProfilesModalOpen] = useState(false);
  const [deletedProfilesCount, setDeletedProfilesCount] = useState(0);

  const fetcher = async () => {
    setAction('fetch');
    setOperations([{ status: 'loading' }]);

    try {
      let results: IDeletedLIProfile[] = [];
      if (searchField === SearchField.Email) {
        results = await findDeletedLIProfileByEmail(fieldValue);
      } else {
        const result = await findDeletedLIProfileById(fieldValue);
        results.push(result);
      }
      setAction(undefined);
      setOperations([]);
      setDeletedLIProfiles(results);
    } catch (error) {
      const message = error instanceof Error ? error.message : `${error}`;
      setOperations([createFailedOperation(`Find Profile failed: ${message}`)]);
    }
  };

  const handleOnDelete = async () => {
    setOperations([{ status: 'loading' }]);

    try {
      const result = await deleteLIProfile(searchField, fieldValue);
      setAction(undefined);
      setOperations([]);
      if (result) {
        setDeletedLIProfiles([result]);
      }
    } catch (error) {
      const message = error instanceof Error ? error.message : `${error}`;
      setOperations([createFailedOperation(`Delete Profile failed: ${message}`)]);
    }
  };

  const handleOnDeleteAll = async () => {
    setOperations([{ status: 'loading' }]);

    try {
      const result = await deleteAllLIProfiles();
      setAction(undefined);
      setOperations([]);
      if (result) {
        setDeletedProfilesCount(result['deletedLIProfilesCount']);
        setDeleteAllProfilesModalOpen(true);
      }
    } catch (error) {
      const message = error instanceof Error ? error.message : `${error}`;
      setOperations([createFailedOperation(`Delete Profiles failed: ${message}`)]);
    }
  };

  const isProcessing = !!action || !!operations.length;
  const contentMessage =
    action === 'deleteAll'
      ? 'Deleting all profiles'
      : action === 'delete'
      ? 'Deleting profile'
      : `Fetching profile by ${searchField}`;
  const disableSubmit = !fieldValue.length;

  return (
    <ViewFrame
      header={
        <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'center' }}>
          <Selector
            label="Find By"
            options={seachFieldOptions}
            value={searchField}
            setValue={(val) => setSearchField(val as SearchField)}
            sx={{ minWidth: 210 }}
          />
          <TextField
            label={`Profile ${searchField}`}
            placeholder={`Profile ${searchField}`}
            size="medium"
            onChange={(e) => setFieldValue(e.target.value)}
            value={fieldValue}
            sx={{ minWidth: 250 }}
          />
          <Button variant="contained" disabled={disableSubmit} sx={{ ml: 2 }} onClick={fetcher}>
            Find
          </Button>
          <Button
            variant="contained"
            disabled={disableSubmit}
            sx={{ ml: 1 }}
            onClick={() => {
              setAction('delete');
              setDeleteConfirmModalOpen(true);
            }}
          >
            Delete
          </Button>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ marginLeft: 2, marginRight: 2 }}
          />
          <Button
            variant="contained"
            sx={{ ml: 1 }}
            onClick={() => {
              setAction('deleteAll');
              setDeleteConfirmModalOpen(true);
            }}
          >
            Delete All
          </Button>
        </Box>
      }
      contentLoader={{
        message: contentMessage,
        contentOperations: operations,
        forceClose: !isProcessing,
        onClose: () => {
          setAction('');
          setOperations([]);
        },
      }}
    >
      <StandardGrid
        dataSet={deletedLIProfiles}
        getRowId={(x) => x.id}
        filterPlaceholder="Filter the results"
        cols={[
          {
            name: 'ID',
            valueProperty: 'id',
          },
          {
            name: 'Email',
            valueProperty: 'email',
          },
          {
            name: 'Date Deleted',
            valueProperty: 'dateCreated',
            type: 'date',
          },
        ]}
      />
      <ConfirmModal
        open={deleteConfirmModalOpen}
        prompt={`Permanently delete ${
          action === 'deleteAll'
            ? 'all linkedin profiles'
            : `the linkedin profile with ${searchField}: ${fieldValue}`
        }?`}
        onClose={() => setDeleteConfirmModalOpen(false)}
        onAccept={() => {
          if (action === 'deleteAll') {
            handleOnDeleteAll();
          } else {
            handleOnDelete();
          }
        }}
      />
      <Modal
        title="Delete all profiles"
        open={deleteAllProfilesModalOpen}
        onClose={() => setDeleteAllProfilesModalOpen(false)}
      >
        <DialogContentText>{`Total profiles deleted: ${deletedProfilesCount}`}</DialogContentText>
      </Modal>
    </ViewFrame>
  );
};
