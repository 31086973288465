import { Box, Button } from '@mui/material';
import { FormFields } from 'components/form/FormFields';
import { ChangeEvent, useCallback, useMemo, useReducer, useState } from 'react';
import { requiredField } from 'utils/form';
import { ColumnContent } from '../columns/ColumnContent';
import { ConfirmModal } from 'components/modals/ConfirmModal';

const getToggleDefaultValue = ({ formInput, field }: FormValueProps<SkuV2MetaState>) => {
  return {
    defaultValue: formInput[field as keyof ISkuV2] || false,
  };
};

const validationFields = ['skuId', 'name'];

const column1Fields: FormFieldInfo[] = [
  {
    name: 'skuId',
    label: 'Sku Id',
  },
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'index',
    label: 'Index',
  },
  {
    type: 'number',
    name: 'msrp',
    label: 'MSRP',
  },
  {
    type: 'number',
    name: 'emailsPerMonth',
    label: 'Emails Per Month',
  },
  {
    type: 'number',
    name: 'exportPerMonth',
    label: 'Export Per Month',
  },
  {
    type: 'number',
    name: 'cseQueryLimit',
    label: 'CSE Query Limit',
  },
  {
    type: 'number',
    name: 'snailMailLimit',
    label: 'Snail Mail Limit',
  },
  {
    name: 'otherNetworks',
    label: 'Other Networks',
  },
  {
    type: 'number',
    name: 'freeTrialTime',
    label: 'Free Trial Time',
  },
  {
    type: 'date',
    name: 'expiryDate',
    label: 'Expiry Date',
  },
];

const column2Fields: FormFieldInfo[] = [
  {
    type: 'toggle',
    name: 'customPowerFilters',
    label: 'Custom Power Filters',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'exportableInsights',
    label: 'Exportable Insights',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'customInsights',
    label: 'Custom Insights',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'startUpPowerFilters',
    label: 'Startup Power Filters',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'ladderLevelsPowerFilters',
    label: 'Ladder Levels Power Filters',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'alumniPowerFilters',
    label: 'Alumni Power Filters',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'enhancedSecurityClearancePowerFilters',
    label: 'Enhanced Security Clearance Power Filters',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'selfServecustomTagsOrStatuses',
    label: 'Self Serve Custom Tags Or Statuses',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'orgWideProjectSharing',
    label: 'Org Wide Project Sharing',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'enterpriseProjectVisibility',
    label: 'Enterprise Project Visibility',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'likelyToMove',
    label: 'Likely To Move',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'sharedMessagingTemplates',
    label: 'Shared Messaging Templates',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'chatGPTSearchAndMessage',
    label: 'Chat GPT Search And Message',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'conversationalSearch',
    label: 'Conversational Search',
    getDefaultValue: getToggleDefaultValue,
  },
  {
    type: 'toggle',
    name: 'searchLanding',
    label: 'Search Landing',
    getDefaultValue: getToggleDefaultValue,
  },
];

interface Props extends FormProps<ISkuV2> {
  disabled?: boolean;
}

interface SkuV2MetaState extends FormState, Partial<ISkuV2> {}

const skuV2Reducer = (state: SkuV2MetaState, newState: SkuV2MetaState) => ({
  ...state,
  ...newState,
});

/**
 * A form to create/edit v2 sku
 */
export const SkuV2Form = ({
  onSubmit,
  onCancel,
  sx,
  initialValues,
  disabled,
}: Props): JSX.Element => {
  const [formErrors, setFormErrors] = useState({} as FormErrors);
  const [formInput, setFormInput] = useReducer(skuV2Reducer, { ...(initialValues || {}) });
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const validateForm = useCallback(
    (fieldValues: Record<string, unknown>, isSubmit = false) => {
      if (isSubmit) {
        // Do full form validation
        validationFields.forEach((field) => {
          formErrors[field] = requiredField(fieldValues, field);
        });
      } else {
        Object.entries(fieldValues)
          .filter(([key]) => validationFields.includes(key))
          .forEach(([key]) => {
            formErrors[key] = requiredField(fieldValues, key);
          });
      }

      setFormErrors({ ...formErrors });
      return Object.values(formErrors).every((x) => x === '');
    },
    [formErrors]
  );

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const fieldValue: Record<string, unknown> = {};
    let newValue;
    switch (e.target.type) {
      case 'number':
      case 'date':
        newValue = parseInt(e.target.value);
        break;

      case 'checkbox':
        newValue = e.target.checked;
        break;

      default:
        newValue = e.target.value;
        break;
    }

    if (Object.keys(fieldValue).length === 0) {
      fieldValue[name] = newValue;
    }
    setFormInput(fieldValue);
    validateForm(fieldValue);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm(formInput, true)) return;
    onSubmit(formInput as ISkuV2);
  };

  const columnFields = (formFields: FormFieldInfo[]) => {
    return FormFields<ISkuV2>({
      formFields,
      formInput: formInput as ISkuV2,
      formErrors: formErrors,
      handleInput: handleInput,
    });
  };

  const isValidForm = useMemo(() => {
    return Object.values(formErrors).every((x) => x === '');
  }, [formErrors]);

  const col1Fields = useMemo(() => {
    const fields = [...column1Fields];
    const index = fields.findIndex((o) => o.name === 'skuId');
    if (index > -1) {
      fields[index].additionalProps = { disabled: !!initialValues?.id };
    }
    return fields;
  }, [initialValues]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        width: '100%',
        margin: (theme) => theme.spacing(0, 'auto', 4, 'auto'),
        ...sx,
      }}
      autoComplete="off"
    >
      <ColumnContent
        columns={[<>{columnFields(col1Fields)}</>, <>{columnFields(column2Fields)}</>]}
        growWidth
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <Button sx={{ mr: 2 }} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant={!isValidForm ? 'outlined' : 'contained'}
          color={!isValidForm ? 'error' : undefined}
          sx={{ width: 125 }}
          disabled={disabled}
        >
          Save
        </Button>
        {initialValues?.id ? (
          <Button
            variant={'outlined'}
            sx={{ ml: 2 }}
            disabled={disabled}
            onClick={() => setConfirmModalOpen(true)}
          >
            Upgrade to V3
          </Button>
        ) : null}
      </Box>
      <ConfirmModal
        open={confirmModalOpen}
        prompt={`Are you sure you want to upgrade "${initialValues?.name}" Sku to V3 Sku? This action is irreversible.`}
        onClose={() => setConfirmModalOpen(false)}
        onAccept={() => {
          const input = {
            ...formInput,
            upgrade: true,
          };
          setConfirmModalOpen(false);
          onSubmit(input as ISkuV2);
        }}
      />
    </Box>
  );
};
