import { Box, Button } from '@mui/material';
import { FormFields } from 'components/form/FormFields';
import dayjs from 'dayjs';
import { ChangeEvent, useCallback, useMemo, useReducer, useState } from 'react';
import { requiredField } from 'utils/form';
import { getRoiReportTypeOptions } from 'utils/selectorOptions';
import { ColumnContent } from './columns/ColumnContent';

const getDropdownDefaultValue = ({ formInput, field }: FormValueProps<RoiReportState>) => {
  return {
    defaultValue: `${formInput[field as keyof IROIReportInput] || ''}`,
  };
};

const validationFields = ['reportType'];
const column1Fields: FormFieldInfo[] = [
  {
    type: 'file',
    name: 'hireDataFiles',
    label: 'Choose Hire Data File',
    additionalProps: {
      accept: '.csv',
      sx: {
        flexDirection: 'column',
        minHeight: 80,
      },
    },
  },
  {
    type: 'date',
    name: 'hiredStartDate',
    label: 'Hired Start Date',
  },
  {
    type: 'date',
    name: 'hiredEndDate',
    label: 'Hired End Date',
  },
];

const column2Fields: FormFieldInfo[] = [
  {
    type: 'dropdown',
    name: 'reportType',
    label: 'Report Type',
    options: getRoiReportTypeOptions(),
    getDefaultValue: getDropdownDefaultValue,
  },
  {
    type: 'date',
    name: 'seekOutStartDate',
    label: 'SeekOut Activity Start Date',
  },
  {
    type: 'date',
    name: 'seekOutEndDate',
    label: 'SeekOut Activity End Date',
  },
];

interface RoiReportState extends FormState, Partial<IROIReportInput> {}

const roiReportReducer = (state: RoiReportState, newState: RoiReportState) => ({
  ...state,
  ...newState,
});

/**
 * A form to create roi report
 */
export const CreateRoiReportForm = ({
  onSubmit,
  onCancel,
  sx,
  initialValues,
}: FormProps<IROIReportInput>): JSX.Element => {
  const [formErrors, setFormErrors] = useState({} as FormErrors);
  const [formInput, setFormInput] = useReducer(roiReportReducer, { ...(initialValues || {}) });

  const validateForm = useCallback(
    (fieldValues: Record<string, unknown>, isSubmit = false) => {
      if (isSubmit) {
        // Do full form validation
        validationFields.forEach((field) => {
          formErrors[field] = requiredField(fieldValues, field);
        });
      } else {
        Object.entries(fieldValues)
          .filter(([key]) => validationFields.includes(key))
          .forEach(([key]) => {
            formErrors[key] = requiredField(fieldValues, key);
          });
      }

      setFormErrors({ ...formErrors });
      return Object.values(formErrors).every((x) => x === '');
    },
    [formErrors]
  );

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const fieldValue: Record<string, unknown> = {};
    let newValue;
    switch (e.target.type) {
      case 'file':
        newValue = e.target.files;
        break;

      case 'date':
        newValue = parseInt(e.target.value);
        break;

      default:
        newValue = e.target.value;
        break;
    }

    if (Object.keys(fieldValue).length === 0) {
      fieldValue[name] = newValue;
    }
    setFormInput(fieldValue);
    validateForm(fieldValue);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm(formInput, true)) return;
    const submitFormInput = { ...formInput };
    if (formInput.seekOutStartDate) {
      submitFormInput.seekOutStartDate = dayjs(formInput.seekOutStartDate, { utc: true }).format();
    }
    if (formInput.seekOutEndDate) {
      submitFormInput.seekOutEndDate = dayjs(formInput.seekOutEndDate, { utc: true }).format();
    }
    if (formInput.hiredStartDate) {
      submitFormInput.hiredStartDate = dayjs(formInput.hiredStartDate, { utc: true }).format();
    }
    if (formInput.hiredEndDate) {
      submitFormInput.hiredEndDate = dayjs(formInput.hiredEndDate, { utc: true }).format();
    }
    onSubmit(submitFormInput as IROIReportInput);
  };

  const columnFields = (formFields: FormFieldInfo[]) => {
    return FormFields<IROIReportInput>({
      formFields,
      formInput: formInput as IROIReportInput,
      formErrors: formErrors,
      handleInput: handleInput,
    });
  };

  const isValidForm = useMemo(() => {
    return Object.values(formErrors).every((x) => x === '');
  }, [formErrors]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        width: '100%',
        margin: (theme) => theme.spacing(0, 'auto', 4, 'auto'),
        ...sx,
      }}
      autoComplete="off"
    >
      <ColumnContent
        columns={[<>{columnFields(column1Fields)}</>, <>{columnFields(column2Fields)}</>]}
        growWidth
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <Button sx={{ mr: 2 }} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant={!isValidForm ? 'outlined' : 'contained'}
          color={!isValidForm ? 'error' : undefined}
          sx={{ width: 125 }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
