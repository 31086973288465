import { useState } from 'react';

import { useClearCreateNewUsersOperations, useCreateNewUsers } from 'api/apiBulkThunks';
import { useClearAction, useSkuFetch } from 'api/apiMetaThunks';
import { selectDerivedOrganization } from 'api/organizationsSlice';
import { selectDerivedSystem } from 'api/systemSlice';
import { useAppSelector } from 'app/hooks';
import { StandardGrid } from 'components/grids/StandardGrid';
import { UploadMappingHeader } from 'components/headers/UploadMappingHeader';
import { ViewFrame } from 'features/frame/ViewFrame';
import { asFullName, asSplitName } from 'utils/convert';
import { getOperations } from 'utils/operable';
import { getSFOrderItemsOperations } from 'utils/organization';
import {
  getFreeTrialSkuSelectionOptions,
  getLicenseSelectionOptions,
  getTeamOptions,
} from 'utils/selectorOptions';
import { usePrivilegeSelector } from 'utils/usePrivilegeSelector';
import { Privilege } from 'types/enums';

/**
 * The organization create users view
 */
export const CreateUsers = (): JSX.Element => {
  // Upload Data
  const [uploadValues, setUploadValues] = useState<CreateUserDef[]>([]);
  const [uploadOperations, setUploadOperations] = useState<Operation[]>([]);

  // Processing values
  const [{ createDefs }, setAction] = useState<{ createDefs?: CreateUserDef[] }>({});

  // State selectors
  const system = useAppSelector(selectDerivedSystem);
  const org = useAppSelector(selectDerivedOrganization);
  const adminPrivilege = usePrivilegeSelector();
  const viewOperations = [
    ...useSkuFetch(system),
    ...getOperations(org, ['users', 'teams']),
    ...getSFOrderItemsOperations(org),
  ];
  const contentOperations = [
    ...uploadOperations,
    ...useCreateNewUsers(org, createDefs ?? [], true),
  ];

  const clearTrigger = !!createDefs?.length && !contentOperations.length;
  useClearCreateNewUsersOperations(org.orgId, clearTrigger);
  useClearAction(setAction, clearTrigger);

  if (!org.orgId) return <></>;

  const { skuV1 = [], skuV2 = [] } = system;
  const { orgId, teams = [], licenses = [], createdUsers } = org;

  const teamOptions = getTeamOptions(teams);
  const licenseOptions = [
    ...getLicenseSelectionOptions(licenses),
    ...(adminPrivilege === Privilege.All ? getFreeTrialSkuSelectionOptions(skuV1, skuV2) : []),
  ];

  if (createdUsers) {
    // Fill in activation links from server response
    uploadValues.forEach((user) => {
      if (user.id) {
        user.fullActivationLink = createdUsers[user.id]?.fullActivationLink;
      }
    });
  }

  const { toBeCreated, message, disableSubmit } = validations(org, uploadValues);
  const isProcessing = !!createDefs?.length || !!uploadOperations.length;
  const enableSubmit = !isProcessing && !disableSubmit;
  const contentMessage = uploadOperations.length ? 'Uploading CSV' : 'Creating Users';

  return (
    <ViewFrame
      viewLoader={{
        message: 'Loading users, teams, and licenses for the organization',
        viewOperations,
      }}
      header={
        <UploadMappingHeader
          onUploadOperation={(ops) => {
            // If a single successful operation, then translate to [] (no operation)
            ops = ops.length === 1 && ops[0]?.status === 'succeeded' ? [] : ops;
            setUploadOperations(ops);
          }}
          onChange={(values) => {
            // Translate header values to CreateUserDef
            setUploadValues(
              values.map((mappedRow, index) => {
                const { firstName, lastName } = asSplitName(mappedRow['Name']);
                const email = mappedRow['Email']?.toLowerCase();
                const userType = mappedRow['Team Admin'] === 'true' ? 'admin' : 'standard';
                const sendActivationEmail = mappedRow['Send Activation Email'] === 'true';
                const teamId = mappedRow['Team'];
                const selectedLicense = mappedRow['License'];

                const isLicenseSelected = selectedLicense !== 'none';
                const isLicense =
                  isLicenseSelected && licenses.some((x) => x.orderItemId === selectedLicense);
                const orderItemId = isLicense ? selectedLicense : undefined;
                const skuId = isLicenseSelected && !isLicense ? selectedLicense : undefined;

                return {
                  // Used client-side, but discarded for a real guid when submitted
                  id: `${email}_${index}`,

                  // INewUser fields
                  orgId,
                  firstName,
                  lastName,
                  email,
                  teamId,
                  userType,
                  skuId,

                  // Fields for secondary calls
                  orderItemId,
                  sendActivationEmail,
                };
              })
            );
          }}
          onSubmit={() => setAction({ createDefs: toBeCreated })}
          fields={[
            {
              name: 'Name',
              description: 'Full name of new user',
              validation: 'string',
            },
            {
              name: 'Email',
              description: 'Email of new user',
              validation: 'email',
            },
            {
              name: 'Team',
              description: "The user's team",
              default: 'not_on_a_team',
              validation: 'string',
              options: teamOptions,
            },
            {
              name: 'License',
              description: 'The license to apply',
              default: 'none',
              validation: 'string',
              options: licenseOptions,
            },
            {
              name: 'Team Admin',
              description: 'Is a team admin instead of a standard user',
              default: 'false',
              validation: 'boolean',
              options: true,
            },
            {
              name: 'Send Activation Email',
              description: 'Send an activation email after user creation',
              default: 'false',
              validation: 'boolean',
              options: true,
            },
          ]}
          disabled={isProcessing}
          canSubmit={enableSubmit}
          confirmMessage={`Create ${toBeCreated.length} users?`}
          warningMessage={message}
          submitText="Create Users"
          csvTemplate="Bulk-Upload-New-Users-Template"
        />
      }
      contentLoader={{
        message: contentMessage,
        contentOperations,
        forceClose: !isProcessing,
        onClose: () => {
          setAction({});
          setUploadOperations([]);
        },
      }}
    >
      <StandardGrid
        dataSet={toBeCreated}
        tipModel="NewUser"
        getRowId={(x) => x.id ?? 'placeholder'}
        filterPlaceholder="Filter the users to create. (Warning: filtered-out users will still be created)"
        cols={[
          {
            name: 'Name',
            valueProperty: 'firstName',
            getValue: (x) => asFullName(x.firstName, x.lastName),
          },
          {
            name: 'Email',
            valueProperty: 'email',
            type: 'email',
          },
          {
            name: 'Team',
            valueProperty: 'teamId',
            getValue: (x) => teams.find((team) => team.id === x.teamId)?.name || 'None',
          },
          {
            name: 'License',
            getValue: (x) => {
              const id = x.orderItemId ?? x.skuId;
              if (id) {
                return licenseOptions.find((license) => license.value === id)?.text;
              }
              return 'None';
            },
          },
          {
            name: 'Team Admin',
            valueProperty: 'userType',
            getValue: (x) => x.userType === 'admin',
            type: 'boolean',
          },
          {
            name: 'Send Activation Email',
            valueProperty: 'sendActivationEmail',
            type: 'boolean',
          },
          {
            name: 'Activation Link',
            valueProperty: 'fullActivationLink',
            relativeWidth: 3,
          },
        ]}
      />
    </ViewFrame>
  );
};

/**
 * Perform validation on the desired users
 * - Extra spaces in CSV file -> All csv values are trimmed before use in UploadMappingHeader
 * - Mismatched email casing -> All emails are lowercased before comparisons or creates
 * - Duplicate email rows -> Removed
 * - Email already taken by a user in this org -> Removed
 * - Email domain doesn't match the domains registered to the organization -> Warning
 * - Not enough licenses available -> Disable submit
 */
function validations(org: DerivedOrganization, desiredUsers: CreateUserDef[]) {
  const { org: orgSettings, users = [], licenses = [] } = org;
  const { domain, ssoDomain1, ssoDomain2 } = orgSettings ?? {};

  let message = '';

  const removeDupeEmails = desiredUsers.filter(
    (x, index) => desiredUsers.findIndex((y) => x.email === y.email) === index
  );
  const removeDupeEmailCount = desiredUsers.length - removeDupeEmails.length;
  if (removeDupeEmailCount) {
    message += `Removed rows for ${removeDupeEmailCount} duplicate emails. `;
  }

  const knownEmails = new Set(
    users.filter((x) => !!x.emailAddress).map((x) => x.emailAddress?.toLowerCase())
  );
  const removeKnownEmails = removeDupeEmails.filter((x) => x.email && !knownEmails.has(x.email));
  const knownEmailCount = removeDupeEmails.length - removeKnownEmails.length;
  if (knownEmailCount) {
    message += `Removed rows for ${knownEmailCount} already-used emails. `;
  }

  const toBeCreated = removeKnownEmails;

  const mismatchDomains = toBeCreated
    .filter((x) => {
      return (
        x.email &&
        domain &&
        !x.email.includes(domain) &&
        (!ssoDomain1 || !x.email.includes(ssoDomain1)) &&
        (!ssoDomain2 || !x.email.includes(ssoDomain2))
      );
    })
    .map((x) => x.email);

  if (mismatchDomains.length) {
    message += `Warning: ${mismatchDomains.length} emails do not match the organization's domains ("${mismatchDomains[0]}") `;
  }

  let notEnoughLicenses = false;
  const orderItemIds = toBeCreated.filter((x) => !!x.orderItemId).map((x) => x.orderItemId);
  const orderItemIdCounts: Record<string, number> = {};

  for (const orderItemId of orderItemIds) {
    if (orderItemId) {
      if (!orderItemIdCounts[orderItemId]) {
        orderItemIdCounts[orderItemId] = 0;
      }
      orderItemIdCounts[orderItemId]++;
    }
  }

  for (const license of licenses) {
    const count = orderItemIdCounts[license.orderItemId] ?? 0;
    if (license.quantityLeft && count > license.quantityLeft) {
      message += `Error: This requires ${count} ${license.skuName} licenses, but only ${license.quantityLeft} are available `;
      notEnoughLicenses = true;
    }
  }

  return {
    toBeCreated,
    message,
    disableSubmit: !toBeCreated.length || notEnoughLicenses,
  };
}
