import ClearIcon from '@mui/icons-material/Clear';
import RefreshIcon from '@mui/icons-material/Refresh';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { clearUserCache } from 'api/usersSlice';
import { clearOrganizationCache } from 'api/organizationsSlice';
import { clearSystemCache } from 'api/systemSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  removeLookupTab,
  selectCurrentLookupTabIndex,
  selectLookupTabs,
  setCurrentLookupTabIndex,
} from './appViewSlice';
import { getLookupDisplayText } from 'utils/lookup';
import { clearNewUserCache } from 'api/newUsersSlice';

const TabName = styled('div')({
  alignSelf: 'center',
  width: '100%',
});

const TabContent = styled('span')({
  display: 'flex',
  alignSelf: 'flex-start',
  width: '100%',
});

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

interface LookupTabsProps {
  onClick?: () => void;
}

/**
 * The left pane where the different lookup tabs live
 */
export const LookupTabs = ({ onClick }: LookupTabsProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const tabs = useAppSelector(selectLookupTabs);
  const displayTabs = tabs.map(getLookupDisplayText);
  const currentIndex = useAppSelector(selectCurrentLookupTabIndex);

  const clearNonSystemCaches = (lookup: Lookup) => {
    dispatch(clearOrganizationCache(lookup));
    dispatch(clearUserCache(lookup));
    dispatch(clearNewUserCache(lookup));
  };

  return (
    <Tabs
      sx={{
        height: '100%',
        maxWidth: '200px',
        borderRight: (theme) => `2px solid ${theme.palette.divider}`,
      }}
      orientation="vertical"
      variant="scrollable"
      value={currentIndex}
      onChange={(_, value) => {
        dispatch(setCurrentLookupTabIndex(value));
        if (onClick) onClick();
      }}
      aria-label="lookup tabs"
      textColor="primary"
      indicatorColor="primary"
    >
      {displayTabs.map((name, index) => (
        <Tab
          key={tabs[index].id || name}
          label={
            <TabContent>
              {name !== 'System' && (
                <IconButton
                  title="Remove"
                  aria-label="Remove"
                  size="small"
                  sx={{ padding: 0 }}
                  onClick={() => {
                    clearNonSystemCaches(tabs[index]);
                    dispatch(removeLookupTab(index));
                  }}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              )}
              <TabName>{name}</TabName>
              <IconButton
                title="Refresh"
                aria-label="Refresh"
                size="small"
                sx={{ padding: 0 }}
                onClick={() => {
                  if (name === 'System') {
                    dispatch(clearSystemCache());
                  } else {
                    clearNonSystemCaches(tabs[index]);
                  }
                }}
              >
                <RefreshIcon fontSize="small" />
              </IconButton>
            </TabContent>
          }
          sx={{ padding: (theme) => theme.spacing(1), overflowWrap: 'anywhere' }}
          {...a11yProps(index)}
        />
      ))}
    </Tabs>
  );
};
