import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

import { MapTable } from 'components/columns/MapTable';

interface Props {
  open: boolean;
  title: string;
  data: Record<string, unknown>;
  onClose: () => void;
  model?: string;
}

/**
 * A modal dialog with a key/value content
 */
export const ColumnModal = ({ data, onClose, open, title, model }: Props) => {
  return (
    <Dialog onClose={onClose} open={open} maxWidth="lg">
      <DialogTitle>{title}</DialogTitle>
      <MapTable
        defaultModel={model}
        defaultValue={data}
        data={
          // Transform to handle typing change
          Object.fromEntries(Object.keys(data).map((key) => [key, key]))
        }
      />
    </Dialog>
  );
};
