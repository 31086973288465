import { ColumnContent } from 'components/columns/ColumnContent';
import { MapTable } from 'components/columns/MapTable';
import { useMemo } from 'react';
import { getLicenseSummary, getOrderSummary } from 'utils/organization';

interface Props {
  sfOrders: IActiveOrder[];
  licenses: License[];
}

/**
 * A component to display an organization's salesforce orders and licenses
 */
export const OrgLicenses = ({ sfOrders, licenses }: Props): JSX.Element => {
  const orderSummary = useMemo(() => getOrderSummary(sfOrders), [sfOrders]);
  const licenseSummary = useMemo(() => getLicenseSummary(licenses), [licenses]);

  return (
    <>
      <ColumnContent
        columns={[
          <>{!!sfOrders.length && <MapTable title="Orders" data={orderSummary} />}</>,
          <>{!!licenses.length && <MapTable title="Licenses" data={licenseSummary} />}</>,
        ]}
        growWidth
      />
    </>
  );
};
