import { useEffect } from 'react';

import { getMailboxConfigs } from 'api/apiThunks';
import { selectDerivedUser } from 'api/usersSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { StandardGrid } from 'components/grids/StandardGrid';
import { ViewFrame } from 'features/frame/ViewFrame';
import { getOperations } from 'utils/operable';

/**
 * The User Messaging view
 */
export const Messaging = (): JSX.Element => {
  const user = useAppSelector(selectDerivedUser);
  const viewOperations = getOperations(user, ['mailboxConfigs']);
  const [mailboxConfigsOp] = viewOperations;
  const { userId, mailboxConfigs = [] } = user;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userId && !mailboxConfigsOp) {
      dispatch(getMailboxConfigs(userId));
    }
  }, [dispatch, userId, mailboxConfigsOp]);

  if (!userId) return <></>;

  return (
    <ViewFrame viewLoader={{ message: 'Fetching email accounts and sequences', viewOperations }}>
      <StandardGrid
        dataSet={mailboxConfigs}
        tipModel="MailboxConfig"
        getRowId={(x) => x.id}
        filterPlaceholder="Filter the mailbox configs"
        cols={[
          {
            name: 'ID',
            description: 'The mailbox configuration id',
            valueProperty: 'id',
            relativeWidth: 0.5,
          },
          {
            name: 'Email',
            description: 'The email address',
            valueProperty: 'emailAddress',
            type: 'email',
          },
          {
            name: 'Account Type',
            description: 'Is account connected to Sandesh or Nylas',
            getValue: (x) => (x.tokenType === 'seekout' ? 'Sandesh' : 'Nylas'),
            relativeWidth: 0.5,
          },
          {
            name: 'Default',
            description: 'Is this the default mailbox?',
            valueProperty: 'isDefault',
            type: 'boolean',
            relativeWidth: 0.5,
          },
          {
            name: 'Provider',
            valueProperty: 'provider',
            relativeWidth: 0.5,
          },
          {
            name: 'Connection Type',
            description: 'Is it Own or Send-On-Behalf-Of connection',
            getValue: (x) => (x.ownerId === userId ? 'Own' : 'Send on Behalf'),
            relativeWidth: 0.5,
          },
          {
            name: 'SeekOut Sync State',
            description: 'The account sync state in SeekOut',
            getValue: (x) =>
              x.authErrorReportMessage ? `Error: ${x.authErrorReportMessage}` : 'Connected',
            relativeWidth: 0.5,
          },
          {
            name: 'Sync State',
            description: 'The account sync state in Sandesh / Nylas',
            valueProperty: 'nylasSyncState',
            relativeWidth: 0.5,
          },
          {
            name: 'Date Created',
            description: 'The date the config was created',
            valueProperty: 'dateCreated',
            type: 'date',
            relativeWidth: 0.6,
          },
          {
            name: 'Properties',
            description: 'The full set of properties on the mailbox config',
            innerModel: 'MailboxConfig',
            getValue: (x) => x,
            type: 'blob',
            relativeWidth: 0.5,
          },
        ]}
      />
    </ViewFrame>
  );
};
