import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useState } from 'react';

import { ValidationTextField } from 'components/ValidationTextField';

interface Props {
  /** The label of the text field */
  label: string;
  /** The tooltip of the text field */
  tooltip: string;
  /** The type of validation to use */
  validationType: Parameters<typeof ValidationTextField>[0]['validationType'];
  /** The label of the submit button */
  submitText: string;
  /** The function to run when the submit button is clicked */
  onSubmit: (val: string) => void;
}

/**
 * A header component with a validation text field and an action button
 */
export const TextControlHeader = ({
  label,
  tooltip,
  validationType,
  submitText,
  onSubmit,
}: Props): JSX.Element => {
  const [text, setText] = useState('');
  const [isValid, setIsValid] = useState(false);

  return (
    <Box>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (onSubmit) onSubmit(text);
        }}
      >
        <ValidationTextField
          label={label}
          size="small"
          tooltip={tooltip}
          validationType={validationType}
          value={text}
          onChange={(val, isValid) => {
            setText(val);
            setIsValid(isValid);
          }}
          sx={{
            width: '50%',
            verticalAlign: 'baseline',
            margin: (theme) => theme.spacing(1),
          }}
        />
        <Button variant="outlined" disabled={!isValid} onClick={() => onSubmit(text)}>
          {submitText}
        </Button>
      </form>
    </Box>
  );
};
