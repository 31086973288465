export const ActivityTypes = [
  'email_account',
  'email_sequence',
  'email_step',
  'email_sequence_user',
  'email_step_template',
  'change_status',
  'copy_sequence',
  'set_bcc',
  'email_preference',
  'email_signature',
  'email_default',
  'email_message_limit',
  'email_auto_skip',
  'email_track_opens',
];

export const TimespanOptions = [
  { value: 'PT30M', text: 'Last 30 minutes' },
  { value: 'PT1H', text: 'Last hour' },
  { value: 'PT4H', text: 'Last 4 hours' },
  { value: 'PT12H', text: 'Last 12 hours' },
  { value: 'PT24H', text: 'Last 24 hours' },
  { value: 'PT48H', text: 'Last 48 hours' },
  { value: 'P3D', text: 'Last 3 days' },
  { value: 'P7D', text: 'Last 7 days' },
  { value: 'P30D', text: 'Last 30 days' },
] as const;
