import { useCallback, useEffect, useMemo, useState } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';

import { deleteUser, useSkuFetch } from 'api/apiMetaThunks';
import {
  applySkuOrAddon,
  cancelSubscription,
  getPasswordResetUrl,
  verifyUser,
} from 'api/apiThunks';
import { clearOperations, selectDerivedUser } from 'api/usersSlice';
import { selectDerivedSystem } from 'api/systemSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ColumnContent } from 'components/columns/ColumnContent';
import { MapTable } from 'components/columns/MapTable';
import { ConfirmModal } from 'components/modals/ConfirmModal';
import { Selector } from 'components/Selector';
import { OpenLookupButton } from 'components/OpenLookupButton';
import { addLookupTab } from 'features/frame/appViewSlice';
import { ViewFrame } from 'features/frame/ViewFrame';
import { getSkuAddonSelectionOptions, getSkuSelectionOptions } from 'utils/selectorOptions';
import { getOperations } from 'utils/operable';
import { IconButton } from '@mui/material';
import VerifyIcon from '@mui/icons-material/VerifiedOutlined';
import { Modal } from 'components/modals/Modal';
import { UserFieldForm } from 'components/UserFieldForm';
import { usePrivilegeSelector } from 'utils/usePrivilegeSelector';
import { Privilege } from 'types/enums';
import { Tip } from 'components/Tip';
import { CancelSubscriptionModal } from 'components/user/CancelSubscriptionModal';

const fieldTypes: Record<string, string> = {
  'Sku.id': 'dropdown',
};
interface UserField {
  userFieldInfo: UserFieldInfo;
  fieldInfo: FormFieldInfo;
  isRequired?: boolean;
  initialValues?: Record<string, FormFieldValue>;
}

/**
 * The user summary view
 */
export const Summary = (): JSX.Element => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmCancelModalOpen, setConfirmCancelModalOpen] = useState(false);
  const [operationType, setOperationType] = useState<UserOpName>();
  const [operationKey, setOperationKey] = useState('');
  const [selectedSkuAddon, setSelectedSkuAddon] = useState('');
  const [showPasswordResetLink, setShowPasswordResetLink] = useState(true);
  const [updateFieldModalOpen, setUpdateFieldModalOpen] = useState(false);
  const [editUserField, setEditUserField] = useState<UserField>();

  const system = useAppSelector(selectDerivedSystem);
  const adminPrivilege = usePrivilegeSelector();
  const { skuV1 = [], skuV2 = [] } = system;
  useSkuFetch(system);

  const derivedUser = useAppSelector(selectDerivedUser);
  const contentOperations = operationType ? getOperations(derivedUser, operationType) : [];
  const [contentOp] = contentOperations;
  const {
    userId,
    email = '',
    user,
    recruiter,
    quota,
    smbSubscription,
    passwordResetUrl,
  } = derivedUser;

  const skuAddonOptions = getSkuAddonSelectionOptions(skuV1);
  const applySkuOptions = useMemo(() => {
    return getSkuSelectionOptions(skuV1, skuV2);
  }, [skuV1, skuV2]);

  const getSkuName = (skuSelectOptions: SelectOption[], skuId: string) => {
    return skuSelectOptions.find((x) => x.value == skuId)?.text || '';
  };

  const dispatch = useAppDispatch();

  const clearOperation = useCallback(() => {
    if (userId && operationType) {
      dispatch(clearOperations({ userId, opNames: [operationType] }));
      setOperationType(undefined);
    }
  }, [dispatch, userId, operationType]);

  useEffect(() => {
    if (operationType === 'delete') {
      if (userId && operationKey === userId) {
        deleteUser(dispatch, derivedUser);
      } else {
        clearOperation();
      }
    }
  }, [dispatch, userId, derivedUser, operationType, operationKey, clearOperation]);

  useEffect(() => {
    if (operationType === 'passwordResetUrl') {
      if (!contentOp) {
        dispatch(
          getPasswordResetUrl({
            userId,
            value: email,
          })
        );
      } else if (contentOp.status === 'succeeded') {
        clearOperation();
      }
    }
  }, [dispatch, userId, email, operationType, contentOp, clearOperation]);

  useEffect(() => {
    if (operationType === 'applySkuOrAddon') {
      if (!contentOp) {
        const skuName = getSkuName(skuAddonOptions, operationKey);
        dispatch(applySkuOrAddon({ userId, skuId: operationKey, skuName, isAddon: true }));
      } else if (contentOp.status === 'succeeded') {
        setOperationKey('');
        clearOperation();
      }
    }
  }, [dispatch, userId, operationType, operationKey, contentOp, skuAddonOptions, clearOperation]);

  useEffect(() => {
    if (operationType === 'verifyUser') {
      if (!contentOp) {
        dispatch(verifyUser(userId || ''));
      } else if (contentOp.status === 'succeeded') {
        clearOperation();
      }
    }
  }, [dispatch, userId, operationType, operationKey, contentOp, clearOperation]);

  useEffect(() => {
    if (
      (operationType === 'updateRecruiter' || operationType === 'cancelSubscription') &&
      contentOp?.status === 'succeeded'
    ) {
      clearOperation();
    }
  }, [dispatch, operationType, contentOp, clearOperation]);

  const verifiedFields = useMemo(() => {
    const verified = [];
    if (user?.userStates?.isEmailVerified) {
      verified.push('Email');
    }
    if (user?.userStates?.isPhoneVerified) {
      verified.push('Phone');
    }
    return verified;
  }, [user]);

  if (!userId) return <></>;

  const handleEditField = (info: UserFieldInfo) => {
    const { label, keyModel, keyProperty, value } = info;
    const labelText = keyModel !== 'Recruiter' ? `${keyModel} ${label}` : label;
    const name = keyProperty || label;
    const fieldKey = `${keyModel}.${keyProperty}`;
    const type = fieldTypes[fieldKey];
    const fieldInfo: FormFieldInfo = {
      label: labelText,
      name,
      type,
      additionalProps: { sx: { width: '100%' } },
    };

    if (type === 'dropdown' && fieldKey === 'Sku.id') {
      fieldInfo.options = applySkuOptions;
    }

    setEditUserField({
      userFieldInfo: info,
      fieldInfo,
      initialValues: {
        [name]: value as FormFieldValue,
      },
    });
    setUpdateFieldModalOpen(true);
  };

  const handleOnModalClose = () => setUpdateFieldModalOpen(false);

  const handleOnSubmit = (formInput: Record<string, unknown>) => {
    handleOnModalClose();

    if (!editUserField) return;

    const fieldName = editUserField.userFieldInfo.keyProperty || editUserField.userFieldInfo.label;
    const fieldValue = formInput[fieldName];
    const fieldKey = `${editUserField.userFieldInfo.keyModel}.${editUserField.userFieldInfo.keyProperty}`;
    switch (fieldKey) {
      case 'Sku.id':
        if (sku?.id !== fieldValue) {
          setOperationType('applySkuOrAddon');
          const skuId = fieldValue as string;
          const skuName = getSkuName(applySkuOptions, skuId);
          dispatch(applySkuOrAddon({ userId, skuId, skuName }));
        }
        break;

      default:
        break;
    }
  };

  const handleOnCancelModalClose = () => setConfirmCancelModalOpen(false);
  const handleOnCancelSubscription = (formInput: Record<string, unknown>) => {
    handleOnCancelModalClose();
    setOperationType('cancelSubscription');
    dispatch(cancelSubscription({ userId, value: formInput.reason as string }));
  };

  const userName = user?.name;
  const sku = recruiter?.sku;

  const orgId = recruiter?.organizationId;
  const companyName = recruiter?.companyName;
  let smbSubscriptionInfo = {};
  if (smbSubscription) {
    const subscriptionStatus = `${smbSubscription?.state}${
      smbSubscription.cancel_at_end_of_period ? ' (Pending Cancellation)' : ''
    }`;
    smbSubscriptionInfo = {
      'Subscription Term': {
        value: smbSubscription?.product.product_price_point_handle,
        keyModel: 'Subscription',
        keyProperty: 'product.product_price_point_handle',
      },
      'Subscription Status': {
        value: subscriptionStatus,
        keyModel: 'Subscription',
        keyProperty: 'state',
      },
    };
  }

  let contentLoaderMessage;
  switch (operationType) {
    case 'applySkuOrAddon':
      contentLoaderMessage = `Apply ${operationKey.length > 0 ? 'add-ons' : 'sku'}`;
      break;

    case 'passwordResetUrl':
      contentLoaderMessage = 'Loading password reset URL';
      break;

    case 'delete':
      contentLoaderMessage = `Deleting user ${userName}`;
      break;

    case 'updateRecruiter':
      contentLoaderMessage = 'Updating Recruiter Settings';
      break;

    case 'verifyUser':
      contentLoaderMessage = 'Verifying User Account';
      break;

    case 'cancelSubscription':
      contentLoaderMessage = 'Canceling Maxio Subscription';
      break;

    default:
      contentLoaderMessage = '';
      break;
  }

  const editabledFields = adminPrivilege === Privilege.All ? ['Sku.id'] : [];

  return (
    <ViewFrame
      header={
        <>
          <Typography variant="h4" sx={{ mt: 1 }}>
            {userName}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'center' }}>
            <Typography variant="h5" display="inline">
              {orgId && (
                <OpenLookupButton
                  openLookup={() => dispatch(addLookupTab({ id: orgId, type: 'Organization' }))}
                  tooltip="Open lookup tab for this user's organization"
                />
              )}
              {companyName}
            </Typography>
            <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 2 }} />
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                setOperationType('passwordResetUrl');
                setShowPasswordResetLink(true);
              }}
            >
              Password Reset
            </Button>
            <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 2 }} />
            <Button
              variant="contained"
              size="small"
              disabled={!!operationType}
              onClick={() => setConfirmModalOpen(true)}
            >
              Delete User
            </Button>
            {adminPrivilege === Privilege.All &&
            smbSubscription &&
            smbSubscription.state !== 'canceled' &&
            !smbSubscription.cancel_at_end_of_period ? (
              <>
                <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 2 }} />
                <Button
                  variant="contained"
                  size="small"
                  disabled={!!operationType}
                  onClick={() => setConfirmCancelModalOpen(true)}
                >
                  <Tip title="Cancel Maxio subscription">
                    <span>Cancel Subscription</span>
                  </Tip>
                </Button>
              </>
            ) : null}
            <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 2 }} />
            <Selector
              label="Apply Add-Ons"
              options={skuAddonOptions}
              value={selectedSkuAddon}
              setValue={(value) => setSelectedSkuAddon(value as string)}
              sx={{ width: '15%' }}
            />
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                setOperationType('applySkuOrAddon');
                setOperationKey(selectedSkuAddon);
              }}
            >
              Apply
            </Button>
          </Box>
          {showPasswordResetLink && passwordResetUrl && (
            <Alert
              severity="success"
              onClose={() => setShowPasswordResetLink(false)}
              sx={{ my: 2 }}
            >
              <Link href={passwordResetUrl}>{passwordResetUrl}</Link>
            </Alert>
          )}
        </>
      }
      contentLoader={{
        message: contentLoaderMessage,
        contentOperations,
        forceClose: !operationType,
        onClose: clearOperation,
      }}
    >
      <ColumnContent
        columns={[
          <MapTable
            title="Identity"
            defaultModel="Recruiter"
            defaultValue={recruiter}
            data={{
              Email: 'primaryEmail',
              Verified: {
                value: verifiedFields.join(', '),
                keyModel: 'User',
                keyProperty: 'userStates',
              },
              Title: 'title',
              Role: 'role',
              Company: 'companyName',
              'Last Login': 'lastLoginTS',
              'User Id': 'userId',
              'Organization Id': 'organizationId',
              'Runtime API Endpoint': 'runtimeAPIEndpoint',
            }}
            valueSxProps={{
              'Runtime API Endpoint': { wordBreak: 'break-all' },
            }}
            fieldActions={{
              'User.userStates':
                verifiedFields.length < 2 ? (
                  <IconButton
                    title="Verify"
                    aria-label="Verify"
                    size="small"
                    sx={{ py: 0 }}
                    onClick={() => setOperationType('verifyUser')}
                  >
                    <VerifyIcon />
                  </IconButton>
                ) : undefined,
            }}
          />,
          <MapTable
            title="Sku"
            defaultModel="Recruiter"
            defaultValue={recruiter}
            data={{
              Name: {
                value: sku?.name,
                keyModel: 'Sku',
                keyProperty: 'name',
              },
              ID: {
                value: recruiter?.skuId || sku?.id,
                keyModel: 'Sku',
                keyProperty: 'id',
              },
              Parent: {
                value: sku?.parentSkuId,
                keyModel: 'Sku',
                keyProperty: 'parentSkuId',
              },
              ...smbSubscriptionInfo,
              Start: 'skuStartTime',
              End: 'skuExpiryTime',
              'Rollover Start': 'skuRolloverTimeStart',
              'Rollover End': 'skuRolloverTimeEnd',
              'Expert Expiry Date': 'expertAllowTS',
              'Github Expiry Date': 'githubAllowTS',
              'Dark Mode': 'darkMode',
              Disabled: 'isDisabled',
              'Alumni Filter Enabled': 'isAlumniFilterEnabled',
            }}
            editabledFields={editabledFields}
            onEditField={handleEditField}
          />,
          <MapTable
            title="Credits"
            defaultModel="Recruiter"
            defaultValue={recruiter}
            data={{
              'Last Reset': 'skuRolloverTimeStart',
              'Next Reset': 'skuRolloverTimeEnd',
              'Email Available': { value: quota?.emails.available },
              'Email Used': { value: quota?.emails.used },
              'Email Add-On': { value: quota?.emails.addOn },
              'Exports Available': { value: quota?.exports.available },
              'Exports Used': { value: quota?.exports.used },
              'Exports Add-On': { value: quota?.exports.addOn },
              'CseQueries Available': { value: quota?.cseQueries.available },
              'CseQueries Used': { value: quota?.cseQueries.used },
              'CseQueries Add-On': { value: quota?.cseQueries.addOn },
              'Snail Mail Available': { value: quota?.snailMail?.available },
              'Snail Mail Used': { value: quota?.snailMail?.used },
              'Snail Mail Add-On': { value: quota?.snailMail?.addOn },
            }}
          />,
        ]}
      />

      <ConfirmModal
        open={confirmModalOpen}
        prompt="Permanently delete this user and all associated data?"
        onClose={() => setConfirmModalOpen(false)}
        onAccept={() => {
          setOperationType('delete');
          setOperationKey(userId);
        }}
      />
      <Modal
        title={`Update ${editUserField?.fieldInfo.label}`}
        open={updateFieldModalOpen}
        onClose={handleOnModalClose}
        additionalProps={{
          fullWidth: true,
          maxWidth: 'sm',
        }}
        noActions
      >
        <UserFieldForm
          {...(editUserField || { fieldInfo: { label: 'Name', name: 'name' } })}
          onSubmit={handleOnSubmit}
          onCancel={handleOnModalClose}
        />
      </Modal>
      <CancelSubscriptionModal
        open={confirmCancelModalOpen}
        onClose={handleOnCancelModalClose}
        userName={userName}
        subscriptionEndTS={smbSubscription?.current_period_ends_at}
        onSubmit={handleOnCancelSubscription}
      />
    </ViewFrame>
  );
};
