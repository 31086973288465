import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Tip } from 'components/Tip';
import { getTabStrings } from 'utils/contentTabs';
import { selectCurrentLookupTab, setContentIndex, setContentSubIndex } from './appViewSlice';

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

interface TabRowProps {
  names: string[];
  tips: string[];
  selected: number;
  onChange: (index: number) => void;
  tooltipsOnTop?: boolean;
}

function TabRow({ names, tips, selected, onChange, tooltipsOnTop }: TabRowProps): JSX.Element {
  if (!names.length) {
    return <></>;
  }

  return (
    <Tabs
      variant="fullWidth"
      value={selected}
      onChange={(_, index) => onChange(index)}
      aria-label="content categories"
      textColor="primary"
      indicatorColor="primary"
      sx={{ borderBottom: (theme) => `2px solid ${theme.palette.divider}` }}
    >
      {names.map((name, index) => (
        <Tip
          key={name}
          title={tips[index] ?? ''}
          arrow
          placement={tooltipsOnTop ? 'top' : 'bottom'}
        >
          <Tab label={name} value={index} sx={{ p: 0.5 }} {...a11yProps(index)} />
        </Tip>
      ))}
    </Tabs>
  );
}

interface ContentTabsProps {
  onClick?: () => void;
}

/**
 * The tabs to select different content aspects of the selected lookup
 */
export const ContentTabs = ({ onClick }: ContentTabsProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const lookup = useAppSelector(selectCurrentLookupTab);
  const { names, tips, subNames, subTips } = getTabStrings(lookup);
  const contentIndex = lookup.contentIndex ?? 0;
  const contentSubIndex = lookup.contentSubIndices?.[contentIndex] ?? 0;

  return (
    <>
      <TabRow
        names={names}
        tips={tips}
        selected={contentIndex}
        onChange={(index) => {
          dispatch(setContentIndex(index));
          if (onClick) onClick();
        }}
        tooltipsOnTop={!!subNames.length}
      />
      <TabRow
        names={subNames}
        tips={subTips}
        selected={contentSubIndex}
        onChange={(index) => {
          dispatch(setContentSubIndex(index));
          if (onClick) onClick();
        }}
      />
    </>
  );
};
